import React, { useContext } from 'react';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';
import SendedFormIcon from '../SendedFormIcon';
import useTranslate from '../../customHooks/useTranslate';

const ServiceDeskStep4 = () => {
    const { inputsData } = useContext(ContextServiceDesk);
    const { Code } = inputsData;

    return (
        <div className='step-content__container'>
            <div className='sended-form__container'>
                <SendedFormIcon />
                <div>
                    <h3 className='sended-form__title'>
                        {useTranslate('thank_you_support_ticket')}
                    </h3>
                    <p className=''>{useTranslate('we_will_check')}</p>
                    <p className=''>
                        {`${useTranslate('reference_number')}`}
                        <span className='font-weight-bold'>{Code}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ServiceDeskStep4;
