import {
    greenSelected,
    jungleGreen,
    greenSelectedDark,
} from '../../../../stylesheets/variables.scss';
import { makeStyles } from '@material-ui/core/styles';

const selectStyles = {
    container: {
        root: {
            width: '100%',
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: jungleGreen,
                },
                '&.Mui-focused fieldset': {
                    borderColor: jungleGreen,
                },
            },
        },
    },
    label: {
        root: {
            top: `-7px !important`,
        },
        filled: {
            top: `0px !important`,
        },
        focused: {
            color: `${jungleGreen} !important`,
            top: `0px !important`,
        },
    },
    menuItem: {
        root: {
            '&.Mui-selected': {
                backgroundColor: `${greenSelected} !important`,
            },
            '&.Mui-selected:focus': {
                backgroundColor: greenSelected,
            },
            '&.Mui-selected:hover': {
                backgroundColor: `${greenSelectedDark} !important`,
            },
        },
    },
};

export const selectClassNames = () => {
    return {
        container: makeStyles(selectStyles.container)().root,
        label: makeStyles(selectStyles.label)(),
        menuItem: makeStyles(selectStyles.menuItem)(),
    };
};
