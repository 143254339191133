import React from 'react';
import { childIconColor as green } from '../ariHelpers';

const IconChild = ({ ...restProps }) => {
    return (
        <i
            className='fas fa-child'
            style={{
                lineHeight: '17px',
                color: green,
                fontSize: 18,
                display: 'flex',
                justifyContent: 'center',
                width: 15,
            }}
            {...restProps}
        />
    );
};
export default IconChild;
