import React, { useContext, useEffect } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
import SwichInput from '../../components/SwitchInput';

export default function MainRoomInfo() {
    const { translate } = useContext(ContextUserProfile);
    const { singleRoomData, handleInputsRoomInfo, trimValueOnBlur } =
        useContext(ContextOwnerPortal);
    const { FreeBaby1, AdultOnly } = singleRoomData;

    useEffect(() => {
        if (!FreeBaby1) {
            handleInputsRoomInfo({
                target: { name: 'FreeBaby2', value: false },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FreeBaby1]);

    useEffect(() => {
        if (AdultOnly) {
            handleInputsRoomInfo({
                target: { name: 'FreeBaby1', value: false },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AdultOnly]);

    return (
        <div className='your-properties-form'>
            {/* <h1 className='your-properties-title'>
                {translate('main_room_information')}
            </h1> */}
            <div className='d-flex justify-content-md-between form-group input-container'>
                <div className='col-8 p-0'>
                    <label htmlFor='Name'>{translate('room_name')}</label>
                    <input
                        name='Name'
                        type='text'
                        placeholder={singleRoomData.Name}
                        className='form-control'
                        value={singleRoomData.Name}
                        title={translate('room_name')}
                        onChange={handleInputsRoomInfo}
                        onBlur={e => trimValueOnBlur(e, handleInputsRoomInfo)}
                    />
                </div>
                <div className='col-4 pr-0'>
                    <label htmlFor='Size'>{translate('square_meter')}</label>
                    <input
                        name='Size'
                        type='number'
                        placeholder='m2'
                        className='form-control'
                        value={singleRoomData.Size}
                        title={translate('square_meter')}
                        onChange={handleInputsRoomInfo}
                    />
                </div>
            </div>
            <label htmlFor='room_description'>
                {translate('rooms_descriptions')}
            </label>
            <textarea
                id='room_description'
                name='Description'
                className='textarea-descriptionCard mb-4'
                value={singleRoomData.Description}
                onChange={handleInputsRoomInfo}
                onBlur={e => trimValueOnBlur(e, handleInputsRoomInfo)}
            />
            <div className='mb-4'>
                <SwichInput
                    checked={singleRoomData.AdultOnly}
                    handleChange={handleInputsRoomInfo}
                    data={{
                        name: 'AdultOnly',
                        title: translate('adults_only'),
                    }}
                />
            </div>
            {!singleRoomData?.AdultOnly && (
                <div className='container-switch_mainRoom d-flex justify-content-between mb-4'>
                    <SwichInput
                        checked={singleRoomData.FreeBaby1}
                        handleChange={handleInputsRoomInfo}
                        data={{
                            name: 'FreeBaby1',
                            title: 'free_baby',
                        }}
                    />
                    {singleRoomData?.FreeBaby1 && (
                        <SwichInput
                            checked={singleRoomData.FreeBaby2}
                            handleChange={handleInputsRoomInfo}
                            data={{
                                name: 'FreeBaby2',
                                title: 'free_baby_2',
                            }}
                        />
                    )}
                </div>
            )}
            <div className='d-flex justify-content-end'>
                <GeneralButton type='next' text={translate('continue')} />
            </div>
        </div>
    );
}
