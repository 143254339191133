import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircleIcon from '@mui/icons-material/Circle';
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { grey950, grey400 } from '../stylesheets/variables.scss';

const Steps = props => {
    const { stepsCount, step, setStep, disabledSteps } = props;

    const steps = [...Array(stepsCount).keys()].map(
        stepNumber => stepNumber + 1
    );

    const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                border: `0.15rem solid ${theme.palette.grey950}`,
                transition:
                    'border-color 3000ms cubic-bezier(0.4, 0, 0.2, 1) 0m',
            },
        },
    }));

    const StyledStepLabel = styled(StepLabel)(() => ({
        '.MuiStepLabel-iconContainer': {
            padding: 0,
        },
    }));

    const StepIcon = props => {
        const { icon: stepNumber } = props;
        const active = stepNumber <= step;

        return (
            <CircleIcon
                style={{
                    width: stepNumber === step ? '12px' : '10px',
                    height: stepNumber === step ? '12px' : '10px',
                    color: active && !disabledSteps ? grey950 : grey400,
                    cursor: active && !disabledSteps ? 'pointer' : 'auto',
                    margin: 0,
                }}
                onClick={() => active && !disabledSteps && setStep(stepNumber)}
            />
        );
    };

    return (
        <div className='steps__container'>
            <Stepper
                activeStep={step}
                connector={<StyledStepConnector disabled />}
            >
                {steps.map(key => {
                    return (
                        <Step key={key}>
                            <StyledStepLabel
                                StepIconComponent={StepIcon}
                                key={key}
                            />
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
};

export default Steps;
