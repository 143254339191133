import React, { useContext, useState, useEffect } from 'react';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import Button from '@mui/material/Button';
import api, { cachedApi } from '../../services/api';
import { validateForm } from '../../utils/validateForms';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import InputFile from '../../common/components/inputs/InputFile';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';

const ServiceDeskStep3 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { setErrorMessage } = useContext(NotificationsContext);
    const { setIsFetching, inputsData, setInputsData, setStep } =
        useContext(ContextServiceDesk);
    const { CheckOutDate, CheckInDate } = inputsData;
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);

    const [issueTypesOptions, setIssueTypesOptions] = useState([]);
    const [supportTicketScopesOptions, setSupportTicketScopesOptions] =
        useState([]);
    const [supportTicketPrioritiesOptions, setSupportTicketPrioritiesOptions] =
        useState([]);

    const [fileFeedback, setFileFeedback] = useState('');

    const fieldsToValidate = {
        IdIssueType: {
            value: inputsData.IdIssueType,
            validators: ['requiredIdEntity'],
        },
        IdSupportTicketScope: {
            value: inputsData.IdSupportTicketScope,
            validators: ['requiredIdEntity'],
        },
        IdSupportTicketPriority: {
            value: inputsData.IdSupportTicketPriority,
            validators: ['requiredIdEntity'],
        },
        Comments: {
            value: inputsData.Comments,
            validators: ['requiredString'],
        },
    };

    useEffect(() => {
        const getTypeaheadsData = async () => {
            try {
                let issueTypesResponse = await cachedApi(
                    publicEndpoints.customerServiceFormIssueTypes
                );
                issueTypesResponse = issueTypesResponse.data.Result;
                let supportTicketScopesResponse = await cachedApi(
                    publicEndpoints.customerServiceFormSupportTicketScopes
                );
                supportTicketScopesResponse =
                    supportTicketScopesResponse.data.Result;
                let supportTicketPrioritiesResponse = await cachedApi(
                    publicEndpoints.customerServiceFormSupportTicketPriorities
                );
                supportTicketPrioritiesResponse =
                    supportTicketPrioritiesResponse.data.Result;

                const todayDay = new Date().getDate();
                const todayMonth = new Date().getMonth();
                const todayYear = new Date().getFullYear();
                const today = new Date(
                    todayYear,
                    todayMonth,
                    todayDay,
                    0,
                    0,
                    0
                );
                const twoDaysAfter = new Date(
                    todayYear,
                    todayMonth,
                    todayDay + 2,
                    0,
                    0,
                    0
                );

                if (CheckInDate && CheckOutDate) {
                    if (CheckOutDate < today) {
                        supportTicketScopesResponse =
                            supportTicketScopesResponse.filter(
                                item => item.Id === '3'
                            );
                        supportTicketPrioritiesResponse =
                            supportTicketPrioritiesResponse.filter(
                                item => item.Id !== '4'
                            );
                    } else if (CheckInDate <= today && CheckOutDate >= today) {
                        supportTicketScopesResponse =
                            supportTicketScopesResponse.filter(
                                item => item.Id === '2'
                            );
                    } else if (CheckInDate >= twoDaysAfter) {
                        supportTicketScopesResponse =
                            supportTicketScopesResponse.filter(
                                item => item.Id === '1'
                            );
                        supportTicketPrioritiesResponse =
                            supportTicketPrioritiesResponse.filter(
                                item => item.Id !== '4'
                            );
                    }
                }
                setIssueTypesOptions(issueTypesResponse);
                setSupportTicketScopesOptions(supportTicketScopesResponse);
                setSupportTicketPrioritiesOptions(
                    supportTicketPrioritiesResponse
                );
            } catch (error) {
                setErrorMessage('something_went_wrong');
            }
        };

        if (
            !issueTypesOptions.length &&
            !supportTicketScopesOptions.length &&
            !supportTicketPrioritiesOptions.length
        ) {
            getTypeaheadsData();
        }
    }, [
        CheckInDate,
        CheckOutDate,
        setErrorMessage,
        issueTypesOptions,
        supportTicketScopesOptions,
        supportTicketPrioritiesOptions,
    ]);

    const handleSend = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            const arefilesUploaded = inputsData.AttachmentFiles.length;
            let newState = { ...inputsData };
            try {
                if (arefilesUploaded) {
                    const files = new FormData();
                    // eslint-disable-next-line no-restricted-syntax
                    for (const file of inputsData.AttachmentFiles) {
                        files.append('', file);
                    }
                    setIsFetching(true);
                    const {
                        data: { Result },
                    } = await api.post(
                        publicEndpoints.customerServiceFormAttachments,
                        files
                    );
                    const dataAttachment = Result;
                    const arrayIdAttachment = [];
                    for (const file of dataAttachment.files) {
                        const Id = parseInt(file.Id);
                        arrayIdAttachment.push(Id);
                    }
                    newState = {
                        ...newState,
                        AttachmentFiles: arrayIdAttachment,
                    };
                    setInputsData(newState);
                }
                setIsFetching(true);
                const { data: dataAdded } = await api
                    .post(
                        publicEndpoints.customerService_addAttachedFiles,
                        newState
                    )
                    .catch(() => {
                        setIsFetching(false);
                        setErrorMessage('something_went_wrong');
                    });
                const { Code } = dataAdded.Result;
                newState = {
                    ...inputsData,
                    Code,
                };
                setInputsData(newState);
                setStep(4);
            } catch {
                setIsFetching(false);
                setErrorMessage('something_went_wrong');
            }
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const handleRemoveFile = event => {
        const { target } = event;
        const attachmentFilesFiltered = inputsData.AttachmentFiles.filter(
            item => item.id !== parseInt(target.id)
        );
        setInputsData(prevState => {
            return {
                ...prevState,
                AttachmentFiles: attachmentFilesFiltered,
            };
        });
    };

    const handleOnChange = event => {
        const fileList = event.target.files;
        handleFileList(fileList);
    };

    const handleOnDrop = event => {
        event.stopPropagation();
        event.preventDefault();
        const fileList = event.dataTransfer.files;
        handleFileList(fileList);
    };

    const handleFileList = fileList => {
        const filesArray = [];
        let lastIndex = 0;
        let newMessage = '';

        const fileExtensions = [
            // pdf
            'application/pdf',
            // doc
            'application/msword',
            // docx
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            // xlsx
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // xls
            'application/vnd.ms-excel',
            // gif
            'image/gif',
            // jpg
            'image/jpeg',
            // png
            'image/png',
        ];

        if (inputsData.AttachmentFiles.length) {
            lastIndex =
                inputsData.AttachmentFiles[
                    inputsData.AttachmentFiles.length - 1
                ].id;
        }
        // Convertimos un Filelist en un array de Files
        for (const file of fileList) {
            if (fileExtensions.includes(file.type)) {
                file.id = lastIndex + filesArray.length + 1;
                const savedFile = inputsData.AttachmentFiles.find(
                    item => item.name === file.name
                );
                if (!savedFile) {
                    filesArray.push(file);
                }
            } else {
                newMessage = `${newMessage}${file.name}, `;
            }
        }

        newMessage &&
            setFileFeedback(
                translate('file_not_acepted') + newMessage.slice(0, -2)
            );
        // De momento no vamos a guardar en caché los archivos, por eso setInputsData en lugar de saveDataCacheState
        setInputsData(prevState => {
            return {
                ...prevState,
                AttachmentFiles: [...prevState.AttachmentFiles, ...filesArray],
            };
        });
    };

    const handleTypeahead = (value, key) => {
        setInputsData(prevState => {
            return { ...prevState, [key]: value ? value.Id : '' };
        });
    };

    return (
        <div className='step-content__container'>
            <LoadedOptionsTypeahead
                labelText={'issue_type'}
                options={issueTypesOptions}
                idValue={inputsData.IdIssueType}
                handleChange={(e, newValue) => {
                    handleTypeahead(newValue, 'IdIssueType');
                }}
                errorMessage={errors.IdIssueType}
                required
            />
            <LoadedOptionsTypeahead
                labelText={'support_ticket_scope'}
                options={supportTicketScopesOptions}
                idValue={inputsData.IdSupportTicketScope}
                handleChange={(e, newValue) =>
                    handleTypeahead(newValue, 'IdSupportTicketScope')
                }
                errorMessage={errors.IdSupportTicketScope}
                required
            />
            <LoadedOptionsTypeahead
                labelText={'support_ticket_priority'}
                options={supportTicketPrioritiesOptions}
                idValue={inputsData.IdSupportTicketPriority}
                handleChange={(e, newValue) =>
                    handleTypeahead(newValue, 'IdSupportTicketPriority')
                }
                errorMessage={errors.IdSupportTicketPriority}
                required
            />
            <TextInput
                labelText='comments'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, Comments: value };
                    });
                }}
                value={inputsData.Comments}
                errorMessage={errors.Comments}
                onBlur={handleValidate}
                multiline
                rows={2}
                required
            />
            <div className=''>
                <p className='dropzone__title'>{`${translate(
                    'upload_files'
                )}: `}</p>
                <p className='invalid-text'>{fileFeedback}</p>
                {inputsData.AttachmentFiles.length > 0 && (
                    <ul className='filelist'>
                        {inputsData.AttachmentFiles.map(item => {
                            return (
                                <li key={item.id} className='filelist-item'>
                                    <i className='far fa-file mr-2 filelist-item__icon'>
                                        <i
                                            className='fas fa-times text-danger ml-5 filelist-item__remove'
                                            onClick={handleRemoveFile}
                                            id={item.id}
                                        />
                                    </i>
                                    <p className='filelist-item__text'>
                                        {item.name}
                                    </p>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <InputFile
                    onChange={handleOnChange}
                    onDrop={handleOnDrop}
                    onClick={() => setFileFeedback('')}
                    attributes={{
                        required: false,
                        placeholder: `${translate('upload_files')}`,
                        name: 'AttachmentFiles',
                    }}
                />
            </div>
            <Button
                variant='contained'
                onClick={handleSend}
                style={{ alignSelf: 'flex-end', marginTop: '50px' }}
            >
                {translate('send')}
            </Button>
        </div>
    );
};

export default ServiceDeskStep3;
