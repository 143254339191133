import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from 'react';
import { INITIAL_STATE_BOOKING_HISTORY } from '../../../../utils/bookingChartsUtils';
import { primaryBrigth } from '../../../../stylesheets/variables.scss';
import api from '../../../../services/api';
import '../../../../stylesheets/stylesComponents/bookingStatistics.scss';
import BookingStatisticsPlaceHolder from './BookingStatisticsPlaceholder';
import BookingTextStatistics from './BookingTextStatistics';
import BookingChart from './BookingChart';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { statusColors } from '../yourBookingsHelpers';
import { getYearPeriod } from '../../../../utils/formatDates';
import endpoints from '../../../../utils/commonVariables/endpoints';
import { formatCancelledAndCommited } from '../../../../utils/usefulFunctions';
import { ContextYourBookings } from '../../../contextsProperty/ContextYourBookings';
import { viewModelEntities } from '../../../../utils/commonVariables/entityKeys';
import bookingCharts_hotelOwner_currencyModel from '../../../../utils/viewModelStructures/bookingCharts_hotelOwner_currency';

function BookingStatisticsSection() {
    const { translate, language, IdHotelOwner } =
        useContext(ContextUserProfile);
    const { isChartVisible } = useContext(ContextYourBookings);

    const [bookingsData, setBookingsData] = useState([]);

    const [chartData, setChartData] = useState({
        labels: bookingsData.map(data =>
            translate(data.month)?.substring(0, 1)
        ),
        datasets: [
            {
                label: translate('Bookings'),
                data: INITIAL_STATE_BOOKING_HISTORY.map(
                    data => data.bookingCount
                ),
                backgroundColor: primaryBrigth,
            },
        ],
    });

    const [isLoadingCharts, setIsLoadingCharts] = useState(true);
    const [currency, setCurrency] = useState('');
    const componentMounted = useRef(true);

    const getBookingData = useCallback(async () => {
        const period = getYearPeriod();
        const endpoint = endpoints.bookingStats(period.start, period.end);

        try {
            const { data } = await api.get(endpoint);
            const newData = formatCancelledAndCommited(
                data,
                INITIAL_STATE_BOOKING_HISTORY
            );
            if (!componentMounted.current) return null;

            setChartData(prevState => {
                return {
                    ...prevState,
                    labels: newData.map(it =>
                        translate(it.month)?.substring(0, 1)
                    ),
                    datasets: [
                        {
                            label: 'Cancelled',
                            data: newData.map(
                                item => item.cancelledBookingCount
                            ),
                            backgroundColor: statusColors.Cancelled,
                        },
                        {
                            label: 'Commited',
                            data: newData.map(
                                item => item.commitedBookingCount
                            ),
                            backgroundColor: primaryBrigth,
                        },
                    ],
                };
            });
            setBookingsData(newData);
            setIsLoadingCharts(false);
        } catch (error) {
            console.log(error);
        }
    }, [translate]);

    const getOwnerCurrency = useCallback(async () => {
        const endpoint = endpoints.viewModel(
            viewModelEntities.HotelOwner,
            IdHotelOwner
        );

        try {
            const { data } = await api.post(
                endpoint,
                bookingCharts_hotelOwner_currencyModel
            );
            if (!componentMounted.current) return null;
            setCurrency(data.Result?.Currency?.Name);
        } catch (error) {
            console.log(error);
        }
    }, [setCurrency, IdHotelOwner]);

    useEffect(() => {
        getBookingData();
        getOwnerCurrency();
    }, [getBookingData, language, getOwnerCurrency]);

    useEffect(() => {
        return () => {
            componentMounted.current = false;
        };
    }, []);

    return (
        <div
            className={`booking-statistics__container ${
                isChartVisible ? '--expanded' : '--collapsed'
            }`}
        >
            {isLoadingCharts ? (
                <BookingStatisticsPlaceHolder />
            ) : (
                <>
                    <div>
                        <SectionTitle
                            variant='h3'
                            title={translate('booking_stats')}
                            className='booking-charts__title'
                        />
                        <p className='booking-charts__subtitle'>{`${translate(
                            'past_12_months'
                        )}`}</p>
                    </div>
                    <div className='booking-statistics__content'>
                        <BookingChart
                            chartData={chartData}
                            bookingsData={bookingsData}
                        />
                        <BookingTextStatistics
                            bookingsData={bookingsData}
                            currency={currency}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default BookingStatisticsSection;
