import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import './cookieConsent.scss';
import '../../stylesheets/stylesComponents/buttons.scss';
import routes from '../../utils/commonVariables/routes';

export default function Cookie() {
    const { translate } = useContext(ContextUserProfile);

    return (
        <CookieConsent
            disableStyles
            cookieName='cookieconsent'
            buttonText={translate('agree')}
            containerClasses='containerCookie'
            buttonClasses='btn-cookie'
        >
            {translate('these_cookies_allow_us_to_remember')}
            <Link
                className='item__cookie-link'
                style={{ fontSize: '15px' }}
                to={routes.cookieConsent}
            >
                {translate('learn_more')}
            </Link>
        </CookieConsent>
    );
}
