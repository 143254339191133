import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { Typography } from '@mui/material';

const SectionTitle = ({ title, variant = 'h1', className = '' }) => {
    const { translate } = useContext(ContextUserProfile);

    return (
        <Typography variant={variant} className={className}>
            {translate(title)}
        </Typography>
    );
};

export default SectionTitle;
