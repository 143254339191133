import React, { createContext, useState, useEffect } from 'react';

export const ContextServiceDesk = createContext();

export const ProviderServiceDesk = props => {
    const { children } = props;

    const INITIAL_STATE_INPUTS_DATA = {
        CompanyName: '',
        EmailAddress: '',
        TravellerName: '',
        BookingCode: '',
        CheckInDate: null,
        CheckOutDate: null,
        Comments: '',
        IdIssueType: '',
        IdSupportTicketScope: '',
        IdSupportTicketPriority: '',
        AttachmentFiles: [],
    };

    const stepsCount = 4;
    const [inputsData, setInputsData] = useState(INITIAL_STATE_INPUTS_DATA);
    const [isInfoVisible, setIsInfoVisible] = useState(true);
    const [step, setStep] = useState(1);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (step !== 1) {
            setIsInfoVisible(false);
        }
    }, [step]);
    return (
        <ContextServiceDesk.Provider
            value={{
                isInfoVisible,
                setIsInfoVisible,
                step,
                setStep,
                isFetching,
                setIsFetching,
                inputsData,
                setInputsData,
                stepsCount,
            }}
        >
            {children}
        </ContextServiceDesk.Provider>
    );
};
