import React, { useContext, useState } from 'react';
import { ContextFastCheckIn } from '../../contexts/FastCheckInContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import SwitchInput from '../../muiComponents/switchInput/SwitchInput';
import Button from '@mui/material/Button';
import { regexBookingCode } from '../../utils/regex';
import { CircularProgress } from '@mui/material';

const FastCheckInStep1 = () => {
    const { translate } = useContext(ContextUserProfile);
    const {
        inputsData,
        setInputsData,
        handleCheckIn,
        handleNotConfirm,
        bookingCodeError,
        isFetching,
    } = useContext(ContextFastCheckIn);

    const [errors, setErrors] = useState({});

    const addError = (name, message) => {
        setErrors(prevState => {
            return {
                ...prevState,
                [name]: message,
            };
        });
    };

    const deleteError = name => {
        const updateErros = { ...errors };
        delete updateErros[name];
        setErrors(updateErros);
    };

    const handleValidateBookingCodeInput = e => {
        const { value, name } = e.target;
        const regexp = new RegExp(regexBookingCode);
        const isValid = regexp.test(value);

        if (isValid) {
            deleteError(name);
        } else {
            addError(name, `${translate('please_fill_booking_code')}`);
        }
    };

    const isErrorInObject = name => {
        return Object.prototype.hasOwnProperty.call(errors, name);
    };

    const handleValidateForm = key => {
        let numberOfErrors = 0;
        //si el toggle esta desactivado, significa que sí se tiene reserva interna
        if (!inputsData.HotelReferenceNotInformed) {
            if (inputsData.HotelReference === '') {
                numberOfErrors += 1;
                addError(
                    'HotelReference',
                    `${translate('internel_booking_number')}`
                );
            }
        } else {
            if (isErrorInObject('HotelReference')) {
                numberOfErrors -= 1;
                deleteError('HotelReference');
            }
        }

        if (inputsData.BookingCode === '') {
            numberOfErrors += 1;
            addError('BookingCode', `${translate('please_fill_booking_code')}`);
        } else {
            if (isErrorInObject('BookingCode')) {
                numberOfErrors -= 1;
                deleteError('BookingCode');
            }
        }

        if (numberOfErrors <= 0) {
            if (key === 'confirmed') {
                handleCheckIn();
            } else {
                handleNotConfirm();
            }
        }
    };

    const handleInputChange = (name, value) => {
        setInputsData(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleSwitchChange = e => {
        const checked = e.target.checked;
        e.persist();
        handleInputChange('HotelReferenceNotInformed', !e.target.checked);
        if (!checked) {
            handleInputChange('HotelReference', '');
        }
    };

    const label = inputsData.HotelReferenceNotInformed
        ? `${translate('no_internal_booking_number')}`
        : `${translate('yes_internal_booking_number')}`;

    const handleValidateHotelReference = e => {
        const { value, name } = e.target;
        if (value === '') {
            addError(name, `${translate('internel_booking_number')}`);
        } else {
            deleteError(name);
        }
    };

    return (
        <div className='step-content__container'>
            <SwitchInput
                name='HotelReferenceNotInformed'
                labelText={label}
                onChangeMethod={handleSwitchChange}
                checked={!inputsData.HotelReferenceNotInformed}
                style={{ marginBottom: 16 }}
            />
            {!inputsData.HotelReferenceNotInformed && (
                <TextInput
                    name='HotelReference'
                    labelText='property_internal_booking_number'
                    handleChange={(e, value) => {
                        handleInputChange('HotelReference', value);
                    }}
                    value={inputsData.HotelReference}
                    errorMessage={errors.HotelReference}
                    onBlur={handleValidateHotelReference}
                    required
                />
            )}
            <TextInput
                name='BookingCode'
                labelText='booking_code'
                handleChange={(e, value) => {
                    handleInputChange('BookingCode', value);
                }}
                value={inputsData.BookingCode}
                errorMessage={errors.BookingCode || bookingCodeError}
                onBlur={handleValidateBookingCodeInput}
                required
            />
            <div className='fast-checkin__buttonBar'>
                <Button
                    variant='contained'
                    onClick={() => handleValidateForm('confirmed')}
                    style={{ alignSelf: 'flex-end' }}
                >
                    {isFetching.key === 'confirmed' && isFetching.value ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>{translate('validate')}</>
                    )}
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleValidateForm('unConfirmed')}
                    style={{ alignSelf: 'flex-end' }}
                >
                    {isFetching.key === 'unConfirmed' && isFetching.value ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>{translate('i_have_an_issue')}</>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default FastCheckInStep1;
