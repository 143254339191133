import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { decodeBase64 } from '../../../../utils/encodeBase64';
import CardRoom from '../../components/CardRoom';
import Loading from '../../../../common/components/Loading';
import api from '../../../../services/api';
import convertToOvariant from '../../../../utils/convertToOvariant';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { capitalize } from '../../../../utils/usefulFunctions';
import LoadingForButtons from '../../components/LoadingForButtons';
import endpoints from '../../../../utils/commonVariables/endpoints';
import { savableEntities } from '../../../../utils/commonVariables/entityKeys';
import { newRoomInitialState } from '../../../../utils/viewModelStructures/SupplierHotelRoomType';
import { NotificationsContext } from '../../../../contexts/NotificationsContext';

function RoomsConfigurationList(props) {
    const { history, match } = props;
    const { translate } = useContext(ContextUserProfile);
    const { idEncode } = match.params;
    const IdDecoded = decodeBase64(idEncode);
    const {
        getSupplierHotelRooms,
        supplierHotelRooms,
        IdHotel,
        isFetching,
        setIsFetching,
        progress,
        setProgress,
        createRoom,
        setSingleRoomData,
    } = useContext(ContextOwnerPortal);
    const { setErrorMessage } = useContext(NotificationsContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [isCreateNewRoomLoading, setIsCreateNewRoomLoading] = useState(false);

    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                stepName: 'room_configuration',
                tabName: '',
            },
        });
        setIsFetching(true);
        getSupplierHotelRooms(IdDecoded);
        return () => {
            if (isFetching) {
                setIsFetching(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputs = e => {
        setSearchTerm(e.currentTarget.value);
    };

    async function handleCreateNewRoom() {
        setIsCreateNewRoomLoading(true);
        const newRoom = newRoomInitialState;
        newRoom.IdSupplierHotel = IdHotel;
        const objectToSend = convertToOvariant(
            savableEntities.SupplierHotelRoomType,
            newRoom,
            '-1'
        );
        try {
            const responseSave = await api.post(
                endpoints.commonSave,
                objectToSend
            );
            const newRoomToAdd = {
                Id: responseSave.data.Id,
                Name: responseSave.data.Name,
                __isAdded: true,
                __isChanged: true,
            };
            createRoom(newRoomToAdd);
            setSingleRoomData(responseSave.data);
            history.push(`inventory/new`);
        } catch (error) {
            setErrorMessage('something_went_wrong');
        }
        setIsCreateNewRoomLoading(false);
    }

    return (
        <div className='your-properties-form roomsConfiguration-container'>
            <h1 className='your-properties-title'>
                {translate('rooms_available')}
            </h1>
            {isFetching ? (
                <Loading />
            ) : (
                <>
                    <div className='search_add--bar '>
                        {supplierHotelRooms?.length > 0 && (
                            <input
                                type='text'
                                placeholder={translate('search_room')}
                                className='form-control search-room__input'
                                onChange={handleInputs}
                                value={searchTerm}
                            />
                        )}
                        <button
                            type='button'
                            className='btn btn-outline-success w-auto'
                            onClick={handleCreateNewRoom}
                            disabled={isCreateNewRoomLoading}
                        >
                            {isCreateNewRoomLoading ? (
                                <LoadingForButtons />
                            ) : (
                                translate('add_room')
                            )}
                        </button>
                    </div>
                    <div className='description-cards-room'>
                        {supplierHotelRooms
                            ?.filter(card =>
                                card.Name.toLowerCase().includes(
                                    searchTerm.toLowerCase()
                                )
                            )
                            .map(room => {
                                const { Id, Name, Url } = room;
                                return (
                                    <div key={Id}>
                                        <CardRoom
                                            RoomName={capitalize(Name)}
                                            Imagen={Url}
                                            Id={Id}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </>
            )}
        </div>
    );
}

export default withRouter(RoomsConfigurationList);
