import React, { useContext, useRef, useState } from 'react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import { validateForm } from '../../utils/validateForms';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { ContextSettings } from '../../contexts/SettingsContext';
import { Grid } from '@mui/material';
import { languagesMenu } from '../../utils/menusData';
import { findIana } from 'windows-iana';

const EditionContent = () => {
    const {
        timeZoneOptions,
        setIsEditionMode,
        selectedTimezone,
        setIsLoading,
    } = useContext(ContextSettings);
    const { settings, setSettings, setTimeZone, setLanguage, saveSettings } =
        useContext(ContextUserProfile);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const initialState = useRef({ ...settings });

    const fieldsToValidate = {
        language: { value: settings.language, validators: ['requiredString'] },
        timeZone: {
            value: settings.timeZone,
            validators: ['requiredString'],
        },
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const handleSend = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            setIsLoading(true);
            await saveSettings({ ...settings }, initialState.current);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Grid container rowSpacing={{ xs: 1, md: 4 }} columns={1}>
                <Grid item xs={1}>
                    <LoadedOptionsTypeahead
                        labelText={'language'}
                        errorMessage={errors.language}
                        id={'language'}
                        options={languagesMenu}
                        optionsLabelKey={'name'}
                        idValue={
                            languagesMenu.find(
                                item => item.text === settings.language
                            ).Id
                        }
                        handleChange={(e, value) => {
                            if (value) {
                                setLanguage(value.text);
                            }
                        }}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={1}>
                    <LoadedOptionsTypeahead
                        labelText={'time_zone'}
                        id={'timeZone'}
                        options={timeZoneOptions}
                        idValue={selectedTimezone.Id}
                        optionsLabelKey={'DisplayName'}
                        handleChange={(e, value) => {
                            const [IANA] = findIana(value?.StandardName);
                            if (IANA) {
                                setTimeZone(IANA);
                            }
                        }}
                        onBlur={handleValidate}
                        errorMessage={errors.timeZone}
                        required
                    />
                </Grid>
            </Grid>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                    variant='outlined'
                    onClick={() => {
                        setIsEditionMode(false);
                        setSettings(initialState.current);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={async () => {
                        await handleSend();
                        setIsEditionMode(false);
                    }}
                >
                    Update
                </Button>
            </CardActions>
        </>
    );
};

export default EditionContent;
