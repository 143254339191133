import React, { useContext, useState } from 'react';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import DateInput from '../../muiComponents/DateInput';
import Button from '@mui/material/Button';
import { validateForm } from '../../utils/validateForms';

const ServiceDeskSteps2 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { inputsData, setInputsData, setStep } =
        useContext(ContextServiceDesk);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);

    const fieldsToValidate = {
        CompanyName: {
            value: inputsData.CompanyName,
            validators: ['requiredString'],
        },
        EmailAddress: {
            value: inputsData.EmailAddress,
            validators: ['requiredString', 'email'],
        },
        TravellerName: {
            value: inputsData.TravellerName,
            validators: ['requiredString'],
        },
        CheckInDate: {
            value: inputsData.CheckInDate,
            validators: ['requiredDate'],
        },
        CheckOutDate: {
            value: inputsData.CheckOutDate,
            validators: ['requiredDate'],
        },
    };

    const handleNext = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            await setStep(3);
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    return (
        <div className='step-content__container'>
            <TextInput
                labelText='company_name'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, CompanyName: value };
                    });
                }}
                value={inputsData.CompanyName}
                errorMessage={errors.CompanyName}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='email'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, EmailAddress: value };
                    });
                }}
                value={inputsData.EmailAddress}
                errorMessage={errors.EmailAddress}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='traveller_name'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, TravellerName: value };
                    });
                }}
                value={inputsData.TravellerName}
                errorMessage={errors.TravellerName}
                onBlur={handleValidate}
                required
            />
            <div style={{ display: 'flex', gap: '20px' }}>
                <DateInput
                    labelText='check_in_date'
                    value={inputsData.CheckInDate}
                    onChange={value => {
                        setInputsData(prevState => {
                            return { ...prevState, CheckInDate: value };
                        });
                        handleValidate();
                    }}
                    errorMessage={errors.CheckInDate}
                    onBlur={handleValidate}
                    required
                />
                <DateInput
                    labelText='check_out_date'
                    value={inputsData.CheckOutDate}
                    onChange={value => {
                        setInputsData(prevState => {
                            return { ...prevState, CheckOutDate: value };
                        });
                        handleValidate();
                    }}
                    errorMessage={errors.CheckOutDate}
                    onBlur={handleValidate}
                    shouldDisableDate={date => date < inputsData.CheckInDate}
                    required
                />
            </div>
            <Button
                variant='contained'
                onClick={handleNext}
                style={{ alignSelf: 'flex-end', marginTop: '50px' }}
            >
                {translate('next')}
            </Button>
        </div>
    );
};

export default ServiceDeskSteps2;
