export const encodeFormUrl = objectInfo => {
    let details = {
        grant_type: 'password',
    };
    details = { ...details, ...objectInfo };
    let formBody = [];
    for (const property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    }
    return (formBody = formBody.join('&'));
};
