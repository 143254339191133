import React, { createContext, useState, useEffect } from 'react';

export const ContextSignUp = createContext();

export const ProviderSignUp = props => {
    const { children } = props;

    const INITIAL_STATE_INPUTS_DATA = {
        ContactPerson: '',
        ContactEmail: '',
        TaxReference: '',
        ContactPhone: '',
        IdHotel: null,
        HotelName: '',
        IdOwnerType: null,
        OwnerType: '',
        IdCountry: 0,
        Country: '',
        IdCity: null,
        City: '',
        IdAvailableSupplierChannel: 0,
        AvailableSupplierChannel: '',
        CustomSupplierChannel: '',
    };

    const stepsCount = 5;
    const [inputsData, setInputsData] = useState(INITIAL_STATE_INPUTS_DATA);
    const [isInfoVisible, setIsInfoVisible] = useState(true);
    const [step, setStep] = useState(1);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (step !== 1) {
            setIsInfoVisible(false);
        }
    }, [step]);

    //hotels typeahead data
    const [hotelInputValue, setHotelInputValue] = useState('');
    const [selectedHotelOption, setSelectedHotelOption] = useState(null);
    const [hotelOptions, setHotelOptions] = useState([]);

    return (
        <ContextSignUp.Provider
            value={{
                isInfoVisible,
                setIsInfoVisible,
                step,
                setStep,
                isFetching,
                setIsFetching,
                inputsData,
                setInputsData,
                stepsCount,
                hotelInputValue,
                setHotelInputValue,
                selectedHotelOption,
                setSelectedHotelOption,
                hotelOptions,
                setHotelOptions,
            }}
        >
            {children}
        </ContextSignUp.Provider>
    );
};
