import React, { useContext, useEffect, useState } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { withRouter } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import api from '../../../../services/api';
import convertToOvariant from '../../../../utils/convertToOvariant';
import MessageInfoBar from '../../components/MessageBarInfo';
import Card1 from './Card1';
import Card2 from './Card2';
import Card3 from './Card3';
import CardPlaceholder from './CardPlaceholder';
import ImgPlaceHolder from './ImgPlaceHolder';
import ImageSection from './ImagesSection';
import { savableEntities } from '../../../../utils/commonVariables/entityKeys';
import endpoints from '../../../../utils/commonVariables/endpoints';

function GeneralDescriptionSteps({ IdEncode, history }) {
    const { translate } = useContext(ContextUserProfile);
    const {
        progress,
        setProgress,
        hotelInfo,
        setMessageBar,
        setHotelInfo,
        isFetching,
    } = useContext(ContextOwnerPortal);
    const { prefilledProgress } = progress;
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDashboard, setButtonDashboard] = useState(false);
    const isVisibleFinishStep = hotelInfo.SupplierHotelInfo.Progress === 95;
    const [msjCreated, setMsjCreated] = useState('');

    const handleSaveFinish = async () => {
        hotelInfo.SupplierHotelInfo.Progress = 100;
        const endPoint = endpoints.saveSupplierHotelFromWizard;
        const objectToSend = convertToOvariant(
            savableEntities.SupplierHotel,
            hotelInfo
        );
        setIsLoading(true);
        try {
            const response = await api.post(endPoint, objectToSend);
            if (response.status === 200) {
                if (response.data.Success) {
                    setHotelInfo(response.data.Result);
                    setMsjCreated('thank_you_for_fill_in_all');
                    setButtonDashboard(true);
                } else {
                    setMessageBar({
                        type: 'error',
                        text: 'error_save_contact_fph',
                    });
                }
            }
        } catch (error) {
            setMessageBar({
                type: 'error',
                text: 'error_save_contact_fph',
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                maxTabs: 1,
                tabName: '',
                currentTab: 1,
                stepName: '',
                maxSubTabs: 1,
                currentSubTab: 1,
                lastTabFilled: 1,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getButtonText = step => {
        if (prefilledProgress[step].percent === 0) {
            return translate('start');
        }
        if (prefilledProgress[step].percent === 100) {
            return translate('edit');
        }
        return translate('continue');
    };

    return (
        <>
            {isFetching ? (
                <div
                    className='general-description-steps__container'
                    style={{ marginTop: 72 }}
                >
                    <div className='animation__container'>
                        <ImgPlaceHolder animationDelay={0.0} />
                        <CardPlaceholder animationDelay={0.1} />
                        <CardPlaceholder animationDelay={0.2} />
                        <CardPlaceholder animationDelay={0.3} />
                    </div>
                </div>
            ) : (
                <div className='general-description-steps__container'>
                    <ImageSection />
                    <>
                        <Card1
                            Porcentage={prefilledProgress.main_info.percent}
                            textButton={getButtonText('main_info')}
                            urlButton={`/your-property/${IdEncode}/management`}
                        />
                        <Card2
                            Porcentage={
                                prefilledProgress.description.percent || 0
                            }
                            textButton={getButtonText('description')}
                            urlButton={`/your-property/${IdEncode}/public-portrait`}
                            disabled={
                                prefilledProgress.main_info.percent !== 100
                            }
                        />
                        <Card3
                            Porcentage={
                                prefilledProgress.room_configuration.percent ||
                                0
                            }
                            textButton={getButtonText('room_configuration')}
                            urlButton={`/your-property/${IdEncode}/inventory`}
                            disabled={
                                prefilledProgress.main_info.percent !== 100
                            }
                        />
                        {isVisibleFinishStep && (
                            <div className='card-steps mb-4 shadow'>
                                <div className='d-flex flex-row'>
                                    <div style={{ width: '100%' }}>
                                        <div className='iconNtext__container'>
                                            <i
                                                className='fas fa-paper-plane iconNtext__icon'
                                                style={{ fontSize: '24px' }}
                                            />
                                            <p className='iconNtext__text-md'>
                                                {translate('complete_process')}
                                            </p>
                                        </div>
                                        <p className='card-text mb-2'>
                                            {translate(
                                                'you_can_now_send_us_your_hotel_details'
                                            )}
                                        </p>
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-outline-success button--finish'
                                        style={{ height: 38 }}
                                        onClick={handleSaveFinish}
                                        disabled={isLoading}
                                    >
                                        {isLoading && (
                                            <Spinner
                                                animation='border'
                                                size='sm'
                                                className='mr-1'
                                            />
                                        )}
                                        {translate('submit')}
                                    </button>
                                </div>
                            </div>
                        )}
                        {buttonDashboard ? (
                            <div>
                                <div className='text-success-save'>
                                    {msjCreated && (
                                        <>
                                            <div
                                                style={{ color: 'green' }}
                                            >{`${translate(
                                                'thank_you_for_fill_in_all'
                                            )}`}</div>
                                            <div
                                                style={{ color: 'green' }}
                                            >{`${translate(
                                                'out_team_will'
                                            )}`}</div>
                                        </>
                                    )}
                                </div>
                                <button
                                    type='button'
                                    className='btn btn_general  btn-outline-success ml-3 mb-4'
                                    onClick={() => {
                                        history.push(
                                            '/owner-portal/your-properties'
                                        );
                                    }}
                                >
                                    {translate('go_to_profile')}
                                </button>
                            </div>
                        ) : (
                            <div className='ml-3'>
                                <MessageInfoBar />
                            </div>
                        )}
                    </>
                </div>
            )}
        </>
    );
}

export default withRouter(GeneralDescriptionSteps);
