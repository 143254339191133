import React, { useContext } from 'react';
import { ContextPaymentTerms } from '../../contexts/PaymentTermsContext';
import NonEditionContent from './NonEditionContent';
import EditionContent from './EditionContent';
import CardTitle from '../components/CardTitle';

const PaymentCard = props => {
    const { paymentData } = props;
    const { openedCard, setEditButtonVisible } =
        useContext(ContextPaymentTerms);

    const isThisCardOpened = openedCard === paymentData.Id;

    const renderContent = () => {
        if (!openedCard) {
            //ninguna cardEstá abierta
            return <NonEditionContent paymentProfile={paymentData} />;
        } else if (openedCard && isThisCardOpened) {
            // la tarjeta que está abierta es esta
            return <EditionContent paymentProfile={paymentData} />;
        } else {
            // hay OTRA tarjeta abierta
            return (
                <CardTitle
                    isisEditButtonVisible={false}
                    title={paymentData.Name}
                />
            );
        }
    };

    return (
        <div
            className='card'
            onMouseEnter={() => setEditButtonVisible(paymentData.Id)}
            onMouseLeave={() => setEditButtonVisible('')}
        >
            {renderContent()}
        </div>
    );
};

export default PaymentCard;
