import React, { useContext } from 'react';
import { ContextTermsAndConditions } from '../../../contexts/TermsAndConditionsContext';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import TextInput from '../../../muiComponents/TextInput/TextInput';

const CustomerServiceTermsAndConditions = () => {
    const {
        formTermsAndConditions,
        handleChange,
        handleValidate,
        errors,
        setIsValidated,
        isReadOnly,
    } = useContext(ContextTermsAndConditions);
    const { translate } = useContext(ContextUserProfile);

    const handleValidateField = () => {
        setIsValidated(true);
        handleValidate();
    };

    return (
        <>
            <div className='form__container--tac'>
                <p>General Contact</p>
                <TextInput
                    labelText={translate('general_contact_point')}
                    handleChange={e => handleChange(e)}
                    value={formTermsAndConditions.generalContactPoint || ''}
                    errorMessage={errors.GeneralContactPoint}
                    onBlur={handleValidateField}
                    name='generalContactPoint'
                    id='generalContactPoint'
                    required
                    inputProps={{
                        readOnly: isReadOnly,
                        disabled: isReadOnly,
                    }}
                    deleteIcon={!isReadOnly}
                />
                <TextInput
                    labelText={translate('email_address')}
                    handleChange={e => handleChange(e)}
                    value={formTermsAndConditions.emailAddressCustomer || ''}
                    errorMessage={errors.EmailAddressCustomer}
                    onBlur={handleValidateField}
                    name='emailAddressCustomer'
                    id='emailAddressCustomer'
                    required
                    inputProps={{
                        readOnly: isReadOnly,
                        disabled: isReadOnly,
                    }}
                    deleteIcon={!isReadOnly}
                />
                <TextInput
                    labelText={translate('estimated_response_time')}
                    handleChange={e => handleChange(e)}
                    value={formTermsAndConditions.estimatedResponseTime || ''}
                    errorMessage={errors.EstimatedResponseTime}
                    onBlur={handleValidateField}
                    name='estimatedResponseTime'
                    id='estimatedResponseTime'
                    required
                    inputProps={{
                        readOnly: isReadOnly,
                        disabled: isReadOnly,
                    }}
                    deleteIcon={!isReadOnly}
                />
            </div>
            <div className='form__container--tac'>
                <p>Escalation Contact</p>
                <TextInput
                    labelText={translate('escalation_contact_person')}
                    handleChange={e => handleChange(e)}
                    value={formTermsAndConditions.escalationContactPerson || ''}
                    errorMessage={errors.EscalationContactPerson}
                    onBlur={handleValidateField}
                    name='escalationContactPerson'
                    id='escalationContactPerson'
                    required
                    inputProps={{
                        readOnly: isReadOnly,
                        disabled: isReadOnly,
                    }}
                    deleteIcon={!isReadOnly}
                />
                <TextInput
                    labelText={translate('escalation_person_email')}
                    handleChange={e => handleChange(e)}
                    value={formTermsAndConditions.escalationPersonEmail || ''}
                    errorMessage={errors.EscalationPersonEmail}
                    onBlur={handleValidateField}
                    name='escalationPersonEmail'
                    id='escalationPersonEmail'
                    required
                    inputProps={{
                        readOnly: isReadOnly,
                        disabled: isReadOnly,
                    }}
                    deleteIcon={!isReadOnly}
                />
                <TextInput
                    labelText={translate('emergency_phone_number')}
                    handleChange={e => handleChange(e)}
                    value={formTermsAndConditions.emergencyPhoneNumber || ''}
                    errorMessage={errors.EmergencyPhoneNumber}
                    onBlur={handleValidateField}
                    name='emergencyPhoneNumber'
                    id='emergencyPhoneNumber'
                    required
                    inputProps={{
                        readOnly: isReadOnly,
                        disabled: isReadOnly,
                    }}
                    deleteIcon={!isReadOnly}
                />
            </div>
        </>
    );
};

export default CustomerServiceTermsAndConditions;
