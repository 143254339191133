import React, { useContext, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import {
    getLosValue,
    getQualityStatus,
    numColumns,
} from '../../formsProperty/Ari/ariHelpers';
import DetailCard from './DetailCard/DetailCard';
import Collapse from '@mui/material/Collapse';
import { formatUTCDateTime, formatDate } from '../../../utils/formatDates';
import Icon from '../../formsProperty/Ari/Icon';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const AriTableRow = ({ item, componentRef }) => {
    const { language } = useContext(ContextUserProfile);
    const { validity, quality } = item;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const isOpen = !isCollapsed;

    const toggleItem = () => setIsCollapsed(state => !state);
    const accordionIconName = isCollapsed
        ? 'KeyboardArrowDown'
        : 'KeyboardArrowUp';
    const qualityStatus = getQualityStatus(validity, quality);
    const { iconName: qualityIconName, iconColor: qualityIconColor } =
        qualityStatus;

    return (
        <>
            <TableRow
                ref={componentRef}
                selected={isOpen}
                hover
                style={{ cursor: 'pointer' }}
                onClick={toggleItem}
                classes={{
                    selected: 'row--selected',
                }}
            >
                <TableCell
                    style={{
                        width: 50,
                    }}
                    size='small'
                >
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        // onClick={() => setOpen(!open)}
                    >
                        <Icon iconName={accordionIconName} />
                    </IconButton>
                </TableCell>
                <TableCell
                    align='center'
                    style={{
                        width: 50,
                    }}
                >
                    <Icon
                        iconName={qualityIconName}
                        sx={{ color: qualityIconColor }}
                    />
                </TableCell>
                <TableCell>{formatDate(item.date, language)}</TableCell>
                <TableCell>{item.mapping}</TableCell>
                <TableCell>{`${item.product.room.supplierName} - ${item.product.room.mapping}`}</TableCell>
                <TableCell>{`${item.product.rate.supplierName} - ${item.product.rate.mapping}`}</TableCell>
                <TableCell>{`${item.product.meal.supplierName} - ${item.product.meal.mapping}`}</TableCell>
                <TableCell>{getLosValue(item.los)}</TableCell>
                <TableCell>
                    {formatUTCDateTime(item.lastUpdated, language)}
                </TableCell>
                <TableCell>{item.availability}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                    }}
                    colSpan={numColumns}
                >
                    <Collapse in={isOpen} timeout='auto' unmountOnExit>
                        <DetailCard item={item} qualityStatus={qualityStatus} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AriTableRow;
