import React, { useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
    jungleGreen,
    greenSelected,
    greenSelectedDark,
} from '../../../../stylesheets/variables.scss';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { capitalizeSentence } from '../../../../utils/usefulFunctions';

const SearchSelect = ({
    options,
    label,
    onChange,
    optionTextKey,
    name,
    valueKey = 'Id',
    ...restProps
}) => {
    const { translate } = useContext(ContextUserProfile);
    const useStyles = makeStyles(() => ({
        root: {
            '& label.Mui-focused': {
                color: jungleGreen,
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: jungleGreen,
                },
                '&.Mui-focused fieldset': {
                    borderColor: jungleGreen,
                },
            },
        },
        option: {
            '&[aria-selected="true"]': {
                backgroundColor: `${greenSelected} !important`,
            },
            '&[aria-selected="true"]:hover': {
                backgroundColor: `${greenSelectedDark} !important`,
            },
        },
    }));

    const classes = useStyles();

    const isOptionEqualToValue = (option, currentValue) =>
        option[valueKey] === currentValue[valueKey];

    return (
        <Autocomplete
            options={options}
            size='small'
            getOptionLabel={option => option[optionTextKey]}
            classes={classes}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={params => {
                return (
                    <TextField
                        label={capitalizeSentence(translate(label))}
                        {...params}
                        // variant='standard'
                    />
                );
            }}
            {...restProps}
            onChange={(event, ...rest) =>
                onChange(
                    {
                        ...event,
                        target: {
                            ...event.target,
                            name,
                        },
                    },
                    ...rest
                )
            }
        />
    );
};

export default SearchSelect;
