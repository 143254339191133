import React, { useContext, useState } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import TextInput from '../../../../muiComponents/TextInput/TextInputV2';
import { getValueFromObjetByComposedKey } from '../../../../utils/usefulFunctions';
import Button from '@mui/material/Button';
import { validateForm } from '../../../../utils/validateForms';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';

export default function Commercial() {
    const { translate } = useContext(ContextUserProfile);
    const {
        hotelInfo,
        handleInputsHotelInfo,
        setProgress,
        progress,
        saveActualProgress,
    } = useContext(ContextOwnerPortal);
    const { currentTab, lastTabFilled, stepName } = progress.current;
    const { Primary } = hotelInfo.SupplierHotelInfo;
    const [isValidated, setIsValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const getValue = (content, name) => {
        const savedValue = getValueFromObjetByComposedKey(content, name);
        const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const storedValue = getValueFromObjetByComposedKey(storedInfo, name);
        const value = storedValue || savedValue;
        return value;
    };

    const fieldsToValidate = {
        'MasterHotel.HotelName': {
            value: hotelInfo.MasterHotel.HotelName,
            validators: ['requiredString'],
        },
    };

    const checkValidate = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            saveActualProgress();
            setProgress({
                ...progress,
                current: {
                    ...progress.current,
                    currentTab: currentTab + 1,
                    tabName: tabsData[stepName].tabs.find(
                        item => item.tabNumber === currentTab + 1
                    ).title,
                    lastTabFilled:
                        currentTab < lastTabFilled
                            ? lastTabFilled
                            : lastTabFilled + 1,
                },
            });
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const isInputDisabled = !Primary;

    return (
        <div
            className='your-properties-formV2'
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <TextInput
                labelText='hotel_name'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.HotelName')}
                errorMessage={errors['MasterHotel.HotelName']}
                onBlur={handleValidate}
                name='MasterHotel.HotelName'
                type='text'
                required
                id='MasterHotel.HotelName'
                disabled={isInputDisabled}
                variant={isInputDisabled ? 'filled' : 'outlined'}
                error={!!errors['MasterHotel.HotelName']}
            />
            <Button
                variant='contained'
                onClick={checkValidate}
                style={{ alignSelf: 'flex-end', marginTop: '30px' }}
            >
                {translate('continue')}
            </Button>
        </div>
    );
}
