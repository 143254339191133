import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import CardList from './CardList';
import RestrictionBadge from './RestrictionBadge';

const Restrictions = ({ restrictions }) => {
    const { translate } = useContext(ContextUserProfile);
    if (!restrictions) return null;
    const { closed, cta, ctd } = restrictions;
    const badges = Object.entries({
        closed,
        open: !closed,
        cta,
        ctd,
    })
        .filter(([, isVisible]) => isVisible)
        .map(([text]) => text);

    const { maxBao, maxSaa, maxSt, minBao, minSaa, minSt, fplos } =
        restrictions;
    const formatRestriction = restriction => restriction || '-';

    const textStyles = {
        width: 35,
        textAlign: 'end',
    };

    const items = [
        <>
            <span>{translate('booking_advance_offset')}</span>
            <div style={{ display: 'flex' }}>
                <div style={textStyles}>{formatRestriction(minBao)}</div>
                <div style={textStyles}>{formatRestriction(maxBao)}</div>
            </div>
        </>,
        <>
            <span>{translate('stay_at_arrival')}</span>
            <div style={{ display: 'flex' }}>
                <div style={textStyles}>{formatRestriction(minSaa)}</div>
                <div style={textStyles}>{formatRestriction(maxSaa)}</div>
            </div>
        </>,
        <>
            <span>{translate('stay_through')}</span>
            <div style={{ display: 'flex' }}>
                <div style={textStyles}>{formatRestriction(minSt)}</div>
                <div style={textStyles}>{formatRestriction(maxSt)}</div>
            </div>
        </>,
        <>
            <div>FP Los</div>
            <div style={{ display: 'flex' }}>
                <div style={{ textAlign: 'end' }}>
                    {formatRestriction(fplos)}
                </div>
            </div>
        </>,
    ];

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    padding: '4px 8px',
                }}
            >
                <div style={{ display: 'flex', gap: 10 }}>
                    {badges.map(badge => (
                        <RestrictionBadge key={badge} text={badge} />
                    ))}
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={textStyles}>Min</div>
                    <div style={textStyles}>Max</div>
                </div>
            </div>
            <CardList items={items} />
        </div>
    );
};

export default Restrictions;
