const HotelOwner = 'HotelOwner';
const PaymentProfile = 'PaymentProfile';
const SupplierHotelRoomType = 'SupplierHotelRoomType';
const SystemUser = 'SystemUser';
const TimeZoneUtil = 'TimeZoneUtil';
const SupplierHotel = 'SupplierHotel';
const Hotel = 'Hotel';
const SupplierHotelRatePlan = 'SupplierHotelRatePlan';
const SupplierHotelMealPlan = 'SupplierHotelMealPlan';
const SupplierBooking = 'SupplierBooking';
const City = 'City';
const Country = 'Country';
const SupplierBookingStatusType = 'SupplierBookingStatusType';
const OwnerType = 'OwnerType';
const InvoiceReceivingMethodType = 'InvoiceReceivingMethodType';
const BedType = 'BedType';
const HotelType = 'HotelType';
const HotelCategory = 'HotelCategory';
const HotelChain = 'HotelChain';
const Currency = 'Currency';
const Province = 'Province';
const Region = 'Region';
const ServiceType = 'ServiceType';
const ServiceCategory = 'ServiceCategory';
const HostSegment = 'HostSegment';
const MealPlanType = 'MealPlanType';
const HotelImageType = 'HotelImageType';
const ContentLanguage = 'ContentLanguage';
const DestinationTaxType = 'DestinationTaxType';
const DestinationTaxScope = 'DestinationTaxScope';
const SupplierBookingStatus = 'SupplierBookingStatus';
const PaymentProfileHotelOwner = 'PaymentProfileHotelOwner';

export const savableEntities = {
    HotelOwner,
    PaymentProfile,
    SupplierHotelRoomType, // se guarda con commonSave Y con /api/common/deleteItems
    SystemUser,
    SupplierHotel, // no se guada con common/save pero sí con SaveSupplierHotelFromWizard
};

export const readOnlyEntities = {
    // tipologías o combos
    TimeZoneUtil,
    City,
    SupplierBookingStatusType,
    Country,
    OwnerType,
    InvoiceReceivingMethodType,
    BedType,
    HotelType,
    HotelCategory,
    HotelChain,
    Currency,
    Region,
    ServiceCategory,
    Province,
    ServiceType,
    HostSegment,
    MealPlanType,
    HotelImageType,
    ContentLanguage,
    DestinationTaxType,
    DestinationTaxScope,
    // específicas para el IdHotelOwner
    SupplierHotel,
    SupplierHotelRoomType,
    SupplierHotelRatePlan,
    SupplierHotelMealPlan,
    SupplierBooking,
    Hotel,
    SupplierBookingStatus,
    PaymentProfileHotelOwner,
};

export const viewModelEntities = {
    HotelOwner,
    PaymentProfile,
    SupplierBooking,
    SystemUser,
    SupplierHotelRoomType,
};
