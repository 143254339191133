import React, { useEffect, useContext } from 'react';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { tabsData } from '../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import Commercial from './mainInfo/Commercial';
import Contact from './mainInfo/Contact';
import Location from './mainInfo/Location';
import Area from './mainInfo/Area';
import Property from './mainInfo/Property';
// import DestinationTaxesTab from './mainInfo/DestinationTaxesTab';

const MainInfo = ({ IdEncode, encodeId }) => {
    const { progress, setProgress, checkLocalStorage } =
        useContext(ContextOwnerPortal);
    const { currentTab, stepName } = progress.current;
    const { lastTabFilled } = progress.prefilledProgress.main_info;

    useEffect(() => {
        checkLocalStorage(IdEncode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                maxTabs: tabsData.main_info.numberOfTabs,
                tabName: tabsData.main_info.tabs[0].title,
                currentTab: 1,
                stepName: 'main_info',
                maxSubTabs: 1,
                currentSubTab: 1,
                lastTabFilled,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepName]);

    const getCurrentForm = () => {
        switch (currentTab) {
            case 1:
                return <Commercial />;
            case 2:
                return <Contact />;
            case 3:
                return <Location />;
            case 4:
                return <Area />;
            // case 5:
            //     return <DestinationTaxesTab />;
            case 5:
                return <Property encodeId={encodeId} />;
            default:
                return <p>componente no encontrada</p>;
        }
    };

    return <>{getCurrentForm()}</>;
};

export default MainInfo;
