import React, { useContext } from 'react';
import { formatUTCDateTime, formatDate } from '../../../../utils/formatDates';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { getLosValue } from '../ariHelpers';
import Icon from '../Icon';
import CardList from './CardList';
import CardSectionTitle from './CardSectionTitle';

const GeneralInfo = props => {
    const {
        date,
        lastUpdated,
        los,
        availability,
        qualityStatus: { iconName, iconColor, reason },
    } = props;

    const { language, translate } = useContext(ContextUserProfile);

    const Row = ({ title, value }) => {
        return (
            <>
                <span>{title.toUpperCase()}</span>
                <span style={{ textAlign: 'end' }}>{value}</span>
            </>
        );
    };

    const generalIngoItems = [
        {
            title: 'date',
            value: formatDate(date, language),
        },
        {
            title: 'ari_last_updated',
            value: formatUTCDateTime(lastUpdated, language),
        },
        {
            title: 'los',
            value: getLosValue(los),
        },
        {
            title: 'availability',
            value: availability,
        },
    ].map(({ title, value }) => (
        <Row title={translate(title)} value={value} key={title} />
    ));

    const qualityItems = [
        <>
            <Icon iconName={iconName} sx={{ color: iconColor }} />
            <span>{translate(reason)}</span>
        </>,
    ];

    return (
        <div>
            <div>
                <CardSectionTitle title='quality' />
                <CardList items={qualityItems} />
            </div>
            <CardList items={generalIngoItems} />
        </div>
    );
};

export default GeneralInfo;
