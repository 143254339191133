export default {
    Id: true,
    Name: true,
    Email: true,
    ContactPerson: true,
    Phone: true,
    IdOwnerType: true,
    OwnerType: { Caption: true },
    IdCountry: true,
    Country: {
        Caption: true,
    },
    DateSigned: true,
};
