import React from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const ButtonUserMenu = props => {
    const { handleClickButton } = props;
    return (
        <IconButton
            sx={{ width: 28, height: 28 }}
            id='user-button'
            onClick={e => handleClickButton(e.currentTarget)}
        >
            <AccountCircleRoundedIcon sx={{ width: 26, height: 26 }} />
        </IconButton>
    );
};

export default ButtonUserMenu;
