import React, { useContext } from 'react';
import IconAndTextComponent from '../../../../accountSection/components/IconAndTextComponent';
import { formatNumberByLanguage } from '../../../../utils/usefulFunctions';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';

const BookingTextStatistics = ({ bookingsData, currency }) => {
    const { translate, language } = useContext(ContextUserProfile);

    const averageStay =
        bookingsData
            .filter(item => item.commitedAverageStay)
            .reduce((acc, obj) => {
                return acc + obj.commitedAverageStay;
            }, 0) /
            bookingsData.filter(item => item.commitedAverageStay).length || 0;

    return (
        <div className='booking-data__container'>
            <IconAndTextComponent
                icon='fas fa-calculator'
                text={`${translate(
                    'total_bookings'
                )} - ${formatNumberByLanguage(
                    bookingsData.reduce((acc, obj) => {
                        return acc + obj.commitedBookingCount;
                    }, 0),
                    language
                )}`}
                description={'total_bookings'}
            />
            <IconAndTextComponent
                icon='fas fa-align-center'
                text={`${translate('average_stay')} - ${formatNumberByLanguage(
                    averageStay,
                    language
                )} ${translate('days')}`}
                description={'average_stay'}
            />
            <IconAndTextComponent
                icon='fas fa-money-bill'
                text={`${translate('revenue')} - ${formatNumberByLanguage(
                    bookingsData
                        .filter(item => item.revenue)
                        .reduce((acc, obj) => {
                            return acc + obj.revenue;
                        }, 0),
                    language
                )} ${currency}`}
                description={'average_stay'}
            />
        </div>
    );
};

export default BookingTextStatistics;
