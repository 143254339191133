/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
// import Matrix from '../../components/occupancyMatrix/Matrix';
import ComponentMultiSelect from '../../components/occupancyMatrix/ComponentMultiSelect';

import InputText from '../../components/TextInput';
import { ContextOccupancy } from '../../../contextsProperty/ContextOccupancy';

export default function Occupancy() {
    const { translate } = useContext(ContextUserProfile);
    const { singleRoomData, handleInputsRoomInfo, setSingleRoomData } =
        useContext(ContextOwnerPortal);
    const {
        MaxAdults,
        MaxChild,
        MinAdults,
        MinChildren,
        StandardOccupancy,
        AdultOnly,
    } = singleRoomData;

    const { occupancyArray, filterOccupancies, createOcc } =
        useContext(ContextOccupancy);

    const [occupancyForm] = useState(React.createRef());

    const hasOccupancy = occupancyArray.length;

    const getMaxOcc = array => {
        const occ = array
            .map(item =>
                item
                    .split('.')
                    .map(el => parseInt(el))
                    .reduce((acc, val) => acc + val)
            )
            .sort((a, b) => a - b);
        const maxOcc = occ[occ.length - 1];
        return maxOcc;
    };

    const occupancyToString = array => {
        return array.join('|');
    };

    const updateRoomInfo = array => {
        if (array.length) {
            const newSingleRoomData = {
                ...singleRoomData,
                MaxOccupancy: getMaxOcc(array),
                Occupancy: occupancyToString(array),
            };
            setSingleRoomData(newSingleRoomData);
        } else {
            const newSingleRoomData = {
                ...singleRoomData,
                MaxOccupancy: MaxAdults + MaxChild,
                Occupancy: '',
            };
            setSingleRoomData(newSingleRoomData);
        }
    };

    useEffect(() => {
        filterOccupancies(
            occupancyArray,
            MinAdults,
            MaxAdults,
            MinChildren,
            MaxChild
        );
    }, [MaxAdults, MinAdults, MaxChild, MinChildren]);

    useEffect(() => {
        updateRoomInfo(occupancyArray);
        // Esta condición sirve para prevenir que se queden límites erróneos en el número de niños si se activa la casilla AdultsOnly.
        if (AdultOnly && (MaxChild > 0 || MinChildren > 0)) {
            const newRoomData = {
                ...singleRoomData,
                MaxChild: 0,
                MinChildren: 0,
            };
            setSingleRoomData(newRoomData);
        }
    }, [occupancyArray, MaxAdults, MinAdults, MaxChild, MinChildren]);

    const handleValidation = e => {
        handleInputsRoomInfo(e);
    };

    const roomOccupancy = {
        adults: createOcc(MinAdults, MaxAdults, 1, 99),
        kids: !AdultOnly ? createOcc(MinChildren, MaxChild, 0, 99) : [0],
    };

    return (
        <div className='your-properties-form-occupancy'>
            {/* <h1 className='your-properties-title'>{translate('occupancy')}</h1> */}
            <div className='matrix__input-section'>
                <div className='matrix__input-container pl-0 pl-md-2'>
                    <div className='d-flex justify-content-center align-items-end'>
                        <i className='fas fa-male icon__adult my-4 mr-2' />
                        <h5>{translate('Adults')}</h5>
                    </div>
                    <div className='d-flex'>
                        <InputText
                            data={{
                                name: 'MinAdults',
                                type: 'number',
                                caption: 'min',
                                min: 1,
                                labelClassName: 'text-center',
                                max: 99,
                                className: `form-control occupancy__input`,
                                validationmessage: 'occupancy_min_adults',
                                containerClassName: ' occupancy__container',
                                messageClassName: 'validation__text',
                            }}
                            handleChange={handleValidation}
                            value={MinAdults}
                        />
                        <InputText
                            data={{
                                name: 'MaxAdults',
                                type: 'number',
                                caption: 'max',
                                min: MinAdults,
                                labelClassName: 'text-center',
                                containerClassName:
                                    ' ml-2 occupancy__container',
                                max: 99,
                                className: 'form-control occupancy__input',
                                validationmessage: 'occupancy_max_adults',
                                messageClassName: 'validation__text',
                            }}
                            handleChange={handleValidation}
                            value={MaxAdults}
                        />
                    </div>
                </div>
                {!AdultOnly && (
                    <div className='matrix__input-container pt-0 pt-md-1'>
                        <div className='d-flex justify-content-center align-items-end'>
                            <i className='fas fa-child icon__child my-4 mr-2' />
                            <h5>{translate('Children')}</h5>
                        </div>
                        <div className='d-flex'>
                            <InputText
                                data={{
                                    name: 'MinChildren',
                                    type: 'number',
                                    caption: 'min',
                                    min: 0,
                                    labelClassName: 'text-center',
                                    containerClassName:
                                        ' ml-2 occupancy__container',
                                    max: 99,
                                    className:
                                        'form-control mr-2 occupancy__input',
                                    validationmessage: 'occupancy_min_child',
                                    messageClassName: 'validation__text',
                                }}
                                handleChange={handleValidation}
                                value={MinChildren}
                            />
                            <InputText
                                data={{
                                    name: 'MaxChild',
                                    type: 'number',
                                    caption: 'max',
                                    min: MinChildren,
                                    labelClassName: 'text-center',
                                    containerClassName:
                                        'ml-2 occupancy__container',
                                    max: 99,
                                    className:
                                        'form-control mr-2 occupancy__input',
                                    validationmessage: 'occupancy_max_child',
                                    messageClassName: 'validation__text',
                                }}
                                handleChange={handleValidation}
                                value={MaxChild}
                            />
                        </div>
                    </div>
                )}
                <div className='matrix__input-container pt-0 pt-md-1 mb-3'>
                    <div
                        className='d-flex justify-content-center align-items-end'
                        style={{ marginBottom: '24px' }}
                    >
                        <h5>{translate('standard_occupancy')}</h5>
                    </div>
                    <InputText
                        data={{
                            name: 'StandardOccupancy',
                            type: 'number',
                            caption: '',
                            labelClassName: 'text-center',
                            containerClassName: 'ml-2 occupancy__container',
                            min: 1,
                            max: 20,
                            className: 'form-control mr-2 occupancy__input',
                            validationmessage: 'occupancy_standard_validation',
                            messageClassName: 'validation__text',
                        }}
                        handleChange={handleValidation}
                        value={StandardOccupancy}
                    />
                </div>
            </div>
            <h2 className='occupancy__title'>
                {translate('occupancy_allowed')}
            </h2>
            <p className='text-justify my-4 mx-0 mx-lg-4'>
                {translate('occupancy_hint')}
            </p>
            <ComponentMultiSelect roomOccupancy={roomOccupancy} />
            {/* <Matrix roomOccupancy={roomOccupancy} /> */}
            <p className='occupancy__error'>
                {!hasOccupancy && translate('select_occupancies')}
            </p>
            <div className='d-flex justify-content-between mt-2 align-self-end'>
                <GeneralButton type='back' text={translate('back')} />
                <GeneralButton
                    type='next'
                    text={translate('continue')}
                    form={occupancyForm}
                />
            </div>
        </div>
    );
}
