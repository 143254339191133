import React, { useContext } from 'react';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const SearcherYourProperty = props => {
    const { onChange, value, onClick } = props;

    const { translate } = useContext(ContextUserProfile);
    return (
        <div className='properties__input__container'>
            <InputBase
                placeholder={translate('search_hotel')}
                inputProps={{ 'aria-label': 'search' }}
                className='properties__input__container--input'
                onChange={onChange}
                value={value}
                onKeyPress={e => {
                    if (e.key === 'Enter') onClick();
                }}
            />
            <div className='properties__input__container--icon'>
                <SearchIcon onClick={e => onClick()} />
            </div>
        </div>
    );
};

export default SearcherYourProperty;
