import React, { useContext } from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { ContextYourBookings } from '../../contextsProperty/ContextYourBookings';

const TableHeader = ({ columns }) => {
    const { translate } = useContext(ContextUserProfile);
    const { orderBy, setOrderBy, isLoading } = useContext(ContextYourBookings);

    const getIcon = column => {
        if (orderBy.key === column.key && orderBy.order === 'asc') {
            return (
                <i
                    className='fas fa-sort-amount-up-alt'
                    style={{ marginLeft: 10 }}
                />
            );
        } else if (orderBy.key === column.key && orderBy.order === 'desc') {
            return (
                <i
                    className='fas fa-sort-amount-down'
                    style={{ marginLeft: 10 }}
                />
            );
        } else {
            return (
                <i className='fas fa-sort ml-2' style={{ marginLeft: 10 }} />
            );
        }
    };

    const handleOrderBy = column => {
        //estás pulsando por primera vez esa columna
        if (column.key === orderBy.key) {
            setOrderBy(prevOrder => {
                return {
                    ...prevOrder,
                    order: prevOrder.order === 'desc' ? 'asc' : 'desc',
                };
            });
        } else {
            setOrderBy({
                key: column.key,
                order: column.ascDefaultOrder ? 'asc' : 'desc',
            });
        }
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((column, index) => (
                    <TableCell
                        key={index}
                        className={`priority-${column.priority}`}
                        style={{
                            zIndex: 0,
                            cursor: column.isSortable ? 'pointer' : 'default',
                        }}
                        onClick={() => {
                            if (!isLoading && column.isSortable) {
                                handleOrderBy(column);
                            }
                        }}
                    >
                        {translate(column.label).toUpperCase()}
                        {column.isSortable && getIcon(column)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
