import React, { useContext, useState, useRef } from 'react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { ContextAccountInformation } from '../../contexts/AccountInformationContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import useOdataGet from '../../customHooks/useOdataGet';
import endpoints from '../../utils/commonVariables/endpoints';
import { validateForm } from '../../utils/validateForms';
import api from '../../services/api';
import convertToOvariant from '../../utils/convertToOvariant';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import accountInformationViewModel from '../../utils/viewModelStructures/accountInformation';
import { Grid } from '@mui/material';
import {
    readOnlyEntities,
    savableEntities,
} from '../../utils/commonVariables/entityKeys';

const EditionContent = () => {
    const {
        accountInfo,
        setAccountInfo,
        setEditionMode,

        setIsLoadingAccountInformation,
    } = useContext(ContextAccountInformation);
    const { setErrorMessage, setSuccessMessage } =
        useContext(NotificationsContext);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const initialState = useRef({ ...accountInfo });
    const handleTextInput = e => {
        const {
            target: { value, name },
        } = e;
        setAccountInfo(prevState => {
            return { ...prevState, [name]: value };
        });
    };

    const handleTypeahead = (e, value) => {
        const {
            target: { name },
        } = e;
        setAccountInfo(prevState => {
            return { ...prevState, [name]: parseInt(value?.Id) || 0 };
        });
    };
    const countriesEnpoint = {
        entity: readOnlyEntities.Country,
        filter: 'Active',
        orderBy: 'Caption asc',
    };

    const countriesRequest = useOdataGet(countriesEnpoint, true);

    const ownerTypesEnpoint = {
        entity: readOnlyEntities.OwnerType,
        filter: 'Enabled',
        orderBy: 'Caption asc',
    };

    const ownerTypeRequest = useOdataGet(ownerTypesEnpoint, true);

    const fieldsToValidate = {
        Name: { value: accountInfo.Name, validators: ['requiredString'] },
        Email: {
            value: accountInfo.Email,
            validators: ['requiredString', 'email'],
        },
        ContactPerson: {
            value: accountInfo.ContactPerson,
            validators: ['requiredString'],
        },
        Phone: {
            value: accountInfo.Phone,
            validators: ['requiredString', 'phone'],
        },
        IdOwnerType: {
            value: accountInfo.IdOwnerType,
            validators: ['requiredIdEntity'],
        },
        IdCountry: {
            value: accountInfo.IdCountry,
            validators: ['requiredIdEntity'],
        },
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const handleSend = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            setIsLoadingAccountInformation(true);
            const objToSend = convertToOvariant(
                savableEntities.HotelOwner,
                accountInfo,
                accountInfo.Id,
                accountInformationViewModel
            );
            try {
                const res = await api.post(endpoints.commonSave, objToSend);
                if (res.status === 201) {
                    setEditionMode(false);
                    setSuccessMessage(
                        'Your_data_has_been_updated_successfully'
                    );
                    setAccountInfo(res.data);
                } else {
                    setErrorMessage('something_went_wrong');
                }
                setIsLoadingAccountInformation(false);
            } catch (error) {
                console.log(error);
                setErrorMessage('something_went_wrong');
                setIsLoadingAccountInformation(false);
            }
        }
    };

    return (
        <>
            <Grid
                container
                rowSpacing={{ xs: 1, md: 4 }}
                columnSpacing={2}
                columns={2}
            >
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'property_name'}
                        value={accountInfo.Name}
                        errorMessage={errors.Name}
                        id={'Name'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'contact_person'}
                        value={accountInfo.ContactPerson}
                        errorMessage={errors.ContactPerson}
                        id={'ContactPerson'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'phone'}
                        value={accountInfo.Phone}
                        errorMessage={errors.Phone}
                        id={'Phone'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'your_email'}
                        value={accountInfo.Email}
                        errorMessage={errors.Email}
                        id={'Email'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <LoadedOptionsTypeahead
                        labelText={'Country'}
                        errorMessage={errors.IdCountry}
                        id={'IdCountry'}
                        options={countriesRequest.options}
                        // optionsLabelKey={'LocalizedCaption'}
                        idValue={accountInfo.IdCountry}
                        handleChange={handleTypeahead}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <LoadedOptionsTypeahead
                        labelText={'registered_as'}
                        id={'IdOwnerType'}
                        options={ownerTypeRequest.options}
                        idValue={accountInfo.IdOwnerType}
                        handleChange={handleTypeahead}
                        onBlur={handleValidate}
                        errorMessage={errors.IdOwnerType}
                        required
                    />
                </Grid>
            </Grid>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                    variant='outlined'
                    onClick={() => {
                        setEditionMode(false);
                        setAccountInfo(initialState.current);
                    }}
                >
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSend}>
                    Update
                </Button>
            </CardActions>
        </>
    );
};

export default EditionContent;
