/* eslint-disable no-restricted-syntax */
import React, { useState, createContext, useContext } from 'react';
import { ContextOwnerPortal } from '../ownerPortal/ContextOwnerPortal';

export const ContextOccupancy = createContext();
export const ProviderOccupancy = props => {
    const { singleRoomData } = useContext(ContextOwnerPortal);
    const { AdultOnly, Occupancy = '' } = singleRoomData;
    const occupancyToArr = string => {
        return string.length ? string.split('|') : [];
    };

    const cleanOccupancy = AdultOnly
        ? occupancyToArr(Occupancy).filter(el => el.match(/00$/))
        : occupancyToArr(Occupancy);
    const [occupancyArray, setOccupancyArray] = useState(cleanOccupancy);
    const { children } = props;

    // Este método se invoca al hacer click en los botones de la matriz
    function updateSelected(value) {
        if (occupancyArray.includes(value)) {
            const newOccArr = occupancyArray.filter(el => el !== value);
            setOccupancyArray(newOccArr);
        } else {
            const newOccArr = [...occupancyArray, value].sort((a, b) => a - b);
            setOccupancyArray(newOccArr);
        }
    }

    // Este método se invoca cuando se cambian los valores en los inputs de min/max Adults/Children. Se ocupa de eliminar valores que habían sido seleccionados y estaban en el array de ocupaciones cuando se restringen los límites de las ocupaciones.
    const filterOccupancies = (array, minA, maxA, minK, maxK) => {
        // minA => min Adultos, minK => min niños, el array es el array de ocupaciones
        if (array.length && minA <= maxA && minK <= maxK) {
            // La expresión regular se usa para filtrar los elementos del array de ocupaciones que estén fuera de los límites de ocupación.
            const limitsArray = [minA, maxA, minK, maxK].map(item =>
                formatValue(item)
            );
            const [minAS, maxAS, minKS, maxKS] = limitsArray;
            const adultsRegExp =
                minAS[0] === maxAS[0]
                    ? `${minAS[0]}[${minAS[1]}-${maxAS[1]}]`
                    : `(${minAS[0]}[${minAS[1]}-9]|${maxAS[0]}[0-${maxAS[1]}])`;
            const kidsRegExp =
                minKS[0] === maxKS[0]
                    ? `${minKS[0]}[${minKS[1]}-${maxKS[1]}]`
                    : `(${minKS[0]}[${minKS[1]}-9]|${maxKS[0]}[0-${maxKS[1]}])`;

            const regex = new RegExp(`${adultsRegExp}.${kidsRegExp}`);
            const newArr = array
                .filter(el => el.match(regex))
                .sort((a, b) => a - b);
            setOccupancyArray(newArr);
        }
    };

    // createOcc se invoca en la matriz para generar los arrays sobre los que vamos a mapear. Para evitar que se pinten valores por encima de lo permitido en la matriz, toma como parámetros los valores de los input y los valores permitidos.
    const createOcc = (minVal, maxVal, minAllowed, maxAllowed) => {
        const array = [];
        if (minVal >= minAllowed && maxVal <= maxAllowed && minVal <= maxVal) {
            for (let i = minVal; i <= maxVal; i++) {
                array.push(i);
            }
        } else {
            for (let i = minAllowed; i <= maxAllowed; i++) {
                array.push(i);
            }
        }
        return array;
    };
    // añado esta función para poder transformar los valores numéricos es strings de 2 cifras.
    const formatValue = integer => {
        return integer > 9 ? integer.toString() : `0${integer}`;
    };
    // update Occupancies es el método que invocan los selectores de fila y columna, con un array como parámetro que contiene los valores que deberían activarse o desactivarse cuando lo pulsamos.
    const updateOccuppancies = array => {
        // selectedItems es un array de booleanos que se genera a partir del array de valores de ocupación del MatrixToggle.
        const selectedItems = array.map(el => occupancyArray.includes(el));
        // si todos son false es que ninguno de los valores asociados al toggle está seleccioado, así que se concatena el array del toggle al array de ocupaciones y se ordena
        if (selectedItems.every(el => el === false)) {
            const updatedArr = occupancyArray
                .concat(array)
                .sort((a, b) => a - b);
            setOccupancyArray(updatedArr);
            // al contrario, si todos los elementos del toggle ya estaban en el array, entonces lo que queremos es deseleccionarlos, por eso se filtra el array de ocupaciones para dejar aquellos elementos que no estén en los valores del toggle
        } else if (selectedItems.every(el => el === true)) {
            const filteredOcc = occupancyArray
                .filter(el => array.indexOf(el) === -1)
                .sort((a, b) => a - b);
            setOccupancyArray(filteredOcc);
        } else {
            // En este caso tendremos algunos valores del la ocupación del toggle en el array de ocupaciones y otros no, así que busco los valores que faltan por añadir y los concateno.
            const missingItems = array.filter(
                el => occupancyArray.indexOf(el) === -1
            );
            const newOccupancyArr = occupancyArray
                .concat(missingItems)
                .sort((a, b) => a - b);
            setOccupancyArray(newOccupancyArr);
        }
    };

    const updateAllOcupancyArray = array => {
        setOccupancyArray(array);
    };

    return (
        <ContextOccupancy.Provider
            value={{
                createOcc,
                formatValue,
                setOccupancyArray,
                occupancyArray,
                updateSelected,
                updateOccuppancies,
                filterOccupancies,
                updateAllOcupancyArray,
            }}
        >
            {children}
        </ContextOccupancy.Provider>
    );
};
