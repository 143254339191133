import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const LoadingPlaceholder = () => {
    return (
        <>
            <Skeleton variant='rectangular' width={'100%'} height={'40px'} />
            <Skeleton variant='rectangular' width={'100%'} height={'40px'} />
            <Skeleton variant='rectangular' width={'100%'} height={'40px'} />
        </>
    );
};

export default LoadingPlaceholder;
