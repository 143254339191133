import React, { createContext, useState, useContext } from 'react';
import api from '../services/api';
import { publicEndpoints } from '../utils/commonVariables/endpoints';
import { ContextUserProfile } from '../contexts/UserProfileContext';

export const ContextFastCheckIn = createContext();

export const ProviderFastCheckIn = props => {
    const { translate } = useContext(ContextUserProfile);
    const { children } = props;

    const INITIAL_STATE_INPUTS_DATA = {
        BookingCode: '',
        HotelReference: '',
        HotelReferenceNotInformed: false,
        Id: null,
    };

    const stepsCount = 2;
    const [inputsData, setInputsData] = useState(INITIAL_STATE_INPUTS_DATA);
    const [isInfoVisible, setIsInfoVisible] = useState(true);
    const [step, setStep] = useState(1);
    const [isFetching, setIsFetching] = useState({ key: '', value: false });
    const [message, setMessage] = useState('');
    const [bookingCodeError, setBookingCodeError] = useState('');

    const handleCheckIn = async () => {
        setIsFetching({ key: 'confirmed', value: true });
        try {
            const data = await api.post(
                publicEndpoints.confirmCheckInForm,
                inputsData
            );
            if (data.status === 200) {
                setStep(step + 1);
                setMessage({
                    ...message,
                    BookingStatus: `${translate('booking_confirmed')}`,
                    BookingStatusInfo: `${translate('booking_confirmed_info')}`,
                    BookingNotConfirmed1: `${translate(
                        'booking_notconfirmed_text'
                    )}`,
                    BookingNotConfirmed2: `${translate(
                        'booking_notconfirmed-text2'
                    )}`,
                    exclamation: false,
                });
            } else {
                setBookingCodeError(
                    `${translate('booking_code')} "${
                        inputsData.BookingCode
                    }" ${translate('does_not_exist')}. ${translate(
                        'please_try_again'
                    )}`
                );
            }
        } catch (error) {
            if (error.response.data.Message === 'Booking not found') {
                setBookingCodeError(
                    `${translate('booking_code')} "${
                        inputsData.BookingCode
                    }" ${translate('does_not_exist')}. ${translate(
                        'please_try_again'
                    )}`
                );
            } else {
                setBookingCodeError(`${translate('something_went_wrong')}`);
            }
        } finally {
            setIsFetching({ key: 'confirmed', value: false });
        }
    };

    const handleNotConfirm = async () => {
        const { BookingCode } = inputsData;
        const objectToSend = {
            BookingCode,
        };
        setIsFetching({ key: 'unConfirmed', value: true });
        try {
            const data = await api.post(
                publicEndpoints.unconfirmCheckInForm,
                objectToSend
            );
            if (data.status === 200) {
                setStep(step + 1);
                setMessage({
                    ...message,
                    BookingStatus: `${translate('booking_notconfirmed')}`,
                    BookingStatusInfo: `${translate(
                        'booking_notconfirmed_info'
                    )}`,
                    BookingNotConfirmed1: `${translate(
                        'booking_notconfirmed_text'
                    )}`,
                    exclamation: true,
                });
            } else {
                setBookingCodeError(
                    `${translate('booking_code')} "${
                        inputsData.BookingCode
                    }" ${translate('does_not_exist')}. ${translate(
                        'please_try_again'
                    )}`
                );
            }
        } catch (error) {
            if (error.response.data.Message === 'Booking not found') {
                setBookingCodeError(
                    `${translate('booking_code')} "${
                        inputsData.BookingCode
                    }" ${translate('does_not_exist')}. ${translate(
                        'please_try_again'
                    )}`
                );
            } else {
                setBookingCodeError(`${translate('something_went_wrong')}`);
            }
        } finally {
            setIsFetching({ key: 'unConfirmed', value: false });
        }
    };

    return (
        <ContextFastCheckIn.Provider
            value={{
                inputsData,
                setInputsData,
                stepsCount,
                step,
                setStep,
                isInfoVisible,
                setIsInfoVisible,
                handleCheckIn,
                message,
                handleNotConfirm,
                bookingCodeError,
                isFetching,
            }}
        >
            {children}
        </ContextFastCheckIn.Provider>
    );
};
