import React, { useContext } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import CoverPageImage from './CoverPageImage';

const ImageSection = () => {
    const { coverPageInfo } = useContext(ContextOwnerPortal);

    return coverPageInfo.Images.length ? (
        <div className='img-container shadow'>
            <>
                <CoverPageImage
                    src={coverPageInfo.Images[0]?.VirtualPath}
                    width={'100%'}
                    className='steps-img'
                    alt='hotel photography'
                />
                <div className='img-container--secondary'>
                    <CoverPageImage
                        src={coverPageInfo.Images[1]?.VirtualPath}
                        className='steps-img--secondary'
                        alt='hotel photography'
                    />
                    <CoverPageImage
                        src={coverPageInfo.Images[2]?.VirtualPath}
                        className='steps-img--secondary'
                        alt='hotel photography'
                    />
                    <CoverPageImage
                        src={coverPageInfo.Images[3]?.VirtualPath}
                        className='steps-img--secondary'
                        alt='hotel photography'
                    />
                    <CoverPageImage
                        src={coverPageInfo.Images[4]?.VirtualPath}
                        className='steps-img--secondary'
                        alt='hotel photography'
                    />
                </div>
            </>
        </div>
    ) : (
        ''
    );
};

export default ImageSection;
