import React, { useContext, useState } from 'react';
import { ContextContact } from '../../contexts/ContactContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import Button from '@mui/material/Button';
import { validateForm } from '../../utils/validateForms';

const ContactStep1 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { inputsData, setInputsData, setStep } = useContext(ContextContact);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);

    const fieldsToValidate = {
        Name: {
            value: inputsData.Name,
            validators: ['requiredString'],
        },
        CompanyName: {
            value: inputsData.CompanyName,
            validators: ['requiredString'],
        },
        Email: {
            value: inputsData.Email,
            validators: ['requiredString', 'email'],
        },
    };
    const checkValidate = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            setStep(2);
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    return (
        <div className='step-content__container'>
            <TextInput
                labelText='name'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, Name: value };
                    });
                }}
                value={inputsData.Name}
                errorMessage={errors.Name}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='surname'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, LastName: value };
                    });
                }}
                value={inputsData.LastName}
                errorMessage={errors.LastName}
                onBlur={handleValidate}
            />
            <TextInput
                labelText='company_name'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, CompanyName: value };
                    });
                }}
                value={inputsData.CompanyName}
                errorMessage={errors.CompanyName}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='Email'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, Email: value };
                    });
                }}
                value={inputsData.Email}
                errorMessage={errors.Email}
                onBlur={handleValidate}
                required
            />

            <Button
                variant='contained'
                onClick={checkValidate}
                style={{ alignSelf: 'flex-end' }}
            >
                {translate('next')}
            </Button>
        </div>
    );
};

export default ContactStep1;
