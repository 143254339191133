import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { ContextUserProfile } from './UserProfileContext';
import api, { commonErrorMessage } from '../services/api';
import { NotificationsContext } from './NotificationsContext';
import endpoints from '../utils/commonVariables/endpoints';
import paymentTerm from '../utils/viewModelStructures/paymentTerm';
import {
    readOnlyEntities,
    viewModelEntities,
} from '../utils/commonVariables/entityKeys';
import { buildOdataEndPoint } from '../utils/odataEndPoint';

export const ContextPaymentTerms = createContext();

export const ProviderPaymentTerms = props => {
    const { children } = props;
    const { IdHotelOwner } = useContext(ContextUserProfile);
    const { setErrorMessage } = useContext(NotificationsContext);
    const componentMounted = useRef(true);
    const [isLoadingPaymentView, setIsLoadingPaymentView] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [openedCard, setOpenedCard] = useState('');
    const [editButtonVisible, setEditButtonVisible] = useState();

    const getDetailData = useCallback(
        async array => {
            return await Promise.all(
                array.map(async item => {
                    try {
                        const endpoint = endpoints.viewModel(
                            viewModelEntities.PaymentProfile,
                            item.IdPaymentProfile
                        );
                        const { data } = await api.post(endpoint, paymentTerm);
                        if (data.Success) {
                            return data.Result;
                        } else {
                            setErrorMessage(data.Messages[0].Title);
                            return {};
                        }
                    } catch (error) {
                        setErrorMessage(commonErrorMessage);
                    }
                })
            );
        },
        [setErrorMessage]
    );

    const getPaymentProfiles = useCallback(async () => {
        try {
            setIsLoadingPaymentView(true);
            const endpoint = buildOdataEndPoint({
                entity: readOnlyEntities.PaymentProfileHotelOwner,
                select: 'Id,IdPaymentProfile',
            });
            const {
                data: { value },
            } = await api.get(endpoint);

            if (value.length) {
                if (!componentMounted.current) return null;
                const paymentProfiles = await getDetailData(value);
                const paymentProfilesHotelOwner = value.map(ids => {
                    const paymentProfile = paymentProfiles.find(
                        payProf =>
                            payProf?.Id?.toString() ===
                            ids?.IdPaymentProfile.toString()
                    );
                    return paymentProfile;
                });
                setPaymentTerms(paymentProfilesHotelOwner);
            } else {
                setIsLoadingPaymentView(false);
            }
        } catch (error) {
            setErrorMessage(commonErrorMessage);
            setIsLoadingPaymentView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (IdHotelOwner && !paymentTerms.length)
            getPaymentProfiles(IdHotelOwner);

        return () => {
            setIsLoadingPaymentView(false);
            componentMounted.current = false;
        };
    }, [IdHotelOwner, getPaymentProfiles, paymentTerms.length]);

    const updatePaymentProfiles = profile => {
        setPaymentTerms(prevState => {
            return prevState.map(prevProfile => {
                if (prevProfile.Id === profile.Id) return profile;
                else return prevProfile;
            });
        });
    };

    return (
        <ContextPaymentTerms.Provider
            value={{
                isLoadingPaymentView,
                setIsLoadingPaymentView,
                paymentTerms,
                openedCard,
                setOpenedCard,
                editButtonVisible,
                setEditButtonVisible,
                updatePaymentProfiles,
            }}
        >
            {children}
        </ContextPaymentTerms.Provider>
    );
};
