import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { TableCell, TableRow } from '@material-ui/core';
import './tablePlaceholder.scss';

const TablePlaceholder = ({
    numColumns,
    singlePlaceholder,
    numRows,
    rowClassName,
}) => {
    const RowPlaceholder = ({ children }) => {
        if (singlePlaceholder)
            return <TableCell colSpan={numColumns}>{children}</TableCell>;
        const cellsList = Array.from(Array(numColumns).keys());
        return cellsList.map(cell => (
            <TableCell key={cell}>{children}</TableCell>
        ));
    };

    const rowList = Array.from(Array(numRows).keys());

    return (
        <>
            {rowList.map(row => (
                <TableRow key={row} className={rowClassName}>
                    <RowPlaceholder>
                        <Skeleton animation='wave' variant='text' />
                    </RowPlaceholder>
                </TableRow>
            ))}
        </>
    );
};

TablePlaceholder.defaultProps = {
    singlePlaceholder: false,
    numRows: 35,
};

TablePlaceholder.propTypes = {
    numColumns: PropTypes.number.isRequired, // número de columnas de la tabla
    singlePlaceholder: PropTypes.bool, // es para  ver si la linea está dividida por celdas o es completa
    numRows: PropTypes.number.isRequired,
    rowClassName: PropTypes.string.isRequired,
};

export default TablePlaceholder;
