import { sortByName } from './sortByName';
import {
    getYearAgoDate,
    addMonthsToDate,
    getCurrentMonth,
    dateIsInRange,
    getCurrentYear,
} from './formatDates';

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

// getValueFromObjetByComposedKey te da el valor de un objeto a partir de una composedKey que es un string con las claves concatenadas por un punto ej country.name
// Dado el object hotelOwner,
// const hotelOwner= {
//     name :'hotel',
//     country : {
//         name: 'Spain',
//         cp: {
//             name: 28012,
//             city: 'Madrid',
//             otro: {
//                 cosa: 'cosa'
//             }
//         }
//     }
// }
// getValueFromObjetByComposedKey(hotelOwner, country.name) = 'Spain'
// getValueFromObjetByComposedKey(hotelOwner, country.cp.name) = '28012'

export const getValueFromObjetByComposedKey = (object, composedKey) => {
    const arrayKeys = composedKey.split('.');
    return getValueFromObjectByArrayKeys(object, arrayKeys);
};

// La función getValueFromObjectByArrayKeys hace lo mismo que getValueFromObjetByComposedKey pero a partir de [country,name] en lugar de country.name
// Esta función es sólo para ser usada en buildNewState, y en getValueFromObjetByComposedKey, en el resto del proyecto se usará getValueFromObjetByComposedKey por ser más manejable

const getValueFromObjectByArrayKeys = (object, arrayKeys) => {
    for (const key of arrayKeys) {
        object = object?.[key];
    }
    return object;
};
// Dado un objeto, una clave compuesta (country.cp.name) y el value a cambiar
// Devuelve el objeto mutado con la clave cambiada a ese valor

export const mutateObjectByComposedKeyAndValue = (
    object,
    composedKey,
    value
) => {
    // caso 1 : arrayKeys = ['name']
    // caso2 : arrayKeys = ['country','cp','city']
    const arrayKeys = composedKey.split('.');

    // caso 1: lastIndex = 0
    // caso 2: lastIndex = 2
    const lastIndex = arrayKeys.length - 1;

    // caso1: arrayKeysMenosUltimo = []
    // arrayKeysMenosUltimo = ['country', 'cp']
    const arrayKeysMenosUltimo = arrayKeys.filter(
        (key, index) => index < lastIndex
    );

    // caso1 : preObject = object = hotelOwner
    // caso2 : preObject = {
    //       "name": 28012,
    //       "city": "Madrid"
    //     }

    let preObject = getValueFromObjectByArrayKeys(object, arrayKeysMenosUltimo);

    // caso 1: lastKey = 'name'
    // caso2 : lastKey = 'city'
    const lastKey = arrayKeys[lastIndex];

    // caso 1: newObject = {
    //     ...hotelOwner,
    //     name : 'CIRUELA'
    // }

    // caso 2 : newObject = {
    //     ...{
    //         "name": 28012,
    //         "city": "Madrid"
    //     } ,
    //     city: 'CIRUELA'
    // }

    let newObject = {
        ...preObject,
        [lastKey]: value,
    };
    // caso1 : No entra en el bucle porque i empieza siendo -1
    // caso 2 : dará dos vueltas, para i = 1, i= 0 , en ese orden
    for (let i = lastIndex - 1; i >= 0; i--) {
        const key = arrayKeys[i];
        // vamos haciendo una copia hacia arriba
        preObject = getValueFromObjectByArrayKeys(
            object,
            arrayKeys.filter((key, index) => index < i)
        );
        newObject = {
            ...preObject,
            [key]: newObject,
        };
    }
    return newObject;
};

// como probar esta función
// const hotelOwner = {
//     name :'hotel',
//     country : {
//         name: 'Spain',
//         cp: {
//             name: 28012,
//             city: 'Madrid',
//             otro: {
//                 cosa: 'cosa'
//             }
//         }
//     }
// }

// mutateObjectByComposedKeyAndValue(hotelOwner,'country.cp.city', 'CIRUELA')
// retorna
// {
//     "name": "hotel",
//     "country": {
//       "name": "Spain",
//       "cp": {
//         "name": 28012,
//         "city": "CIRUELA",
//         "otro": {
//           "cosa": "cosa"
//         }
//       }
//     }
//   }

export const cutArrayInSmallArrays = (array, itemsPerArray) => {
    // dado un array [1,2,3,4,5,6,7,8,9,0] si le paso un 4 como segundo parámetro me hará [[1,2,3,4],[5,6,7,8],[9,0]]
    const arrayLength = array.length;

    const newArrayLength = Math.ceil(arrayLength / itemsPerArray);
    const newArray = [];
    for (let i = 1; i <= newArrayLength; i++) {
        const firstIndex = itemsPerArray * (i - 1);
        const lastIndex = itemsPerArray * i;
        const smallArray = array.slice(firstIndex, lastIndex);
        newArray.push(smallArray);
    }

    return newArray;
};

export const organizeDataCategories = dataArray => {
    const serviceCategories = [];
    for (const service of dataArray) {
        service.Key = service.ServiceCategory.Key;
        if (!serviceCategories.find(item => item.Key === service.Key)) {
            const category = {
                ...service.ServiceCategory,
                Id: parseInt(service.Id),
                Content: {
                    Free: [],
                    Extra: [],
                },
            };
            delete service.ServiceCategory;
            if (service.Free) {
                category.Content.Free.push(service);
            } else {
                category.Content.Extra.push(service);
            }
            serviceCategories.push(category);
        } else {
            const indexInSerCategory = serviceCategories.findIndex(
                category => category.Key === service.Key
            );
            delete service.ServiceCategory;
            if (service.Free) {
                serviceCategories[indexInSerCategory].Content.Free.push(
                    service
                );
            } else {
                serviceCategories[indexInSerCategory].Content.Extra.push(
                    service
                );
            }
        }
    }

    // ordenar los service types por el campo order
    for (let i = 0; i < serviceCategories.length; i++) {
        const newFreeArray = sortByName(
            serviceCategories[i].Content.Free,
            'Order'
        );
        const newExtraArray = sortByName(
            serviceCategories[i].Content.Extra,
            'Order'
        );

        serviceCategories[i].Content.Free = newFreeArray;
        serviceCategories[i].Content.Extra = newExtraArray;
    }

    // ordenar los service categories por el campo order
    const orderedArray = sortByName(serviceCategories, 'Order');

    // //cortar el array para separarlo por pantallas

    return orderedArray;
};

export const capitalize = string => {
    return (
        string.substring(0, 1).toUpperCase() + string.substring(1).toLowerCase()
    );
};

export const capitalizeSentence = string => {
    const words = string.split(' ');
    const wordsNotCapitalized = ['de', 'du', 'FPH', 'del'];
    return words
        .map(word => {
            if (wordsNotCapitalized.includes(word)) return word;
            return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
};

/**
 * Función que calcula la ruta de la homePage
 * @param pathname  string pathname del router, representa la ubicación actual(property)
 */

export const getHomeUrl = '/owner-portal';

export const getTodayDate = () => {
    return new Date().toISOString().substring(0, 19);
};

export const formatNumber = (data, _style, curr) => {
    const userLang = localStorage.getItem('language');
    const lang = userLang === 'en' ? 'en-EN' : 'de-DE';
    const num = typeof data === 'string' ? parseFloat(data) : data;

    if (_style === 'currency') {
        return new Intl.NumberFormat(lang, {
            style: _style,
            currency: curr,
        }).format(num);
    }
    return new Intl.NumberFormat(lang, {
        style: _style,
    }).format(num);
};

export const formatLocalToNumber = stringNumber => {
    // si el usuario escribe '0.' / '0.' / '0.0' retorna ''
    const emptyRegex = /[0.,]/g;
    const isEmpty = !stringNumber.replace(emptyRegex, '');
    if (isEmpty) return '';
    // --------
    const regex = /[0-9]/g;
    let string = '';
    let stringAlreadyHasSeparator = false;
    for (let i = 0; i < stringNumber.length; i++) {
        if (stringNumber[i].match(regex)) {
            string += stringNumber[i];
        } else if (!stringAlreadyHasSeparator) {
            // coge el primer separador y evita los siguientes
            string = `${string}.`;
            stringAlreadyHasSeparator = true;
        }
    }
    return string;
};

export const formatNumberToLocal = number => {
    const stringNumber = number.toString();
    const decimalSeparator = formatNumber(1000.01)[5];
    return stringNumber.replace('.', decimalSeparator);
};
const moment = require('moment');

export const datesFormatByLanguage = {
    es: 'DD/MM/YYYY',
    en: 'MM/DD/YYYY',
    de: 'DD/MM/YYYY',
    fr: 'DD/MM/YYYY',
};

export const getLanguageFormat = lang => {
    let language;
    if (lang) {
        language = lang;
    } else {
        language = localStorage.getItem('language');
    }
    return datesFormatByLanguage[language];
};

export const formatDate = (date, lang) => {
    const formatLanguage = getLanguageFormat(lang);
    return moment(date).format(formatLanguage);
};

export const formatTime = (date, lang, timeZone = 'Europe/Madrid') => {
    const dateUtc = moment.utc(date);
    return dateUtc.clone().tz(timeZone).format(`HH:mm:ss`);
};

export const formatDateTime = (date, lang, timeZone = 'Europe/Madrid') => {
    const formatLanguage = getLanguageFormat(lang);

    const dateUtc = moment.utc(date);
    return dateUtc.clone().tz(timeZone).format(`${formatLanguage}, HH:mm:ss`);
};

export const formatDateCard = date => {
    return moment(date).format('MM/YYYY');
};

export const convertDateToUTC = date => {
    return `${moment(date).format('YYYY-MM-DD')}T00:00:00`;
};

export const formatDateTo_YY_MM_DD_String = date => {
    return `${moment(date).format('YYYY-MM-DD')}`;
};

export const getOrdinalSuffix = number => {
    const units = number % 10;
    const tens = Math.floor((number % 100) / 10);
    if (tens === 1) return `${number}th`;
    const suffixes = {
        1: 'st',
        2: 'nd',
        3: 'rd',
    };
    const suffix = suffixes[units] || 'th';
    return `${number}${suffix}`;
};

export const oneDayMilliseconds = 86400000;

export const debounce = (fn, delay) => {
    let timer = null;
    return function (...args) {
        const context = this;
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
};

export const encodeURIOdata = string => {
    // Para odata los caracteres especiales se encodean con encodeUriComponent
    // Excepto para la comilla simple ' que no es suficiente.
    // Si escribimos hola lo envía como 'hola' (obligatoriamente con comilla simple)
    // Si escribimos sportsman's lo envía como 'sportsman's' y falla . La manera correcta es 'sportsman''s'
    // visto aquí https://stackoverflow.com/questions/3979367/how-to-escape-a-single-quote-to-be-used-in-an-odata-query
    // Si el string a buscar contiene una comilla la sustituyo por ''
    const hasSingleQuote = string.includes(`'`);
    const formatedString = hasSingleQuote ? string.replace(/'/g, `''`) : string;
    return encodeURIComponent(formatedString);
};

export const formatNumberByLanguage = (number, lang) => {
    const hasDecimals = parseFloat(number) % 1 !== 0;
    if (hasDecimals) {
        return number.toFixed(2).toLocaleString(lang);
    } else {
        return number.toLocaleString(lang);
    }
};

export const setFavicon = () => {
    const env = process.env.NODE_ENV;
    const favicon = document.getElementById('favicon');
    const isDevEnv = env === 'development';
    const href = isDevEnv ? '/localFavicon.ico' : process.env.REACT_APP_FAVICON;
    favicon.href = href;
};

export const getRandomNumber = (min = 1, max = 100) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const openExtLink = e => {
    e.preventDefault();
    // Recoge el href y se lo paso a window.open tanto como url como como nombre de la ventana que se abre.
    const targetUrl = e.currentTarget.href;
    window.open(targetUrl, targetUrl);
};

export const stringifyCode = obj => {
    const indentSpaces = 2;
    return JSON.stringify(obj || '', null, indentSpaces);
};

/**
 * Para exportar breakpoints de la página de variables. Llegan exportados como un string y en react se necesitan como un number
 * @param {*string} pixelUnits
 * @example '576px' => 576
 */

export const convertBreakpoint = pixelUnits =>
    Number(pixelUnits.slice(0, pixelUnits.length - 2));

export const isJson = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const parseJson = string => {
    if (isJson(string)) return JSON.parse(string);
    return {};
};

export const isEmptyObject = object => {
    return !Object.keys(object).length;
};

const reduceArrayFromObjectKey = (array, key) => {
    return array.reduce((acc, obj) => acc + obj[key], 0);
};

const getAverageFromArray = (array, keyToSum, divider) => {
    return reduceArrayFromObjectKey(array, keyToSum) / divider || 0;
};

export const formatBookingDataForCharts = (data, INITIAL_STATE) => {
    let oneYearAgo = getYearAgoDate();

    let arrayOfMonths = [];
    for (let i = 0; i < 12; i++) {
        const initialDate = addMonthsToDate(oneYearAgo, i);
        const finalDate = addMonthsToDate(oneYearAgo, i + 1);
        let newItem = INITIAL_STATE.find(
            item => item.id === getCurrentMonth(initialDate)
        );

        newItem.year = getCurrentYear(initialDate);

        const bookingsDataForThisMonth = data.filter(book => {
            return dateIsInRange(book.DateStats, {
                start: initialDate,
                end: finalDate,
            });
        });

        newItem.bookings = bookingsDataForThisMonth;
        arrayOfMonths.push(newItem);
    }
    return arrayOfMonths;
};

export const formatCancelledAndCommited = (data, INITIAL_STATE) => {
    const generalData = formatBookingDataForCharts(data, INITIAL_STATE);
    return generalData.map(month => {
        const commitedBookingCount = reduceArrayFromObjectKey(
            month.bookings,
            'CommitedCount'
        );
        const cancelledBookingCount = reduceArrayFromObjectKey(
            month.bookings,
            'CancelledCount'
        );
        const commitedAverageStay = getAverageFromArray(
            month.bookings,
            'CommitedNights',
            commitedBookingCount
        );
        const cancelledAverageStay = getAverageFromArray(
            month.bookings,
            'CancelledNights',
            cancelledBookingCount
        );
        const revenue = reduceArrayFromObjectKey(
            month.bookings,
            'CommitedTotal'
        );

        return {
            ...month,
            commitedBookingCount,
            cancelledBookingCount,
            commitedAverageStay,
            cancelledAverageStay,
            revenue,
        };
    });
};

export const formatMostBookedHotelsData = data => {
    const colorsArray = [
        '#0075a4',
        '#008fad',
        '#00a697',
        '#1eb769',
        '#94c031',
        '#f3bc00',
    ];

    const sortedData = data.sort((a, b) => b.BookingCount - a.BookingCount);
    const cutMostBookedHotelsDataArray =
        sortedData.length > 6 ? sortedData.slice(0, 6) : sortedData;

    let newData = cutMostBookedHotelsDataArray.map((item, index) => ({
        ...item,
        color: colorsArray[index],
    }));

    return newData;
};

export const sortByField = (data, field) => {
    return data.sort((a, b) => {
        if (a[field] < b[field]) {
            return -1;
        }
        if (a[field] > b[field]) {
            return 1;
        }
        return 0;
    });
};
