import React, { useContext } from 'react';
import TypeAhead from './Filters/TypeAhead';
import IconButton from '@mui/material/IconButton';
import Icon from '../../formsProperty/Ari/Icon';
import { ContextAri } from '../../contextsProperty/ContextAri';
import SearchSelect from './Filters/SearchSelect';
import DateRangeFilter from './Filters/DateRangeFilter';
import { losOptions } from './ariHelpers';
import TypeaheadErrorMessage from './Filters/TypeaheadErrorMessage';
import QualityFilter from './Filters/QualityFilter';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { capitalize } from '../../../utils/usefulFunctions';
import WithTooltip from './WithTooltip';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from '@material-ui/core/styles';

const FiltersBar = () => {
    const {
        filtersOptions,
        setFilters,
        handleSearch,
        supplierHotels,
        filters,
        clearFilters,
        isOpenedFiltersBar,
        getOptions,
        isLoading,
        validationErrors,
    } = useContext(ContextAri);

    const classes = makeStyles({
        root: {
            minHeight: 'unset !important',
        },
    })();
    const { translate } = useContext(ContextUserProfile);

    const [roomFilter, rateFilter, boardFilter] = filtersOptions;
    const {
        los,
        dateLastUpdateFrom,
        dateLastUpdateTo,
        code,
        idSupplierHotel,
        idSupplierHotelRoomType,
        idSupplierHotelRatePlan,
        idSupplierHotelMealPlan,
        dateFrom,
        dateTo,
        quality,
    } = filters;

    const handlaChangeFilter = (e, option) => {
        const { name } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: option,
        }));
    };

    const handleChangeDate = ([from, to]) => {
        const { nameFrom, valueFrom } = from;
        const { nameTo, valueTo } = to;

        setFilters(prevFilters => ({
            ...prevFilters,
            [nameFrom]: valueFrom,
            [nameTo]: valueTo,
        }));
    };

    const hotelsTypeaheadValue = (() => {
        const noHotelSelected = !code && !idSupplierHotel;
        if (noHotelSelected) return null;
        return {
            Cyrenne: code,
            IdSupplierHotel: idSupplierHotel,
            MappingCode: filters.MappingCode,
            Name: filters.Name,
        };
    })();

    const noOptionsText = filter => {
        const { hasError, name } = filter;
        if (hasError) {
            return <TypeaheadErrorMessage onClick={() => getOptions(name)} />;
        }

        if (!code && !idSupplierHotel) return translate('ari_required_hotel');
        return 'No options';
    };

    const handleChangeQualityFilter = ({ target: { name, value } }) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const getOptionLabel = option => `${option.Name} - ${option.MappingCode}`;

    return (
        <Collapse classes={classes} in={isOpenedFiltersBar} unmountOnExit>
            <div
                className='d-flex justify-content-between align-items-center'
                style={{
                    gap: '10px',
                    height: 'auto',
                }}
            >
                <div
                    className='row m-0'
                    style={{
                        flexGrow: 1,
                    }}
                >
                    <div className='col-md-6 col-xl-4 p-1'>
                        <TypeAhead
                            options={supplierHotels.hotels}
                            isSmallOwner={supplierHotels.isSmallOwner}
                            value={hotelsTypeaheadValue}
                            loading={supplierHotels.isLoading}
                            errorOnLoad={supplierHotels.errorMessage}
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <DateRangeFilter
                            value={[dateFrom, dateTo]}
                            onChange={handleChangeDate}
                            nameFrom='dateFrom'
                            nameTo='dateTo'
                            label='date'
                            fromInputProps={{
                                error: !!validationErrors?.dateFrom,
                            }}
                            toInputProps={{
                                error: !!validationErrors?.dateTo,
                            }}
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <SearchSelect
                            label='LoS'
                            options={losOptions}
                            optionTextKey='text'
                            onChange={handlaChangeFilter}
                            name='los'
                            valueKey='value'
                            value={los}
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <SearchSelect
                            label='room'
                            options={roomFilter.options}
                            onChange={handlaChangeFilter}
                            name='idSupplierHotelRoomType'
                            value={idSupplierHotelRoomType}
                            loading={roomFilter.isLoading}
                            noOptionsText={noOptionsText(roomFilter)}
                            getOptionLabel={getOptionLabel}
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <SearchSelect
                            label='rate'
                            options={rateFilter.options}
                            onChange={handlaChangeFilter}
                            name='idSupplierHotelRatePlan'
                            value={idSupplierHotelRatePlan}
                            loading={rateFilter.isLoading}
                            noOptionsText={noOptionsText(rateFilter)}
                            getOptionLabel={getOptionLabel}
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <SearchSelect
                            label='meal_plan'
                            options={boardFilter.options}
                            onChange={handlaChangeFilter}
                            name='idSupplierHotelMealPlan'
                            value={idSupplierHotelMealPlan}
                            loading={boardFilter.isLoading}
                            noOptionsText={noOptionsText(boardFilter)}
                            getOptionLabel={getOptionLabel}
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <DateRangeFilter
                            value={[dateLastUpdateFrom, dateLastUpdateTo]}
                            onChange={handleChangeDate}
                            nameFrom='dateLastUpdateFrom'
                            nameTo='dateLastUpdateTo'
                            label='last_updated'
                        />
                    </div>
                    <div className='col-md-6 col-xl-4 p-1'>
                        <QualityFilter
                            value={quality}
                            label='quality'
                            name='quality'
                            onChange={handleChangeQualityFilter}
                        />
                    </div>
                </div>
                <div>
                    <WithTooltip title={capitalize(translate('search'))}>
                        <IconButton
                            size='small'
                            disabled={isLoading}
                            onClick={() => {
                                const newPage = 1;
                                handleSearch(newPage);
                            }}
                        >
                            <Icon iconName='Search' />
                        </IconButton>
                    </WithTooltip>
                    <WithTooltip title={translate('clear_filters')}>
                        <IconButton
                            size='small'
                            onClick={clearFilters}
                            disabled={isLoading}
                        >
                            <Icon iconName='SearchOff' />
                        </IconButton>
                    </WithTooltip>
                </div>
            </div>
        </Collapse>
    );
};

export default FiltersBar;
