import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { formatNumberByLanguage } from '../../../../utils/usefulFunctions';
import CardList from './CardList';

const PerRoom = ({ price }) => {
    const { language } = useContext(ContextUserProfile);

    const items = [
        <>
            <i style={{ fontSize: 18 }} className='ms-Icon ms-Icon--Hotel' />
            <span>{formatNumberByLanguage(price, language)}</span>
        </>,
    ];
    return <CardList items={items} />;
};

export default PerRoom;
