import React from 'react';
import sendedFormIcon from '../images/sended-form.svg';

const SendedFormIcon = () => {
    return (
        <img
            src={sendedFormIcon}
            className='sended-form__icon'
            alt={'sended form icon'}
        />
    );
};

export default SendedFormIcon;
