import React, { createContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../services/api';
import {
    mutateObjectByComposedKeyAndValue,
    scrollToTop,
} from '../../utils/usefulFunctions';
import convertToOvariant from '../../utils/convertToOvariant';
import { sortByKeyDesc } from '../../utils/sortByName';
import {
    tabsData,
    setCompletedProgressArray,
} from '../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import endpoints from '../../utils/commonVariables/endpoints';
import { savableEntities } from '../../utils/commonVariables/entityKeys';

export const ContextOwnerPortal = createContext();

const ProviderOwner = ({ children }) => {
    const [hotelInfo, setHotelInfo] = useState({
        MasterHotel: {
            HotelName: '',
        },
        SupplierHotelInfo: {
            RoomTypes: [],
            SupplierHotelContentWizardProgress: [],
        },
    });
    const [originalHotelInfo, setOriginalHotelInfo] = useState({
        MasterHotel: {
            HotelName: '',
        },
        SupplierHotelInfo: {
            RoomTypes: [],
            SupplierHotelContentWizardProgress: [],
        },
    });

    const { Progress } = hotelInfo.SupplierHotelInfo;

    const INITIAL_STATE_SINGLE_ROOM = {
        Name: '',
        AdultOnly: false,
        FreeBaby1: false,
        FreeBaby2: false,
        Size: 0,
        Description: '',
    };

    const [singleRoomData, setSingleRoomData] = useState(
        INITIAL_STATE_SINGLE_ROOM
    );

    const [isChecked, setIsChecked] = useState(false);

    const [supplierHotelRooms, setSupplierHotelRooms] = useState([]);

    const [messageBar, setMessageBar] = useState({
        type: '',
        text: '',
    });

    const [isFetching, setIsFetching] = useState(false);

    const [visibleMessageTypeahead, setVisibleMessageTypeahead] =
        useState(false);

    const [progress, setProgress] = useState({
        prefilledProgress: {
            main_info: {
                percent: 0,
            },
            description: {
                percent: 0,
            },
            room_configuration: {
                percent: 0,
            },
        },
        current: {
            maxTabs: 1,
            currentTab: 1,
            tabName: '',
            lastTabFilled: 1,
            stepName: '',
            // subtabs de services fusionado
            maxSubTabs: 1,
            currentSubTab: 1,
        },
    });

    const INITIAL_STATE_COVER_INFO = {
        HotelName: '',
        Address: '',
        GeneralEmail: '',
        Services: 0,
        AdultsOnly: false,
        Description: '',
        Rooms: 0,
        Images: [],
    };
    const [coverPageInfo, setCoverPageInfo] = useState(
        INITIAL_STATE_COVER_INFO
    );

    const { stepName, tabName, currentTab, currentSubTab } = progress.current;

    useEffect(() => {
        scrollToTop();
    }, [currentTab, currentSubTab]);

    const handleProgress = updatedProgress => {
        handleInputsHotelInfo({
            target: {
                name: 'SupplierHotelInfo.SupplierHotelContentWizardProgress',
                value: updatedProgress,
            },
        });
    };

    const parseToInt = (name, val) => {
        const supplierEntities = [
            'SupplierHotelInfo.HostSegments',
            'SupplierHotelInfo.Services',
            'SupplierHotelInfo.SupplierHotelMealPlansTypes',
        ];
        let typedVal;
        if (name.includes('MasterHotel.Id')) {
            typedVal = parseInt(val);
        } else if (
            Array.isArray(val) &&
            supplierEntities.some(item => item === name)
        ) {
            typedVal = val.map(item =>
                typeof item.Id === 'string'
                    ? { ...item, Id: parseInt(item.Id) }
                    : item
            );
        } else {
            typedVal = val;
        }
        return typedVal;
    };

    const checkLocalStorage = currentId => {
        const savedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const savedId = savedInfo ? savedInfo.SupplierHotelInfo.Id : '';
        if (savedId !== currentId) {
            // elimina la información de otro hotel y guarda los datos del hotel que se está editando ahora
            localStorage.removeItem('hotelInfo');
            localStorage.setItem('hotelInfo', JSON.stringify(hotelInfo));
        }
    };

    const handleInputsHotelInfo = event => {
        const { name, type } = event.target;
        let { value } = event.target;
        if (type === 'number') value = parseInt(value) || 0;
        value = parseToInt(name, value);
        const newHotelInfo = mutateObjectByComposedKeyAndValue(
            hotelInfo,
            name,
            value
        );
        setHotelInfo(newHotelInfo);
        localStorage.setItem('hotelInfo', JSON.stringify(newHotelInfo));
    };

    const trimValueOnBlur = (event, handler) => {
        event.target.value = event.target.value.trim();
        return handler(event);
    };

    const handleInputsRoomInfo = event => {
        const { name, type } = event.target;
        let { value } = event.target;
        if (type === 'number') value = parseInt(value);
        const newRoomInfo = mutateObjectByComposedKeyAndValue(
            singleRoomData,
            name,
            value
        );
        setSingleRoomData(newRoomInfo);
    };

    // Add and remove images form supplier and rooms
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorMaxAllowed, setErrorMaxAllowed] = useState([]);

    const uploadFiles = async (files, url) => {
        const filesToUpload = new FormData();
        files.forEach(file => filesToUpload.append('', file));
        setIsFetching(true);
        const { data } = await api.post(url, filesToUpload);
        // La url está compuesta por 3 parámetros variables. El último de ellos es IdSupplierHotelRoomType. Cuando es 0 es porque la imagen no es de la habitación si no de las zonas comunes del hotel.
        const isRoom = url[url.length - 1] !== '0';
        if (isRoom) {
            handleAddImagesToRoom(data.Result.files);
        } else {
            handleUpdateImages(data.Result.files);
        }
        setIsFetching(false);
        setErrorMessage(data.Result.failed);
    };

    const deleteImage = async image => {
        setIsFetching(true);
        try {
            const {
                IdHotel,
                Id,
                IdSupplierHotel,
                IdSupplierHotelRoomType,
                IdHotelRoomType,
            } = image;
            const objToSend = {
                IdHotel,
                IdFile: parseInt(Id),
                IdSupplierHotel,
                IdSupplierHotelRoomType,
                IdHotelRoomType,
            };
            const { data } = await api.post(
                endpoints.removeSupplierHotelImages,
                convertToOvariant(null, objToSend)
            );
            // Cuando el IdHotelImageType es 2 quiere decir que la imagen es de una habitación.
            const imageArrayName =
                image.IdHotelImageType === 2
                    ? 'SupplierHotelRoomTypesImages'
                    : 'SupplierHotelInfo.SupplierHotelImages';
            const imageArray =
                image.IdHotelImageType === 2
                    ? singleRoomData.SupplierHotelRoomTypesImages
                    : hotelInfo.SupplierHotelInfo?.SupplierHotelImages;
            if (!data.Result) {
                const failedImage = imageArray.find(
                    item => parseInt(item.Id) === image.Id
                );
                const errorMessageImage = `Unable to delete ${failedImage.OriginalFileName}. Please try again.`;
                setErrorMessage(errorMessageImage);
            } else {
                const remainingImages = imageArray.filter(
                    img => img.Id !== data.Result.Id
                );
                if (imageArrayName === 'SupplierHotelRoomTypesImages') {
                    handleInputsRoomInfo({
                        target: {
                            name: 'SupplierHotelRoomTypesImages',
                            value: remainingImages,
                        },
                    });
                } else {
                    handleInputsHotelInfo({
                        target: {
                            name: 'SupplierHotelInfo.SupplierHotelImages',
                            value: remainingImages,
                        },
                    });
                }
            }
            setErrorMaxAllowed([]);
        } catch (err) {
            setMessageBar({
                type: 'error',
                text: 'something_went_wrong',
            });
            setTimeout(() => {
                setMessageBar({
                    type: '',
                    text: '',
                });
            }, 2000);
        }
        setIsFetching(false);
    };

    const handleAddImagesToRoom = images => {
        const { SupplierHotelRoomTypesImages } = singleRoomData;
        const newImages = [...images, ...SupplierHotelRoomTypesImages];
        handleInputsRoomInfo({
            target: {
                name: 'SupplierHotelRoomTypesImages',
                value: newImages,
            },
        });
    };

    const handleUpdateImages = images => {
        const {
            SupplierHotelInfo: { SupplierHotelImages },
        } = hotelInfo;
        const newImages = [...images, ...SupplierHotelImages];
        handleInputsHotelInfo({
            target: {
                name: 'SupplierHotelInfo.SupplierHotelImages',
                value: newImages,
            },
        });
    };

    const updateIs360ImageKey = ({
        naturalWidth,
        naturalHeight,
        id,
        isRoom,
    }) => {
        const imageArray = isRoom
            ? singleRoomData.SupplierHotelRoomTypesImages
            : hotelInfo.SupplierHotelInfo?.SupplierHotelImages;
        const imgToUpdate = imageArray.find(img => img.Id === id);
        const imgIndex = imageArray.indexOf(imgToUpdate);
        const hasIs360 = imgToUpdate.Is360 !== undefined;
        if (!hasIs360 && naturalWidth / naturalHeight > 1.9) {
            const updatedImage = {
                ...imgToUpdate,
                Is360: true,
                __isChanged: true,
            };
            imageArray[imgIndex] = updatedImage;
            if (isRoom) {
                handleInputsRoomInfo({
                    target: {
                        name: 'SupplierHotelRoomTypesImages',
                        value: imageArray,
                    },
                });
            } else {
                handleInputsHotelInfo({
                    target: {
                        name: 'SupplierHotelInfo.SupplierHotelImages',
                        value: imageArray,
                    },
                });
            }
        }
    };

    async function getSupplierHotelRooms(Id) {
        const endpoint = endpoints.getRoomTypes(Id);
        const { data } = await api.get(endpoint);
        const rooms = data?.Result;
        const orderedRooms = sortByKeyDesc(rooms, 'CreationDate');
        setSupplierHotelRooms(orderedRooms);
        setIsFetching(false);
    }

    const toggleFeature = ({ Id }) => {
        const updatedImages =
            hotelInfo.SupplierHotelInfo.SupplierHotelImages.map(item => {
                if (item.Id === Id) {
                    return {
                        ...item,
                        __isChanged: true,
                        Feature: !item.Feature,
                    };
                }
                if (item.Feature === true) {
                    return {
                        ...item,
                        __isChanged: true,
                        Feature: false,
                    };
                }
                return item;
            });
        handleInputsHotelInfo({
            target: {
                name: 'SupplierHotelInfo.SupplierHotelImages',
                value: updatedImages,
            },
        });
    };

    const toggle360 = ({ Id, IdHotelImageType }) => {
        const isRoom = IdHotelImageType === 2;
        const imageArray = isRoom
            ? singleRoomData.SupplierHotelRoomTypesImages
            : hotelInfo.SupplierHotelInfo?.SupplierHotelImages;

        const currentImages = [...imageArray];
        const currentItem = currentImages.find(item => item.Id === Id);
        const currentItemIndex = currentImages.indexOf(currentItem);
        currentImages[currentItemIndex] = {
            ...currentItem,
            __isChanged: true,
            Is360: !currentItem.Is360,
        };
        if (isRoom && Progress < 94) {
            //Si el progreso del hotel no está completo, tengo que actualizar la imagen tanto en el array del supplier como en el array de la habitación, porque se ejecuta primero el common/save y después el saveSupplierInfoFromWizzard y si no lo hago el segundo método machaca los cambios.
            const supplierImageArr =
                hotelInfo.SupplierHotelInfo.SupplierHotelImages;
            const currentSupplierImages = [...supplierImageArr];
            const currentSupplierItem = currentSupplierImages.find(
                item => item.Id === Id
            );
            const currentSupplierItemIndex =
                currentSupplierImages.indexOf(currentSupplierItem);
            currentSupplierImages[currentSupplierItemIndex] = {
                ...currentSupplierItem,
                __isChanged: true,
                Is360: !currentSupplierItem.Is360,
            };
            handleInputsRoomInfo({
                target: {
                    name: 'SupplierHotelRoomTypesImages',
                    value: currentImages,
                },
            });
            handleInputsHotelInfo({
                target: {
                    name: 'SupplierHotelInfo.SupplierHotelImages',
                    value: currentSupplierImages,
                },
            });
        } else if (isRoom && Progress >= 94) {
            handleInputsRoomInfo({
                target: {
                    name: 'SupplierHotelRoomTypesImages',
                    value: currentImages,
                },
            });
        } else {
            handleInputsHotelInfo({
                target: {
                    name: 'SupplierHotelInfo.SupplierHotelImages',
                    value: currentImages,
                },
            });
        }
    };

    // End of images methods
    async function fetchCurrentHotelOwner(id) {
        setIsFetching(true);
        const endpoint = endpoints.getSupplierHotelDetail(id);
        let { data } = await api.get(endpoint);
        data = data.Result;
        setIsFetching(false);
        data = resetDDBBProgress(data, id);
        const coverInfo = getCoverPropertyInfo(data);
        setCoverPageInfo(coverInfo);
        //clean emptyspaces in strings from ddbb
        setHotelInfo(data);
        setOriginalHotelInfo(data);
        getGeneralProgressInfo(
            data.SupplierHotelInfo.SupplierHotelContentWizardProgress
        );
    }

    const resetDDBBProgress = (data, id) => {
        // Para hoteles antiguos, el SupplierHotelContentWizardProgress viene vacío
        const isArrayProgressEmpty =
            !data.SupplierHotelInfo.SupplierHotelContentWizardProgress.length;
        // Si el hotel tiene progress 100 y ese array de progresos viene vacío se lo vamos a rellenar
        if (data.SupplierHotelInfo.Progress === 100 && isArrayProgressEmpty) {
            data.SupplierHotelInfo.SupplierHotelContentWizardProgress =
                setCompletedProgressArray(id);
        } else if (
            data.SupplierHotelInfo.Progress > 0 &&
            data.SupplierHotelInfo.Progress < 100 &&
            isArrayProgressEmpty
        ) {
            data.SupplierHotelInfo.Progress = 0;
        }
        return data;
    };

    const getCoverPropertyInfo = data => {
        const DDBBImages =
            [...data?.SupplierHotelInfo?.SupplierHotelImages] || [];
        const featureImageIndex = DDBBImages.findIndex(img => img.Feature);
        let images = [];
        if (featureImageIndex === -1) {
            //no hay img favorita
            images = DDBBImages.filter((img, index) => index < 5);
        } else if (featureImageIndex >= 5) {
            //no está entre las 5 primeras
            const firstFourImgs = DDBBImages.slice(0, 4);
            images = [{ ...DDBBImages[featureImageIndex] }, ...firstFourImgs];
        } else {
            images = DDBBImages.slice(0, 5);
            images = [
                { ...DDBBImages[featureImageIndex] },
                ...images.filter(img => !img.Feature),
            ];
            if (images.length < 5) {
                for (let i = images.length; i <= 5; i++) {
                    images.push({ VirtualPath: '' });
                }
            }
        }

        let description = data.SupplierHotelInfo?.Descriptions?.filter(
            des => des.IdContentLanguage === 2 && des.IdDescriptionType === 2
        );
        if (description.length) {
            description = description[0].Description;
        } else {
            description = '';
        }

        return {
            HotelName: data.MasterHotel.HotelName,
            Address: data.MasterHotel.Address,
            GeneralEmail: data.MasterHotel.GeneralEmail,
            Services: data.SupplierHotelInfo.Services.length,
            AdultsOnly: data.MasterHotel.AdultsOnly,
            Description: description,
            Rooms: data.SupplierHotelInfo.SupplierHotelRoomTypes.length,
            Images: images.map(image => {
                return { VirtualPath: image.VirtualPath };
            }),
        };
    };

    const getGeneralProgressInfo = progressArray => {
        // A partir del array de progresos SupplierHotelContentWizardProgress, devolvemos
        // un objeto del tipo { decription : { percent: 80, lastTabFilled : 2}}
        const progressObject = {};
        for (const step in tabsData) {
            progressObject[step] = getProgressStepInfo(progressArray, step);
        }
        setProgress({
            ...progress,
            prefilledProgress: progressObject,
        });
        return progressObject;
    };

    const getProgressStepInfo = (progressArray, _stepName) => {
        // Calculamos el progreso en cada step. Miramos del array grande las que son de un step concreto
        const stepProgressArray = progressArray.filter(
            item => item.Step === _stepName && item.Completed
        );
        let percent = 0;
        // Si no hay nada, ponemos el percent a 0 y la última tab rellenada la 1.
        if (!stepProgressArray.length) {
            return { percent, lastTabFilled: 1 };
        }

        // Si hay algo en el stepProgressArray, calculamos la última que hemos rellenado
        const step = tabsData[_stepName];
        let lastTabCompleted = {};
        for (const tab of step.tabs) {
            // Recorremos las todas las tabs de este paso, por orden y vemos si está en nuestro stepProgress, si está de momento es la última
            const isThisTabFilled = !!stepProgressArray.find(
                item => item.Tab === tab.title
            );
            if (isThisTabFilled) {
                lastTabCompleted = tab;
            }
        }

        percent = (lastTabCompleted.tabNumber * 100) / step.numberOfTabs;
        return {
            percent,
            lastTabFilled: lastTabCompleted.tabNumber || 1,
        };
    };

    const handleSendProgress = () => {
        // hacer los cálculos del progreso sin esperar a que se actualice el estado
        // guardar el array de progreso actualizado

        const infoToSend = savedInfo ? { ...savedInfo } : { ...hotelInfo };

        // si el progreso general ya estaba a 100, no lo tocamos
        if (Progress !== 100) {
            const progressArray = saveActualProgress();
            infoToSend.SupplierHotelInfo.SupplierHotelContentWizardProgress =
                progressArray;

            // actualizar el progreso general
            const newProgressObject = getGeneralProgressInfo(progressArray);
            let newTotalProgress = 0;
            for (const key in newProgressObject) {
                newTotalProgress += newProgressObject[key].percent;
            }
            newTotalProgress /= Object.keys(newProgressObject).length;
            newTotalProgress = Math.floor(newTotalProgress) - 5;
            infoToSend.SupplierHotelInfo.Progress = newTotalProgress;
        }
        return infoToSend;
    };

    async function getSingleRoomData(IdRoom) {
        const endpoint = endpoints.getRoomDetail(IdRoom);
        const { data } = await api.get(endpoint);
        const room = data.Result;
        if (room.MinAdults === 0) {
            room.Occupancy = '';
            room.MinAdults = 1;
        }
        if (room.MaxAdults === 0) {
            room.Occupancy = '';
            room.MaxAdults = 1;
        }
        if (room.MaxChild < 0 || room.MinChildren < 0) {
            room.Occupancy = '';
            room.MaxChild = 0;
            room.MinChildren = 0;
        }
        setSingleRoomData(room);
    }

    const cloneRoom = (IdClonedRoom, value) => {
        const clonedRoomIndex = supplierHotelRooms.findIndex(
            room => room.Id === IdClonedRoom
        );
        const newRooms = [
            ...supplierHotelRooms.slice(0, clonedRoomIndex + 1),
            value,
            ...supplierHotelRooms.slice(
                clonedRoomIndex + 1,
                supplierHotelRooms.length
            ),
        ];
        setSupplierHotelRooms(newRooms);
    };

    const createRoom = value => {
        const newRooms = [value, ...supplierHotelRooms];
        setSupplierHotelRooms(newRooms);
    };

    const [deletedRooms, setDeletedRooms] = useState([]);

    const deleteRoom = IdRoom => {
        setDeletedRooms([...deletedRooms, IdRoom]);
        const newRooms = supplierHotelRooms.filter(room => room.Id !== IdRoom);
        setSupplierHotelRooms(newRooms);
    };

    const [serviceCategories, setServiceCategories] = useState([[]]);

    /// /destination taxes/////////////

    const handleChangeDestinationTaxes = (idDestinationTax, value, name) => {
        const destinationTaxes = hotelInfo.MasterHotel.DestinationTaxes;
        const destinationTaxIndex =
            hotelInfo.MasterHotel.DestinationTaxes.findIndex(
                item => item.Id === idDestinationTax
            );

        const destinationTax = destinationTaxes[destinationTaxIndex];

        const newDestinationTax = {
            ...destinationTax,
            [name]: value,
            __isChanged: true,
        };

        destinationTaxes[destinationTaxIndex] = newDestinationTax;

        handleInputsHotelInfo({
            target: {
                name: 'MasterHotel.DestinationTaxes',
                value: destinationTaxes,
            },
        });
    };

    const handleAddDestinationTax = () => {
        let DestinationTaxes = [...hotelInfo.MasterHotel.DestinationTaxes];

        const id = Math.floor(Math.random() * -99 + -1);

        const newDestinationTaxes = [
            {
                __isChanged: true,
                __isAdded: true,
                Amount: 0,
                Id: `${id}`,
                Description: '',
                IdDestinationTaxType: '',
                IdDestinationTaxScope: '',
                IdCurrency: '',
                PerPerson: false,
                OnlyForAgeOver: false,
            },
        ];

        DestinationTaxes = [...newDestinationTaxes, ...DestinationTaxes];

        handleInputsHotelInfo({
            target: {
                name: 'MasterHotel.DestinationTaxes',
                value: DestinationTaxes,
            },
        });
    };

    const handleDeleteDestinationTaxes = idDestinationTax => {
        let destinationTaxesArray = [
            ...hotelInfo.MasterHotel?.DestinationTaxes,
        ];

        const destinationTaxIndex = destinationTaxesArray.findIndex(
            item => item.Id === idDestinationTax
        );

        const deletedDest = destinationTaxesArray[destinationTaxIndex];
        if ('__isAdded' in deletedDest) {
            destinationTaxesArray = destinationTaxesArray.filter(
                tax => tax.Id !== idDestinationTax
            );
        } else {
            destinationTaxesArray = destinationTaxesArray.map(tax => {
                if (tax.Id === idDestinationTax) {
                    tax.__isChanged = true;
                    tax.Deleted = true;
                }
                return tax;
            });
        }
        handleInputsHotelInfo({
            target: {
                name: 'MasterHotel.DestinationTaxes',
                value: destinationTaxesArray,
            },
        });
    };

    const handleChangeDescription = (id, value) => {
        const Descriptions = hotelInfo.SupplierHotelInfo?.Descriptions.map(
            item =>
                typeof item.Id === 'string'
                    ? { ...item, Id: parseInt(item.Id) }
                    : item
        );
        const descriptionIndex = Descriptions.findIndex(
            item => item.Id === parseInt(id)
        );
        const newDescriptions = [...Descriptions];
        newDescriptions[descriptionIndex].Description = value;
        newDescriptions[descriptionIndex].__isChanged = true;

        handleInputsHotelInfo({
            target: {
                name: 'SupplierHotelInfo.Descriptions',
                value: newDescriptions,
            },
        });
    };

    const IdDescriptionTypeLong = 2;

    const handleAddDescription = idLanguage => {
        const Descriptions = [...hotelInfo.SupplierHotelInfo?.Descriptions];
        const languageDescriptions = Descriptions.find(
            des => des.IdContentLanguage === idLanguage
        );

        let newDescriptionsArray;
        // Si tiene las descripciones en ese lenguaje, ya están en el array es porque están con la clave Deleted, así que le quitamos esa clave
        // Si las descripciones no están en ese array entonces hay que añadirlas
        if (languageDescriptions) {
            newDescriptionsArray = Descriptions.map(des => {
                if (des.IdContentLanguage === idLanguage) {
                    delete des.Deleted;
                }
                return des;
            });
        } else {
            const newDescription = [
                {
                    __isChanged: true,
                    __isAdded: true,
                    Id: -idLanguage,
                    IdDescriptionType: IdDescriptionTypeLong,
                    IdContentLanguage: idLanguage,
                    Description: '',
                },
            ];
            newDescriptionsArray = [...Descriptions, ...newDescription];
        }
        // Si no tiene la clave Deleted añadimos las descripciones corta y larga
        handleInputsHotelInfo({
            target: {
                name: 'SupplierHotelInfo.Descriptions',
                value: newDescriptionsArray,
            },
        });
    };

    const handleDeleteDescription = idLanguage => {
        const Descriptions = hotelInfo.SupplierHotelInfo?.Descriptions;
        let newDescription = [];
        // languageDescriptions descripciones en el idioma idLanguage
        const descriptionToDelete = Descriptions.find(
            des =>
                des.IdContentLanguage === idLanguage &&
                des.IdDescriptionType === IdDescriptionTypeLong
        );
        // Si tiene la clave __isAdded es que lo acabamos de añadir por tanto filtramos el array
        if ('__isAdded' in descriptionToDelete) {
            newDescription = Descriptions.filter(
                des => des.IdContentLanguage !== idLanguage
            );
        } else {
            // Si no tiene la clave __isAdded es que ya estaba guardado, por tanto le añadimos la clave Deleted
            newDescription = Descriptions.map(des => {
                if (des.IdContentLanguage === idLanguage) {
                    return {
                        ...des,
                        __isChanged: true,
                        Deleted: true,
                        Description: '',
                    };
                }
                return des;
            });
        }
        handleInputsHotelInfo({
            target: {
                name: 'SupplierHotelInfo.Descriptions',
                value: newDescription,
            },
        });
    };

    const handleChangeServices = (id, value) => {
        const Services = savedInfo
            ? savedInfo.SupplierHotelInfo.Services
            : hotelInfo.SupplierHotelInfo?.Services;
        const serviceIndex = Services.findIndex(item => item.Id === id);
        let newServices = [...Services];
        if (serviceIndex === -1) {
            newServices = newServices.concat(value);
        } else {
            newServices[serviceIndex] = value;
        }
        handleInputsHotelInfo({
            target: { name: 'SupplierHotelInfo.Services', value: newServices },
        });
    };

    const handleChangeRoomServices = (id, value) => {
        const Services = singleRoomData?.Services;
        const idToString = { ...value, Id: `${value.Id}` };
        const serviceIndex = Services.findIndex(item => item.Id === `${id}`);
        let newServices = [...Services];
        if (serviceIndex === -1) {
            newServices = newServices.concat(idToString);
        } else {
            newServices[serviceIndex] = idToString;
        }
        handleInputsRoomInfo({
            target: { name: 'Services', value: newServices },
        });
    };

    const { MinAdults, MaxAdults, AdultOnly, MinChildren, MaxChild } =
        singleRoomData;
    const occupancyConditionsAdults =
        MinAdults <= 10 && MinAdults <= MaxAdults && MaxAdults <= 10;
    const occupancyConditionsChildren =
        MinChildren <= 10 && MinChildren <= MaxChild && MaxChild <= 10;

    const handleSave = async () => {
        let endPoint;
        let objectToSend;
        if (!stepName || !tabName) {
            return;
        } else if (stepName === 'room_configuration') {
            endPoint = endpoints.commonSave;
            if (AdultOnly) {
                singleRoomData.MaxOccupancy = MaxAdults;
            } else {
                singleRoomData.MaxOccupancy = MaxAdults + MaxChild;
            }
            // esta condición es para prevenir que se guarden ocupaciones no válidas si se pulsa guardar y salir.

            objectToSend =
                occupancyConditionsAdults && occupancyConditionsChildren
                    ? convertToOvariant(
                          savableEntities.SupplierHotelRoomType,
                          singleRoomData,
                          singleRoomData.Id
                      )
                    : {};
            try {
                const response = await api.post(endPoint, objectToSend);
                if (response.status === 201) {
                    const _singleRoomData = response.data;
                    _singleRoomData.StandardOccupancy = '1';
                    setSingleRoomData(singleRoomData);
                    const infoToSend = handleSendProgress();
                    // Esta condición evita que se llame al método SaveSupplierHotelFromWizzard cuando modificar datos ya no tiene impacto en clave Progress.
                    if (Progress < 94) {
                        endPoint = endpoints.saveSupplierHotelFromWizard;
                        objectToSend = convertToOvariant(
                            savableEntities.SupplierHotel,
                            infoToSend
                        );
                        const { data } = await api.post(endPoint, objectToSend);
                        setHotelInfo(data.Result);
                        const coverInfo = getCoverPropertyInfo(data.Result);
                        setCoverPageInfo(coverInfo);
                    }
                    setMessageBar({
                        type: 'success',
                        text: 'Your_data_has_been_updated_successfully',
                    });
                    setDeletedRooms([]);
                    setTimeout(() => {
                        setMessageBar({
                            type: '',
                            text: '',
                        });
                    }, 2000);
                }
            } catch (error) {
                setMessageBar({
                    type: 'error',
                    text: 'something_went_wrong',
                });
            }
        } else {
            const infoToSend = handleSendProgress();
            endPoint = endpoints.saveSupplierHotelFromWizard;
            objectToSend = convertToOvariant(
                savableEntities.SupplierHotel,
                infoToSend
            );
            try {
                const response = await api.post(endPoint, objectToSend);
                if (response.status === 200) {
                    if (response.data.Success === false) {
                        setMessageBar({
                            type: 'error',
                            text: 'error_save_contact_fph',
                        });
                    } else {
                        setHotelInfo(response.data.Result);
                        setOriginalHotelInfo(response.data.Result);
                        const coverInfo = getCoverPropertyInfo(
                            response.data.Result
                        );
                        setCoverPageInfo(coverInfo);
                        getGeneralProgressInfo(
                            response.data.Result.SupplierHotelInfo
                                .SupplierHotelContentWizardProgress
                        );
                        setMessageBar({
                            type: 'success',
                            text: 'Your_data_has_been_updated_successfully',
                        });
                        setTimeout(() => {
                            setMessageBar({
                                type: '',
                                text: '',
                            });
                        }, 2000);
                        localStorage.removeItem('hotelInfo');
                    }
                }
            } catch (error) {
                setMessageBar({
                    type: 'error',
                    text: 'something_went_wrong',
                });
            }
        }
    };

    function saveActualProgress() {
        const { Id, SupplierHotelContentWizardProgress } =
            hotelInfo.SupplierHotelInfo;

        let lastProgress = [...SupplierHotelContentWizardProgress];

        const newProgress = {
            IdSupplierHotel: parseInt(Id),
            Step: stepName,
            Tab: tabName,
            Completed: true,
            __isAdded: true,
            __isChanged: true,
            Id: '-1',
        };

        const isNotCompletedYet = !lastProgress.find(
            item =>
                item.Tab === newProgress.Tab && item.Step === newProgress.Step
        );
        if (isNotCompletedYet && stepName && tabName) {
            lastProgress = lastProgress.concat(newProgress);
            handleProgress(lastProgress);
        }
        return lastProgress;
    }

    const savedInfo = JSON.parse(localStorage.getItem('hotelInfo'));

    const resetLocation = () => {
        const newObj = Object.assign({}, hotelInfo);
        newObj.MasterHotel.IdRegion = 0;
        newObj.MasterHotel.IdProvince = 0;
        newObj.MasterHotel.IdCity = 0;
        setHotelInfo(newObj);
        localStorage.setItem('hotelInfo', JSON.stringify(newObj));
    };

    return (
        <ContextOwnerPortal.Provider
            value={{
                hotelInfo,
                handleInputsHotelInfo,
                handleInputsRoomInfo,
                setHotelInfo,
                fetchCurrentHotelOwner,
                isChecked,
                setIsChecked,
                messageBar,
                setMessageBar,
                setErrorMaxAllowed,
                Descriptions: hotelInfo.SupplierHotelInfo?.Descriptions,
                handleChangeDescription,
                handleAddDescription,
                handleDeleteDescription,
                handleSave,
                serviceCategories,
                setServiceCategories,
                RoomServices: singleRoomData.Services,
                handleChangeServices,
                IdHotel: hotelInfo.SupplierHotelInfo?.Id,
                visibleMessageTypeahead,
                setVisibleMessageTypeahead,
                setSupplierHotelRooms,
                getSupplierHotelRooms,
                supplierHotelRooms,
                getSingleRoomData,
                cloneRoom,
                deletedRooms,
                deleteRoom,
                createRoom,
                handleChangeRoomServices,
                singleRoomData,
                setSingleRoomData,
                errorMessage,
                errorMaxAllowed,
                toggleFeature,
                toggle360,
                progress,
                setProgress,
                handleProgress,
                getProgressStepInfo,
                saveActualProgress,
                isFetching,
                setIsFetching,
                SupplierHotelMealPlansTypes:
                    hotelInfo.SupplierHotelInfo?.SupplierHotelMealPlansTypes,
                deleteImage,
                uploadFiles,
                SupplierHotelImages:
                    hotelInfo.SupplierHotelInfo?.SupplierHotelImages,
                DestinationTaxes: hotelInfo.MasterHotel?.DestinationTaxes,
                handleChangeDestinationTaxes,
                handleAddDestinationTax,
                handleDeleteDestinationTaxes,
                checkLocalStorage,
                updateIs360ImageKey,
                setErrorMessage,
                coverPageInfo,
                trimValueOnBlur,
                resetLocation,
                originalHotelInfo, // Para comparar si se han hecho cambios
                SupplierHotelSegments:
                    hotelInfo.SupplierHotelInfo?.HostSegments,
            }}
        >
            {children}
        </ContextOwnerPortal.Provider>
    );
};

export const ProviderOwnerPortal = withRouter(ProviderOwner);
