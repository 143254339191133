import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import LanguageSelector from './languageSelector/LanguageSelector';
import routes from '../../utils/commonVariables/routes';

const FooterNav = () => {
    const { translate } = useContext(ContextUserProfile);
    const contactUsText = translate('contact_US');
    const privacyPolicyText = translate('privacy_policy');

    return (
        <nav className='footer__nav'>
            <ul className='footer-nav__list'>
                <li className='footer-nav__item'>
                    <LanguageSelector />
                </li>
                <li className='footer-nav__item'>
                    <Link
                        to={routes.contact}
                        className='footer-nav__link'
                        data-before={contactUsText}
                    >
                        {contactUsText}
                    </Link>
                </li>
                <li className='footer-nav__item'>
                    <Link
                        to={routes.privacyPolicy}
                        target='blank'
                        className='footer-nav__link'
                        data-before={privacyPolicyText}
                    >
                        {privacyPolicyText}
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default FooterNav;
