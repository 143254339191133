import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import CheckboxList from '../../components/CheckboxList';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';

const Segments = () => {
    const { translate } = useContext(ContextUserProfile);
    const { handleInputsHotelInfo, SupplierHotelSegments } =
        useContext(ContextOwnerPortal);

    const mealPlansCheckBoxProps = {
        name: 'SupplierHotelInfo.HostSegments',
        apiEndPoint: buildOdataEndPoint({
            entity: readOnlyEntities.HostSegment,
            select: 'Id,Caption,LocalizedCaption',
            filter: 'Enabled',
        }),
        ActionInfo: 'LocalizedCaption',
        ActionInfo2: 'Caption',
        containerClassName: 'meal-plans__container',
    };

    return (
        <div className='your-properties-formV2'>
            <h1 className='your-properties-title'>
                {translate('description_segments')}
            </h1>
            <CheckboxList
                data={mealPlansCheckBoxProps}
                value={SupplierHotelSegments}
                handleChange={handleInputsHotelInfo}
            />
            <div className='d-flex justify-content-between'>
                <GeneralButton type='back' text={translate('back')} />
                <GeneralButton type='next' text={translate('continue')} />
            </div>
        </div>
    );
};

export default Segments;
