import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

export default function InputText(props) {
    const { data, handleChange, value } = props;
    const { translate } = useContext(ContextUserProfile);
    const { title, name, required } = data;

    return (
        <div className='form-group input-container'>
            <label htmlFor={name}>
                {`${translate(title)} ${required ? ' *' : ''}`}
            </label>
            <textarea
                {...data}
                className='form-control'
                id={name}
                value={value}
                placeholder={translate(title)}
                onChange={handleChange}
            />
        </div>
    );
}
