import React, { useContext } from 'react';
import IconDetail from './IconDetail';
import CardList from './CardList';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import WithTooltip from '../WithTooltip';
import { formatNumberByLanguage } from '../../../../utils/usefulFunctions';

const PerOccupancy = ({ occupancies }) => {
    const { translate, language } = useContext(ContextUserProfile);

    const sortedOccupancies = occupancies.sort((a, b) => {
        if (a.adults === b.adults) return a.children - b.children;
        return a.adults - b.adults;
    });

    const Icons = ({ num, iconName }) => {
        const array = [];
        array.length = num;
        array.fill(num);
        return array.map((_item, index) => (
            <IconDetail iconName={iconName} key={index} />
        ));
    };

    const textToolTip = (adults, children) => {
        const textAdults =
            adults > 1
                ? translate('Adults')
                : adults === 1
                ? translate('adult')
                : null;
        const textAdults2 = textAdults ? `${adults} ${textAdults}` : null;
        const textChild =
            children > 1
                ? translate('Children')
                : children === 1
                ? translate('child')
                : null;
        const textChild2 = textChild ? `${children} ${textChild}` : '';
        return `${textAdults2} ${textChild2}`;
    };

    const occupancyItems = sortedOccupancies.map(
        ({ adults, children, aat }) => (
            <WithTooltip
                key={`${adults}-${children}`}
                title={textToolTip(adults, children)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                containerStyles={{ width: '100%' }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    key={`${adults}-${children}`}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                        }}
                    >
                        <Icons num={adults} iconName='adult' />
                        <Icons num={children} iconName='child' />
                    </div>
                    <span>{formatNumberByLanguage(aat, language)}</span>
                </div>
            </WithTooltip>
        )
    );

    return <CardList items={occupancyItems} />;
};

export default PerOccupancy;
