import { formatDateYYMMDD } from '../../../utils/formatDates';

export const columns = [
    { label: 'accordion' },
    { label: 'quality' },
    {
        label: 'date',
        sortableProps: { field: 'date', defaultDir: 'asc' },
    },
    { label: 'hotel' },
    { label: 'room' },
    { label: 'rate' },
    { label: 'meal_plan' },
    {
        label: 'LoS',
        sortableProps: { field: 'los', defaultDir: 'asc' },
    },
    {
        label: 'ari_last_updated',
        sortableProps: { field: 'datelastupdated', defaultDir: 'desc' },
    },
    { label: 'availability' },
];

export const numColumns = columns.length;

export const cleanedAriId = ariId => ariId.replace(/\[|\]/g, ''); // le quito al ariId los corchetes pq si no da error en la consola

const DailyKey = 'Daily';
export const getLosValue = los => (los === 0 ? DailyKey : los);
export const green = '#107c10';
export const red = '#d13438';
const orange = '#ea4300';

const qualities = {
    0: {
        iconName: 'mood',
        color: green,
        reason: 'high_quality_reason',
        text: 'high_quality',
    },
    1: {
        iconName: 'SentimentNeutral',
        color: orange,
        reason: 'medium_quality_reason',
        text: 'medium_quality',
    },
    2: {
        iconName: 'SentimentVeryDissatisfied',
        color: red,
        reason: 'low_quality_reason',
        text: 'low_quality',
    },
    3: {
        iconName: 'WarningAmber',
        color: red,
        text: 'invalid_quality',
    },
};

export const qualitySelectOptions = Object.values(qualities).map(
    ({ text, iconName, color }, index) => ({
        text,
        value: index,
        iconName,
        color,
    })
);

export const getQualityStatus = (validity, quality) => {
    const { valid } = validity;
    if (valid) {
        const { level } = quality;
        const { iconName, color: iconColor, reason } = qualities[level];
        return {
            iconName,
            iconColor,
            reason,
        };
    } else {
        const { iconName, color: iconColor } = qualities['3'];
        return {
            iconName,
            iconColor,
            reason: validity.reason,
        };
    }
};

export const getRateModelKey = rates => {
    const model = rates?.model;
    if (!rates || !model) return 'noRates';
    if (model === 1) return 'occupancy';
    if (model === 2) return 'room';
    if (model === 3) return 'person';
};

export const formatHotels = hotel => ({
    IdSupplierHotel: hotel.Id,
    Name: hotel.Name,
    MappingCode: hotel.MappingCode,
    Cyrenne: hotel.Hotel.Code,
    IdHotel: hotel.IdHotel,
});

export const formatLosParam = losValue => {
    // los = length of stay
    // si es un precio diario (Daily) se manda el nodo { daily: true }
    // Te doy un precio sólo si vienes al menos 5 días { daily: false, los: 5 }
    // En general, la mayoría de hoteles tienen precio daily pero este HGB-9C3XGR9J+M6-E00 tiene precios con los definido
    const isDaily = losValue === 0;
    return losValue === undefined
        ? undefined
        : {
              daily: isDaily,
              los: isDaily ? undefined : losValue,
          };
};

export const losOptions = [
    { text: 'Daily', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
];

export const formatAriDates = date => {
    if (!date) return undefined;
    return formatDateYYMMDD(date);
};

export const formatQualityFilter = quality => {
    const isQualityType = typeof quality === 'number';
    if (!isQualityType) return undefined;
    return quality;
};

export const ageSegmentTypes = (segmentId = 0) => {
    const types = {
        0: { tooltipText: 'child', iconName: 'child' },
        1: { tooltipText: 'baby', iconName: 'baby' },
        2: {
            tooltipText: 'Child first age range',
            ageRange: 'A',
            iconName: 'childSegment',
        },
        3: {
            tooltipText: 'Child second age range',
            ageRange: 'B',
            iconName: 'childSegment',
        },
        4: {
            tooltipText: 'Child third age range',
            ageRange: 'C',
            iconName: 'childSegment',
        },
    };
    return types[segmentId] || types[0];
};

export const childIconColor = 'rgb(25, 151, 78)';

export const dateInThreeMonths = startDate => {
    const copyStartDate = new Date(startDate);
    return new Date(copyStartDate.setMonth(copyStartDate.getMonth() + 3));
};
