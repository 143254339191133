import React from 'react';

const CollapseMenuIcon = props => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <rect width='24' height='24' fill='white' />
            <rect
                x='22'
                y='19'
                width='14'
                height='2'
                transform='rotate(180 22 19)'
                fill='#727272'
            />
            <rect
                x='22'
                y='7'
                width='14'
                height='2'
                transform='rotate(180 22 7)'
                fill='#727272'
            />
            <rect
                x='22'
                y='13'
                width='19'
                height='2'
                transform='rotate(180 22 13)'
                fill='#727272'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.25116 8.50098L5.74885 9.49944L4.08167 12.0002L5.74885 14.501L4.25116 15.4994L1.91833 12.0002L4.25116 8.50098Z'
                fill='#727272'
            />
        </svg>
    );
};

export default CollapseMenuIcon;
