import React, { useContext, useEffect } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
import ServiceStepsContainer from '../../components/accordion/ServiceStepsContainer';
import './services.scss';
import MessageBarInfo from '../../components/MessageBarInfo';
import { Button } from '@mui/material';

export default function Services({ type, text }) {
    const { translate } = useContext(ContextUserProfile);
    const { progress, setProgress } = useContext(ContextOwnerPortal);
    const { currentSubTab } = progress.current;

    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentSubTab: 1,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeMoreServices = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentSubTab: 1,
            },
        });
    };

    const openMoreServices = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentSubTab: currentSubTab + 1,
            },
        });
    };

    return (
        <div className='your-properties-formV2'>
            <ServiceStepsContainer />
            <div className='d-flex justify-content-between'>
                <GeneralButton type='back' text={translate('back')} />
                <div className='services-next-buttons__container'>
                    {currentSubTab === 1 ? (
                        <Button onClick={openMoreServices} variant='contained'>
                            {translate('view_more_services')}
                        </Button>
                    ) : (
                        <Button onClick={closeMoreServices} variant='contained'>
                            {translate('view_quick_services')}
                        </Button>
                    )}
                    <GeneralButton
                        type={currentSubTab === 1 ? 'skip' : 'next'}
                        text={translate('continue')}
                        className='services-next__button'
                    />
                </div>
            </div>
            <div className='mt-4'>
                <MessageBarInfo type={type} text={text} />
            </div>
        </div>
    );
}
