import React from 'react';
import ThumbNailButton from './ThumbNailButton';

export default function ThumbNailButtonList({ buttonList, image }) {
    return (
        <ul className='thumbNail__button-list'>
            {buttonList.map(button => (
                <li key={`${button.title}-${image.Id}`}>
                    <ThumbNailButton button={button} image={image} />
                </li>
            ))}
        </ul>
    );
}
