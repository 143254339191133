import React, { useContext } from 'react';
import '../../../stylesheets/stylesComponents/simpleTable.scss';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const SimpleTable = props => {
    const { Name, Value, Headers } = props;
    const { translate } = useContext(ContextUserProfile);
    const jc = Value.length < 3 ? '--start' : '--between';
    const getClassName = (arrLength, itemIndex) => {
        if (arrLength === 2 && itemIndex === 1) {
            return '-extend';
        }
        return '';
    };
    return (
        <div className='s-table__container'>
            {Name && <p className='s-table--title'>{Name}</p>}
            <table className='s-table'>
                <tbody>
                    <tr className={`s-table__row${jc}`}>
                        {Headers.map((header, index) => (
                            <th
                                className={`s-table__row--header${getClassName(
                                    Headers.length,
                                    index
                                )}`}
                                key={index}
                            >
                                {translate(header)}
                            </th>
                        ))}
                    </tr>

                    {Value.map((item, i) => (
                        <tr className={`s-table__row${jc}`} key={i}>
                            {item.map((el, index) => (
                                <td
                                    key={index}
                                    className={`s-table__row--item${getClassName(
                                        Value[0].length,
                                        index
                                    )}`}
                                >
                                    {typeof el === 'string'
                                        ? el.toLowerCase()
                                        : el}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SimpleTable;
