import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';

export default function SwitchInput({
    labelText,
    onChangeMethod,
    checked,
    ...restProps
}) {
    return (
        <FormControlLabel
            control={<Switch onChange={onChangeMethod} checked={checked} />}
            label={labelText}
            {...restProps}
        />
    );
}
