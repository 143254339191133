import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import useTranslate from '../../customHooks/useTranslate';

const CardTitle = props => {
    const {
        isEditButtonVisible,
        title = '',
        setEditionMode,
        label = '',
    } = props;
    const labelText = useTranslate(label) || '';
    const editText = useTranslate('edit');

    return (
        <div className='card__title'>
            <Tooltip title={labelText} placement='top' arrow>
                <h2 className='card__title-text'>{useTranslate(title)}</h2>
            </Tooltip>
            {isEditButtonVisible && (
                <Tooltip title={editText} placement='top' arrow>
                    <IconButton
                        color='primary'
                        onClick={() => setEditionMode(true)}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default CardTitle;
