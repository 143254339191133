import React from 'react';
import { Skeleton } from '@mui/material';
import '../../../stylesheets/stylesLayout/cardHotelProperties.scss';

const CardPropertyShimmer = () => {
    return (
        <div className='card__property__container'>
            <Skeleton
                variant='rectangular'
                animation='wave'
                width={'100%'}
                height={'70%'}
                style={{ borderRadius: '7px 7px 0 0' }}
            />
            <Skeleton animation='wave' style={{ margin: '12px' }} />
            <Skeleton animation='wave' style={{ margin: '0 12px' }} />
        </div>
    );
};

export default CardPropertyShimmer;
