import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../../formsProperty/Ari/Icon';

const TypeaheadErrorMessage = ({ onClick }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                justifyContent: 'space-between',
            }}
        >
            <span>There was an error, please try again</span>
            <IconButton title='Reload' size='small' onClick={onClick}>
                <Icon iconName='Replay' />
            </IconButton>
        </div>
    );
};

export default TypeaheadErrorMessage;
