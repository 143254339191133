import React, { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import SectionTitle from '../../property-partners-portal/components/SectionTitle/SectionTitle';
import { ContextAccountInformation } from '../../contexts/AccountInformationContext';
import CardPlaceholder from '../components/CardPlaceholder';
import NonEditionContent from './NonEditionContent';
import EditionContent from './EditionContent';
import '../../stylesheets/presentationCards.scss';
import TermsAndConditionsViewPopUp from '../../popups/TermsAndConditionsViewPopUp';
import TermsAndConditionsAcceptedInfo from './TermsAndConditionsAcceptedInfo';
import { ContextTermsAndConditions } from '../../contexts/TermsAndConditionsContext';

const AccountInformationContainer = () => {
    const {
        isEditionMode,
        setIsEditButtonVisible,
        isLoadingAccountInformation,
    } = useContext(ContextAccountInformation);
    const { setIsReadOnly } = useContext(ContextTermsAndConditions);

    const [isOpenPopUp, setIsOpenPopUp] = useState(false);

    const handleOpenPopUp = () => {
        setIsOpenPopUp(true);
        setIsReadOnly(true);
    };

    return (
        <div className='account-info__container'>
            <div className='page-title__container'>
                <SectionTitle title={'account_info'} />
            </div>
            {isLoadingAccountInformation ? (
                <CardPlaceholder height='500px' />
            ) : (
                <div
                    className='card'
                    onMouseEnter={() => setIsEditButtonVisible(true)}
                    onMouseLeave={() => setIsEditButtonVisible(false)}
                >
                    {isEditionMode ? <EditionContent /> : <NonEditionContent />}
                </div>
            )}

            <Typography variant='body1'>
                <TermsAndConditionsAcceptedInfo
                    handleOpenPopUp={handleOpenPopUp}
                />
            </Typography>

            <TermsAndConditionsViewPopUp
                isOpenPopUp={isOpenPopUp}
                setIsOpenPopUp={setIsOpenPopUp}
            />
        </div>
    );
};

export default AccountInformationContainer;
