export default {
    IdSupplierHotel: true,
    Name: true,
    Size: true,
    Description: true,
    AdultOnly: true,
    FreeBaby1: true,
    FreeBaby2: true,
    Occupancy: true,
    MinAdults: true,
    MaxAdults: true,
    MinChildren: true,
    MaxChild: true,
    StandardOccupancy: true,
    Services: true,
    SupplierHotelRoomBedTypes: true,
    SupplierHotelRoomTypesImages: true,
};

export const newRoomInitialState = {
    Id: "-1",
    Name: "New room",
    Size: 1,
    Description: "",
    AdultOnly: true,
    FreeBaby1: false,
    FreeBaby2: false,
    Occupancy: "",
    MinAdults: 1,
    MaxAdults: 1,
    MinChildren: 0,
    MaxChild: 0,
    StandardOccupancy: 1,
    Services: [],
    SupplierHotelRoomBedTypes: [],
    SupplierHotelRoomTypesImages: [],
}
