import React, { useContext } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { ContextUserProfile } from '../contexts/UserProfileContext';
import useTranslate from '../customHooks/useTranslate';
import TextField from '@mui/material/TextField';

const DateInput = props => {
    const { labelText, errorMessage, required, onBlur, ...restProps } = props;
    const { language } = useContext(ContextUserProfile);

    const languageSettings = {
        en: { locale: enLocale, mask: '__/__/____' },
        fr: { locale: frLocale, mask: '__/__/____' },
        es: { locale: esLocale, mask: '__/__/____' },
        de: { locale: deLocale, mask: '__.__.____' },
    };
    const locale = languageSettings[language].locale;
    // const mask = languageSettings[language].mask;

    const translatedErrorMessage = useTranslate(errorMessage);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <DatePicker
                label={useTranslate(labelText)}
                renderInput={params => (
                    <TextField
                        size='small'
                        required={required}
                        {...params}
                        error={!!errorMessage}
                        helperText={translatedErrorMessage}
                        onBlur={onBlur}
                    />
                )}
                required={required}
                onBlur={() => console.log('blur')}
                {...restProps}
            />
        </LocalizationProvider>
    );
};

export default DateInput;
