import React from 'react';
import './conditionsstatemens.scss';

export default function PrivacyPolicyES() {
    return (
        <div className='container__policies'>
            <h1 className='conditions__title'>
                Condiciones y Términos de Uso del sitio web de Fastpayhotels
            </h1>
            <ol className='conditions__section'>
                <li className='section__title-li'>
                    Titularidad del sitio web y aceptación de los Términos de
                    Uso
                </li>
                <p className='section__paragraph'>
                    La lectura y aceptación de los presentes Términos y
                    Condiciones de Uso (en adelante, los “Términos de Uso”) es
                    condición necesaria para el uso del sitio
                    www.fastpayhotels.com (en adelante, el “Sitio Web”).
                </p>
                <p className='section__paragraph--policies'>
                    Los Términos de Uso constituyen Condiciones Generales de
                    Contratación, tienen naturaleza contractual y obligacional
                    y, sin perjuicio de las condiciones particulares que las
                    partes puedan acordar en su caso, rigen la relación entre
                    todo usuario del Sitio Web (en lo sucesivo, el “Usuario”)
                    con Fastpayhotels, S.L. (en adelante, “Fastpayhotels”), una
                    sociedad de nacionalidad española, con domicilio en la calle
                    Pare Antoni Oliver 2-8, CP 07014, inscrita en el Registro
                    Mercantil de Palma de Mallorca al Tomo 2635, Folio 19, Hoja
                    PM-77490, con CIF B-57940066 y con e-mail:
                    info@fastpayhotels.com
                </p>
                <p className='section__paragraph--policies'>
                    La utilización de este Sitio Web y sus servicios implica la
                    plena aceptación de las disposiciones incluidas en estos
                    Términos de Uso conforme a la versión publicada por
                    Fastpayhotels en el momento en que el Usuario acceda al
                    Sitio Web.
                </p>
                <li className='section__title-li'>Uso del Sitio Web</li>
                <p className='section__paragraph'>
                    Los Usuarios renuncian a utilizar cualquiera de los
                    materiales e informaciones contenidos en este Sitio Web con
                    fines ilícitos o expresamente prohibidos en los presentes
                    Términos de Uso, así como a las condiciones particulares
                    que, en su caso, se habiliten, o en contra de los derechos e
                    intereses de Fastpayhotels, sus miembros o terceros y
                    deberán responder frente a éstos en caso de contravenir o
                    incumplir dichas obligaciones o, de cualquier modo (incluida
                    la introducción o difusión de “virus informáticos”) dañar,
                    inutilizar, sobrecargar, deteriorar o impedir la normal
                    utilización de los materiales e información contenidos en el
                    Sitio Web, los sistemas de información o los documentos,
                    archivos y toda clase de contenidos almacenados en cualquier
                    equipo informático (hacking) de Fastpayhotels, de sus
                    miembros o de cualquier Usuario del Sitio Web.
                </p>
                <li className='section__title-li'>
                    Propiedad intelectual e industrial
                </li>
                <p className='section__paragraph'>
                    El Sitio Web en su totalidad, incluyendo sin exclusión
                    alguna su diseño, estructura y distribución, textos y
                    contenidos, logotipos, botones, imágenes, dibujos, marcas,
                    nombres comerciales, código fuente, creatividades, así como
                    todos los derechos de propiedad intelectual e industrial y
                    cualquier otro signo distintivo, pertenecen o tienen como
                    cesionaria a Fastpayhotels, o, en su caso, a las personas
                    físicas o jurídicas que figuran como autores o titulares de
                    los derechos o de sus licenciantes, sin que pueda entenderse
                    que el uso y acceso al Sitio Web y la utilización de sus
                    servicios atribuya al Usuario derecho alguno sobre los
                    citados elementos.
                </p>
                <p className='section__paragraph--policies'>
                    Está prohibida la reproducción, explotación, alteración,
                    distribución o comunicación pública del Sitio Web para usos
                    diferentes de la legítima información de los Usuarios.
                </p>
                <li className='section__title-li'>Política de cookies.</li>
                <p className='section__paragraph'>
                    El Sitio Web puede utilizar cookies cuando un Usuario accede
                    al mismo.
                </p>
                <p className='section__paragraph--policies'>
                    Las cookies es un archivo de datos que se descarga en su
                    ordenador al acceder a determinadas páginas web y que
                    permiten a la misma, almacenar y recuperar datos y otras
                    funcionalidades técnicas, como guardar preferencias de
                    navegación, recopilar información estadística, información
                    sobre el equipo, pueden utilizarse para reconocer usuarios
                    ofreciéndoles el contenido más apropiado según sus hábitos.
                </p>
                <p className='section__paragraph--policies'>
                    Sin embargo, una cookie no puede borrar ni leer información
                    del ordenador, ni tampoco identifica a una persona, sino que
                    reconoce una combinación de computación-navegador-Usuario.
                </p>
                <p className='section__paragraph--policies'>
                    Este Sitio Web utiliza exclusivamente cookies propias de
                    carácter técnico, cuya finalidad es permitir la comunicación
                    entre esta Web y el dispositivo del Usuario.
                </p>
                <p className='section__paragraph--policies'>
                    Mediante la configuración por defecto de este Sitio Web, el
                    Usuario acepta que se puedan instalar cookies en su terminal
                    y que nos permiten conocer la siguiente información:
                </p>
                <ul>
                    <li className='section__paragraph--policies'>
                        El login del Usuario para mantener la sesión activa en
                        el Sitio Web.
                    </li>
                    <li>
                        El formato del Sitio Web preferente en el acceso desde
                        dispositivos móviles.
                    </li>
                    <li>
                        Las últimas búsquedas realizadas en los servicios del
                        Sitio Web y datos de personalización de estos servicios.
                    </li>
                </ul>
                <p className='section__paragraph--policies'>
                    El Usuario puede aceptar, revocar el consentimiento o
                    eliminar las cookies, así como borrar sus datos de
                    navegación (incluidas las cookies) instaladas en su equipo
                    mediante la configuración de las opciones del navegador que
                    el Usuario utiliza.
                </p>
                <p className='section__paragraph--policies'>
                    A continuación, se detallan los pasos necesarios para
                    configurar el uso de las cookies en función del navegador
                    que se utilice:
                </p>
                <p className='section__paragraph--policies'>
                    Puede usted aceptar, revocar el consentimiento o eliminar
                    las cookies, así como borrar sus datos de navegación
                    (incluidas las cookies) instaladas en su equipo mediante la
                    configuración de las opciones del navegador que usted
                    utiliza. A continuación se detallan los pasos necesarios
                    para configurar el uso de las cookies en función del
                    navegador que se utilice:
                    <ul>
                        <li className='section__paragraph--policies'>
                            Internet Explorer: Herramientas -> Opciones de
                            Internet -> Privacidad -> Configuración. Para más
                            información, puede consultar el soporte de Microsoft
                            o la Ayuda del navegador.
                        </li>
                        <li>
                            Firefox: Herramientas -> Opciones -> Privacidad ->
                            Historial -> Configuración Personalizada. Para más
                            información, puede consultar el soporte de Mozilla o
                            la Ayuda del navegador.
                        </li>
                        <li>
                            Chrome: Configuración -> Mostrar opciones avanzadas
                            -> Privacidad -> Configuración de contenido. Para
                            más información, puede consultar el soporte de
                            Google o la Ayuda del navegador.
                        </li>
                        <li>
                            Safari: Preferencias -> Seguridad. Para más
                            información, puede consultar el soporte de Apple o
                            la Ayuda del navegador.
                        </li>
                    </ul>
                    En caso de bloquear el uso de ‘cookies’ en su navegador es
                    posible que algunos servicios o funcionalidades de la página
                    web no estén disponibles.
                </p>
                <li className='section__title-li'>Política de privacidad.</li>
                <p className='section__paragraph'>
                    La lectura y aceptación de la política de privacidad de
                    Fastpayhotels (en adelante, la “Política de Privacidad”) es
                    condición necesaria para la utilización del Sitio.
                </p>
                <p className='section__paragraph--policies'>
                    Esta Política de Privacidad regula la recopilación y el uso
                    de la información de los Usuarios del Sitio Web a partir de
                    la fecha de entrada en vigor que aparece al final de los
                    presentes Términos de Uso. No se aplica a cualquier otra URL
                    a la que se acceda desde el Sitio Web, que tendrá su propia
                    política en relación con la recopilación de datos y su uso.
                </p>
                <p className='section__paragraph--policies'>
                    Por favor, lea atentamente esta Política de Privacidad antes
                    de empezar a utilizar el Sitio Web y facilitar cualquier
                    tipo de información personal. Le ayudará a tomar decisiones
                    informadas.
                </p>
                <p className='section__paragraph--policies'>
                    En el tratamiento de los datos de carácter personal de sus
                    Usuarios, Fastpayhotels cumple en todo momento con la
                    legislación vigente en España y en la Unión Europea y en
                    concreto, con la Ley Orgánica 3/2018, de 5 de diciembre, de
                    Protección de Datos Personales y garantía de los derechos
                    digitales, y el Reglamento Europeo 2016/679 de 27 de abril,
                    relativo a la protección de las personas físicas en lo que
                    respecta al tratamiento de datos personales y a la libre
                    circulación de estos datos y por el que se deroga la
                    Directiva 95/46/CE de Protección de Datos de Carácter
                    Personal (en adelante el “RGPD”). Fastpayhotels, en su
                    condición de Responsable del tratamiento de datos personales
                    (en adelante, el “Responsable”) sólo trata los datos
                    personales obtenidos de los formularios voluntariamente
                    rellenados por los Usuarios bajos los principios de licitud,
                    lealtad y transparencia, limitación de la finalidad,
                    minimización de datos, exactitud, limitación del plazo de
                    conservación, integridad y confidencialidad y
                    responsabilidad proactiva.
                </p>
                <p className='section__paragraph--policies'>
                    IDENTIDAD Y DATOS DE CONTACTO DEL RESPONSABLE
                </p>
                <p className='section__paragraph'>
                    Fastpayhotels, S.L. con domicilio en la calle Pare Antoni
                    Oliver 2-8, CP 07014, CIF B-57940066, con e-mail
                    info@fastpayhotels.com y teléfono +34 971 416 770.
                </p>
                <p className='section__paragraph'>
                    El Usuario puede contactar con el Delegado de Protección de
                    Datos del Responsable en la siguiente dirección de correo
                    electrónico info@fastpayhotels.com y mediante correo postal
                    a la dirección del Responsable.
                </p>
                <p className='section__paragraph--policies'>
                    CATEGORIAS DE DATOS, FINALIDAD DEL TRATAMIENTO Y BASE
                    JURÍDICA DEL MISMO
                </p>
                <p className='section__paragraph'>
                    El Responsable trata las siguientes categorías de datos de
                    los Usuarios:
                </p>
                <p className='section__paragraph'>
                    Datos de contacto: nombre, apellidos, documento de
                    identidad, edad, NIF, dirección postal y de correo
                    electrónico, teléfono, IP, país, dispositivo e idioma.
                </p>
                <p className='section__paragraph'>
                    Preferencias: información que el Usuario facilite acerca de
                    sus preferencias, por ejemplo, el tipo de servicios
                    adquiridos por el Usuario.
                </p>
                <p className='section__paragraph--policies'>
                    Uso de la web y de las comunicaciones: cómo utiliza nuestra
                    web así como la información recabada por medio de cookies
                    que el usuario puede consultar en nuestra Política sobre
                    Cookies. Determinadas cookies pueden afectar a la privacidad
                    del Usuario al permitir relacionar su contenido con
                    determinados datos de carácter personal, incluidos sus
                    gustos y preferencias.
                </p>
                <p className='section__paragraph--policies'>
                    El Responsable trata exclusivamente los datos personales
                    facilitados por el Usuario y no trata categorías especiales
                    de datos personales. La finalidad principal para la que
                    tratamos la información del Usuario es proporcionar los
                    servicios expresamente solicitados por el Usuario.
                </p>
                <p className='section__paragraph--policies'>
                    El Responsable trata los datos de los usuarios con los
                    siguientes fines:
                </p>
                <ol type='a'>
                    <li className='section__paragraph--policies'>
                        La prestación de los servicios expresamente solicitados
                        por el Usuario, así como para gestionar sus pagos y
                        pedidos.
                    </li>
                    <li>
                        Prestar servicios de soporte y apoyo a los Usuarios,
                        notificar cuestiones de servicio o cuenta (por ejemplo,
                        avisar de conducta impropia).
                    </li>
                    <li>
                        Realización de actividades comerciales, operativas y
                        estadísticas. No se tomarán decisiones automatizadas en
                        base a dicho perfil.
                    </li>
                    <li>
                        Asesoramiento y remisión de boletines informativos y
                        otras comunicaciones comerciales por correo electrónico
                        sobre el Responsable y la prestación de servicios
                        propios de su sector. El Usuario podrá indicar su
                        oposición a la recepción de tal boletín en cualquier
                        momento. El Usuario consiente expresamente recibir estos
                        mensajes de correo electrónico, aunque podrán darse de
                        baja (i) en el enlace especificado en cualquier
                        comunicación que se envíe por correo electrónico, (ii)
                        enviando un correo electrónico a
                        [info@fastpayhotels.com. No obstante, ello no supondrá
                        la baja de determinadas comunicaciones de carácter no
                        comercial, como los mensajes relativos al acceso del
                        Usuario al Sitio Web.
                    </li>
                </ol>
                <p className='section__paragraph--policies'>
                    La base jurídica para el tratamiento de los datos de los
                    Usuarios por parte del Responsable es el consentimiento del
                    Usuario (art 6.1 a) RGPD). Subsidiariamente, el Responsable
                    podrá tratar los datos del Usuario bajo la base jurídica del
                    interés legítimo. Dicho interés legítimo consiste en el
                    mantenimiento y promoción de relaciones comerciales y
                    profesionales entre el Responsable y el Usuario y la
                    prevención del fraude.
                </p>
                <p className='section__paragraph--policies'>
                    La comunicación de datos personales por el Usuario es
                    voluntaria, si bien constituye condición contractual para
                    que pueda adquirir los servicios y productos comercializados
                    por el Responsable. Así, la negativa del Usuario supondrá la
                    imposibilidad de prestar determinados servicios al Usuario
                    por parte del Responsable.
                </p>
                <p className='section__paragraph--policies'>
                    El Usuario podrá retirar el consentimiento en cualquier
                    momento, sin que ello afecte a la licitud del tratamiento
                    basado en el consentimiento previo a su retirada.
                </p>
                <p className='section__paragraph--policies'>
                    El Responsable siempre pedirá consentimiento e informará al
                    Usuario antes de utilizar sus datos para cualquier fin
                    distinto de los que se describen en esta Política de
                    Privacidad.
                </p>
                <p className='section__paragraph--policies'>CESIÓN DE DATOS</p>
                <p className='section__paragraph'>
                    El Responsable informa y garantiza expresamente al Usuario
                    que sus datos personales no serán cedidos en ningún caso a
                    terceras personas, salvo obligación legal.
                </p>
                <p className='section__paragraph--policies'>
                    PLAZO DE CONSERVACIÓN DE LOS DATOS
                </p>
                <p className='section__paragraph'>
                    Los datos personales proporcionados se conservarán por
                    tiempo indefinido, en la medida en que el Responsable los
                    necesite con el fin de utilizarlos para la finalidad para la
                    que fueron obtenidos:
                </p>
                <ol type='a'>
                    <li className='section__paragraph--policies'>
                        Mientras exista una relación contractual y/o comercial.
                    </li>
                    <li>
                        Mientras no se solicite la supresión, oposición y/o
                        limitación del tratamiento de los datos por el Usuario.
                    </li>
                    <li>Por exigencia legal.</li>
                </ol>
                <p className='section__paragraph--policies'>
                    OTROS DERECHOS DEL USUARIO
                </p>
                <p className='section__paragraph'>
                    El Usuario puede obtener confirmación sobre si el
                    Responsable está tratando datos personales que le conciernan
                    y acceder a los mismos.
                </p>
                <p className='section__paragraph--policies'>
                    El Usuario podrá solicitar del Responsable el ejercicio de
                    los derechos de acceso a los datos personales, su
                    rectificación, supresión, la limitación de su tratamiento,
                    oposición y portabilidad, mediante la remisión de un correo
                    electrónico a la dirección […], adjuntando copia del
                    documento oficial identificativo del Usuario y expresando
                    claramente el derecho que desea ejercitar. Puede solicitar
                    los formularios para el ejercicio de los referidos derechos
                    a dicha dirección de correo.
                </p>
                <p className='section__paragraph--policies'>
                    PRIVACIDAD DE MENORES DE EDAD
                </p>
                <p className='section__paragraph'>
                    El Responsable se dirige a mayores de edad y no recopila ni
                    mantiene voluntariamente ningún tipo de datos personales de
                    menores de edad. Si el Responsable tuviera conocimiento de
                    que el Usuario es menor de edad, procederá a la cancelación
                    inmediata de sus datos de carácter personal y de las
                    solicitudes o pedidos realizados por el mismo, en su caso.
                </p>
                <p className='section__paragraph--policies'>
                    MEDIDAS DE SEGURIDAD QUE ADOPTA EL RESPONSABLE PARA
                    GARANTIZAR LA CONFIDENCIALIDAD DE LOS DATOS
                </p>
                <p className='section__paragraph'>
                    El Responsable adopta las medidas técnicas y organizativas
                    necesarias para evitar la pérdida, mal uso, alteración,
                    acceso no autorizado, robo de los datos personales
                    facilitados, así como para garantizar un nivel de seguridad
                    adecuado al riesgo, habida cuenta del estado de la
                    tecnología, los costes de aplicación, la naturaleza, el
                    alcance, el contexto y los fines del tratamiento, así como
                    los riesgos de probabilidad y gravedad variables para los
                    derechos y libertades de las personas físicas.
                </p>
                <p className='section__paragraph--policies'>
                    En la utilización de los datos personales del Usuario, el
                    Responsable se compromete a respetar la confidencialidad y a
                    utilizarlos de acuerdo con la finalidad de su tratamiento,
                    así como a dar cumplimiento a su obligación de guardarlos y
                    adoptar todas las medidas técnicas y organizativas de
                    seguridad para garantizar un nivel de seguridad adecuado al
                    riesgo.
                </p>
                <p className='section__paragraph--policies'>
                    El Responsable tiene particularmente en cuenta los riesgos
                    que presente el tratamiento de datos, en particular como
                    consecuencia de la destrucción, pérdida o alteración
                    accidental o ilícita de datos personales transmitidos,
                    conservados o tratados de otra forma, o la comunicación o
                    acceso no autorizados a dichos datos. El Responsable toma
                    medidas para garantizar que cualquier persona que actúe bajo
                    su autoridad y tenga acceso a los datos personales del
                    Usuario sólo pueda tratar dichos datos siguiendo las
                    instrucciones del Responsable, salvo que esté obligada a
                    ello en virtud del Derecho aplicable.
                </p>
                <li className='section__title-li'>
                    Legislación aplicable y jurisdicción competente.
                </li>
                <p className='section__paragraph'>
                    Los presentes Términos de Uso se regirán por la legislación
                    española, salvo que la legislación de aplicación imperativa
                    al Usuario determinara otro.
                </p>
                <p className='section__paragraph--policies'>
                    Las partes se someten, a su elección, para la resolución de
                    los conflictos y con renuncia a cualquier otro fuero, a los
                    juzgados y tribunales de Palma de Mallorca, salvo que la
                    legislación de aplicación imperativa al Usuario determinara
                    otro.
                </p>
                <li className='section__title-li'>
                    Fecha efectiva y modificaciones de los Términos de Uso
                </li>
                <p className='section__paragraph'>
                    Estos Términos de Uso fueron actualizados por última vez el
                    11 /12/2020.
                </p>
                <p className='section__paragraph--policies'>
                    Fastpayhotels se reserva el derecho a modificar, corregir o
                    cambiar de forma unilateral estos Términos de Uso en
                    cualquier momento. Cuando esto ocurra, se publicarán los
                    nuevos Términos de Uso en el Sitio Web así como la fecha en
                    la que entrarán en vigor en la parte final de los Términos
                    de Uso.
                </p>
                <p className='section__paragraph--policies'>
                    Recomendamos que se visite esta página con regularidad para
                    estar al día de las modificaciones que se puedan producir.
                </p>
                <p className='section__paragraph--policies'>
                    Si no acepta las modificaciones de estos Términos de Uso, no
                    utilice el Sitio Web. El uso del Sitio Web continuadamente
                    implica la aceptación de estos Términos de Uso y sus
                    posibles modificaciones.
                </p>
            </ol>
            <a href='https://www.fastpayhotels.com/' className='d-flex'>
                <button
                    type='button'
                    className='button-submit policies__button mx-auto'
                >
                    Inicio
                </button>
            </a>
        </div>
    );
}
