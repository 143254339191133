/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { ContextOccupancy } from '../../../contextsProperty/ContextOccupancy';
import './index.scss';

const ComponentMultiSelectButton = props => {
    const { adult, kid, selectedItems, index, handleItemClick } = props;
    const { formatValue } = useContext(ContextOccupancy);

    const buttonValue = `${formatValue(adult)}.${formatValue(kid)}`;
    const isSelected = selectedItems.includes(buttonValue);

    const active = isSelected
        ? '--active'
        : selectedItems.length === 0
        ? '--empty'
        : '';

    return (
        <div
            aria-checked={isSelected}
            aria-label={`${adult} adults and ${kid} children`}
            className={`occupancy-matrix__cell${active} d-flex flex-column flex-md-row justify-content-between`}
            onClick={e => handleItemClick(e, index, buttonValue)}
            value={buttonValue}
        >
            <div className='d-flex align-items-end'>
                <p className='icon__matrix__text'>{adult}</p>
                <i className='fas fa-male icon__adult' />
            </div>
            <div className='d-flex align-items-end'>
                <p className='icon__matrix__text'> {kid}</p>
                <i className='fas fa-child icon__child' />
            </div>
        </div>
    );
};

export default ComponentMultiSelectButton;
