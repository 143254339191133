import React, { useContext } from 'react';
import IconAndTextComponent from '../components/IconAndTextComponent';
import CardTitle from '../components/CardTitle';
import { ContextAccountInformation } from '../../contexts/AccountInformationContext';

const NonEditionContent = () => {
    const { accountInfo, setEditionMode, isEditButtonVisible } = useContext(
        ContextAccountInformation
    );

    return (
        <>
            <CardTitle
                title={accountInfo.Name}
                isEditButtonVisible={isEditButtonVisible}
                setEditionMode={setEditionMode}
                label={'property_name'}
            />
            <div className='account-info__non-edition'>
                <IconAndTextComponent
                    icon={'far fa-id-badge'}
                    text={accountInfo.ContactPerson}
                    description={'contact_person'}
                    fontWeight={'700'}
                />
                <IconAndTextComponent
                    icon={'fas fa-mobile-alt'}
                    text={accountInfo.Phone}
                    description={'phone'}
                />
                <IconAndTextComponent
                    icon={'far fa-envelope'}
                    text={accountInfo.Email}
                    description={'your_email'}
                />
                <IconAndTextComponent
                    icon={'fas fa-map-marker-alt'}
                    text={accountInfo.Country.Caption}
                    description={'Country'}
                />
                <IconAndTextComponent
                    icon={'fas fa-briefcase'}
                    text={accountInfo.OwnerType.Caption}
                    description={'registered_as'}
                />
            </div>
        </>
    );
};

export default NonEditionContent;
