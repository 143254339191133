import React from 'react';
import { openExtLink } from '../../utils/usefulFunctions';

const SocialLink = ({ iconName, url, ...restProps }) => {
    return (
        <a
            className='social-links__link'
            onClick={openExtLink}
            href={url}
            {...restProps}
        >
            <i className={iconName} />
        </a>
    );
};

export default SocialLink;
