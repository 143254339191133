import React, { useContext } from 'react';
import { ContextFastCheckIn } from '../../contexts/FastCheckInContext';
import FastCheckInStep1 from './FastCheckInStep1';
import FastCheckInStep2 from './FastCheckInStep2';

const FastCheckInStepsContainer = () => {
    const { step } = useContext(ContextFastCheckIn);

    const fastCheckInStep = {
        1: <FastCheckInStep1 />,
        2: <FastCheckInStep2 />,
    };
    return <div>{fastCheckInStep[step]}</div>;
};

export default FastCheckInStepsContainer;
