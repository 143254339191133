import React, { useContext, useState } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import TextInput from '../../../../muiComponents/TextInput/TextInputV2';
import { getValueFromObjetByComposedKey } from '../../../../utils/usefulFunctions';
import Button from '@mui/material/Button';
import { validateForm } from '../../../../utils/validateForms';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import CheckboxInputForVisibility from '../../components/CheckboxInputForVisibility';

export default function Commercial() {
    const { translate } = useContext(ContextUserProfile);
    const {
        hotelInfo,
        handleInputsHotelInfo,
        setProgress,
        progress,
        saveActualProgress,
        isChecked,
    } = useContext(ContextOwnerPortal);
    const { currentTab, lastTabFilled, stepName } = progress.current;
    const { Primary } = hotelInfo.SupplierHotelInfo;
    const [isValidated, setIsValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const getValue = (content, name) => {
        const savedValue = getValueFromObjetByComposedKey(content, name);
        const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const storedValue = getValueFromObjetByComposedKey(storedInfo, name);
        const value = storedValue || savedValue;
        return value;
    };

    const fieldsToValidate = {
        'MasterHotel.GeneralPhone': {
            value: hotelInfo.MasterHotel.GeneralPhone,
            validators: ['phone'],
        },
        'MasterHotel.GeneralEmail': {
            value: hotelInfo.MasterHotel.GeneralEmail,
            validators: ['email'],
        },
    };

    const checkValidate = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            saveActualProgress();
            setProgress({
                ...progress,
                current: {
                    ...progress.current,
                    currentTab: currentTab + 1,
                    tabName: tabsData[stepName].tabs.find(
                        item => item.tabNumber === currentTab + 1
                    ).title,
                    lastTabFilled:
                        currentTab < lastTabFilled
                            ? lastTabFilled
                            : lastTabFilled + 1,
                },
            });
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const goBack = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab - 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab - 1
                ).title,
                currentSubTab: 1,
            },
        });
    };

    const isInputDisabled = !Primary;

    const marginBottom = { marginBottom: '30px' };
    const marginBottonError = { marginBottom: '7px' };

    const variant = isInputDisabled ? 'filled' : 'outlined';

    return (
        <div className='your-properties-formV2'>
            <TextInput
                labelText='general_phone'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.GeneralPhone')}
                errorMessage={errors['MasterHotel.GeneralPhone']}
                onBlur={handleValidate}
                name='MasterHotel.GeneralPhone'
                type='text'
                required
                id='MasterHotel.GeneralPhone'
                disabled={isInputDisabled}
                variant={variant}
                error={!!errors['MasterHotel.GeneralPhone']}
                style={
                    errors['MasterHotel.GeneralPhone']
                        ? marginBottonError
                        : marginBottom
                }
            />
            <TextInput
                labelText='general_email'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.GeneralEmail')}
                errorMessage={errors['MasterHotel.GeneralEmail']}
                onBlur={handleValidate}
                name='MasterHotel.GeneralEmail'
                type='text'
                required
                id='MasterHotel.GeneralEmail'
                disabled={isInputDisabled}
                variant={variant}
                error={!!errors['MasterHotel.GeneralEmail']}
                style={
                    errors['MasterHotel.GeneralEmail']
                        ? marginBottonError
                        : marginBottom
                }
            />
            <CheckboxInputForVisibility
                data={{
                    caption: 'want_to_set_diferent',
                    name: 'setDifferentContactInfo',
                    subtype: 'checkbox',
                }}
            />
            {isChecked && (
                <>
                    <TextInput
                        labelText='reservation_phone'
                        handleChange={handleInputsHotelInfo}
                        value={getValue(
                            hotelInfo,
                            'MasterHotel.ReservationPhone'
                        )}
                        name='MasterHotel.ReservationPhone'
                        type='text'
                        id='MasterHotel.ReservationPhone'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginBottom}
                    />
                    <TextInput
                        labelText='reservation_email'
                        handleChange={handleInputsHotelInfo}
                        value={getValue(
                            hotelInfo,
                            'MasterHotel.ReservationEmail'
                        )}
                        name='MasterHotel.ReservationEmail'
                        type='text'
                        id='MasterHotel.ReservationEmail'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginBottom}
                    />
                    <TextInput
                        labelText='accounting_phone'
                        handleChange={handleInputsHotelInfo}
                        value={getValue(
                            hotelInfo,
                            'MasterHotel.AccountingPhone'
                        )}
                        name='MasterHotel.AccountingPhone'
                        type='text'
                        id='MasterHotel.AccountingPhone'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginBottom}
                    />
                    <TextInput
                        labelText='accounting_email'
                        handleChange={handleInputsHotelInfo}
                        value={getValue(
                            hotelInfo,
                            'MasterHotel.AccountingEmail'
                        )}
                        name='MasterHotel.AccountingEmail'
                        type='text'
                        id='MasterHotel.AccountingEmail'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginBottom}
                    />
                </>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant='contained'
                    onClick={goBack}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('back')}
                </Button>
                <Button
                    variant='contained'
                    onClick={checkValidate}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('continue')}
                </Button>
            </div>
        </div>
    );
}
