import React, { useEffect, useState, useContext } from 'react';
import api from '../../../services/api';
import Loading from '../../../common/components/Loading';
import { ContextOwnerPortal } from '../ContextOwnerPortal';

// Este es el que se usa en Segments y Beds
export default function CheckboxList(props) {
    const { data, value, handleChange } = props;
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const { isFetching, setIsFetching } = useContext(ContextOwnerPortal);
    let defaultDataProps = { containerClassName: '' };
    defaultDataProps = { ...defaultDataProps, ...data };
    const { apiEndPoint, ActionInfo, name, containerClassName, ActionInfo2 } =
        defaultDataProps;

    useEffect(() => {
        fetchOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const valIdToInt = value.map(item => {
            if (typeof item.Id === 'string') {
                return {
                    ...item,
                    Id: parseInt(item.Id),
                };
            }
            return item;
        });
        setCheckedOptions(valIdToInt);
    }, [value]);

    const fetchOptions = async () => {
        setIsFetching(true);
        let { data } = await api.get(apiEndPoint);
        data = data.value;
        setOptions(
            data.map(option => ({
                Name: ActionInfo2
                    ? option[ActionInfo] || option[ActionInfo2]
                    : option[ActionInfo],
                Id: parseInt(option.Id),
            }))
        );
        setIsFetching(false);
    };

    const isInCheckedOptionsArray = id =>
        !!checkedOptions?.find(item => item.Id === parseInt(id));

    const isDeleted = id => {
        const item = checkedOptions.find(item => item.Id === parseInt(id));
        return !!item.Deleted;
    };

    const handleChangeCheckbox = e => {
        const isBeingAdded = e.target.checked;
        const itemId = parseInt(e.target.id);
        const item = checkedOptions.find(item => item.Id === itemId);

        let newCheckedOptions;
        if (isBeingAdded) {
            if (isInCheckedOptionsArray(itemId)) {
                // Es porque tiene las claves Deleted y __isChanged, así que se las borro y lo dejo como si no hubiera pasado nada
                newCheckedOptions = [...checkedOptions];
                const itemIndex = newCheckedOptions.findIndex(
                    item => item.Id === itemId
                );
                delete item.Deleted;
                delete item.__isChanged;
                newCheckedOptions[itemIndex] = item;
                setCheckedOptions(newCheckedOptions);
            } else {
                // No estaba en el array, así que lo añado
                const newOption = {
                    Id: parseInt(e.target.id),
                    __isAdded: true,
                    __isChanged: true,
                };
                newCheckedOptions = [...checkedOptions];
                newCheckedOptions = newCheckedOptions.concat(newOption);
                setCheckedOptions(newCheckedOptions);
            }
        }
        // Me dispongo a descheckear
        // item nunca es undefined, el item siempre está en el array
        else if ('__isAdded' in item) {
            // Lo "acabo de añadir", y lo vuelvo a quitar así que lo quito del array
            newCheckedOptions = checkedOptions.filter(
                checkedOption => checkedOption.Id !== itemId
            );
            setCheckedOptions(newCheckedOptions);
        } else {
            item.Deleted = true;
            item.__isChanged = true;
            const itemIndex = checkedOptions.findIndex(
                item => item.Id === itemId
            );
            newCheckedOptions = [...checkedOptions];
            newCheckedOptions[itemIndex] = item;
            setCheckedOptions(newCheckedOptions);
        }
        handleChange({ target: { name, value: newCheckedOptions } });
    };
    return (
        <div className={`input-container ${containerClassName}`}>
            {isFetching ? (
                <div className='loading__container'>
                    <Loading />
                </div>
            ) : (
                options.map(option => {
                    return (
                        <div
                            className='custom-control custom-checkbox pb-2'
                            key={option.Id}
                        >
                            <input
                                type='checkbox'
                                className='custom-control-input'
                                id={option.Id}
                                name={option.Name}
                                onChange={handleChangeCheckbox}
                                checked={
                                    !(
                                        !isInCheckedOptionsArray(option.Id) ||
                                        isDeleted(option.Id)
                                    )
                                }
                            />
                            <label
                                className='custom-control-label checkbox_label pointer'
                                htmlFor={option.Id}
                            >
                                {option.Name}
                            </label>
                        </div>
                    );
                })
            )}
        </div>
    );
}
