import React, { useContext } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import ImageDropZone from '../../components/ImageDropZone';
import ThumbNailList from '../../components/ThumbNailList';
import MessageBarInfo from '../../components/MessageBarInfo';
import Loading from '../../../../common/components/Loading';
import GeneralButton from '../../components/GeneralButton';
import endpoints from '../../../../utils/commonVariables/endpoints';

export default function ImagesStep2(props) {
    const { section, setCurrentPage } = props;
    const { translate } = useContext(ContextUserProfile);
    const {
        IdHotel,
        SupplierHotelImages,
        messageBar,
        errorMaxAllowed,
        errorMessage,
        deleteImage,
        isFetching,
        toggleFeature,
        toggle360,
    } = useContext(ContextOwnerPortal);

    const maxSizeAllowed = 10_485_760; // 10MB = 10 * 1024 *1024 bytes = 26214400
    const maxSizeAllowedMB = 10;

    const IdSupplierHotel = parseInt(IdHotel);
    const IdHotelImageType = parseInt(section.Id);
    const IdSupplierHotelRoomType = 0;
    const uploadEndPoint = endpoints.addImages(
        IdSupplierHotel,
        IdHotelImageType,
        IdSupplierHotelRoomType
    );
    const errors = errorMaxAllowed.concat(errorMessage);
    const buttons = [
        {
            title: 'fav_image',
            icon: 'far fa-star',
            handleClick: toggleFeature,
        },
        {
            title: 'panorama_image',
            icon: 'thumbNail__360-icon',
            handleClick: toggle360,
        },
        {
            title: 'delete_image',
            icon: 'fas fa-trash-alt',
            handleClick: deleteImage,
        },
    ];
    return (
        <form noValidate className='needs-validation your-properties-form-img'>
            <h1 className='your-properties-title'>{translate('images')}</h1>
            <p className='description-image-drag_title'>{section.Caption}</p>
            {messageBar.text && (
                <MessageBarInfo
                    type={messageBar.type}
                    text={translate(messageBar.text)}
                />
            )}
            {isFetching ? (
                <Loading />
            ) : (
                <div className='description-image-container'>
                    <ImageDropZone
                        uploadEndPoint={uploadEndPoint}
                        maxSizeAllowed={maxSizeAllowed}
                        maxSizeAllowedMB={maxSizeAllowedMB}
                    />
                    <ThumbNailList
                        imageList={SupplierHotelImages.filter(
                            image => image.IdHotelImageType === IdHotelImageType
                        )}
                        buttonList={buttons}
                    />
                    {!!errors.length && (
                        <ul>
                            {errors.map(error => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    )}
                </div>
            )}

            <div className='d-flex justify-content-between description-card-img_btn'>
                <GeneralButton
                    type='goBackListImages'
                    text={translate('back')}
                    setCurrentPage={setCurrentPage}
                />
                <GeneralButton type='submit' text={translate('finish')} />
            </div>
        </form>
    );
}
