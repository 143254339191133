import React, { useContext } from 'react';
import { ContextUserProfile } from '../contexts/UserProfileContext';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TermsAndConditions from '../common/components/termsAndConditions/TermsAndConditions';
import '../stylesheets/stylesPopUp/termsAndConditionsPopUp.scss';
import { ContextTermsAndConditions } from '../contexts/TermsAndConditionsContext';

const TermsAndConditionsViewPopUp = props => {
    const { isOpenPopUp, setIsOpenPopUp } = props;

    const { translate } = useContext(ContextUserProfile);
    const { handleMouseDown, handleMouseMove, handleMouseUp } = useContext(
        ContextTermsAndConditions
    );

    const handleClose = () => {
        setIsOpenPopUp(false);
    };

    return (
        <>
            {isOpenPopUp && (
                <div className='container__popup--view'>
                    <Dialog
                        open={isOpenPopUp}
                        onClose={handleClose}
                        aria-labelledby='draggable-dialog-title'
                        maxWidth='lg'
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        className='container__popup--custom__dialog'
                    >
                        <DialogTitle
                            style={{ cursor: 'move' }}
                            id='draggable-dialog-title'
                        >
                            {translate('title_view_terms_conditions')}
                            <IconButton
                                aria-label='close'
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 14,
                                    top: 14,
                                    color: theme => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent className='dialog__content'>
                            <TermsAndConditions />
                        </DialogContent>
                        <DialogActions
                            classes={{
                                root: 'dialog__footer dialog__footer--right',
                            }}
                        >
                            <Button
                                size='small'
                                variant='contained'
                                className='accept__button'
                                onClick={handleClose}
                            >
                                {translate('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </>
    );
};

export default TermsAndConditionsViewPopUp;
