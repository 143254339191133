import React, { useContext } from 'react';
//librerías
import Button from '@mui/material/Button';
import LanguageIcon from '@mui/icons-material/Language';
import { styled } from '@mui/material/styles';
//propias
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const StyledButtonLanguageSelector = styled(Button)(({ theme }) => ({
    border: 0,
    padding: 0,
    textTransform: 'uppercase',
    ':hover': {
        backgroundColor: 'transparent !important',
        border: 0,
        outline: 'none',
        color: theme.palette.grey700,
    },
}));

const ButtonLanguageSelector = props => {
    const { onClick } = props;
    const { language } = useContext(ContextUserProfile);

    return (
        <StyledButtonLanguageSelector
            variant='outlined'
            color='secondary'
            onClick={onClick}
            startIcon={<LanguageIcon />}
        >
            {language}
        </StyledButtonLanguageSelector>
    );
};

export default ButtonLanguageSelector;
