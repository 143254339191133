import React, { useContext } from 'react';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import ConditionalStatemensES from './ConditionalStatemensES';
import ConditionalStatemensEN from './ConditionalStatemensEN';

const CookieConsentConditional = () => {
    const { language } = useContext(ContextUserProfile);

    const translatedCookie = {
        en: <ConditionalStatemensEN />,
        es: <ConditionalStatemensES />,
        fr: <ConditionalStatemensEN />,
        de: <ConditionalStatemensEN />,
    };

    const getTranslatedCookie = currentLanguage => {
        return translatedCookie[currentLanguage];
    };

    return <div>{getTranslatedCookie(language)}</div>;
};

export default CookieConsentConditional;
