import React from 'react';
import { childIconColor as green } from '../ariHelpers';

const IconBaby = ({ ...restProps }) => {
    return (
        <i
            className='fas fa-baby'
            style={{
                lineHeight: '17px',
                color: green,
                fontSize: 14,
                display: 'flex',
                justifyContent: 'center',
                width: 15,
                marginBottom: -2,
            }}
            {...restProps}
        />
    );
};

export default IconBaby;
