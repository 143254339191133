import React, { useContext, useState } from 'react';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { ContextSignUp } from '../../contexts/SignUpContext';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import Button from '@mui/material/Button';
import { validateForm } from '../../utils/validateForms';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import LoadingPlaceholder from './LoadingPlaceholder';
import useErrorHandledApiCall from '../../customHooks/useErrorHandledApiCall';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';
import api from '../../services/api';

const SignUpStep4 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { inputsData, setInputsData, setStep, setIsFetching, isFetching } =
        useContext(ContextSignUp);
    const { setErrorMessage } = useContext(NotificationsContext);

    const [errors, setErrors] = useState({});
    const { options: availableSupplierChannelsOptions } =
        useErrorHandledApiCall(
            publicEndpoints.signUpFormAvailableSupplierChannels
        );
    const { options: ownerTypeOptions } = useErrorHandledApiCall(
        publicEndpoints.signUpFormOwnerTypes
    );

    const [isValidated, setIsValidated] = useState(false);

    const fieldsToValidate = {
        IdAvailableSupplierChannel: {
            value: inputsData.IdAvailableSupplierChannel,
            validators: ['requiredIdEntity'],
        },
        CustomSupplierChannel: {
            value: {
                IdAvailableSupplierChannel:
                    inputsData.IdAvailableSupplierChannel,
                CustomSupplierChannel: inputsData.CustomSupplierChannel,
            },
            validators: ['customCustomSupplierChannel'],
        },
        IdOwnerType: {
            value: inputsData.IdOwnerType,
            validators: ['requiredIdEntity'],
        },
    };

    const handleSave = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            await sendRequest();
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const sendRequest = async () => {
        try {
            setIsFetching(true);
            const { data } = await api.post(
                publicEndpoints.signUpForm,
                inputsData
            );
            setIsFetching(false);
            if (data.Success && data.StatusCode === 200) {
                setStep(prev => prev + 1);
            } else {
                setErrorMessage('something_went_wrong');
            }
        } catch (error) {
            setErrorMessage('something_went_wrong');
        }
    };

    return (
        <div
            className={`step-content__container ${
                isFetching && 'loading-container'
            }`}
        >
            {isFetching ? (
                <LoadingPlaceholder />
            ) : (
                <>
                    <LoadedOptionsTypeahead
                        labelText='select_channel_manager'
                        options={availableSupplierChannelsOptions}
                        idValue={inputsData.IdAvailableSupplierChannel}
                        handleChange={(e, value) =>
                            setInputsData(prev => {
                                return {
                                    ...prev,
                                    IdAvailableSupplierChannel: value
                                        ? parseInt(value.Id)
                                        : null,
                                    AvailableSupplierChannel: value
                                        ? value.label
                                        : '',
                                };
                            })
                        }
                        onBlur={handleValidate}
                        errorMessage={errors.IdAvailableSupplierChannel}
                        required
                    />
                    {
                        //other
                        inputsData.IdAvailableSupplierChannel === 22 && (
                            <TextInput
                                labelText='other'
                                handleChange={(e, value) => {
                                    setInputsData(prevState => {
                                        return {
                                            ...prevState,
                                            CustomSupplierChannel: value,
                                        };
                                    });
                                }}
                                value={inputsData.CustomSupplierChannel}
                                errorMessage={errors.CustomSupplierChannel}
                                onBlur={handleValidate}
                                required
                            />
                        )
                    }
                    <LoadedOptionsTypeahead
                        labelText='choose_type_of_hotel'
                        options={ownerTypeOptions}
                        handleChange={(e, value) =>
                            setInputsData(prev => {
                                return {
                                    ...prev,
                                    IdOwnerType: value
                                        ? parseInt(value.Id)
                                        : null,
                                    OwnerType: value ? value.label : '',
                                };
                            })
                        }
                        errorMessage={errors.IdOwnerType}
                        idValue={inputsData.IdOwnerType}
                        onBlur={handleValidate}
                        required
                    />
                    <Button
                        variant='contained'
                        onClick={() => handleSave()}
                        style={{ alignSelf: 'flex-end' }}
                    >
                        {translate('next')}
                    </Button>
                </>
            )}
        </div>
    );
};

export default SignUpStep4;
