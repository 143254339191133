import React, { useState, useContext } from 'react';
import { NotificationsContext } from '../contexts/NotificationsContext';
import { ContextUserProfile } from '../contexts/UserProfileContext';
import { getTodayDate } from '../utils/usefulFunctions';
import convertToOvariant from '../utils/convertToOvariant';
import api from '../services/api';
import endpoints from '../utils/commonVariables/endpoints';
import TermsAndConditions from '../common/components/termsAndConditions/TermsAndConditions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import '../stylesheets/stylesPopUp/termsAndConditionsPopUp.scss';
import { savableEntities } from '../utils/commonVariables/entityKeys';
import { ContextTermsAndConditions } from '../contexts/TermsAndConditionsContext';
import { validateForm } from '../utils/validateForms';

const TermsAndConditionsPopUp = () => {
    const { translate, settings, saveNewSettings, IdHotelOwner } =
        useContext(ContextUserProfile);
    const { setNotificationMessage } = useContext(NotificationsContext);
    const {
        setErrors,
        setIsValidated,
        fieldsTermsAndConditionsToValidate,
        formTermsAndConditions,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleStopDragging,
    } = useContext(ContextTermsAndConditions);

    const [isClosedPopUp, setIsClosedPopUp] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);

    const errorMessageBar = {
        text: 'something_went_wrong',
        componentText: null,
        snackbarProps: { position: 2 },
        alertProps: { severity: 'error' },
    };
    const successMessageBar = {
        text: 'success_message',
        componentText: null,
        snackbarProps: { position: 2 },
        alertProps: { severity: 'success' },
    };

    const handleClose = () => {
        setIsClosedPopUp(true);
    };

    const onAcceptTermsAndConditions = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(
            fieldsTermsAndConditionsToValidate
        );
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;

        const dataToSend = {
            Signed: true,
            DateSigned: getTodayDate(),
            IdCurrency: Number(formTermsAndConditions.selectedCurrency.Id),
            Contacts: [
                {
                    Email: formTermsAndConditions.emailAddress,
                    EntityId: IdHotelOwner,
                    EntityName: 'HotelOwner',
                    Name: formTermsAndConditions.contactName,
                    Phone: formTermsAndConditions.phoneNumber,
                    Position: 'Finance Contact',
                    Id: '-1',
                    __isAdded: true,
                    __isChanged: true,
                },
                {
                    Email: formTermsAndConditions.emailAddressCustomer,
                    EntityId: IdHotelOwner,
                    EntityName: 'HotelOwner',
                    Name: formTermsAndConditions.generalContactPoint,
                    Position: 'General Contact',
                    ResponseTime: formTermsAndConditions.estimatedResponseTime,
                    Id: '-2',
                    __isAdded: true,
                    __isChanged: true,
                },
                {
                    Email: formTermsAndConditions.escalationPersonEmail,
                    EntityId: IdHotelOwner,
                    EntityName: 'HotelOwner',
                    Name: formTermsAndConditions.escalationContactPerson,
                    Phone: formTermsAndConditions.emergencyPhoneNumber,
                    Position: 'Escalation Contact',
                    Id: '-3',
                    __isAdded: true,
                    __isChanged: true,
                },
            ],
        };
        const objectToSend = convertToOvariant(
            savableEntities.HotelOwner,
            dataToSend,
            IdHotelOwner
        );

        if (isCheckedTerms && isValid) {
            try {
                const endpoint = endpoints.commonSave;
                const res = await api.post(endpoint, objectToSend);
                const resStatus = res?.status;
                if (resStatus === 201) {
                    saveNewSettings(true, 'signed');
                    setNotificationMessage(successMessageBar);
                    handleClose();
                } else {
                    setNotificationMessage(errorMessageBar);
                }
            } catch {
                setNotificationMessage(errorMessageBar);
            }
        }
    };

    const onCheckTermsAndConditions = () => {
        setIsCheckedTerms(!isCheckedTerms);
    };

    return (
        <>
            {!settings?.signed && (
                <div className='container__popup'>
                    <Dialog
                        open={!isClosedPopUp}
                        aria-labelledby='draggable-dialog-title'
                        maxWidth='lg'
                        className='container__popup--custom__dialog'
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    >
                        <DialogTitle
                            style={{ cursor: 'move' }}
                            id='draggable-dialog-title'
                        >
                            {translate('title_terms_conditions')}
                        </DialogTitle>
                        <DialogContent
                            className='dialog__content'
                            onMouseDown={handleStopDragging}
                            onMouseMove={handleStopDragging}
                            onMouseUp={handleStopDragging}
                        >
                            <TermsAndConditions />
                        </DialogContent>
                        <DialogActions
                            classes={{ root: 'dialog__footer' }}
                            onMouseDown={handleStopDragging}
                            onMouseMove={handleStopDragging}
                            onMouseUp={handleStopDragging}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color='success'
                                        onChange={onCheckTermsAndConditions}
                                        checked={isCheckedTerms}
                                    />
                                }
                                label={translate('I_have_read_terms')}
                                classes={{ root: 'dialog__checkbox' }}
                            />
                            <Button
                                size='small'
                                variant='contained'
                                className='accept__button'
                                onClick={onAcceptTermsAndConditions}
                                disabled={!isCheckedTerms}
                            >
                                {translate('accept')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </>
    );
};

export default TermsAndConditionsPopUp;
