import React from 'react';
import WithTooltip from '../WithTooltip';
import { childIconColor as green } from '../ariHelpers';

const IconChildSegment = ({ ageRange, title, ...restProps }) => {
    return (
        <>
            <i
                className='fas fa-child'
                style={{
                    lineHeight: '17px',
                    color: green,
                    fontSize: 18,
                    display: 'flex',
                    justifyContent: 'center',
                    width: 15,
                }}
                {...restProps}
            />
            <WithTooltip
                title={title}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <div
                    style={{
                        width: 17,
                        height: 17,
                        marginLeft: 6,
                        backgroundColor: green,
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <span
                        style={{
                            color: 'white',
                        }}
                    >
                        {ageRange}
                    </span>
                </div>
            </WithTooltip>
        </>
    );
};

export default IconChildSegment;
