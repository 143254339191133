import { createTheme } from '@mui/material';
import {
    primary200,
    primary400,
    primary700,
    error200,
    error400,
    error700,
    grey100,
    grey200,
    grey600,
    grey700,
    grey900,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
} from '../stylesheets/variables.scss';
import { convertBreakpoint } from '../utils/usefulFunctions';

const defaultTheme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: convertBreakpoint(sm),
            md: convertBreakpoint(md),
            lg: convertBreakpoint(lg),
            xl: convertBreakpoint(xl),
            xxl: convertBreakpoint(xxl),
            xxxl: convertBreakpoint(xxxl),
        },
    },
    palette: {
        primary: {
            light: primary200,
            main: primary400,
            dark: primary700,
        },
        secondary: {
            light: grey200,
            main: grey600,
            dark: grey700,
        },
        error: {
            light: error200,
            main: error400,
            dark: error700,
        },
        grey100,
        grey200,
        grey600,
        grey700,
        grey900,
    },
    typography: {
        fontFamily: `'Montserrat', sans-serif`,
        h1: {
            fontSize: 24,
            fontWeight: 600,
        },
        h2: {
            fontSize: 20,
            fontWeight: 600,
        },
        h3: {
            fontSize: 14,
            fontWeight: 600,
        },
        button: {
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 14,
            fontWeight: 600,
        },
    },
    components: {
        //     // input
        //     MuiOutlinedInput: {
        //         styleOverrides: {
        //             root: {
        //                 fontFamily: `'Montserrat', sans-serif`,
        //             },
        //         },
        //     },
        //     // button
        MuiButton: {
            styleOverrides: {
                root: {
                    ':hover': {
                        color: primary700,
                        // outline: `1px solid ${primary700}`,
                    },
                },
            },
        },
        //     MuiTableCell: {
        //         styleOverrides: {
        //             stickyHeader: {
        //                 fontFamily: `'Arial', sans-serif`,
        //             },
        //         },
        //     },
        //     MuiDialogTitle: {
        //         styleOverrides: {
        //             root: {
        //                 fontFamily: `'Montserrat', sans-serif !important`,
        //                 fontWeight: '400 !important',
        //                 fontSize: '24px !important',
        //                 borderBottom: `1px solid ${grey200}`,
        //                 display: 'flex',
        //                 justifyContent: 'space-between',
        //                 alignItems: 'center',
        //             },
        //         },
        //     },
        //     MuiDialogActions: {
        //         styleOverrides: {
        //             root: {
        //                 height: '100px !important',
        //                 padding: '16px 24px',
        //                 borderTop: `1px solid ${grey200}`,
        //             },
        //         },
        //     },
    },
};

export default createTheme(defaultTheme);
