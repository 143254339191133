const year = new Date().getFullYear();

export const INITIAL_STATE_BOOKING_HISTORY = [
    {
        id: 0,
        month: 'January',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 1,
        month: 'February',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 2,
        month: 'March',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 3,
        month: 'April',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 4,
        month: 'May',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 5,
        month: 'June',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 6,
        month: 'July',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 7,
        month: 'August',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 8,
        month: 'September',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 9,
        month: 'October',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 10,
        month: 'November',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
    {
        id: 11,
        month: 'December',
        year: year,
        commitedBookingCount: 0,
        cancelledBookingCount: 0,
        bookings: [],
    },
];
