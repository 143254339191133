import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    IconButton,
    InputAdornment,
    Button,
    CardContent,
    CardActions,
    TextField,
    Card,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { NotificationsContext } from '../../../contexts/NotificationsContext';
import { ContextGlobalFeatures } from '../../../contexts/GlobalFeatures';
import { encodeFormUrl } from '../../../utils/encodeFormUrl';
import BunnyLoading from '../BunnyLoading';
import {
    logIn,
    lastPageVisitedKey,
} from '../../../utils/userProfileContextHelpers';
import conejoLogin from '../../../images/conejito.svg';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import routes from '../../../utils/commonVariables/routes';
import useTranslate from '../../../customHooks/useTranslate';

export default function FormLogin() {
    const { setErrorMessage } = useContext(NotificationsContext);
    const { isLoadingInterface, setIsLoadingInterface } = useContext(
        ContextGlobalFeatures
    );
    const [inputsData, setInputsData] = useState({
        username: '',
        password: '',
    });
    const { username, password } = inputsData;
    const [validated, setValidated] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleLogIn = async () => {
        setValidated(true);
        if (username && inputsData.password) {
            const formBody = encodeFormUrl({
                username,
                password,
            });
            try {
                setIsLoadingInterface(true);
                const loggedIn = await logIn(formBody);
                if (loggedIn) {
                    const lastPageVisited =
                        localStorage.getItem(lastPageVisitedKey) ||
                        routes.landingPrivate;
                    window.location = lastPageVisited;
                } else {
                    setIsLoadingInterface(false);
                    setErrorMessage('the_data_entered_is_not_correct');
                }
            } catch (_error) {
                console.log(_error);
                setIsLoadingInterface(false);
                setErrorMessage('the_data_entered_is_not_correct');
            }
        }
    };

    const handleChange = event => {
        const { value, name } = event.currentTarget;
        setInputsData({
            ...inputsData,
            [name]: value,
        });
    };

    const logInTitleText = useTranslate('hotels_log_in');
    const requiredText = useTranslate('required');
    const passwordText = useTranslate('password');
    const logInText = useTranslate('log_in');
    const forgotPasswordText = useTranslate('i_forgot_my_password');
    const newText = useTranslate('are_you_new');
    const signUpText = useTranslate('sign_up_here');
    const userText = useTranslate('user');

    return (
        <>
            {isLoadingInterface ? (
                <BunnyLoading />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Card
                        sx={{
                            width: 380,
                            padding: '40px 50px',
                            borderRadius: 2.5,
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 0,
                            }}
                        >
                            <img
                                alt={'Fast pay hotel logo'}
                                src={conejoLogin}
                                height={120}
                            />
                            <Typography variant='h1'>
                                {logInTitleText}
                            </Typography>
                            <TextField
                                required
                                size='small'
                                label={`${userText}`}
                                color='primary'
                                margin='normal'
                                onChange={e => handleChange(e)}
                                name='username'
                                value={username}
                                error={validated && !username}
                                helperText={
                                    validated && !username ? requiredText : ''
                                }
                            />
                            <FormControl
                                variant='outlined'
                                size='small'
                                margin='normal'
                                required
                                color='primary'
                                sx={{ width: '100%' }}
                                error={validated && !password}
                            >
                                <InputLabel
                                    htmlFor='password'
                                    sx={{ marginRight: '5px' }}
                                >
                                    {passwordText}
                                </InputLabel>
                                <OutlinedInput
                                    id='password'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={e => handleChange(e)}
                                    name='password'
                                    value={password}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() =>
                                                    setShowPassword(
                                                        prevState => !prevState
                                                    )
                                                }
                                                onMouseDown={e =>
                                                    e.preventDefault()
                                                }
                                                edge='end'
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Password'
                                />
                                {validated && !password && (
                                    <p className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root'>
                                        {requiredText}
                                    </p>
                                )}
                            </FormControl>
                        </CardContent>
                        <CardActions
                            sx={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Button
                                size='small'
                                variant='contained'
                                onClick={() => handleLogIn()}
                            >
                                {logInText}
                            </Button>
                            <Link
                                to={routes.forgotPassword}
                                className='linkStyleLogin'
                            >
                                <Typography
                                    style={{
                                        marginTop: '10px',
                                        display: 'block',
                                    }}
                                    variant='body1'
                                >
                                    {forgotPasswordText}
                                </Typography>
                            </Link>
                            <Typography
                                style={{
                                    marginTop: '10px',
                                    display: 'block',
                                }}
                            >
                                {newText}
                                <Link
                                    to={routes.signUp}
                                    className='linkStyleLogin'
                                >
                                    {signUpText}.
                                </Link>
                            </Typography>
                        </CardActions>
                    </Card>
                </div>
            )}
        </>
    );
}
