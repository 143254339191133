import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { ContextUserProfile } from './UserProfileContext';
import api from '../services/api';
import endpoints from '../utils/commonVariables/endpoints';
import accountInformationViewModel from '../utils/viewModelStructures/accountInformation';
import { viewModelEntities } from '../utils/commonVariables/entityKeys';

export const ContextAccountInformation = createContext();

export const ProviderAccountInformation = props => {
    const { children } = props;
    const { IdHotelOwner } = useContext(ContextUserProfile);
    const INITIAL_STATE = {
        Id: IdHotelOwner,
        Name: '',
        Email: '',
        ContactPerson: '',
        Phone: '',
        IdOwnerType: 0,
        OwnerType: { Caption: '' },
        IdCountry: 0,
        Country: {
            Caption: '',
        },
        DateSigned: '',
    };
    const componentMounted = useRef(true);
    const [accountInfo, setAccountInfo] = useState(INITIAL_STATE);

    const getAccountInformation = useCallback(async () => {
        setIsLoadingAccountInformation(true);
        try {
            const endpoint = endpoints.viewModel(
                viewModelEntities.HotelOwner,
                IdHotelOwner
            );
            const { data } = await api.post(
                endpoint,
                accountInformationViewModel
            );
            if (data.Success) {
                if (!componentMounted.current) return null;
                setAccountInfo(data.Result);
            } else {
                console.log(data);
            }
            setIsLoadingAccountInformation(false);
        } catch (error) {
            setIsLoadingAccountInformation(false);
            console.log(error);
        }
    }, [IdHotelOwner]);

    const [isLoadingAccountInformation, setIsLoadingAccountInformation] =
        useState(false);
    const [isEditionMode, setEditionMode] = useState(false);
    const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);

    useEffect(() => {
        getAccountInformation();

        return () => {
            componentMounted.current = false;
        };
    }, [getAccountInformation]);

    return (
        <ContextAccountInformation.Provider
            value={{
                accountInfo,
                setAccountInfo,
                isEditionMode,
                setEditionMode,
                isEditButtonVisible,
                setIsEditButtonVisible,
                isLoadingAccountInformation,
                setIsLoadingAccountInformation,
            }}
        >
            {children}
        </ContextAccountInformation.Provider>
    );
};
