import React, { useState } from 'react';
import PropertyImagesPopUp from '../../popups/PropertyImagesPopUp';

export default function Image({ img, className }) {
    const { Id, VirtualPath: src, Name: alt } = img;

    const [open, setOpen] = useState(false);

    const handleOpenPopUp = () => {
        setOpen(true);
    };

    return (
        <>
            <img
                id={Id}
                src={src}
                alt={alt}
                className={className}
                onClick={() => handleOpenPopUp()}
            />
            <PropertyImagesPopUp open={open} setOpen={setOpen} img={img} />
        </>
    );
}
