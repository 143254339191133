import React, { useContext } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import './tableHeader.scss';

const TableHeader = ({ columns, order, sort, isLoading, canSearch }) => {
    const { translate } = useContext(ContextUserProfile);
    const { field: prevField, dir: prevDir } = order;

    const sortableIcon = <i className='ms-Icon ms-Icon--Sort ml-2' />;

    const handleSort = (isSortable, currentField, currentDir) => {
        if (isSortable && !isLoading)
            return () => sort(currentField, currentDir);
    };

    const getCursor = isSortable => {
        if (!canSearch) return 'auto';
        if (isSortable) {
            return isLoading ? 'progress' : 'pointer';
        }
        return 'auto';
    };

    const getIcon = (isSortable, isSorted) => {
        if (!canSearch) return null;
        if (!isSortable) return null;
        if (isSorted) {
            const iconName = prevDir === 'asc' ? 'SortUp' : 'SortDown';
            return (
                <i
                    className={`ms-Icon ms-Icon--SortUp animation--${iconName} ml-1`}
                />
            );
        }
        return sortableIcon;
    };

    return (
        <TableHead>
            <TableRow className='table__header'>
                {columns.map(({ label, sortableProps }) => {
                    const isSortable = !!sortableProps;
                    const currentField = sortableProps?.field;
                    const currentDir = sortableProps?.defaultDir;
                    const isSorted = currentField === prevField;

                    if (label === 'accordion') return <TableCell key={label} />;
                    return (
                        <TableCell
                            key={label}
                            onClick={handleSort(
                                isSortable,
                                currentField,
                                currentDir
                            )}
                            style={{
                                cursor: getCursor(isSortable),
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                padding: '6px 10px',
                                backgroundColor: 'inherit',
                            }}
                        >
                            {translate(label).toUpperCase()}
                            {getIcon(
                                isSortable,

                                isSorted
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
