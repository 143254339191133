import React, { useContext } from 'react';
import { ContextAccountInformation } from '../../contexts/AccountInformationContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { formatDate } from '../../utils/formatDates';
import Button from '@mui/material/Button';
import useTranslate from '../../customHooks/useTranslate';

const TermsAndConditionsAcceptedInfo = props => {
    const { handleOpenPopUp } = props;

    const { accountInfo } = useContext(ContextAccountInformation);
    const { language, timeZone } = useContext(ContextUserProfile);

    const dateSigned = formatDate(accountInfo.DateSigned, language, timeZone);
    return (
        <>
            {`${'Fastpayhotels'} `}
            <Button
                href='#text-buttons'
                className='viewPopUp__button'
                onClick={handleOpenPopUp}
            >
                {useTranslate('terms_and_conditions')}
            </Button>
            {`${useTranslate('accepted_on')} `}
            {dateSigned}
        </>
    );
};

export default TermsAndConditionsAcceptedInfo;
