import React from 'react';
import '../../../stylesheets/stylesPopUp/termsAndConditionsPopUp.scss';
import FinanceTermsAndConditionsForm from './FinanceTermsAndConditionsForm';
import CurrenciesTermsAndConditions from './CurrenciesTermsAndConditions';
import CustomerServiceTermsAndConditions from './CustomerServiceTermsAndConditions';

export default function TermsAndConditions() {
    return (
        <div
            role='dialog'
            aria-labelledby='reviewTerms'
            className='terms__Conditions__Text'
        >
            <div className='dialog__content--text'>
                <p>
                    <strong>
                        Please read these Terms and Conditions before using the
                        Fastpayhotels website. When you register on the
                        Fastpayhotels website, you agree to accept these terms
                        and conditions (“the Agreement”)
                    </strong>
                </p>
                <h2 className='dialog__content--subtitle'>DEFINITIONS</h2>
                <p className='dialog__content--special'>
                    In this Agreement the following words and expressions shall
                    have the meanings set out opposite them:
                </p>
                <ul className='dialog__content--list'>
                    <li>
                        <strong>Accommodation</strong> means the hotel room(s),
                        apartment(s), villa(s) and any other form of
                        accommodation owned and/or managed by the Supplier as
                        listed in Annex 2 (which list may be amended from time
                        to time).
                    </li>
                    <li>
                        <strong>Booking</strong> means the Customer’s acceptance
                        of a booking in relation to Accommodation.
                    </li>
                    <li>
                        <strong>Booking Conditions</strong> means the Supplier’s
                        terms and conditions for Customers as notified to the
                        Agent via the Agent XML Link or otherwise supplied to
                        the Agent.
                    </li>
                    <li>
                        <strong>Confidential Information</strong>
                        {` means
                                all information of a confidential nature in any
                                medium or format (written, oral, visual or
                                electronic) together with copies, whether or not
                                marked or described as confidential, received
                                before or after the date of this Agreement. The
                                following information is not "Confidential
                                Information" for the purposes of this Agreement:
                                (i) information which is in the public domain
                                other than as a result of breach of this
                                Agreement; (ii) information which the recipient
                                Party can demonstrate in writing was received,
                                free of any obligation of confidence, from a
                                third party which itself was not under any
                                obligation of confidence in relation to that
                                information; (iii) information which the
                                recipient Party can demonstrate in writing was
                                developed or created independently by or on
                                behalf of the recipient Party and (iv)
                                information which is required to be disclosed by
                                law.`}
                    </li>
                    <li>
                        <strong>Customer</strong> means an end user making a
                        Booking for Accommodation.
                    </li>
                    <li>
                        <strong>Distributors</strong> means the distributors of
                        Agent who shall procure bookings for the Accommodation
                        from Customers.
                    </li>
                    <li>
                        <strong>Force Majeure</strong>means an event which is
                        beyond the reasonable control of either party affecting
                        its ability to perform any of its obligations under this
                        Agreement, including (without limitation) the following:
                        (i) act of God (including earthquake or other natural
                        disaster), act of terrorism, war or warlike operations,
                        civil unrest or riot; (ii) industrial action, fire,
                        flood, explosion or malicious damage, or failure of
                        plant or equipment (but only to the extent that any of
                        these is itself caused by a Force Majeure event or is
                        otherwise beyond the reasonable control of the affected
                        party).
                    </li>
                    <li>
                        <strong>Marks</strong> means trademarks, trade names,
                        logos, URLs and any other similar identifying marks or
                        slogans of each party to this Agreement, whether or not
                        registered.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>1. APPOINTMENT</h2>
                <ul className='dialog__content--list'>
                    <li>
                        1.1 Supplier appoints Agent as its worldwide,
                        non-exclusive agent to procure Bookings for the
                        Accommodation through its Distributors and Agent accepts
                        such appointment subject to the terms and conditions set
                        out in this Agreement.
                    </li>
                    <li>
                        1.2 Supplier acknowledges that Agent acts as agent only
                        and will not assume any hotelier obligations.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>
                    2. YOUR OBLIGATIONS
                </h2>
                <p className='dialog__content--special'>Supplier shall</p>
                <ul className='dialog__content--list'>
                    <li>
                        2.1 ensure that each Accommodation complies with all
                        applicable local and national laws, licensing
                        requirements and industry regulations,
                    </li>
                    <li>
                        2.2 ensure that each Accommodation maintains all
                        relevant approvals, licences, consents, registrations or
                        other authorisations necessary for it to enter into this
                        Agreement,
                    </li>
                    <li>
                        2.3 ensure that each Accommodation complies with all
                        relevant health and safety requirements and regulations
                        and ensure that health and safety audits are carried out
                        regularly in respect of each Accommodation,
                    </li>
                    <li>
                        2.4 honor all Bookings and avoid overbooking each
                        Accommodation,
                    </li>
                    <li>
                        2.5 provide with the Booking Conditions to the Agent,
                        guaranteeing at any time full access to all rates being
                        commercialized by the Supplier,
                    </li>
                    <li>
                        2.6 ensure that all pricing, content and information
                        relating to each Accommodation is accurate, up to date
                        and not misleading,
                    </li>
                    <li>
                        2.7 ensure that all communications regarding rates and
                        inventory are passed to Agent through the Agent XML link
                        or Agent extranet, including but not limited to stop
                        sale notifications,
                    </li>
                    <li>
                        2.8 ensure that Customers who have booked through Agent
                        or Agent’s Distributors shall not be treated differently
                        than customers who have booked through Supplier’s own
                        distribution channels, including but not limited to
                        handling of overbookings, allocation of room types,
                        customer services etc.
                    </li>
                    <li>
                        2.9 Supplier warrants that it has the right and
                        authority to enter into this Agreement and distribute
                        the Accommodation to the Agent for onwards distribution
                        to Customers. Supplier warrants that there is no
                        material or legal obstacle to providing the
                        Accommodation under this Agreement and further the
                        Supplier is not aware of any potential material or legal
                        obstacle which would prevent the fulfillment of its
                        obligations as Supplier.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>
                    3. AGENT OBLIGATIONS
                </h2>
                <ul className='dialog__content--list'>
                    <li>
                        3.1 Agent shall arrange for Customers to enter into
                        Bookings via its Distributors,
                    </li>
                    <li>
                        3.2 Agent shall ensure that Customers accept the Booking
                        Conditions before a Booking is made and that the
                        Supplier is named in all documentation issued to
                        Customers,
                    </li>
                    <li>
                        3.3 Agent shall collect payment for Bookings from
                        Customers via its Distributors.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>4. PAYMENT</h2>
                <ul className='dialog__content--list'>
                    <li>
                        4.1 Supplier shall provide Agent with a net rate, which
                        rate shall reflect the cost of the Accommodation. Such
                        net rate shall include all indirect taxes applicable to
                        the country where the Accommodation is located. Supplier
                        shall ensure that the net rate will represent during all
                        the term of the agreement a discount of XX% from the
                        accommodation´s best available rate, of XX % for package
                        rate, of XX% for advance purchase... (the rate plans
                        agreed through the channel manager).
                        <br></br>
                        The Supplier shall guarantee best possible commercial
                        conditions to the Agent. To this purpose, the Supplier
                        shall not make any rules, rates, restrictions, policies,
                        and/or conditions (including cancellation rules)
                        applicable to any accommodation distributed by the Agent
                        more restrictive than those applicable to any similar or
                        comparable accommodation available through to any 3rd
                        party or distribution channels. Both parties acknowledge
                        that providing the Agent with best rates, rules,
                        restrictions, policies and conditions is part of a fair
                        and balanced commercial bargain for the Agent to enter
                        into this Agreement.
                        <br></br>
                        Additionally, both parties agree a yearly override 1% in
                        favour of the Agent, to be calculated on the aggregated
                        turnover resulting from the total sales by Agent during
                        each fiscal year. Agent shall issue an invoice to
                        Supplier for the payable override, the amount of such
                        override to be offset from the last payment of the
                        fiscal year.
                    </li>
                    <li>
                        4.2 Invoicing and payment shall be carried out in
                        accordance with Annex 1 to this Agreement.
                    </li>
                    <li>
                        4.3 Supplier is responsible for the calculation and
                        payment of VAT or any other sales or indirect tax to the
                        relevant tax authorities in the Supplier’s tax
                        jurisdiction.
                    </li>
                    <li>
                        4.4 If any deductions from amounts due to the Agent are
                        required by law or regulation (including but not limited
                        to VAT or any other sales or indirect tax or any other
                        charges) these costs shall be met by Supplier.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>
                    5. CHANGES TO ACCOMMODATION / BOOKINGS
                </h2>
                <ul className='dialog__content--list'>
                    <li>
                        5.1 Supplier shall not significantly alter the
                        Accommodation less than 30 days before the Customer’s
                        arrival except in the case of Force Majeure. Any
                        alteration to the Accommodation must be notified to the
                        Agent in writing. Agent may at its discretion inform
                        Customer of such alteration. .
                    </li>
                    <li>
                        5.2 If Supplier overbooks, cancels or significantly
                        alters a Booking for reasons other than Force Majeure
                        e.g lower category of accommodation, Supplier shall
                        immediately notify Agent and shall make alternative
                        arrangements for the Customer of a similar or higher
                        standard. Agent (through its Distributors) shall offer
                        such alternatives to the Customer who shall be entitled
                        to cancel the Booking for a full refund or accept the
                        alternative arrangements.
                    </li>
                    <li>
                        5.3 If Supplier cancels a Booking as a result of Force
                        Majeure, Supplier shall refund to the Customer via the
                        Agent the full amount of the Booking.
                    </li>
                    <li>
                        5.4 Supplier shall not be entitled to charge Agent for
                        name changes to Bookings.
                    </li>
                    <li>
                        5.5 If pricing information provided by Supplier is
                        inaccurate, Supplier shall bear the cost of any
                        increased price of the Booking.
                    </li>
                    <li>
                        5.6 In the event of operational or technical issues
                        (including but not limited to technical errors,
                        duplicate or test bookings), Agent shall have the right
                        to cancel any Booking within 24 hours via the Agent XML
                        link.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>6. COMPLAINTS</h2>
                <ul className='dialog__content--list'>
                    <li>
                        6.1 Supplier authorises Agent and its Distributors to
                        liaise directly with Customers in respect of any
                        complaints.
                    </li>
                    <li>
                        6.2 Agent shall inform Supplier of any complaints from
                        Customers and Supplier shall work together in good faith
                        with Agent in order to respond to the Customer within 21
                        days.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>7. TRADEMARKS</h2>
                <ul className='dialog__content--list'>
                    <li>
                        7.1 Each party acknowledges and agrees that all Marks
                        used by the respective parties to this Agreement remain
                        the exclusive property of the owning party.
                    </li>
                    <li>
                        7.2 Each party grants to the other party a non-exclusive
                        royalty-free licence for the term of this Agreement to
                        use the other party’s Marks solely for the purpose of
                        performing their obligations under this Agreement.
                    </li>
                    <li>{`7.3 Each party agrees that they shall not acquire
                            any goodwill or rights to the other party's Marks or
                            brands.`}</li>
                    <li>
                        7.4 Each party will refrain from any action that would
                        prejudice the other’s Mark, including creating or using
                        any likeness of a Mark or anything similar and bidding
                        on similar domain names or key words.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>8. LIABILITY</h2>
                <ul className='dialog__content--list'>
                    <li>
                        8.1 Supplier warrants that it holds liability insurance
                        with a reputable insurer covering an amount that is
                        consistent with best industry practice, such insurance
                        to include all third party risks, including liability
                        for damages for death or any personal injury whatsoever
                        suffered by Customers. Agent shall have the right to
                        request Supplier to provide copies of such insurance for
                        inspection at any time.
                    </li>
                    <li>
                        8.2 Supplier shall indemnify and keep fully indemnified
                        Agent, its Distributors, shareholders, directors,
                        employees, agents and representatives from and against
                        all claims, losses, liabilities, expenses, damages or
                        costs of any nature incurred by such parties arising
                        from the Supplier’s provision of the Accommodation
                        (whether provided negligently or not) including but not
                        limited to:
                        <ul className='dialog__content--listInList'>
                            <li>
                                {`i) any claim made or threatened to be made
                                against any Agent by any Customer in connection
                                with that customer’s Booking;`}
                            </li>
                            <li>
                                {`ii) any breach by You of your obligations under
                                this Agreement; or`}
                            </li>
                            <li>
                                {`iii) any breach by You of your statutory or
                                regulatory obligations.`}
                            </li>
                        </ul>
                    </li>
                    <li>
                        8.3 In the event that Agent becomes aware of any
                        circumstances that may reasonably be expected to give
                        rise to a claim by a Customer, Agent shall
                        <ul className='dialog__content--listInList'>
                            <li>
                                {`a) notify You in writing as soon as possible.
                                `}
                            </li>
                            <li>
                                {`b) not make any admission of liability or offer of settlement in relation to the Claim without the prior written consent of Supplier, such consent not to be unreasonably withheld. `}
                            </li>
                        </ul>
                    </li>
                    <li>
                        8.4 Agent’s maximum aggregate liability (howsoever
                        arising) in connection with this Agreement shall be
                        limited to ten percent of the average monthly total of
                        Bookings made by Customers under this Agreement in the
                        three month period prior to any claim arising. Any claim
                        over and above this amount shall be the responsibility
                        of the Supplier.
                    </li>
                    <li>
                        8.5 Agent shall not be liable for any consequential or
                        indirect loss or damage arising out of or in connection
                        with this Agreement.
                    </li>
                    <li>
                        8.6 Nothing in this Agreement shall operate so as to
                        exclude or limit the liability of either party for death
                        or personal injury arising out of their own negligence,
                        or for any other liability which cannot be excluded or
                        limited by law.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>
                    9. TERM and TERMINATION
                </h2>
                <ul className='dialog__content--list'>
                    <li>
                        9.1 This Agreement shall commence on the Effective Date
                        and shall continue indefinitely until terminated by
                        either party giving at least one (1) month’s prior
                        written notice to the other party.
                    </li>
                    <li>
                        9.2 Either Party may terminate this Agreement
                        immediately on notice in writing to the other Party if:
                        (i) the other Party commits a material breach of any of
                        its obligations under this Agreement which is incapable
                        of remedy (ii) the other party fails to remedy, where it
                        is capable of remedy any breach of its obligations under
                        this Agreement within 14 days after receiving written
                        notice to do so; (ii) the other Party ceases or
                        threatens to cease to trade, becomes unable to pay its
                        debts, admits its inability to pay its debts or becomes
                        insolvent; (iii)the other party has a receiver or
                        administrative receiver appointed, passes a resolution
                        for winding up, becomes subject to an administrative
                        order or enters into a voluntary arrangement with this
                        creditors (iv) or anything equivalent to any of the
                        events or circumstances stated in this clause occurs in
                        any applicable jurisdiction.
                    </li>
                    <li>
                        9.3 The expiry of termination of this Agreement for
                        whatever reason shall not affect any provision of this
                        Agreement which is expressly or by implication intended
                        to come into force or continue in force or or after such
                        termination. Each party shall honour any Bookings made
                        before the date of termination and, for this purpose,
                        each party shall continue to comply with all obligations
                        in this Agreement which are reasonably necessary for the
                        purpose of honouring such Bookings.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>
                    10. DATA PROTECTION
                </h2>
                <ul className='dialog__content--list'>
                    <li>
                        10.1 Each party agrees to comply with the Data
                        Protection Directive 95/46/EC, the General Data
                        Protection Regulation 2016/679/EU (GDPR) and all other
                        applicable Data Protection legislation which is in force
                        or may come into force from time to time (the “Data
                        Protection Legislation”) in relation to the collection
                        and processing of “Personal Data.
                    </li>
                    <li>
                        10.2 Each party shall ensure that it has in place, or
                        shall establish and maintain adequate security
                        procedures and controls to prevent unintended disclosure
                        of and unauthorised access to personal data or
                        information of Customers.
                    </li>
                    <li>
                        10.3 Supplier agrees that it shall not send unsolicited
                        marketing communications to Customers without prior
                        consent of the Customer.
                    </li>
                    <li>
                        10.4 Both parties acknowledge that for the purposes of
                        the Data Protection Legislation Supplier acts as the
                        “Data Controller” of the Personal Data which is
                        collected by the Agent and Distributors both also acts
                        as “Data Controllers” under this Agreement.
                    </li>
                    <li>
                        10.5 Notwithstanding anything to the contrary in the
                        Agreement, each party shall indemnify and hold harmless
                        the other Party against all losses, fines and sanctions
                        from any claim by a third party or supervisory authority
                        of data protection arising any infringement of the Data
                        Protection Legislation.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>
                    11. CONFIDENTIALITY
                </h2>
                <ul className='dialog__content--list'>
                    <li>
                        11.1 Each Party shall keep and procure to be kept secret
                        and confidential all Confidential Information belonging
                        to the disclosing party and disclosed or obtained as a
                        result of the relationship of the parties under this
                        Agreement and shall not use or disclose the same save
                        for the purposes of the proper performance of this
                        Agreement or with the prior written consent of the
                        disclosing party.
                    </li>
                    <li>
                        11.2 Each party shall use its best endeavours to ensure
                        that Confidential Information within its control is kept
                        securely protected against theft or unauthorised access,
                        and in any event shall maintain its security, integrity
                        and confidentiality to at least the same standard as it
                        applies to its own confidential information.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>12. FORCE MAJEURE</h2>
                <ul className='dialog__content--list'>
                    <li>
                        12.1 If either party is affected by Force Majeure, it
                        shall not be liable to the other party and shall be
                        released from its obligations under this Agreement to
                        the extent that its ability to perform the obligations
                        under this Agreement has been directly affected by the
                        Force Majeure Event, provided that it has promptly given
                        notice to the other party of the nature and likely
                        duration of the Force Majeure event.
                    </li>
                    <li>
                        12.2 If the impact of Force Majeure continues for a
                        period of 30 consecutive days, either Party may, by
                        written notice, terminate this Agreement with immediate
                        effect.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>13. GENERAL</h2>
                <ul className='dialog__content--list'>
                    <li>
                        13.1 If any dispute arises between the parties under or
                        in relation to this Agreement it shall first be referred
                        to the sales directors of each party. If it cannot be
                        resolved to the satisfaction of all Parties within 15
                        working days it shall be referred to the chief
                        executives/managing directors of each Party for
                        resolution.
                    </li>
                    <li>
                        13.2 Any notices sent under this Agreement must be in
                        writing (which for these purposes includes e-mail) at
                        the address set out in the Specific Terms to this
                        Agreement by personal delivery with confirmation of
                        receipt, by mail or by electronic mail provided that
                        there is evidence of receipt by the addressee.
                    </li>
                    <li>
                        13.3 Neither party may assign this Agreement without the
                        prior written consent of the other party, which shall
                        not be unreasonably withheld.
                    </li>
                    <li>
                        13.4 If any provision of this Agreement is found by any
                        court of competent jurisdiction to be illegal, invalid
                        or unenforceable, that provision shall be deemed not to
                        form part of this Agreement, and the legality, validity
                        or enforceability of the remainder of the provisions of
                        this Agreement shall not be affected,
                    </li>
                    <li>
                        13.5 This Agreement shall be governed by and construed
                        in accordance with Spanish law and each party submits to
                        the exclusive jurisdiction of the Courts of Spain for
                        any dispute arising out of or related to this Agreement.
                    </li>
                </ul>
                <br />
                <h2 className='dialog__content--subtitle'>
                    Annex 1 - INVOICING & PAYMENT PROCEDURE
                </h2>
                <h2 className='dialog__content--subtitle'>INVOICING</h2>
                <ul className='dialog__content--list'>
                    <li>
                        1. Supplier shall issue Agent an invoice for the amount
                        of each Booking.
                    </li>
                    <li>
                        2. The invoiced amount shall include indirect taxes
                        applicable to the country where the Accommodation is
                        located.
                    </li>
                </ul>
                <h2 className='dialog__content--subtitle'>PAYMENT</h2>
                <p>
                    Agent shall pay Supplier by VCC within 15 days from check
                    out
                </p>
                <p>
                    Supplier undertakes to collect payment in the currency
                    stated in the booking confirmation. In the event that the
                    Supplier collects the payment in any other currency, the
                    Supplier will bear any and all charges for currency
                    conversion that could be charged to Agent by its credit card
                    provider.
                </p>
                <h2 className='dialog__content--subtitle'>CURRENCY</h2>
                <p>Please select. Payment for Bookings will be made in:</p>
                <CurrenciesTermsAndConditions />
                <h2 className='dialog__content--subtitle'>
                    FINANCE CONTACT DETAILS
                </h2>
                <p>Please complete</p>
                <FinanceTermsAndConditionsForm />
                <h2 className='dialog__content--subtitle'>
                    CUSTOMER SERVICE CONTACT DETAILS
                </h2>
                <p>Please complete</p>
                <CustomerServiceTermsAndConditions />
                <p className='dialog__content--small-subtitle'>03.05.2023</p>
            </div>
        </div>
    );
}
