import React, { createContext, useContext, useState, useEffect } from 'react';
import { findIana, findWindows } from 'windows-iana';
import { ContextUserProfile } from './UserProfileContext';
import { buildOdataEndPoint } from '../utils/odataEndPoint';
import { cachedApi } from '../services/api';
import { readOnlyEntities } from '../utils/commonVariables/entityKeys';

export const ContextSettings = createContext();

export const ProviderSettings = props => {
    const { children } = props;
    const { timeZone } = useContext(ContextUserProfile);

    const [isEditionMode, setIsEditionMode] = useState(false);
    const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    //para no tener que importar todos los timezones a la componente
    const [selectedTimezone, setSelectedTimezone] = useState({
        Id: 0,
        DisplayName: '',
    });

    const getTimeZones = async () => {
        const endpoint = buildOdataEndPoint({
            entity: readOnlyEntities.TimeZoneUtil,
            select: 'Id,StandardName,DisplayName',
            orderBy: 'BaseUtcOffsetSec',
        });
        setIsLoading(true);
        try {
            const { data } = await cachedApi.get(endpoint);

            // quitamos las zonas horarias que no tengan ningún IANA porque si no, al transformar dará error al transformar las fechas
            const usefulTimeZones = data?.value.flatMap(item => {
                const [IANA] = findIana(item.StandardName);
                if (!IANA) return [];
                return item;
            });
            setTimeZoneOptions(usefulTimeZones);
        } catch (error) {
            // setHasErrors(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getTimeZones();
    }, []);

    useEffect(() => {
        if (timeZone && timeZoneOptions.length) {
            const [standardName] = findWindows(timeZone);

            const selectedOption = timeZoneOptions.find(
                item => item?.StandardName === standardName
            );

            setSelectedTimezone({
                Id: selectedOption.Id,
                DisplayName: selectedOption?.DisplayName,
            });
        }
    }, [timeZone, timeZoneOptions]);

    return (
        <ContextSettings.Provider
            value={{
                isEditionMode,
                setIsEditionMode,
                isEditButtonVisible,
                setIsEditButtonVisible,
                timeZoneOptions,
                selectedTimezone,
                isLoading,
                setIsLoading,
            }}
        >
            {children}
        </ContextSettings.Provider>
    );
};
