import React, { useContext } from 'react';
import TextInput from './TextInput';
import CheckboxInputForVisibility from './CheckboxInputForVisibility';
import Typeahead from './Typeahead';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { getValueFromObjetByComposedKey } from '../../../utils/usefulFunctions';
import SelectInput from './SelectInput';
import CheckboxList from './CheckboxList';
import Textarea from './Textarea';
import SwitchInput from './SwitchInput';

export default function PaintInputs(array, content, handler) {
    const state = useContext(ContextOwnerPortal);
    // Los item son los objetos del json
    // Los value lo que ya viene marcado/rellenado de base de datos
    // EL name es la clave del objeto hotelInfo que habra que modificar con los datos que se toquen al rellenar

    const getElements = _array => {
        return _array.map(item => {
            const { type, name } = item;
            const savedValue = getValueFromObjetByComposedKey(content, name);
            const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
            const storedValue = getValueFromObjetByComposedKey(
                storedInfo,
                name
            );
            const value = storedValue || savedValue;
            switch (type) {
                case 'text':
                case 'email':
                case 'number':
                case 'time':
                    item.onBlur = e => state.trimValueOnBlur(e, handler);
                    return (
                        <TextInput
                            data={item}
                            key={name}
                            value={value}
                            handleChange={handler}
                        />
                    );
                case 'textarea':
                    return (
                        <Textarea
                            data={item}
                            key={name}
                            value={value}
                            handleChange={handler}
                        />
                    );
                case 'select':
                    return (
                        <SelectInput
                            data={item}
                            key={name}
                            handleChange={handler}
                            value={value}
                        />
                    );
                case 'TypeaheadInput':
                    return (
                        <Typeahead
                            data={item}
                            key={name}
                            handleChange={handler}
                            value={value}
                        />
                    );
                case 'SwitchInput':
                    return (
                        <SwitchInput
                            data={item}
                            key={name}
                            value={value}
                            handleChange={handler}
                        />
                    );
                case 'checkboxList':
                    return (
                        <CheckboxList
                            data={item}
                            key={name}
                            value={value || []}
                            handleChange={handler}
                        />
                    );
                case 'checkboxForVisibility':
                    return (
                        <CheckboxInputForVisibility data={item} key={name} />
                    );
                case 'hiddenInput':
                    item.onBlur = e => state.trimValueOnBlur(e, handler);
                    return (
                        <div key={name}>
                            {state[item.hiddenkey] && (
                                <TextInput
                                    data={item}
                                    key={name}
                                    value={value}
                                    handleChange={handler}
                                />
                            )}
                        </div>
                    );
                default:
                    return <div key='test'>Componente no encontrado</div>;
            }
        });
    };

    return getElements(array);
}
