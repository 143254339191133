export const sortByName = (array, key) => {
    return array.sort(function (a, b) {
        if (a[key] > b[key]) {
            return 1;
        }
        if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    });
};

export const sortByKeyDesc = (array, key) => {
    return array.sort(function (a, b) {
        if (a[key] > b[key]) {
            return -1;
        }
        if (a[key] < b[key]) {
            return 1;
        }
        return 0;
    });
};
