import React from 'react';
import '../../stylesheets/bunnyLine.scss';
import { Typography } from '@mui/material';
import useTranslate from '../../customHooks/useTranslate';

const BunnyLoading = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '80vh',
            }}
        >
            <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 92 108'
                height={80}
            >
                <path
                    className='outside-line'
                    d='M30.2,104.9h33.9l1.7-1.1-15.05-10L68.5,93l.6-.9-17.3-7.7l6-6.1L69.5,88l.9-.8-4-14.7l8.4,3.2-5.3-15.2l3.7-11l14.7-5.8l1.8-8L76.4,23.5L54.5,5.4L38.8,1.6c0,0-13.6,3-13.6,3v1.5L50.75,18.2L36.4,15.7L7.3,27.3l1.2,1.3l50.2,3.3-.6,8.9L19,60.2L15,54L7.3,57.5v2.7L4.9,62.4L3.9,71L17,74.1L15,89.8l14.714005,14.663475'
                    fill='none'
                    strokeWidth='4'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
            <Typography
                variant='body1'
                style={{ marginTop: '10px' }}
            >{`${useTranslate('loading')}`}</Typography>
        </div>
    );
};

export default BunnyLoading;
