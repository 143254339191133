import React, { useContext } from 'react';
//librerías
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
//propias
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { languagesMenu } from '../../../utils/menusData';

const MenuLanguageSelector = props => {
    const { isMenuOpen, anchorEl, handleClose, handleClickOption } = props;
    const { language } = useContext(ContextUserProfile);

    return (
        <Menu
            open={isMenuOpen}
            id='language-button'
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {languagesMenu
                .filter(lang => lang.text !== language)
                .map(lang => {
                    return (
                        <MenuItem
                            key={lang.text}
                            onClick={() => {
                                handleClickOption(lang.text);
                            }}
                        >
                            <img
                                src={lang.Icon}
                                width={20}
                                alt={'language flag'}
                            />
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                    fontWeight: 600,
                                    color: 'grey600',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {lang.text}
                            </Typography>
                        </MenuItem>
                    );
                })}
        </Menu>
    );
};

export default MenuLanguageSelector;
