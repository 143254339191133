import React, { useContext } from 'react';
import '../../../stylesheets/stylesLayout/header/generalHeader.scss';
import UserMenu from './userMenu/UserMenu';
import GenericMenu from './GenericMenu';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

export default function GeneralHeader({ menuData, isVisible, isMuggle }) {
    const { isLogged } = useContext(ContextUserProfile);
    const style = isVisible ? 'header__container' : 'd-none';
    const isVisibleGenericMenu = !isMuggle;
    return (
        <header className={style}>
            <a
                className='logo__header'
                href='https://www.fastpayhotels.com/'
                aria-label='logo image to home'
                alt='logo image to home'
            >
                {/* <span className='sr-only'>Go to Home</span> */}
            </a>
            <div className='language__navbar--container'>
                {isVisibleGenericMenu && <GenericMenu menuData={menuData} />}
                {isLogged && <UserMenu />}
            </div>
        </header>
    );
}
