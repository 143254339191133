import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
} from '@mui/material';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { capitalize } from '../../utils/usefulFunctions';
import SuffixButton from './SuffixButton';

const StyledTextInput = styled(OutlinedInput)(() => ({
    '&:hover .text-input__delete-icon': {
        display: 'flex',
    },
    '&:not(:hover) .text-input__delete-icon': {
        display: 'none',
    },
    '& legend': {
        width: 0,
    },
}));

const StyledFormControl = styled(FormControl)(() => ({
    margin: 0,
    width: '100%',
    '& label.Mui-focused': {
        width: 'auto',
    },
    '& label.MuiInputLabel-shrink': {
        width: 'auto',
    },
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
    position: 'relative',
    fontSize: '10px',
    marginBottom: '10px',
    height: '16px',
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: '100%',
    padding: '0 5px',
}));

const TextInput = props => {
    const {
        labelText,
        errorMessage,
        helperText,
        id,
        handleChange,
        deleteIcon,
        prefix,
        suffix,
        formControlProps,
        labelProps,
        helperTextProps,
        ...restProps
    } = props;

    const { translate } = useContext(ContextUserProfile);
    const label = capitalize(translate(labelText));

    const helper = errorMessage || helperText;

    const handleChangeInput = e => handleChange(e, e.target.value);
    const inputRef = useRef();

    const clearText = () => {
        inputRef.current.focus();
        handleChange({ target: { name: id, value: '' } }, '');
    };

    return (
        <StyledFormControl
            size='small'
            required={props.required}
            error={!!errorMessage}
            {...formControlProps}
        >
            <StyledInputLabel htmlFor={id} children={label} {...labelProps} />
            <StyledTextInput
                id={id}
                name={id}
                endAdornment={
                    <InputAdornment position='end'>
                        {prefix}
                        {deleteIcon && props.value && (
                            <SuffixButton
                                classes={{ root: 'text-input__delete-icon' }}
                                iconName='Close'
                                onClick={clearText}
                            />
                        )}
                        {suffix}
                    </InputAdornment>
                }
                label={labelText}
                onChange={handleChangeInput}
                inputRef={inputRef}
                {...restProps}
            />
            <StyledFormHelperText
                children={translate(helper)}
                {...helperTextProps}
            />
        </StyledFormControl>
    );
};

TextInput.defaultProps = {
    deleteIcon: true,
    prefix: null,
    suffix: null,
    errorMessage: '',
    helperText: '',
};

TextInput.propTypes = {
    /**
     * 	deleteIcon: Determina si el botón con forma de cruz para borrar todo el contenido del input es visible o no
     * 	@default true
     */
    deleteIcon: PropTypes.bool,
    /**
     * 	prefix: Componente a renderizar antes del deleteIcon
     * 	@default null
     */
    prefix: PropTypes.element,
    /**
     * 	suffix: Componente a renderizar después del deleteIcon
     * 	@default null
     */
    suffix: PropTypes.element,
    /**
     * 	errorMessage: String que se pone debajo del input y que se traduce
     * 	@default null
     */
    errorMessage: PropTypes.string,
    /**
     * 	helperText: String que se pone debajo del input, como texto de ayuda y que se traduce
     * 	@default null
     */
    helperText: PropTypes.string,
};

export default TextInput;
