import { formatNumber } from '../../../utils/usefulFunctions';

export const getTableStructure = IdOwner => {
    return {
        columns: [
            { label: 'status', key: 'Status', type: 'Status', priority: 1 },
            { label: 'booking_code', key: 'BookingCode', priority: 0 },
            {
                label: 'hotel',
                isSortable: true,
                key: 'SupplierHotel/Name',
                ascDefaultOrder: true,
                priority: 1,
            },
            {
                label: 'booking_date',
                isSortable: true,
                key: 'BookingDate',
                ascDefaultOrder: false,
                type: 'DateTime',
                priority: 0,
            },
            {
                label: 'check_in_date',
                isSortable: true,
                key: 'CheckInDate',
                ascDefaultOrder: false,
                type: 'Date',
                priority: 0,
            },
            {
                label: 'check_out_date',
                isSortable: true,
                key: 'CheckOutDate',
                ascDefaultOrder: false,
                type: 'Date',
                priority: 2,
            },
        ],
        filters: [
            {
                odataKey: 'SupplierBookingStatus/IdSupplierBookingStatusType',
                stateKey: 'status',
                type: 'select',
                priority: 1,
            },
            {
                stateKey: 'bookingCode',
                odataKey: 'BookingCode',
                type: 'text',
                priority: 0,
            },
            {
                stateKey: 'hotel',
                odataKey: 'IdSupplierHotel',
                type: 'typeahead',
                priority: 1,
            },
            {
                stateKey: 'bookingDate',
                odataKey: 'BookingDate',
                type: 'daterange',
                priority: 0,
            },
            {
                stateKey: 'checkInDate',
                odataKey: 'CheckInDate',
                type: 'daterange',
                priority: 0,
            },
            {
                stateKey: 'checkOutDate',
                odataKey: 'CheckOutDate',
                type: 'daterange',
                priority: 2,
            },
        ],
    };
};

export const statusColors = {
    Commited: '#28743E',
    Cancelled: '#b50027',
};

export const filtersInitialState = {
    status: 0,
    bookingCode: '',
    hotel: 0,
    bookingDate: [null, null],
    checkInDate: [new Date(), null],
    checkOutDate: [null, null],
};

export const orderByInitialState = { key: 'DateLastUpdated', order: 'desc' };

export const cleanBookingData = dataArray => {
    return dataArray.map(item => {
        return {
            ...item,
            // eslint-disable-next-line
            ['SupplierHotel/Name']: item.SupplierHotel.Name, 
            Status: item.SupplierBookingStatus.SupplierBookingStatusType
                .Caption,
            StatusColor:
                item.SupplierBookingStatus.SupplierBookingStatusType.Color,
            IdStatus: item.SupplierBookingStatus.IdSupplierBookingStatusType,
        };
    });
};

export const viewModelStructure = {
    SupplierBookingStatus: {
        SupplierBookingStatusType: {
            Name: true,
        },
    },
    SupplierHotel: {
        Name: true,
    },
    BookingCode: true,
    CheckInDate: true,
    CheckOutDate: true,
    BookingDate: true,
    Nights: true,
    Paxes: true,
    TotalAmount: true,
    Currency: {
        Key: true,
    },
    SupplierBookingPaymentInfo: {
        CreditCardNumberObfuscated: true,
        HolderName: true,
        HolderSurname: true,
        IdVirtualCreditCard: true,
        VirtualCreditCard: {
            CVV: true,
            CardNumber: true,
        },
    },
    ReservationIDs: {
        ResID_Value: true,
        OTAReferenceTableCode: {
            Key: true,
        },
    },
    SupplierBookingRequest: true,
    Rooms: {
        SupplierHotelMealPlan: {
            Name: true,
            MappingCode: true,
        },
        SupplierHotelRoomType: {
            Name: true,
            Description: true,
            MappingCode: true,
        },
        Paxes: {
            Age: true,
            Name: true,
            Surname: true,
            IsAdult: true,
            IsBaby: true,
            IsChild: true,
        },
        Rates: {
            AmountAfterTax: true,
        },
        SupplierHotelRatePlan: {
            MappingCode: true,
            Name: true,
        },
    },
    Remarks: true,
    SupplierBookingCancellationPolicy: {
        DecodedPenalties: true,
    },
    ZoeBooking: {
        ExternalReferenceCodes: true,
        EndCustomer: {
            Name: true,
            Surname: true,
            EmailAddress: true,
        },
    },
};

export const detailInitialState = {
    SupplierBookingStatus: {
        SupplierBookingStatusType: {
            Name: '',
        },
    },
    SupplierHotel: {
        Name: '',
    },
    SupplierBookingPaymentInfo: {
        CreditCardNumberObfuscated: '',
        HolderName: '',
        HolderSurname: '',
        IdVirtualCreditCard: 0,
        VirtualCreditCard: {
            CVV: '',
            CardNumber: '',
        },
    },
    ReservationIDs: {
        ResID_Value: '',
        OTAReferenceTableCode: {
            Key: '',
        },
    },
    BookingCode: '',
    CheckInDate: '',
    CheckOutDate: '',
    BookingDate: '',
    Nights: 0,
    Paxes: 0,
    TotalAmount: '',
    Currency: {
        Name: '',
    },
    Remarks: '',
    ZoeBooking: {
        ExternalReferenceCodes: '',
        EndCustomer: [
            {
                Name: '',
                Surname: '',
                EmailAddress: '',
            },
        ],
        Rooms: [
            {
                HotelMealPlan: {
                    Name: '',
                    MappingCode: '',
                },
                HotelRoomType: {
                    Name: '',
                    Description: '',
                    MappingCode: '',
                },
                Paxes: [
                    {
                        Age: '',
                        Name: '',
                        Surname: '',
                        IsAdult: '',
                        IsBaby: '',
                        IsChild: '',
                    },
                ],
                SupplierHotelRatePlan: {
                    MappingCode: '',
                    Name: '',
                },
                Rates: [
                    {
                        Amount: '',
                        SellCurrencyAmount: '',
                        Currency: {
                            Key: '',
                        },
                    },
                ],
            },
        ],
        CancellationPolicy: {
            DecodedPenalties: [''],
        },
    },
};

export const cleanSingleBookingData = booking => {
    const findHotelRef = IdList => {
        const refObject = IdList.find(
            item => item.OTAReferenceTableCode.Key === '10'
        );
        return refObject?.ResID_Value || '';
    };

    const hotelRef =
        booking?.ReservationIDs.length && findHotelRef(booking?.ReservationIDs);

    const client = booking?.ZoeBooking.EndCustomer;

    return {
        BookingCode: booking.BookingCode,
        Status: booking.SupplierBookingStatus.SupplierBookingStatusType.Name,
        BookingDate: booking.BookingDate,
        Hotel: booking.SupplierHotel.Name,
        BookingReferenceHotel: hotelRef,
        CheckIn: booking.CheckInDate,
        CheckOut: booking.CheckOutDate,
        Nights: booking.Nights,
        Guests: booking.Paxes,
        Price:
            booking?.Currency?.Key && booking?.TotalAmount
                ? formatNumber(
                      booking?.TotalAmount,
                      'currency',
                      booking.Currency.Key
                  ) + ` (${booking.Currency.Key})`
                : booking?.TotalPrice
                ? formatNumber(booking?.TotalPrice, 'decimal')
                : booking?.TotalPrice,
        PaymentInfo:
            booking?.SupplierBookingPaymentInfo?.CreditCardNumberObfuscated,
        CancellationPolicy:
            booking?.SupplierBookingCancellationPolicy?.DecodedPenalties,
        Notes: booking?.Remarks,
        ClientName: client.Name,
        ClientSurname: client?.Surname,
        EmailAdress: client?.EmailAddress,
    };
};
