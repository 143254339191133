import React, { useContext, useState } from 'react';
import '../../stylesheets/stylesLayout/lateralMenu.scss';
import RenderIcon from './RenderIcon';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import CollapseMenuIcon from '../../images/icons/CollapseMenuIcon';
import {
    // Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { lastPageVisitedKey } from '../../utils/userProfileContextHelpers';

const LateralMenu = ({ list = [] }) => {
    const { translate } = useContext(ContextUserProfile);
    const [isOpenMenu, setIsOpenMenu] = useState(true);
    // const [openMenu, setOpenMenu] = useState('Main');
    const [selectedSubMenu, setSelectedSubMenu] = useState(
        localStorage.getItem(lastPageVisitedKey) ||
            '/owner-portal/your-properties'
    );

    const history = useHistory();

    // const handleClick = text => {
    //     setOpenMenu(text);
    // };

    const handleItemClick = link => {
        setSelectedSubMenu(link);
        history.push(link);
    };

    return (
        <nav
            className={`menu__list-container
        ${isOpenMenu ? '--expanded' : '--collapsed'}`}
        >
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                }}
                component='nav'
                aria-labelledby='nested-list-subheader'
            >
                <ListItemButton
                    // onClick={() => handleClick('Main')}
                    onClick={() => handleItemClick('/owner-portal')}
                >
                    <ListItemIcon>
                        <RenderIcon
                            iconData={{
                                text: 'Main',
                                iconClass: 'fas fa-house',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary='Main' />
                    {/* {openMenu === 'Main' ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                {/* <Collapse in={openMenu === 'Main'} timeout='auto' unmountOnExit> */}
                <List component='div' disablePadding>
                    {list.map((item, i) => {
                        const { link, text, iconClass } = item;
                        return (
                            <ListItemButton
                                sx={{ pl: 4 }}
                                key={i}
                                href={link}
                                selected={selectedSubMenu === link}
                                onClick={() => handleItemClick(link)}
                            >
                                <ListItemIcon>
                                    <RenderIcon
                                        iconData={{
                                            text,
                                            iconClass,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={translate(item.text)} />
                            </ListItemButton>
                        );
                    })}
                </List>
                {/* </Collapse> */}
                {/* <ListItemButton onClick={() => handleClick('Zinantis')}>
                    <ListItemIcon>
                        <RenderIcon
                            iconData={{
                                text: 'Zinantis',
                                iconClass: 'fas fa-z',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary='Zinantis' />
                    {openMenu === 'Zinantis' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                    in={openMenu === 'Zinantis'}
                    timeout='auto'
                    unmountOnExit
                >
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary='Members' />
                        </ListItemButton>
                    </List>
                </Collapse> */}
            </List>
            <CollapseMenuIcon
                className={`menu__colapse-icon ${
                    isOpenMenu ? '--expanded' : '--collapsed'
                }`}
                onClick={() => setIsOpenMenu(prev => !prev)}
            />
        </nav>
    );
};

export default LateralMenu;
