import React, { useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ContextLanding } from '../../../contexts/LandingContext';
import useTranslate from '../../../customHooks/useTranslate';
import { grey200 } from '../../../stylesheets/variables.scss';
import LoadingV2 from '../../../common/components/LoadingV2';

const MostBookedChart = () => {
    const { mostBookedHotels, isLoadingMostBookedHotels } =
        useContext(ContextLanding);

    const data = {
        labels: mostBookedHotels?.map(hot => hot.HotelName),
        datasets: [
            {
                data: mostBookedHotels?.map(hot => hot.BookingCount),
                backgroundColor: mostBookedHotels?.map(hot => hot.color),
            },
        ],
    };

    const noData = {
        labels: [useTranslate('No data')],
        datasets: [
            {
                data: [1],
                backgroundColor: grey200,
            },
        ],
    };

    const renderData = () => {
        if (mostBookedHotels.length > 0) {
            return data;
        } else {
            return noData;
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'right',
        },
        width: 100,
        height: 100,
    };

    return (
        <div className='card-hotels__container'>
            <div className='card-hotels__chart'>
                {isLoadingMostBookedHotels ? (
                    <div className='landing__menu-card-loading'>
                        <LoadingV2 />
                    </div>
                ) : (
                    <Doughnut
                        data={renderData}
                        options={options}
                        width={270}
                        height={270}
                    />
                )}
            </div>
        </div>
    );
};

export default MostBookedChart;
