import React from 'react';

const PublicSectionContainer = props => {
    const { children } = props;

    return (
        <main className='flex-grow-1 d-flex flex-column main-property'>
            {children}
        </main>
    );
};

export default PublicSectionContainer;
