import React, { useState, useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { getFlag } from '../../../utils/flags/getFlag';

export default function LanguagesDropdown({ langArray, handleAddDescription }) {
    const [isOpen, setIsOpen] = useState(false);
    const { translate } = useContext(ContextUserProfile);
    const visibleDropdown = isOpen ? 'show' : '';

    // Esta función cierra el dropdown de idiomas cuando se pierde el foco en él. Como pulsar en el propio dropdown para elegir un lenguaje dispara el onblur, necesita el timeout para que se ejecute despúes del onClick.
    const handleBlur = () => {
        if (isOpen) {
            setTimeout(() => {
                setIsOpen(!isOpen);
            }, 250);
        }
    };
    const handleChooseLanguage = e => {
        const { id } = e.target;
        e.preventDefault();
        setIsOpen(!isOpen);
        handleAddDescription(parseInt(id));
    };
    return (
        <div className='input-group mb-5'>
            {!!langArray.length && (
                <div
                    className={`input-group-prepend ${visibleDropdown}`}
                    onBlur={handleBlur}
                >
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className='btn btn-outline-success dropdown-toggle'
                        type='button'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                    >
                        {translate('add_new_language_description')}
                    </button>
                    <div
                        className={`language__dropdown dropdown-menu  ${visibleDropdown}`}
                    >
                        {langArray.map(({ Id, LocalizedCaption, Caption }) => (
                            <button
                                type='button'
                                onClick={handleChooseLanguage}
                                className='dropdown-item dropdown__item'
                                id={Id}
                                key={Id}
                                data-name={LocalizedCaption}
                            >
                                <img
                                    src={getFlag(Id)}
                                    alt={`flag ${LocalizedCaption}`}
                                    className='flag'
                                />
                                {LocalizedCaption || Caption}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
