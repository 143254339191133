import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CheckboxList from '../CheckboxListAccordion';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { withRouter } from 'react-router-dom';

const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled(props => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CustomizedAccordions = props => {
    const { data, firstServiceId } = props;
    const {
        match: { path },
    } = props;

    const {
        handleChangeRoomServices,
        handleChangeServices,
        RoomServices,
        hotelInfo: {
            SupplierHotelInfo: { Services },
        },
    } = useContext(ContextOwnerPortal);

    const [expanded, setExpanded] = useState(`panel${firstServiceId}`);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const savedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const savedServices = savedInfo
            ? savedInfo.SupplierHotelInfo.Services
            : Services;
        const arrayToRender = path.includes('/inventory')
            ? RoomServices
            : savedServices;
        setSelected(arrayToRender);
    }, [RoomServices, Services, path]);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangeSelected = path.includes('/inventory')
        ? handleChangeRoomServices
        : handleChangeServices;

    return (
        <div style={{ minHeight: 326, width: '100%' }}>
            {data?.map(category => {
                return (
                    <Accordion
                        expanded={expanded === `panel${category.Id}`}
                        onChange={handleChange(`panel${category.Id}`)}
                        key={category.Id}
                        style={{ border: 0 }}
                    >
                        <AccordionSummary
                            aria-controls={`panel${category.Id}d-content`}
                            id={`panel${category.Id}d-header`}
                        >
                            <Typography>{category.Caption}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ display: 'flex' }}>
                                {category.Content.Free.length ? (
                                    <CheckboxList
                                        title='Free'
                                        checkboxList={category.Content.Free}
                                        handleChange={handleChangeSelected}
                                        arraySelected={selected}
                                    />
                                ) : (
                                    ''
                                )}
                                {category.Content.Extra.length ? (
                                    <CheckboxList
                                        title='Extra'
                                        checkboxList={category.Content.Extra}
                                        handleChange={handleChangeSelected}
                                        arraySelected={selected}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
};

export default withRouter(CustomizedAccordions);
