import React, { useContext } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';

const Card3 = props => {
    const { coverPageInfo } = useContext(ContextOwnerPortal);
    const { translate } = useContext(ContextUserProfile);
    const { Porcentage, textButton, urlButton, disabled } = props;
    return (
        <div className='card-steps shadow'>
            <div className='iconNtext__container'>
                <i
                    className='fas fa-bed iconNtext__icon'
                    style={{ fontSize: '24px' }}
                ></i>
                <p className={`iconNtext__text-md`}>
                    {coverPageInfo.Rooms} {translate('rooms_selected')}
                </p>
            </div>
            <div className='card-steps__footer mt-4'>
                <div
                    className='progress mt-2 mb-3'
                    style={{ height: '7px', width: '30%' }}
                >
                    <div
                        className='progress-bar progress-bar bg-success '
                        style={{ width: `${Porcentage}%` }}
                        aria-valuenow={Porcentage}
                        aria-valuemin='0'
                        aria-valuemax='100'
                    />
                </div>
                <GeneralButton
                    type='openStep'
                    text={textButton}
                    url={urlButton}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default Card3;
