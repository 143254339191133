import { useContext, useEffect, useState, useRef } from 'react';
import { NotificationsContext } from '../contexts/NotificationsContext';
import api from '../services/api';

const useErrorHandledApiCall = (endpoint, errorFunction, trigger = true) => {
    const { setErrorMessage } = useContext(NotificationsContext);
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const componentMounted = useRef(true);

    useEffect(() => {
        const getApiCall = async () => {
            try {
                setIsLoading(true);
                const { data } = await api.get(endpoint);

                setOptions(data.Result || data);
                setIsLoading(false);
            } catch (error) {
                if (errorFunction) {
                    errorFunction();
                } else {
                    if (!componentMounted.current) return null;
                    setErrorMessage('something_went_wrong');
                }
            }
            if (!componentMounted.current) return null;
        };
        if (trigger && endpoint) {
            getApiCall();
        }
        return () => {
            componentMounted.current = false;
        };
    }, [endpoint, trigger, errorFunction, setErrorMessage]);

    return { options, isLoading };
};

export default useErrorHandledApiCall;
