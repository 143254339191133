/* eslint-disable no-nested-ternary */
import React from 'react';
import SimpleTable from './SimpleTable';
import CardList from './CardList';

const CardItem = props => {
    const { Name, Value, Headers, button } = props;
    const { icon, handler, active } = button;
    const isArray = Array.isArray(Value);
    const isMatrix = isArray && Headers.length;
    return (
        <>
            {isMatrix ? (
                <SimpleTable Name={Name} Value={Value} Headers={Headers} />
            ) : isArray ? (
                <CardList Name={Name} Value={Value} />
            ) : (
                <>
                    <p className='card__field-name'> {Name} </p>
                    <p className='card__field-value'>{Value}</p>
                    {button.icon && (
                        <button
                            disabled={!active}
                            type='button'
                            className={`${icon}`}
                            onClick={handler}
                        >
                            <i className='fas fa-eye'></i>
                        </button>
                    )}
                </>
            )}
        </>
    );
};
CardItem.defaultProps = {
    Name: '',
    Value: '',
    Headers: [],
    button: {},
};
export default CardItem;
