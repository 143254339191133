import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

export default function ThumbNailButton({ button, image }) {
    const { translate } = useContext(ContextUserProfile);
    const { title, handleClick, icon } = button;
    const getIcon = (_title, _icon) => {
        if (_title === 'fav_image' && image.Feature) {
            return 'fas fa-star active';
        }
        if (_title === 'panorama_image' && image.Is360) {
            return 'thumbNail__360-icon--active';
        }
        return _icon;
    };
    const buttonTitle = getIcon(title, icon).match(/active/)
        ? `${title}_active`
        : title;
    return (
        <button
            type='button'
            className='thumbNail__button'
            data-toggle='tooltip'
            data-placement='bottom'
            title={translate(buttonTitle)}
            onClick={() => handleClick(image)}
        >
            <i className={getIcon(title, icon)} aria-hidden='true'>
                {title === 'panorama_image' ? <span>360&deg;</span> : ''}
            </i>
        </button>
    );
}
