import { readOnlyEntities } from '../commonVariables/entityKeys';
import { buildOdataEndPoint } from '../odataEndPoint';

export default {
    entityName: 'room_configuration_beds',
    entityTitle: 'room_configuration_beds',
    entityStepOf: 'room_configuration',
    Elements: [
        {
            Caption: 'beds',
            title: 'beds',
            name: `SupplierHotelRoomBedTypes`,
            type: 'checkboxList',
            apiEndPoint: buildOdataEndPoint({
                entity: readOnlyEntities.BedType,
                select: 'Id,Caption,LocalizedCaption',
                filter: 'Enabled',
                orderBy: 'LocalizedCaption',
            }),
            required: true,
            ActionInfo: 'LocalizedCaption',
            ActionInfo2: 'Caption',
        },
    ],
};
