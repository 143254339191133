import React from 'react';
import SendedFormIcon from '../SendedFormIcon';
import useTranslate from '../../customHooks/useTranslate';

const ContactStep4 = () => {
    return (
        <div className='step-content__container'>
            <div className='sended-form__container'>
                <SendedFormIcon />
                <div>
                    <h3 className='sended-form__title'>
                        {useTranslate('thank_you_support_ticket')}
                    </h3>
                    <p className=''>{useTranslate('we_will_check')}</p>
                </div>
            </div>
        </div>
    );
};

export default ContactStep4;
