import React, { createContext, useState, useEffect } from 'react';

export const ContextContact = createContext();

export const ProviderContact = props => {
    const { children } = props;

    const INITIAL_STATE_INPUTS_DATA = {
        Name: '',
        LastName: '',
        CompanyName: '',
        Category: '',
        IdCategory: null,
        Email: '',
        Subject: '',
        Comments: '',
    };

    const stepsCount = 3;
    const [inputsData, setInputsData] = useState(INITIAL_STATE_INPUTS_DATA);
    const [isInfoVisible, setIsInfoVisible] = useState(true);
    const [step, setStep] = useState(1);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (step !== 1) {
            setIsInfoVisible(false);
        }
    }, [step]);
    return (
        <ContextContact.Provider
            value={{
                isInfoVisible,
                setIsInfoVisible,
                step,
                setStep,
                isFetching,
                setIsFetching,
                inputsData,
                setInputsData,
                stepsCount,
            }}
        >
            {children}
        </ContextContact.Provider>
    );
};
