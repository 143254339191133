import React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Vcc from '../property-partners-portal/ownerPortal/components/Vcc';
import ButtonClose from '../common/components/buttons/ButtonClose';
import '../stylesheets/stylesPopUp/VccPopUp.scss';

const VccPopUp = props => {
    const { open, setOpen, vcc } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            className='container__creditcard'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} timeout={500}>
                {/* Necesita este div porque necesita devolver un unico elemento */}
                <div className='container__creditcard--vcc'>
                    <ButtonClose
                        handleClose={handleClose}
                        top={-23}
                        left={vcc.cvc ? 725 : 350}
                    />
                    <Vcc
                        name={vcc.name}
                        number={vcc.number}
                        expiry={vcc.expiry}
                        cvc={vcc.cvc}
                    />
                </div>
            </Fade>
        </Modal>
    );
};

export default VccPopUp;
