import React, { useContext, useState } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import ButtonLanguageSelector from './ButtonLanguageSelector';
import MenuLanguageSelector from './MenuLanguageSelector';

const LanguageSelector = () => {
    const { saveNewSettings, isLogged, setLanguage } =
        useContext(ContextUserProfile);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setIsMenuOpen(false);
        setAnchorEl(null);
    };

    const handleClickOption = opt => {
        selectLanguage(opt);
        handleClose();
    };

    const selectLanguage = value => {
        //está loggeado => guardar en la bbdd
        if (isLogged) {
            saveNewSettings(value, 'language');
            //no está loggeado
        } else {
            setLanguage(value);
        }
    };

    const handleClickButton = e => {
        setAnchorEl(e.currentTarget);
        setIsMenuOpen(true);
    };

    return (
        <div>
            <ButtonLanguageSelector onClick={handleClickButton} />
            <MenuLanguageSelector
                isMenuOpen={isMenuOpen}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleClickOption={handleClickOption}
            />
        </div>
    );
};

export default LanguageSelector;
