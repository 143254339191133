import React, { useContext } from 'react';
import { ContextYourBookings } from '../../contextsProperty/ContextYourBookings';
import '../../../stylesheets/stylesLayout/listFilters.scss';
import { Grid } from '@mui/material';
import TextInput from '../../../muiComponents/TextInput/TextInput';
import LoadedOptionsTypeahead from '../../../muiComponents/LoadedOptionsTypeahead';
import useOdataGet from '../../../customHooks/useOdataGet';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import OdataTypeahead from './OdataTypeahead';
import DateRangeFilter from './DateRangeFilter';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import { readOnlyEntities } from '../../../utils/commonVariables/entityKeys';

const FiltersBar = () => {
    const {
        areFiltersVisible,
        filters,
        setFilters,
        getBookingData,
        handleClearFilters,
    } = useContext(ContextYourBookings);
    const { IdHotelOwner } = useContext(ContextUserProfile);

    const COMMITTED_STATUS = 'COMMITTED';
    const CANCELLED_STATUS = 'CANCELLED';

    const bookingStatusEndpoint = {
        entity: readOnlyEntities.SupplierBookingStatusType,
        select: 'Id,Caption',
        filter: [
            {
                or: [
                    { Key: { eq: COMMITTED_STATUS } },
                    { Key: { eq: CANCELLED_STATUS } },
                ],
            },
        ],
    };
    const statusRequest = useOdataGet(bookingStatusEndpoint);
    const handleFilterChange = e => {
        const {
            target: { name, value },
        } = e;
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    return (
        <>
            <div
                className={`filters__section ${
                    areFiltersVisible ? '--expanded' : '--collapsed'
                }`}
            >
                <Grid
                    container
                    rowSpacing={{ xs: 1, md: 4 }}
                    columnSpacing={2}
                    columns={3}
                    style={{ marginTop: 10 }}
                >
                    <Grid
                        item
                        xs={3}
                        md={1}
                        lg={1}
                        style={{ paddingTop: 0 }}
                        className={'priority-1'}
                    >
                        <LoadedOptionsTypeahead
                            labelText={'status'}
                            options={statusRequest.options}
                            id={'status'}
                            idValue={filters.status}
                            handleChange={handleFilterChange}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        md={1}
                        lg={1}
                        style={{ paddingTop: 0 }}
                        className={'priority-0'}
                    >
                        <TextInput
                            labelText={'booking_code'}
                            id={'bookingCode'}
                            onChange={handleFilterChange}
                            value={filters.bookingCode}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        md={1}
                        lg={1}
                        style={{ paddingTop: 0 }}
                        className={'priority-1'}
                    >
                        <OdataTypeahead
                            labelText={'hotel'}
                            optionsLabelKey={'Name'}
                            endpoint={`${readOnlyEntities.SupplierHotel}?$select=Id,Name&$top=50`}
                            odataAdditionalFilter={{
                                key: 'IdHotelOwner',
                                operator: 'eq',
                                value: IdHotelOwner,
                            }}
                            id={'hotel'}
                            handleChange={handleFilterChange}
                            idValue={filters.hotel}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        lg={1}
                        style={{ paddingTop: 0 }}
                        className={'priority-0'}
                    >
                        <DateRangeFilter
                            value={filters.bookingDate}
                            label={'booking_date'}
                            id={'bookingDate'}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        lg={1}
                        style={{ paddingTop: 0 }}
                        className={'priority-0'}
                    >
                        <DateRangeFilter
                            value={filters.checkInDate}
                            label={'check_in_date'}
                            id={'checkInDate'}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        lg={1}
                        style={{ paddingTop: 0 }}
                        className={'priority-2'}
                    >
                        <DateRangeFilter
                            value={filters.checkOutDate}
                            label={'check_out_date'}
                            id={'checkOutDate'}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                </Grid>
                <div className='filters__button-wrap'>
                    <IconButton onClick={handleClearFilters}>
                        <FindReplaceIcon />
                    </IconButton>
                    <IconButton onClick={() => getBookingData()}>
                        <SearchIcon />
                    </IconButton>
                </div>
            </div>
        </>
    );
};

export default FiltersBar;
