import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import property from '../../../../images/property.png';
import contact from '../../../../images/contact.png';
import '../../../../stylesheets/stylesLayout/contact.scss';
import routes from '../../../../utils/commonVariables/routes';

const LandingContact = () => {
    const { translate } = useContext(ContextUserProfile);

    return (
        <main className='main mb-4'>
            <h1 className='header-title-contact mt-5 mb-4'>
                {translate('contact_US')}
            </h1>
            <div className='pb-4'>
                <ul className='d-flex justify-content-center nav'>
                    <div>
                        <li className='mx-4 p-2 contact-us-li'>
                            <img
                                src={contact}
                                alt='contact'
                                className='contact-us-icons'
                            />
                            <Link
                                to={`${routes.contact}/contact-form`}
                                className='aside__item-link-contact m-0'
                            >
                                {translate('contact_form')}
                            </Link>
                        </li>
                    </div>
                    <li className='mx-4 p-2 contact-us-li'>
                        <img
                            src={property}
                            alt='property'
                            className='contact-us-icons'
                        />
                        <Link
                            to={routes.serviceDesk}
                            className='aside__item-link-contact m-0'
                        >
                            {translate('property_service_desk')}
                        </Link>
                    </li>
                </ul>
                <div className='row justify-content-center'>
                    <div className='col-md-5 col-lg-5 mb-4'>
                        <div className='card shadow border-0 contact-card mb-3'>
                            <div className='card-title contact-card__title'>
                                {translate('partner_services')}
                            </div>
                            <div className='card-body py-0'>
                                <p className='card-text m-0'>
                                    +34 971 416 770 Ext.2
                                </p>
                                <p className='card-text m-0'>
                                    {' '}
                                    partners@fastpayhotels.com
                                </p>
                            </div>
                        </div>
                        <div className='card shadow border-0 contact-card mb-3'>
                            <div className='card-title contact-card__title'>
                                {translate('finance_invoicing')}
                            </div>
                            <div className='card-body py-0'>
                                <p className='m-0'>+34 971 416 770 Ext.1</p>
                                <p className='card-text m-0'>
                                    accounts@fastpayhotels.com
                                </p>
                            </div>
                        </div>
                        <div className='card shadow border-0 contact-card mb-3'>
                            <div className='card-title contact-card__title'>
                                {translate('connectivity')}
                            </div>
                            <div className='card-body py-0'>
                                <p className='card-text m-0'>
                                    +34 971 416 770 Ext.3
                                </p>
                                <p className='card-text m-0'>
                                    connectivity@fastpayhotels.com
                                </p>
                            </div>
                        </div>
                        <div className='card shadow border-0 contact-card mb-3'>
                            <div className='card-title contact-card__title'>
                                {translate('talent_culture')}
                            </div>
                            <div className='card-body py-0'>
                                <p className='card-text m-0'>
                                    hr@fastpayhotels.com
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5 col-lg-5 mb-4'>
                        <div className='card shadow border-0 contact-card mb-3'>
                            <div className='card-title contact-card__title'>
                                {translate('supply_partners_payments')}
                            </div>
                            <div className='card-body py-0'>
                                <p className='card-text m-0'>+34 518 888 959</p>
                                <p className='card-text m-0'>
                                    {' '}
                                    supplierpayments@fastpayhotels.com
                                </p>
                            </div>
                        </div>
                        <div className='card shadow border-0 contact-card mb-4'>
                            <div className='card-title contact-card__title-service '>
                                {translate('service_desk')}
                            </div>
                            <div className='card-body py-0'>
                                <p>
                                    <span className='font-weight-bold m-0'>
                                        Spain
                                    </span>{' '}
                                    +34 518 899 100
                                </p>
                                <p>
                                    <span className='font-weight-bold m-0'>
                                        Brazil
                                    </span>{' '}
                                    +55 513 376 9316
                                </p>
                                <p>
                                    <span className='font-weight-bold m-0'>
                                        United Kingdom
                                    </span>{' '}
                                    +44 143 190 5054
                                </p>
                                <p>
                                    <span className='font-weight-bold m-0'>
                                        United States
                                    </span>{' '}
                                    +1 518 897 3222
                                </p>
                                <p>
                                    <span className='font-weight-bold m-0'>
                                        Austria
                                    </span>{' '}
                                    +43 720 115 136
                                </p>
                                <p>
                                    <span className='font-weight-bold m-0'>
                                        Emergency 24/7
                                    </span>{' '}
                                    +34 616 250 672
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default LandingContact;
