import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

const CardList = ({ items }) => {
    return (
        <List>
            {items.map((item, index) => (
                <ListItemButton
                    component='li'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'auto',
                        alignItems: 'end',
                        padding: '4px 8px',
                        gap: 5,
                    }}
                    dense
                    key={index}
                >
                    {item}
                </ListItemButton>
            ))}
        </List>
    );
};

export default CardList;
