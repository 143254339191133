import React from 'react';
import './conditionsstatemens.scss';

export default function Conditions() {
    return (
        <div id='privacy-page'>
            <div className='container privacy-page'>
                <div id='title'>
                    <h1 className='text-center'>
                        Condiciones de uso y Declaración de Condiciones
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Condiciones de uso
                        </h4>
                        <p>
                            En cumplimiento de la Ley 34/2002, de 11 de junio,
                            de Servicios de la Sociedad de la Información y de
                            Comercio Electrónico (LSSI-CE), FASTPAYHOTELS SL
                            informa al usuario que es el titular del sitio web
                            www.fastpayhotels.com. En cumplimiento de lo
                            establecido en el artículo 10 de la citada Ley,
                            FASTPAYHOTELS SL informa a los usuarios de lo
                            siguiente:{' '}
                        </p>
                        <p>
                            El titular de esta página web es FAST AYHOTELS SL,
                            con CIF B57940066 y con domicilio social en PERE
                            ANTONI OLIVER 2B 07014, PALMA DE MALLORCA (ILLES
                            BALEARS) inscrita en el Registro Mercantil en el
                            Tomo 2535 hoja 19 folio PM77490 e inscripción 1, El
                            correo electrónico de contacto de la empresa es:
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                        </p>
                        <p>
                            El titular de esta página web es FAST AYHOTELS SL,
                            con CIF B57940066 y con domicilio social en PERE
                            ANTONI OLIVER 2B 07014, PALMA DE MALLORCA (ILLES
                            BALEARS) inscrita en el Registro Mercantil en el
                            Tomo 2535 hoja 19 folio PM77490 e inscripción 1, El
                            correo electrónico de contacto de la empresa es:
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Régimen de usuarios y responsabilidad
                        </p>
                        <p>
                            La navegación, el acceso y la utilización de la
                            página web de FASTPAYHOTELS SL, constituye la
                            persona como usuario. La navegación por el sitio web
                            de FASTPAYHOTELS SL supone la aceptación por parte
                            del usuario de todas las condiciones de uso
                            establecidas en el presente documento, sin perjuicio
                            de la aplicación de la normativa de obligado
                            cumplimiento que en cada caso resulte de aplicación.
                        </p>
                        <p>
                            El sitio web de FASTPAYHOTELS SL ofrece una amplia
                            gama de información, servicios y datos. El usuario
                            acepta su responsabilidad personal por el uso
                            correcto de esta página web. Esta responsabilidad
                            incluye:
                        </p>
                        <ul>
                            <li>
                                La veracidad y licitud de la información
                                facilitada por el usuario en las fórmulas
                                solicitadas por FASTPAYHOTELS SL para acceder a
                                determinados contenidos o servicios ofrecidos en
                                el sitio web.
                            </li>
                            <li>
                                El uso de la información, servicios y datos
                                ofrecidos por FASTPAYHOTELS SL de forma
                                contraria a lo establecido en las presentes
                                condiciones, a la ley, a la moral, a las buenas
                                costumbres y al orden público, o cualquier otro
                                uso que suponga una violación de los derechos de
                                terceros o que afecte al buen funcionamiento del
                                sitio web.
                            </li>
                        </ul>
                        <p className='font-weight-bold mt-4'>
                            Política relativa a los vínculos y la exención de
                            responsabilidad
                        </p>
                        <p>
                            FASTPAYHOTELS SL no se hace responsable del
                            contenido de ninguno de los sitios web a los que el
                            usuario pueda acceder a través de los enlaces
                            incluidos en el sitio web, y en ningún caso
                            examinará ni ejercerá ningún tipo de control sobre
                            el contenido de otros sitios web. Asimismo, no puede
                            garantizar la disponibilidad técnica, exactitud,
                            veracidad, validez o legalidad de los sitios web de
                            terceros a los que se pueda acceder a través de los
                            enlaces.
                        </p>
                        <p>
                            FASTPAYHOTELS SL ha adoptado todas las medidas
                            necesarias para evitar cualquier tipo de perjuicio a
                            los usuarios de su página web que pudiera derivarse
                            del uso de la misma. Por ello, FASTPAYHOTELS SL no
                            se hace responsable, en ningún caso, de los posibles
                            perjuicios que pueda sufrir el usuario como
                            consecuencia de su uso de Internet.
                        </p>
                        <p className='font-weight-bold mt-4'>Modificaciones</p>
                        <p>
                            FASTPAYHOTELS SL se reserva el derecho de realizar
                            las modificaciones que considere oportunas en el
                            contenido de su página web, sin previo aviso. Esto
                            incluye el contenido de la página web, sus Términos
                            y Condiciones de Uso, o las Condiciones Generales de
                            Contratación. Dichas modificaciones podrán
                            realizarse a través de su página web en cualquier
                            forma permitida por la ley, y serán vinculantes
                            durante el tiempo en que permanezcan publicadas en
                            la página web y hasta que sean modificadas por
                            posteriores modificaciones.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Protección de datos
                        </h4>
                        <p className='font-weight-bold mt-4'>
                            Titularidad del sitio web y aceptación de los
                            Términos de Uso
                        </p>
                        <p>
                            La lectura y aceptación de los presentes Términos y
                            Condiciones de Uso (en adelante, los “Términos de
                            Uso”) es condición necesaria para el uso del sitio
                            www.fastpayhotels.com (en adelante, el “Sitio Web”).
                        </p>
                        <p>
                            Los Términos de Uso constituyen Condiciones
                            Generales de Contratación, tienen naturaleza
                            contractual y obligacional y, sin perjuicio de las
                            condiciones particulares que las partes puedan
                            acordar en su caso, rigen la relación entre todo
                            usuario del Sitio Web (en lo sucesivo, el “Usuario”)
                            con Fastpayhotels, S.L. (en adelante,
                            “Fastpayhotels”), una sociedad de nacionalidad
                            española, con domicilio en la calle Pare Antoni
                            Oliver 2-8, CP 07014, inscrita en el Registro
                            Mercantil de Palma de Mallorca al Tomo 2635, Folio
                            19, Hoja PM-77490, con CIF B-57940066 y con e-mail:
                            info@fastpayhotels.com
                        </p>
                        <p>
                            La utilización de este Sitio Web y sus servicios
                            implica la plena aceptación de las disposiciones
                            incluidas en estos Términos de Uso conforme a la
                            versión publicada por Fastpayhotels en el momento en
                            que el Usuario acceda al Sitio Web.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Uso del sitio web
                        </p>
                        <p>
                            Los Usuarios renuncian a utilizar cualquiera de los
                            materiales e informaciones contenidos en este Sitio
                            Web con fines ilícitos o expresamente prohibidos en
                            los presentes Términos de Uso, así como a las
                            condiciones particulares que, en su caso, se
                            habiliten, o en contra de los derechos e intereses
                            de Fastpayhotels, sus miembros o terceros y deberán
                            responder frente a éstos en caso de contravenir o
                            incumplir dichas obligaciones o, de cualquier modo
                            (incluida la introducción o difusión de “virus
                            informáticos”) dañar, inutilizar, sobrecargar,
                            deteriorar o impedir la normal utilización de los
                            materiales e información contenidos en el Sitio Web,
                            los sistemas de información o los documentos,
                            archivos y toda clase de contenidos almacenados en
                            cualquier equipo informático (hacking) de
                            Fastpayhotels, de sus miembros o de cualquier
                            Usuario del Sitio Web.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Propiedad intelectual e industrial
                        </p>
                        <p>
                            El Sitio Web en su totalidad, incluyendo sin
                            exclusión alguna su diseño, estructura y
                            distribución, textos y contenidos, logotipos,
                            botones, imágenes, dibujos, marcas, nombres
                            comerciales, código fuente, creatividades, así como
                            todos los derechos de propiedad intelectual e
                            industrial y cualquier otro signo distintivo,
                            pertenecen o tienen como cesionaria a Fastpayhotels,
                            o, en su caso, a las personas físicas o jurídicas
                            que figuran como autores o titulares de los derechos
                            o de sus licenciantes, sin que pueda entenderse que
                            el uso y acceso al Sitio Web y la utilización de sus
                            servicios atribuya al Usuario derecho alguno sobre
                            los citados elementos.
                        </p>
                        <p>
                            Está prohibida la reproducción, explotación,
                            alteración, distribución o comunicación pública del
                            Sitio Web para usos diferentes de la legítima
                            información de los Usuarios.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Política de cookies.
                        </p>
                        <p>
                            El Sitio Web puede utilizar cookies cuando un
                            Usuario accede al mismo.
                        </p>
                        <p>
                            Las cookies es un archivo de datos que se descarga
                            en su ordenador al acceder a determinadas páginas
                            web y que permiten a la misma, almacenar y recuperar
                            datos y otras funcionalidades técnicas, como guardar
                            preferencias de navegación, recopilar información
                            estadística, información sobre el equipo, pueden
                            utilizarse para reconocer usuarios ofreciéndoles el
                            contenido más apropiado según sus hábitos.
                        </p>
                        <p>
                            Sin embargo, una cookie no puede borrar ni leer
                            información del ordenador, ni tampoco identifica a
                            una persona, sino que reconoce una combinación de
                            computación-navegador-Usuario.
                        </p>
                        <p>
                            Este Sitio Web utiliza exclusivamente cookies
                            propias de carácter técnico, cuya finalidad es
                            permitir la comunicación entre esta Web y el
                            dispositivo del Usuario.
                        </p>
                        <p>
                            Mediante la configuración por defecto de este Sitio
                            Web, el Usuario acepta que se puedan instalar
                            cookies en su terminal y que nos permiten conocer la
                            siguiente información:
                        </p>
                        <ul>
                            <li>
                                El login del Usuario para mantener la sesión
                                activa en el Sitio Web.
                            </li>
                            <li>
                                El formato del Sitio Web preferente en el acceso
                                desde dispositivos móviles.
                            </li>
                            <li>
                                Las últimas búsquedas realizadas en los
                                servicios del Sitio Web y datos de
                                personalización de estos servicios.
                            </li>
                        </ul>
                        <p>
                            El Usuario puede aceptar, revocar el consentimiento
                            o eliminar las cookies, así como borrar sus datos de
                            navegación (incluidas las cookies) instaladas en su
                            equipo mediante la configuración de las opciones del
                            navegador que el Usuario utiliza.
                        </p>
                        <p>
                            A continuación, se detallan los pasos necesarios
                            para configurar el uso de las cookies en función del
                            navegador que se utilice:
                        </p>
                        <p>
                            Puede usted aceptar, revocar el consentimiento o
                            eliminar las cookies, así como borrar sus datos de
                            navegación (incluidas las cookies) instaladas en su
                            equipo mediante la configuración de las opciones del
                            navegador que usted utiliza. A continuación se
                            detallan los pasos necesarios para configurar el uso
                            de las cookies en función del navegador que se
                            utilice:
                        </p>
                        <ul>
                            <li>
                                Internet Explorer: Herramientas -> Opciones de
                                Internet -> Privacidad -> Configuración. Para
                                más información, puede consultar el soporte de
                                Microsoft o la Ayuda del navegador.
                            </li>
                            <li>
                                Firefox: Herramientas -> Opciones -> Privacidad
                                -> Historial -> Configuración Personalizada.
                                Para más información, puede consultar el soporte
                                de Mozilla o la Ayuda del navegador.
                            </li>
                            <li>
                                Chrome: Configuración -> Mostrar opciones
                                avanzadas -> Privacidad -> Configuración de
                                contenido. Para más información, puede consultar
                                el soporte de Google o la Ayuda del navegador.
                            </li>
                            <li>
                                Safari: Preferencias -> Seguridad. Para más
                                información, puede consultar el soporte de Apple
                                o la Ayuda del navegador.
                            </li>
                        </ul>
                        <p>
                            En caso de bloquear el uso de ‘cookies’ en su
                            navegador es posible que algunos servicios o
                            funcionalidades de la página web no estén
                            disponibles.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Política de privacidad.
                        </p>
                        <p>
                            La lectura y aceptación de la política de privacidad
                            de Fastpayhotels (en adelante, la “Política de
                            Privacidad”) es condición necesaria para la
                            utilización del Sitio.
                        </p>
                        <p>
                            Esta Política de Privacidad regula la recopilación y
                            el uso de la información de los Usuarios del Sitio
                            Web a partir de la fecha de entrada en vigor que
                            aparece al final de los presentes Términos de Uso.
                            No se aplica a cualquier otra URL a la que se acceda
                            desde el Sitio Web, que tendrá su propia política en
                            relación con la recopilación de datos y su uso.
                        </p>
                        <p>
                            Por favor, lea atentamente esta Política de
                            Privacidad antes de empezar a utilizar el Sitio Web
                            y facilitar cualquier tipo de información personal.
                            Le ayudará a tomar decisiones informadas.
                        </p>
                        <p>
                            En el tratamiento de los datos de carácter personal
                            de sus Usuarios, Fastpayhotels cumple en todo
                            momento con la legislación vigente en España y en la
                            Unión Europea y en concreto, con la Ley Orgánica
                            3/2018, de 5 de diciembre, de Protección de Datos
                            Personales y garantía de los derechos digitales, y
                            el Reglamento Europeo 2016/679 de 27 de abril,
                            relativo a la protección de las personas físicas en
                            lo que respecta al tratamiento de datos personales y
                            a la libre circulación de estos datos y por el que
                            se deroga la Directiva 95/46/CE de Protección de
                            Datos de Carácter Personal (en adelante el “RGPD”).
                            Fastpayhotels, en su condición de Responsable del
                            tratamiento de datos personales (en adelante, el
                            “Responsable”) sólo trata los datos personales
                            obtenidos de los formularios voluntariamente
                            rellenados por los Usuarios bajos los principios de
                            licitud, lealtad y transparencia, limitación de la
                            finalidad, minimización de datos, exactitud,
                            limitación del plazo de conservación, integridad y
                            confidencialidad y responsabilidad proactiva.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            IDENTIDAD Y DATOS DE CONTACTO DEL RESPONSABLE
                        </p>
                        <p>
                            Fastpayhotels, S.L. con domicilio en la calle Pare
                            Antoni Oliver 2-8, CP 07014, CIF B-57940066, con
                            e-mail info@fastpayhotels.com y teléfono +34 971 416
                            770.
                        </p>
                        <p>
                            El Usuario puede contactar con el Delegado de
                            Protección de Datos del Responsable en la siguiente
                            dirección de correo electrónico
                            info@fastpayhotels.com y mediante correo postal a la
                            dirección del Responsable.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            CATEGORIAS DE DATOS, FINALIDAD DEL TRATAMIENTO Y
                            BASE JURÍDICA DEL MISMO
                        </p>
                        <p>
                            El Responsable trata las siguientes categorías de
                            datos de los Usuarios:
                        </p>
                        <p>
                            Datos de contacto: nombre, apellidos, documento de
                            identidad, edad, NIF, dirección postal y de correo
                            electrónico, teléfono, IP, país, dispositivo e
                            idioma.
                        </p>
                        <p>
                            Preferencias: información que el Usuario facilite
                            acerca de sus preferencias, por ejemplo, el tipo de
                            servicios adquiridos por el Usuario.
                        </p>
                        <p>
                            Uso de la web y de las comunicaciones: cómo utiliza
                            nuestra web así como la información recabada por
                            medio de cookies que el usuario puede consultar en
                            nuestra Política sobre Cookies. Determinadas cookies
                            pueden afectar a la privacidad del Usuario al
                            permitir relacionar su contenido con determinados
                            datos de carácter personal, incluidos sus gustos y
                            preferencias.
                        </p>
                        <p>
                            El Responsable trata exclusivamente los datos
                            personales facilitados por el Usuario y no trata
                            categorías especiales de datos personales. La
                            finalidad principal para la que tratamos la
                            información del Usuario es proporcionar los
                            servicios expresamente solicitados por el Usuario.
                        </p>
                        <p>
                            El Responsable trata los datos de los usuarios con
                            los siguientes fines:
                        </p>
                        <ol type='a'>
                            <li>
                                La prestación de los servicios expresamente
                                solicitados por el Usuario, así como para
                                gestionar sus pagos y pedidos.
                            </li>
                            <li>
                                Prestar servicios de soporte y apoyo a los
                                Usuarios, notificar cuestiones de servicio o
                                cuenta (por ejemplo, avisar de conducta
                                impropia).
                            </li>
                            <li>
                                Realización de actividades comerciales,
                                operativas y estadísticas. No se tomarán
                                decisiones automatizadas en base a dicho perfil.
                            </li>
                            <li>
                                Asesoramiento y remisión de boletines
                                informativos y otras comunicaciones comerciales
                                por correo electrónico sobre el Responsable y la
                                prestación de servicios propios de su sector. El
                                Usuario podrá indicar su oposición a la
                                recepción de tal boletín en cualquier momento.
                                El Usuario consiente expresamente recibir estos
                                mensajes de correo electrónico, aunque podrán
                                darse de baja (i) en el enlace especificado en
                                cualquier comunicación que se envíe por correo
                                electrónico, (ii) enviando un correo electrónico
                                a [info@fastpayhotels.com. No obstante, ello no
                                supondrá la baja de determinadas comunicaciones
                                de carácter no comercial, como los mensajes
                                relativos al acceso del Usuario al Sitio Web.
                            </li>
                        </ol>
                        <p>
                            La base jurídica para el tratamiento de los datos de
                            los Usuarios por parte del Responsable es el
                            consentimiento del Usuario (art 6.1 a) RGPD).
                            Subsidiariamente, el Responsable podrá tratar los
                            datos del Usuario bajo la base jurídica del interés
                            legítimo. Dicho interés legítimo consiste en el
                            mantenimiento y promoción de relaciones comerciales
                            y profesionales entre el Responsable y el Usuario y
                            la prevención del fraude.
                        </p>
                        <p>
                            La comunicación de datos personales por el Usuario
                            es voluntaria, si bien constituye condición
                            contractual para que pueda adquirir los servicios y
                            productos comercializados por el Responsable. Así,
                            la negativa del Usuario supondrá la imposibilidad de
                            prestar determinados servicios al Usuario por parte
                            del Responsable.
                        </p>
                        <p>
                            El Usuario podrá retirar el consentimiento en
                            cualquier momento, sin que ello afecte a la licitud
                            del tratamiento basado en el consentimiento previo a
                            su retirada.
                        </p>
                        <p>
                            El Responsable siempre pedirá consentimiento e
                            informará al Usuario antes de utilizar sus datos
                            para cualquier fin distinto de los que se describen
                            en esta Política de Privacidad.
                        </p>
                        <p className='font-weight-bold mt-4'>CESIÓN DE DATOS</p>
                        <p>
                            El Responsable informa y garantiza expresamente al
                            Usuario que sus datos personales no serán cedidos en
                            ningún caso a terceras personas, salvo obligación
                            legal.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            PLAZO DE CONSERVACIÓN DE LOS DATOS
                        </p>
                        <p>
                            Los datos personales proporcionados se conservarán
                            por tiempo indefinido, en la medida en que el
                            Responsable los necesite con el fin de utilizarlos
                            para la finalidad para la que fueron obtenidos:
                        </p>
                        <ol type='a'>
                            <li>
                                Mientras exista una relación contractual y/o
                                comercial.
                            </li>
                            <li>
                                Mientras no se solicite la supresión, oposición
                                y/o limitación del tratamiento de los datos por
                                el Usuario.
                            </li>
                            <li>Por exigencia legal.</li>
                        </ol>
                        <p className='font-weight-bold mt-4'>
                            OTROS DERECHOS DEL USUARIO
                        </p>
                        <p>
                            El Usuario puede obtener confirmación sobre si el
                            Responsable está tratando datos personales que le
                            conciernan y acceder a los mismos.
                        </p>
                        <p>
                            El Usuario podrá solicitar del Responsable el
                            ejercicio de los derechos de acceso a los datos
                            personales, su rectificación, supresión, la
                            limitación de su tratamiento, oposición y
                            portabilidad, mediante la remisión de un correo
                            electrónico a la dirección […], adjuntando copia del
                            documento oficial identificativo del Usuario y
                            expresando claramente el derecho que desea
                            ejercitar. Puede solicitar los formularios para el
                            ejercicio de los referidos derechos a dicha
                            dirección de correo.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            PRIVACIDAD DE MENORES DE EDAD
                        </p>
                        <p>
                            El Responsable se dirige a mayores de edad y no
                            recopila ni mantiene voluntariamente ningún tipo de
                            datos personales de menores de edad. Si el
                            Responsable tuviera conocimiento de que el Usuario
                            es menor de edad, procederá a la cancelación
                            inmediata de sus datos de carácter personal y de las
                            solicitudes o pedidos realizados por el mismo, en su
                            caso.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            MEDIDAS DE SEGURIDAD QUE ADOPTA EL RESPONSABLE PARA
                            GARANTIZAR LA CONFIDENCIALIDAD DE LOS DATOS
                        </p>
                        <p>
                            El Responsable adopta las medidas técnicas y
                            organizativas necesarias para evitar la pérdida, mal
                            uso, alteración, acceso no autorizado, robo de los
                            datos personales facilitados, así como para
                            garantizar un nivel de seguridad adecuado al riesgo,
                            habida cuenta del estado de la tecnología, los
                            costes de aplicación, la naturaleza, el alcance, el
                            contexto y los fines del tratamiento, así como los
                            riesgos de probabilidad y gravedad variables para
                            los derechos y libertades de las personas físicas.
                        </p>
                        <p>
                            En la utilización de los datos personales del
                            Usuario, el Responsable se compromete a respetar la
                            confidencialidad y a utilizarlos de acuerdo con la
                            finalidad de su tratamiento, así como a dar
                            cumplimiento a su obligación de guardarlos y adoptar
                            todas las medidas técnicas y organizativas de
                            seguridad para garantizar un nivel de seguridad
                            adecuado al riesgo.
                        </p>
                        <p>
                            El Responsable tiene particularmente en cuenta los
                            riesgos que presente el tratamiento de datos, en
                            particular como consecuencia de la destrucción,
                            pérdida o alteración accidental o ilícita de datos
                            personales transmitidos, conservados o tratados de
                            otra forma, o la comunicación o acceso no
                            autorizados a dichos datos. El Responsable toma
                            medidas para garantizar que cualquier persona que
                            actúe bajo su autoridad y tenga acceso a los datos
                            personales del Usuario sólo pueda tratar dichos
                            datos siguiendo las instrucciones del Responsable,
                            salvo que esté obligada a ello en virtud del Derecho
                            aplicable.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Legislación aplicable y jurisdicción competente.
                        </p>
                        <p>
                            Los presentes Términos de Uso se regirán por la
                            legislación española, salvo que la legislación de
                            aplicación imperativa al Usuario determinara otro.
                        </p>
                        <p>
                            Las partes se someten, a su elección, para la
                            resolución de los conflictos y con renuncia a
                            cualquier otro fuero, a los juzgados y tribunales de
                            Palma de Mallorca, salvo que la legislación de
                            aplicación imperativa al Usuario determinara otro.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Fecha efectiva y modificaciones de los Términos de
                            Uso
                        </p>
                        <p>
                            Estos Términos de Uso fueron actualizados por última
                            vez el 11 /12/2020.
                        </p>
                        <p>
                            Fastpayhotels se reserva el derecho a modificar,
                            corregir o cambiar de forma unilateral estos
                            Términos de Uso en cualquier momento. Cuando esto
                            ocurra, se publicarán los nuevos Términos de Uso en
                            el Sitio Web así como la fecha en la que entrarán en
                            vigor en la parte final de los Términos de Uso.
                        </p>
                        <p>
                            Recomendamos que se visite esta página con
                            regularidad para estar al día de las modificaciones
                            que se puedan producir.
                        </p>
                        <p>
                            Si no acepta las modificaciones de estos Términos de
                            Uso, no utilice el Sitio Web. El uso del Sitio Web
                            continuadamente implica la aceptación de estos
                            Términos de Uso y sus posibles modificaciones.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Política de cookies{' '}
                        </h4>
                        <p>
                            Si usted navega por nuestra web está aceptando de
                            forma tácita las condiciones de uso de nuestra
                            Política de Cookies. En esta política se detallan
                            las condiciones y uso de las cookies y puede ser
                            actualizada regularmente. Advertimos que en el caso
                            de bloquear el uso de ciertas cookies para la
                            navegación de esta web puede provocar que algunos de
                            nuestros servicios no funciones correctamente o no
                            se encuentren disponibles.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            ¿Qué son las cookies?
                        </p>
                        <p>
                            Las cookies es un archivo de datos que se descarga
                            en su ordenador al acceder a determinadas páginas
                            web y que permiten a la misma, almacenar y recuperar
                            datos y otras funcionalidades técnicas, como guardar
                            preferencias de navegación, recopilar información
                            estadística, información sobre el equipo, pueden
                            utilizarse para reconocer usuarios ofreciéndoles el
                            contenido más apropiado según sus hábitos. Sin
                            embargo, una cookie no puede borrar ni leer
                            información del ordenador, ni tampoco identifica a
                            una persona, sino que reconoce una combinación de
                            computación-navegador-usuario.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            ¿Qué tipos de cookies utiliza nuestra web?
                        </p>
                        <p>
                            La web https://www.fastpayhotels.com utiliza
                            exclusivamente cookies propias de carácter técnico,
                            cuya finalidad es permitir la comunicación entre
                            esta Web y el dispositivo del usuario.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            ¿Cómo se utilizan las ‘cookies’?
                        </p>
                        <p>
                            Mediante la configuración por defecto de esta página
                            web, el usuario acepta que se puedan instalar
                            ‘cookies’ en su terminal y que nos permiten conocer
                            la siguiente información:
                        </p>
                        <ul>
                            <li className='section__paragraph--policies'>
                                El ‘login’ del usuario para mantener la sesión
                                activa en la web.
                            </li>
                            <li>
                                El formato de la web preferente en el acceso
                                desde dispositivos móviles.
                            </li>
                            <li>
                                Las últimas búsquedas realizadas en los
                                servicios de la web y datos de personalización
                                de estos servicios.
                            </li>
                        </ul>
                        <p className='font-weight-bold mt-4'>
                            ¿Cómo administrar las cookies en el navegador?
                        </p>
                        <p>
                            Puede usted aceptar, revocar el consentimiento o
                            eliminar las cookies, así como borrar sus datos de
                            navegación (incluidas las cookies) instaladas en su
                            equipo mediante la configuración de las opciones del
                            navegador que usted utiliza. A continuación se
                            detallan los pasos necesarios para configurar el uso
                            de las cookies en función del navegador que se
                            utilice:
                        </p>
                        <ul>
                            <li className='section__paragraph--policies'>
                                Internet Explorer: Herramientas -> Opciones de
                                Internet -> Privacidad -> Configuración. Para
                                más información, puede consultar el soporte de
                                Microsoft o la Ayuda del navegador.
                            </li>
                            <li>
                                Firefox: Herramientas -> Opciones -> Privacidad
                                -> Historial -> Configuración Personalizada.
                                Para más información, puede consultar el soporte
                                de Mozilla o la Ayuda del navegador.
                            </li>
                            <li>
                                Chrome: Configuración -> Mostrar opciones
                                avanzadas -> Privacidad -> Configuración de
                                contenido. Para más información, puede consultar
                                el soporte de Google o la Ayuda del navegador.
                            </li>
                            <li>
                                Safari: Preferencias -> Seguridad. Para más
                                información, puede consultar el soporte de Apple
                                o la Ayuda del navegador.
                            </li>
                        </ul>
                        <p>
                            En caso de bloquear el uso de ‘cookies’ en su
                            navegador es posible que algunos servicios o
                            funcionalidades de la página web no estén
                            disponibles.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Transferencias de datos a terceros países.
                        </p>
                        <p>
                            Puedes informarte de las transferencias a terceros
                            países que, en su caso, realizan los terceros
                            identificados en esta política de cookies en sus
                            correspondientes políticas (ver los enlaces
                            facilitados en el apartado “Cookies de terceros”).
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Período de conservación de los datos.
                        </p>
                        <p>
                            Mantendremos sus datos personales en la medida en
                            que los necesitemos con el fin de utilizarlos para
                            la finalidad para la que fueron obtenidos:
                        </p>
                        <ul>
                            <li className='section__paragraph--policies'>
                                Mientras exista una relación contractual y/o
                                comercial.
                            </li>
                            <li>
                                Mientras usted no ejerza su derecho de
                                supresión, oposición y/o limitación del
                                tratamiento de sus datos.
                            </li>
                            <li>Por exigencia legal.</li>
                        </ul>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            SSL (Secure Sockets Layer) Certificate{' '}
                        </h4>
                        <p>
                            The SSL CERTIFICATE provides authentication, privacy
                            and security of information between FASTPAYHOTELS SL
                            and the user.
                        </p>
                        <p>
                            FASTPAYHOTELS SL has an SSL security certificate in
                            order to perform safe connections.
                        </p>
                        <p>
                            In this process, several parameters are established
                            to ensure safe connections. These are made using
                            pre-established keys, encoding and decoding all
                            information sent until the connection is closed.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Propiedad intelectual e industrial{' '}
                        </h4>
                        <p>
                            FASTPAYHOTELS SL por derecho propio o como
                            franquiciado es titular de todos los derechos de
                            propiedad intelectual e industrial de su página web.
                            Además, todos los elementos contenidos en la misma
                            (a título enunciativo, imágenes, sonido, audio,
                            vídeo, software o textos; marcas o logotipos,
                            combinaciones de colores, disposición y diseño,
                            selección de materiales utilizados, programas
                            informáticos necesarios para su correcto
                            funcionamiento, acceso y utilización, etc.) son
                            titularidad de FASTPAYHOTELS SL. En consecuencia,
                            estas obras están protegidas como propiedad
                            intelectual por la legislación española, siendo de
                            aplicación en este caso tanto la legislación
                            española y europea en la materia como los acuerdos
                            internacionales en la materia a los que España se
                            adhiere.
                        </p>
                        <p>
                            Todos los derechos reservados. En virtud de lo
                            establecido en la Ley de Propiedad Intelectual,
                            queda expresamente prohibida la reproducción,
                            distribución o comunicación pública de la totalidad
                            o parte de los contenidos de esta página web
                            (incluidos los medios por los que se ponen a
                            disposición) con fines comerciales, en cualquier
                            forma o por cualquier medio técnico, sin la
                            autorización de FASTPAYHOTELS SL.
                        </p>
                        <p>
                            El usuario se compromete a respetar los Derechos de
                            Propiedad Intelectual e Industrial titularidad de
                            FASTPAYHOTELS SL. El usuario podrá visualizar los
                            elementos de la web e incluso imprimirlos, copiarlos
                            o almacenarlos en el disco duro de su ordenador o en
                            cualquier otro soporte físico siempre y cuando sea,
                            única y exclusivamente, para su uso personal y
                            privado. El usuario deberá abstenerse de suprimir,
                            alterar, eludir o manipular cualquier dispositivo de
                            protección o sistema de seguridad que estuviera
                            instalado en las páginas web de FASTPAYHOTELS SL.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Acciones legales, legislación aplicable y
                            jurisdicción
                        </p>
                        <p>
                            FASTPAYHOTELS SL se reserva el derecho a iniciar las
                            acciones civiles o penales que estime oportunas por
                            el uso indebido de su página web y de los contenidos
                            de la misma, o por el incumplimiento de las
                            presentes condiciones.
                        </p>
                        <p>
                            La relación entre el usuario y el prestador de
                            servicios se regirá por la legislación vigente que
                            se aplica en el territorio español. En caso de que
                            surja alguna controversia, las partes podrán someter
                            sus litigios a arbitraje o proceder por los cauces
                            legales ordinarios, de conformidad con las normas de
                            jurisdicción y competencia aplicables. El domicilio
                            social de FASTPAYHOTELS SL se encuentra en España.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Política de privacidad para las redes sociales
                        </h4>
                        <p>
                            En cumplimiento de lo establecido en la normativa
                            vigente sobre Protección de Datos de Carácter
                            Personal y en la Ley 34/2002, de 11 de julio, de
                            Servicios de la Sociedad de la Información y de
                            Comercio Electrónico (LSSI-CE), FASTPAYHOTELS SL
                            informa a los usuarios que ha creado un perfil en
                            la(s) red(es) social(es) Facebook, Twitter,
                            Instagram y LinkedIn con el objetivo principal de
                            publicitar sus productos y servicios.
                        </p>
                        <p>Información para FASTPAYHOTELS SL:</p>
                        <ul>
                            <li>
                                Número de identificación fiscal (CIF): B57940066
                            </li>
                            <li>
                                {' '}
                                DIRECCIÓN: PERE ANTONI OLIVER 2B 07014, PALMA DE
                                MALLORCA (ILLES BALEARS)
                            </li>
                            <li>
                                {' '}
                                EMAIL:{' '}
                                <a
                                    className='linkStyle'
                                    href='mailto:info@fastpayhotels.com'
                                >
                                    info@fastpayhotels.com
                                </a>
                            </li>
                            <li>
                                DIRECCIÓN WEB:{' '}
                                <a
                                    className='linkStyle'
                                    href='https://www.fastpayhotels.com'
                                >
                                    www.fastpayhotels.com
                                </a>
                            </li>
                        </ul>
                        <p>
                            El usuario tiene un perfil en la misma Red Social y
                            ha decidido unirse a la página creada por
                            FASTPAYHOTELS SL, mostrando así su interés en la
                            información que se hace pública a través de la Red.
                            Al unirse a nuestra página, está autorizando el uso
                            de cualquier información personal publicada en su
                            perfil.
                        </p>
                        <p>
                            El usuario puede acceder a las políticas de
                            privacidad de la Red Social en cualquier momento, o
                            puede configurar su perfil para garantizar su
                            privacidad.
                        </p>
                        <p>
                            FASTPAYHOTELS SL tiene acceso y maneja la
                            información pública de los usuarios, especialmente
                            su nombre de contacto. Esta información sólo se
                            utiliza dentro de la Red Social. No se añade a
                            ningún tipo de archivo.
                        </p>
                        <p>
                            En relación con los derechos de acceso,
                            rectificación, cancelación u oposición que posee y
                            que puede ejercer ante FASTPAYHOTELS SL en
                            cumplimiento del RGPD, debe tener en cuenta lo
                            siguiente:
                        </p>
                        <ul>
                            <li>
                                Acceso: Esto se definirá por la funcionalidad de
                                la Red Social y la capacidad de acceso a la
                                información de los perfiles de usuario.
                            </li>
                            <li>
                                Rectificación: Sólo se puede satisfacer en
                                relación con la información bajo el control de
                                FASTPAYHOTELS SL. Por ejemplo, eliminando los
                                comentarios publicados en la página de la
                                empresa. Normalmente, este derecho debe ser
                                ejercido ante la Red Social.
                            </li>
                            <li>
                                Cancelación y/u oposición: Al igual que en el
                                caso anterior, esto sólo se puede satisfacer en
                                relación con la información que está bajo el
                                control de FASTPAYHOTELS SL. Por ejemplo, dejar
                                de estar conectado al perfil.
                            </li>
                        </ul>
                        <p>
                            FASTPAYHOTELS SL realizará las siguientes acciones:
                        </p>
                        <ul>
                            <li>Acceder a la información de perfil público.</li>
                            <li>
                                Publicar cualquier información ya disponible en
                                la página web de FASTPAYHOTELS SL en su perfil
                                de usuario.
                            </li>
                            <li>
                                Envíar mensajes personales e individuales a
                                través de los canales de la Red Social.
                            </li>
                            <li>
                                Actualizar el estado de su página, que se
                                publicará en su perfil de usuario.
                            </li>
                        </ul>
                        <p>
                            El usuario siempre puede controlar sus conexiones,
                            eliminar el contenido que deja de interesarle y
                            restringir con quién comparte sus conexiones. Para
                            ello, debe acceder a sus configuraciones de
                            privacidad.
                        </p>
                        <p className='font-weight-bold mt-4'>Publicaciones</p>
                        <p>
                            Una vez que se haya unido a la página de
                            FASTPAYHOTELS SL, el usuario podrá publicar en la
                            página comentarios, enlaces, imágenes, fotografías o
                            cualquier otro tipo de contenido multimedia
                            soportado por la Red Social. En todo caso, el
                            usuario deberá ser titular de dichos contenidos,
                            tener los derechos de autor y propiedad intelectual
                            de los mismos, o contar con el consentimiento de los
                            terceros afectados. Queda expresamente prohibido
                            cualquier contenido publicado en la página, ya sea
                            texto, gráficos, vídeos, etc. que atente contra la
                            moral, la ética, el buen gusto o el decoro, así como
                            cualquier contenido que vulnere los derechos de
                            propiedad intelectual o industrial, los derechos de
                            imagen o la Ley. En tales circunstancias,
                            FASTPAYHOTELS SL se reserva el derecho de retirar
                            inmediatamente el contenido, pudiendo solicitar el
                            bloqueo permanente del usuario.
                        </p>
                        <p>
                            FASTPAYHOTELS SL no se hace responsable de ningún
                            contenido publicado libremente por un usuario.
                        </p>
                        <p>
                            El usuario debe tener en cuenta que sus
                            publicaciones serán vistas por otros usuarios. Como
                            resultado, ellos son los principales responsables de
                            proteger su propia privacidad.
                        </p>
                        <p>
                            Las imágenes publicadas en la página no serán
                            almacenadas en ningún tipo de archivo por
                            FASTPAYHOTELS SL, pero permanecerán en la Red
                            Social.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Concursos y promociones
                        </p>
                        <p>
                            FASTPAYHOTELS SL se reserva el derecho de organizar
                            concursos y promociones en los que los usuarios
                            conectados a su página pueden participar. Cuando se
                            utilice la Red Social para estos concursos y
                            promociones, los términos y condiciones serán
                            publicados en la misma. Esto será siempre en
                            cumplimiento de la LSSI-CE y de cualquier otra
                            normativa aplicable.
                        </p>
                        <p>
                            La Red Social no patrocina, apoya o administra
                            ninguna de nuestras promociones de ninguna manera,
                            ni están asociadas a ninguna de ellas.
                        </p>
                        <p className='font-weight-bold mt-4'>Publicity</p>
                        <p>
                            FASTPAYHOTELS SL utilizará la Red Social para
                            anunciar sus productos y servicios. En cualquier
                            caso, si deciden manejar su información de contacto
                            para acciones de investigación comercial directa, lo
                            harán siempre de acuerdo con la RGPD y la LSSI-CE.
                        </p>
                        <p>
                            Recomendar la página de FASTPAYHOTELS SL a otros
                            usuarios para que ellos también puedan disfrutar de
                            las promociones ofrecidas en ella o mantenerse
                            informados de su actividad no será considerado
                            publicidad.
                        </p>
                        <p>
                            A continuación se muestra un enlace a la política de
                            privacidad de la Red Social:
                        </p>
                        <ul>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='https://twitter.com/privacy'
                                >
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='https://www.facebook.com/privacy/explanation'
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='http://instagram.com/about/legal/privacy/'
                                >
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='http://www.google.com/intl/es/policies/privacy/'
                                >
                                    Google +
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv'
                                >
                                    LinkedIn
                                </a>
                            </li>
                        </ul>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Declaración informativa sobre el PIBR y la LSSI-CE
                        </h4>
                        <p>
                            De conformidad con lo dispuesto en la legislación
                            vigente en materia de Protección de Datos de
                            Carácter Personal, le informamos que sus datos serán
                            incorporados al sistema de tratamiento propiedad de
                            FASTPAYHOTELS SL, con CIF B57940066 y domicilio
                            social en PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) con la finalidad de
                            facilitar, atender y cumplir los compromisos
                            establecidos entre ambas partes. De acuerdo con la
                            normativa aplicable, FASTPAYHOTELS SL informa a los
                            usuarios que sus datos serán conservados durante el
                            periodo necesario para el cumplimiento de las
                            citadas condiciones.
                        </p>
                        <p>
                            FASTPAYHOTELS SL también informa a sus usuarios que
                            procesará los datos de forma legal, honesta,
                            transparente, adecuada, relevante, limitada, precisa
                            y actualizada. Por ello, FASTPAYHOTELS SL se
                            compromete a adoptar todas las medidas razonables
                            para garantizar que sean borrados o rectificados sin
                            demora en caso de que resulten inexactos.
                        </p>
                        <p>
                            De conformidad con los derechos que le confiere la
                            legislación vigente en materia de Protección de
                            Datos de Carácter Personal, podrá ejercer los
                            derechos de acceso, rectificación, limitación del
                            tratamiento, cancelación, cesión y oposición
                            enviando su solicitud a la dirección postal arriba
                            indicada o escribiendo un correo electrónico a
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Asimismo, y de acuerdo con lo establecido en el
                            artículo 21 de la Ley 34/2002, de 11 de junio, de
                            Servicios de la Sociedad de la Información y de
                            Comercio Electrónico (LSSI-CE), solicitamos su
                            consentimiento expreso para enviarle publicidad
                            sobre nuestros productos u ofertas que consideremos
                            puedan ser de su interés, ya sea a través del correo
                            electrónico o de cualquier otro medio de
                            comunicación equivalente. □ He leído las presentes
                            condiciones y estoy dispuesto a recibir información
                            publicitaria o promocional de FASTPAYHOTELS SL. □ He
                            leído las presentes condiciones y no deseo recibir
                            información publicitaria o promocional de
                            FASTPAYHOTELS SL.
                        </p>
                        <p>
                            Le informamos que puede revocar su consentimiento
                            para recibir correo comercial enviando un correo
                            electrónico a la siguiente dirección:
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <ul>
                            <li>Nombre y apellido o nombre de la empresa:</li>
                            <li>
                                Número de identificación personal o número de
                                identificación fiscal:
                            </li>
                            <li>Firma del interesado.</li>
                        </ul>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Cláusula para entrar en el formulario web con la
                            publicidad
                        </h4>
                        <p>
                            De conformidad con lo dispuesto en la normativa
                            vigente en materia de Protección de Datos de
                            Carácter Personal, le informamos que sus datos serán
                            incorporados al sistema de tramitación de
                            FASTPAYHOTELS SL, con CIF B57940066 y domicilio
                            social en PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) con la finalidad de atender
                            sus consultas y enviarle comunicaciones comerciales
                            que puedan ser de su interés. De acuerdo con la
                            normativa vigente, FASTPAYHOTELS SL informa a los
                            usuarios que sus datos serán conservados durante el
                            periodo necesario para cumplir con las condiciones
                            mencionadas.
                        </p>
                        <p>
                            FASTPAYHOTELS SL también informa a sus usuarios que
                            procesará los datos de forma legal, honesta,
                            transparente, adecuada, relevante, limitada, precisa
                            y actualizada. Por ello, FASTPAYHOTELS SL se
                            compromete a adoptar todas las medidas razonables
                            para garantizar que sean borrados o rectificados sin
                            demora en caso de que resulten inexactos.
                        </p>
                        <p>
                            De conformidad con los derechos que le confiere la
                            legislación vigente en materia de Protección de
                            Datos de Carácter Personal, podrá ejercer los
                            derechos de acceso, rectificación, limitación del
                            tratamiento, cancelación, cesión y oposición
                            enviando su solicitud a la dirección postal arriba
                            indicada o escribiendo un correo electrónico a
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Asimismo, y de acuerdo con lo establecido en el
                            artículo 21 de la Ley 34/2002, de 11 de junio, de
                            Servicios de la Sociedad de la Información y de
                            Comercio Electrónico (LSSI-CE), solicitamos su
                            consentimiento expreso para enviarle publicidad
                            sobre nuestros productos u ofertas que consideremos
                            puedan ser de su interés, ya sea a través del correo
                            electrónico o de cualquier otro medio de
                            comunicación equivalente. □ He leído las presentes
                            condiciones y estoy dispuesto a recibir información
                            publicitaria o promocional de FASTPAYHOTELS SL. □ He
                            leído las presentes condiciones y no deseo recibir
                            información publicitaria o promocional de
                            FASTPAYHOTELS SL.
                        </p>
                        <p>
                            Le informamos que puede revocar su consentimiento
                            para recibir correo comercial enviando un correo
                            electrónico a la siguiente dirección:
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Puede acudir a la autoridad de control competente
                            para presentar la reclamación que considere
                            oportuna.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Cláusula para entrar en el formulario web
                        </h4>
                        <p>
                            De conformidad con lo dispuesto en la legislación
                            vigente en materia de Protección de Datos de
                            Carácter Personal, le informamos que sus datos serán
                            incorporados al sistema de tratamiento propiedad de
                            FASTPAYHOTELS SL, con CIF B57940066 y domicilio
                            social en PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) con el fin de atender sus
                            consultas. De acuerdo con la normativa vigente,
                            FASTPAYHOTELS SL informa a los usuarios que sus
                            datos serán conservados durante el periodo necesario
                            para cumplir con las condiciones mencionadas.
                        </p>
                        <p>
                            FASTPAYHOTELS SL también informa a sus usuarios que
                            procesará los datos de forma legal, honesta,
                            transparente, adecuada, relevante, limitada, precisa
                            y actualizada. Por ello, FASTPAYHOTELS SL se
                            compromete a adoptar todas las medidas razonables
                            para garantizar que sean borrados o rectificados sin
                            demora en caso de que resulten inexactos.
                        </p>
                        <p>
                            De conformidad con los derechos que le confiere la
                            legislación vigente en materia de Protección de
                            Datos de Carácter Personal, podrá ejercer los
                            derechos de acceso, rectificación, limitación del
                            tratamiento, cancelación, cesión y oposición
                            enviando su solicitud a la dirección postal arriba
                            indicada o escribiendo un correo electrónico a
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Puede acudir a la autoridad de control competente
                            para presentar la reclamación que considere
                            oportuna.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Cláusula sobre la newsletter
                        </h4>
                        <p>
                            De conformidad con lo dispuesto en la legislación
                            vigente en materia de Protección de Datos de
                            Carácter Personal, le informamos que la dirección de
                            correo electrónico que nos ha facilitado será
                            incorporada al sistema de tratamiento de propiedad
                            de FASTPAYHOTELS SL, con CIF B57940066 y domicilio
                            social en PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) y almacenado en un fichero
                            del que es responsable FASTPAYHOTELS SL, con el fin
                            de tramitar su solicitud de suscripción y poder
                            enviarle regularmente nuestro Boletín de Noticias.
                        </p>
                        <p>
                            De acuerdo con la normativa aplicable, FASTPAYHOTELS
                            SL informa a los usuarios que sus datos serán
                            conservados durante el periodo necesario para el
                            cumplimiento de las citadas condiciones.
                        </p>
                        <p>
                            A menos que se nos notifique lo contrario,
                            asumiremos que sus datos no han sido modificados,
                            que usted está de acuerdo en notificarnos si cambian
                            y que tenemos su consentimiento para utilizarlos
                            para los fines mencionados.
                        </p>
                        <p>
                            FASTPAYHOTELS SL declara que procesará los datos de
                            forma legal, honesta, transparente, adecuada,
                            relevante, limitada, precisa y actualizada.
                            FASTPAYHOTELS SL se compromete por tanto a tomar
                            todas las medidas razonables para garantizar que se
                            borrará o rectificará sin demora cuando sea
                            inexacto.
                        </p>
                        <p>
                            De conformidad con los derechos que le confiere la
                            legislación vigente en materia de protección de
                            datos personales, podrá ejercer los derechos de
                            acceso, rectificación, limitación del tratamiento,
                            supresión, portabilidad y oposición al tratamiento
                            de sus datos personales y el consentimiento otorgado
                            para su tratamiento, enviando su solicitud a la
                            dirección postal arriba indicada o escribiendo un
                            correo electrónico a
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Salvo que no nos comunique lo contrario,
                            entenderemos que sus datos no han sido modificados,
                            que usted se compromete a notificarnos cualquier
                            cambio y que, de acuerdo con el artículo 21.1 de la
                            Ley 34/2002 de servicios de la sociedad de la
                            información y de comercio electrónico, una vez
                            realizada su solicitud de suscripción, tenemos su
                            consentimiento para tratar sus datos de acuerdo con
                            las finalidades antes mencionadas.
                        </p>
                        <p>
                            Asimismo, le informamos que puede revocar su
                            consentimiento, cancelando así su suscripción al
                            Boletín, enviando un correo electrónico a la
                            siguiente dirección:
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Puede ponerse en contacto con la Autoridad
                            Supervisora competente para presentar cualquier
                            reclamación que considere apropiada
                        </p>
                        <p>
                            La confirmación de la suscripción implica la
                            aceptación de esta cláusula.
                        </p>
                    </div>
                    <a href='https://www.fastpayhotels.com/'>
                        <button
                            type='button'
                            className='button-submit conditions__button'
                        >
                            Inicio
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
