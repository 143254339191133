import React from 'react';
import ThumbNail from './ThumbNail';

export default function ThumbNailList({ imageList, buttonList }) {
    return (
        <ul className='description-img-thumbs_container'>
            {imageList.map(image => (
                <li className='thumbNail-wrap' key={image.Id}>
                    <ThumbNail img={image} buttonList={buttonList} />
                </li>
            ))}
        </ul>
    );
}
