import React, { useContext } from 'react';
import SectionTitle from '../../property-partners-portal/components/SectionTitle/SectionTitle';
import NonEditionContent from './NonEditionContent';
import EditionContent from './EditionContent';
import { ContextSettings } from '../../contexts/SettingsContext';
import Clock from './Clock';
import CardPlaceholder from '../components/CardPlaceholder';

const SettingsContainer = () => {
    const {
        isEditionMode,
        isEditButtonVisible,
        setIsEditButtonVisible,
        isLoading,
    } = useContext(ContextSettings);
    return (
        <div className='settings__container'>
            <div className='page-title__container'>
                <SectionTitle title='settings' />
            </div>
            <Clock />
            {isLoading ? (
                <CardPlaceholder />
            ) : (
                <div
                    className='card'
                    onMouseEnter={() => setIsEditButtonVisible(true)}
                    onMouseLeave={() => setIsEditButtonVisible(false)}
                >
                    {isEditionMode ? (
                        <EditionContent />
                    ) : (
                        <NonEditionContent
                            isEditButtonVisible={isEditButtonVisible}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default SettingsContainer;
