import React, { useContext } from 'react';
import {
    Divider,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { userMenu } from '../../../../utils/menusData';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';

const ListUserMenu = props => {
    const { isMenuOpen, anchorEl, handleClose } = props;
    const { translate, setIsLogged } = useContext(ContextUserProfile);

    return (
        <Menu
            open={isMenuOpen}
            id='user-button'
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {userMenu.map(item => {
                const { type, IconComponent, link, onClick } = item;
                switch (type) {
                    case 'link':
                    case 'linkAndMethod':
                        return (
                            <MenuItem
                                key={item.text}
                                sx={{ padding: 0 }}
                                onClick={() => {
                                    handleClose();
                                    if (onClick) {
                                        onClick();
                                        setIsLogged(false);
                                    }
                                }}
                            >
                                <Link
                                    to={link}
                                    className='user-menu__link'
                                    style={{
                                        width: '100%',
                                        padding: '6px 16px',
                                    }}
                                >
                                    <ListItemIcon>
                                        <IconComponent fontSize='small' />
                                    </ListItemIcon>
                                    <Typography sx={{ fontWeight: 600 }}>
                                        {translate(item.text)}
                                    </Typography>
                                </Link>
                            </MenuItem>
                        );
                    case 'divider':
                        return <Divider key={item.text} />;
                    default:
                        return '';
                }
            })}
        </Menu>
    );
};

export default ListUserMenu;
