import React, { useContext } from 'react';
import SectionTitle from '../../property-partners-portal/components/SectionTitle/SectionTitle';
import IconButton from '../../muiComponents/IconButton';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { getInstructionsData } from '../../utils/PublicSectionsUtils';
import { countryFlagById } from '../../utils/flags/getFlag';
import emergencyPhone from '../../images/icons/emergencyPhone.svg';

const ServiceDeskInformation = () => {
    const { translate } = useContext(ContextUserProfile);
    const { isInfoVisible, setIsInfoVisible } = useContext(ContextServiceDesk);

    const additionalInfoData = getInstructionsData('serviceDesk');

    return (
        <>
            <div className='title__container'>
                <SectionTitle title={'service_desk_portal'} />
                <IconButton
                    iconName='InfoOutlinedIcon'
                    onClick={() => setIsInfoVisible(prevState => !prevState)}
                />
            </div>
            {isInfoVisible && (
                <div className='additional-info__container'>
                    <div>
                        {additionalInfoData.paragraphs.map(para => (
                            <p className='additional_info' key={para.id}>
                                {translate(para.text)}
                            </p>
                        ))}
                    </div>
                    <div className='telephone__container'>
                        {additionalInfoData.contact.map(tel => {
                            return (
                                <div className='telephone' key={tel.id}>
                                    <img
                                        src={
                                            countryFlagById[tel.id] ||
                                            emergencyPhone
                                        }
                                        alt={`flag ${tel.key}`}
                                        className='telefone__flag'
                                    />
                                    <p className='telephone__text'>
                                        {translate(tel.contactNumber)}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default ServiceDeskInformation;
