import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { decodeBase64 } from '../../../utils/encodeBase64';
import {
    formatNumber,
    formatDate,
    formatDateTime,
    formatDateCard,
} from '../../../utils/usefulFunctions';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import api from '../../../services/api';
import Card from './Card';
import Loading from '../../../common/components/Loading';
import '../../../stylesheets/stylesLayout/privateDetail.scss';
import { viewModelStructure, detailInitialState } from './yourBookingsHelpers';
import VccPopUp from '../../../popups/VccPopUp';
import endpoints from '../../../utils/commonVariables/endpoints';
import { viewModelEntities } from '../../../utils/commonVariables/entityKeys';

const BookingDetail = props => {
    const { translate, language, timeZone } = useContext(ContextUserProfile);
    const history = useHistory();
    const [booking, setBooking] = useState(detailInitialState);
    const [cardDetails, setCardDetails] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [open, setOpen] = useState(false);

    const {
        match: {
            params: { encodedIdBooking },
        },
    } = props;

    const IdBooking = decodeBase64(encodedIdBooking);

    const getBooking = async () => {
        setIsFetching(true);
        const endpoint = endpoints.viewModel(
            viewModelEntities.SupplierBooking,
            IdBooking
        );
        const {
            data: { Result: _booking },
        } = await api.post(endpoint, viewModelStructure);
        setBooking(_booking);
        setIsFetching(false);
    };

    useEffect(() => {
        getBooking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPaymentInfo = async () => {
        const paymentId = booking?.SupplierBookingPaymentInfo.Id;
        const endpoint = endpoints.getCreditCardNumber(paymentId);
        const { data } = await api.get(endpoint);
        const { Result: _cardDetails, StatusCode } = data;
        if (StatusCode === 200) {
            setCardDetails({
                name: booking?.SupplierBookingPaymentInfo.HolderName,
                number: _cardDetails?.CreditCardNumber,
                expiry: formatDateCard(_cardDetails?.ExpirationDate),
                cvc: booking?.SupplierBookingPaymentInfo.VirtualCreditCard.CVV,
            });
            setHasAccess(true);
        }
    };

    useEffect(() => {
        const paymentId = parseInt(booking?.SupplierBookingPaymentInfo.Id);
        if (paymentId > 0) {
            getPaymentInfo();
        }

        return () => setHasAccess(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking?.SupplierBookingPaymentInfo]);

    const handleOpenPopUp = () => {
        setOpen(true);
    };

    const findHotelRef = IdList => {
        const refObject = IdList.find(
            item => item.OTAReferenceTableCode.Key === '10'
        );
        return refObject?.ResID_Value || '';
    };

    const hotelRef =
        booking?.ReservationIDs.length && findHotelRef(booking?.ReservationIDs);

    const cardFields = [
        {
            name: 'status',
            value: booking?.SupplierBookingStatus.SupplierBookingStatusType
                .Name,
        },
        {
            name: 'booking_date',
            value: formatDateTime(booking?.BookingDate, language, timeZone),
        },
        {
            name: 'hotel',
            value: booking?.SupplierHotel?.Name,
        },
        {
            name: 'booking_code',
            value: booking?.BookingCode,
        },
        {
            name: 'booking_reference_hotel',
            value: hotelRef,
        },
        {
            name: 'check_in_date',
            value: formatDate(booking?.CheckInDate, language),
        },
        {
            name: 'check_out_date',
            value: formatDate(booking?.CheckOutDate, language),
        },
        {
            name: 'nights',
            value: booking?.Nights,
        },
        {
            name: 'guests',
            value: booking?.Paxes,
        },
        {
            name: 'price',
            value:
                booking?.Currency?.Key && booking?.TotalAmount
                    ? formatNumber(
                          booking?.TotalAmount,
                          'currency',
                          booking.Currency.Key
                      ) + ` (${booking.Currency.Key})`
                    : booking?.TotalPrice
                    ? formatNumber(booking?.TotalPrice, 'decimal')
                    : booking?.TotalPrice,
        },
        {
            name: 'payment_info',
            value: booking?.SupplierBookingPaymentInfo
                ?.CreditCardNumberObfuscated,
            button: {
                icon: 'eye-button',
                handler: handleOpenPopUp,
                active: hasAccess,
            },
        },
        {
            name: 'cancellation_policy',
            value: booking?.SupplierBookingCancellationPolicy?.DecodedPenalties,
        },
        {
            name: 'notes',
            value: booking?.Remarks,
        },
    ];

    const client = booking?.ZoeBooking.EndCustomer;
    const defaultClient = [{ name: translate('no_data'), value: '' }];

    const clientFields = [
        { name: 'name', value: client?.Name },
        {
            name: 'surname',
            value: client?.Surname,
        },
        {
            name: 'email2',
            value: client?.EmailAddress,
        },
    ];

    const rooms = [
        booking?.Rooms?.map(room => [
            {
                name: 'room_type',
                value:
                    room?.SupplierHotelRoomType?.Name +
                    ' (' +
                    room?.SupplierHotelRoomType?.MappingCode +
                    ')',
            },
            {
                name: 'meal_plan',
                value:
                    room?.SupplierHotelMealPlan?.Name +
                    ' (' +
                    room?.SupplierHotelMealPlan?.MappingCode +
                    ')',
            },
            {
                name: 'rate_plan',
                value:
                    room?.SupplierHotelRatePlan?.Name +
                    ' (' +
                    room?.SupplierHotelRatePlan?.MappingCode +
                    ')',
            },
            {
                name: `${translate('price')} (${booking?.Currency.Key})`,
                value: [
                    room?.Rates?.map(price =>
                        booking?.Currency?.Key
                            ? formatNumber(
                                  price?.AmountAfterTax,
                                  'currency',
                                  booking.Currency.Key
                              )
                            : formatNumber(price?.AmountAfterTax, 'decimal')
                    ),
                ],
                headers: room?.Rates?.map(price => [
                    `${translate('night')} #${room?.Rates?.indexOf(price) + 1}`,
                ]),
            },
            {
                name: 'guests',
                value: room.Paxes?.map(pax => [
                    pax?.Name,
                    pax?.Surname,
                    pax?.Age,
                    pax?.IsAdult
                        ? translate('adult')
                        : pax?.IsChild
                        ? translate('child')
                        : pax?.IsBaby
                        ? translate('baby')
                        : 'undefined',
                ]),
                headers: ['name', 'surname', 'age', 'age_segment'],
            },
        ]),
    ];

    const [roomFields] = rooms;

    const closeDetail = () => {
        history.push('/owner-portal/your-bookings');
    };

    return (
        <div className='private-detail'>
            {isFetching ? (
                <Loading />
            ) : (
                <>
                    <div className='private-detail__title'>
                        <h1 className='title'>
                            {`${translate('booking')} : ${
                                booking?.BookingCode
                            }`}
                        </h1>
                        <button
                            type='button'
                            className='close-button'
                            onClick={closeDetail}
                        >
                            <i
                                className='fa fa-times '
                                aria-hidden='true'
                                title={translate('close')}
                            />
                        </button>
                    </div>

                    <Card
                        cardTitle='main_information'
                        cardItems={cardFields}
                    ></Card>

                    {clientFields ? (
                        <Card
                            cardTitle='client_information'
                            cardItems={clientFields}
                        />
                    ) : (
                        <Card
                            cardTitle='client_information'
                            cardItems={defaultClient}
                        />
                    )}

                    <h2 className='card-title--secondary pb-4 pl-3'>
                        {translate('room_list')}
                    </h2>
                    {roomFields &&
                        roomFields?.map(room => (
                            <Card
                                key={roomFields.indexOf(room)}
                                cardTitle={`${
                                    room.filter(
                                        obj => obj.name === 'room_type'
                                    )[0].value
                                }`}
                                cardItems={room}
                            />
                        ))}

                    {cardDetails && (
                        <VccPopUp
                            open={open}
                            setOpen={setOpen}
                            vcc={cardDetails}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default BookingDetail;
