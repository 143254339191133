import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ContextUserProfile } from '../contexts/UserProfileContext';
import { logOut, lastPageVisitedKey } from './userProfileContextHelpers';
import routes from './commonVariables/routes';

const ProtectedRoute = ({
    path,
    component: Component,
    render,
    actionWhenNotLoggedIn,
    ...rest
}) => {
    const { isLogged } = useContext(ContextUserProfile);
    const defaultActionWhenNotLoggedIn = () => {
        logOut();
        return <Redirect to={routes.login} />;
    };
    actionWhenNotLoggedIn =
        actionWhenNotLoggedIn || defaultActionWhenNotLoggedIn;

    localStorage.setItem(lastPageVisitedKey, rest.location.pathname);

    return (
        <Route
            {...rest}
            path={path}
            render={props => {
                if (isLogged !== null) {
                    if (isLogged) {
                        return Component ? (
                            <Component {...props} />
                        ) : (
                            render(props)
                        );
                    }
                    return actionWhenNotLoggedIn();
                }
            }}
        />
    );
};

export default ProtectedRoute;
