import React from 'react';

const IconHome = ({ ...restProps }) => {
    return (
        <i
            className='fas fa-home'
            style={{
                fontSize: 18,
                lineHeight: '15px',
                width: '26px',
                display: 'flex',
                justifyContent: 'center',
            }}
            {...restProps}
        />
    );
};

export default IconHome;
