import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '../IconButton';

const StyledSuffixButton = styled(IconButton)(() => ({
	'&:hover': {
		borderRadius: 0,
	},
	'&:focus': {
		outline: 0,
	},
	borderRadius: 0,
}));

const SuffixButton = props => {
	return <StyledSuffixButton {...props} />;
};

export default SuffixButton;
