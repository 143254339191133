import axios from 'axios';
import {
    cacheKey,
    logOut,
    lsToken,
    tokenProd,
} from '../utils/userProfileContextHelpers';
import { oneDayMilliseconds } from '../utils/usefulFunctions';
import routes from '../utils/commonVariables/routes';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

axios.ChangeAxiosToken = function (currentToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`;
};
axios.ChangeAxiosLanguage = function (language) {
    axios.defaults.headers.common.Lang = language;
};

axios.interceptors.response.use(null, error => {
    if (error?.response?.status === 401) {
        logOut();
        window.location = routes.login;
    } else {
        console.log(' Ocurrió un error');
    }
    return Promise.reject(error);
});

export default axios;

// Instancia para probar datos de prod en dev. Usar con cuidado
export const prodApi = axios.create({
    baseURL: 'https://api.fastpayhotels.net',
});

prodApi.defaults.headers.common['Authorization'] = 'Bearer ' + tokenProd;

export const cachedApi = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});

cachedApi.defaults.headers.common['Authorization'] = 'Bearer ' + lsToken;
cachedApi.CancelToken = axios.CancelToken;
cachedApi.isCancel = axios.isCancel;
cachedApi.ChangeAxiosToken = function (currentToken) {
    cachedApi.defaults.headers.common.Authorization = `Bearer ${currentToken}`;
};

cachedApi.ChangeAxiosLanguage = function (language) {
    cachedApi.defaults.headers.common.Lang = language;
};

cachedApi.interceptors.request.use(
    request => {
        const cache = JSON.parse(localStorage.getItem(cacheKey));
        //milisegundos del momento actual
        const nowNumber = new Date().getTime();
        const sameRequestAndLang =
            cache &&
            cache[request.url] &&
            cache[request.url]?.lang === request?.headers?.Lang;
        if (request.method === 'get') {
            if (
                // si hay caché, esta petición está guardada y ha pasado menos de un dia desde que se guardó
                cache &&
                sameRequestAndLang &&
                nowNumber - cache[request.url].timeStamp < oneDayMilliseconds
            ) {
                request.adapter = config => {
                    return Promise.resolve({
                        data: cache[request.url].data,
                        status: request.status,
                        statusText: request.statusText,
                        headers: request.headers,
                        config: config,
                        request: request,
                    });
                };
            }
        }
        return request;
    },
    error => Promise.reject(error)
);

cachedApi.interceptors.response.use(
    response => {
        let cache = JSON.parse(localStorage.getItem(cacheKey)) || {};
        if (response.config.method === 'get') {
            const newCacheItem = {
                [response.config.url]: {
                    data: response.data,
                    timeStamp: new Date().getTime(),
                    lang: response?.config?.headers?.Lang,
                },
            };
            //limpiar cache de llamadas antiguas
            let cleanedOldCache = {};
            const nowNumber = new Date().getTime();
            for (let key in cache) {
                const isOldContent =
                    nowNumber - cache[key].timeStamp > oneDayMilliseconds;
                if (!isOldContent) {
                    cleanedOldCache[key] = cache[key];
                }
            }
            cache = { ...cleanedOldCache, ...newCacheItem };
            localStorage.setItem(cacheKey, JSON.stringify(cache));
        }
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            logOut();
            window.location = routes.login;
        } else if (
            error?.response?.status === 500 &&
            // has mandado un usuario en el mensaje de odata pero no está autenticado
            (error.response.data['odata.error'].code === '9001' ||
                // ha habido un catch a la hora de coger el usuario o en el proceso de comprobar si está autenticado
                error.response.data['odata.error'].code === '9002')
        ) {
            logOut();
            window.location = routes.login;
        } else if (cachedApi.isCancel(error)) {
            console.log(error);
        } else {
            console.log({ error });
        }
        return Promise.reject(error);
    }
);

export const commonErrorMessage = 'There was a problem, please try again';
