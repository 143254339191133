/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import Loading from '../../common/components/Loading';
import api from '../../services/api';
import CardJobs from './CardJobs';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';

export default function Jobs() {
    const [jobs, setJob] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleJobs = async () => {
        setIsLoading(true);
        const endpoint = publicEndpoints.getJobOffers;
        const res = await api.get(endpoint);
        setJob(res.data.Result);
        setIsLoading(false);
    };

    useEffect(() => {
        handleJobs();
    }, []);

    return (
        <div className='faq mx-auto'>
            <div className='join-us-container mx-auto'>
                <h1 className='text-center section_title-muggle-long'>
                    Join a new and challenging team now!
                </h1>
            </div>
            <div>
                <h3>Become a disruptor!</h3>
                <p className='text-justify introduction mt-3'>
                    Join our team and be a part of tomorrow's hotel distribution
                    model. We are a young company, fast moving and highly
                    motivated to create the next generation hotel distribution
                    business. We love what we do, we aim to be different and
                    thrive to create new technologies to solve complex problems.
                </p>
                <p className='text-center'>Contact us at:</p>
                <p className='text-center font-weight-bold'>
                    hr@fastpayhotels.com
                </p>
            </div>
            <div className='container-jobs'>
                {isLoading ? (
                    <Loading />
                ) : (
                    <ul>
                        {jobs.map(job => {
                            return (
                                <li
                                    className='jobs-item text-center py-2'
                                    key={job.Id}
                                >
                                    <CardJobs job={job} />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
}
