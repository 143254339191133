import React, { useContext, useState } from 'react';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import Button from '@mui/material/Button';
import { validateForm } from '../../utils/validateForms';
import { ContextSignUp } from '../../contexts/SignUpContext';

const SignUpStep2 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { inputsData, setInputsData, setStep } = useContext(ContextSignUp);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);

    const fieldsToValidate = {
        HotelName: {
            value: inputsData.HotelName,
            validators: ['requiredString'],
        },
        ContactPerson: {
            value: inputsData.ContactPerson,
            validators: ['requiredString'],
        },
        ContactEmail: {
            value: inputsData.ContactEmail,
            validators: ['email'],
        },
        ContactPhone: {
            value: inputsData.ContactPhone,
            validators: ['phone'],
        },
    };

    const hadleNextStep = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            setStep(prev => prev + 1);
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    return (
        <div className='step-content__container'>
            <TextInput
                labelText='name_and_surname'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, ContactPerson: value };
                    });
                }}
                value={inputsData.ContactPerson}
                errorMessage={errors.ContactPerson}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='Email'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, ContactEmail: value };
                    });
                }}
                value={inputsData.ContactEmail}
                errorMessage={errors.ContactEmail}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='tax_id'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, TaxReference: value };
                    });
                }}
                value={inputsData.TaxReference}
                errorMessage={errors.TaxReference}
                onBlur={handleValidate}
            />
            <TextInput
                labelText='country_code_and_phone'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, ContactPhone: value };
                    });
                }}
                value={inputsData.ContactPhone}
                errorMessage={errors.ContactPhone}
                onBlur={handleValidate}
                required
            />
            <Button
                variant='contained'
                onClick={() => hadleNextStep()}
                style={{ alignSelf: 'flex-end' }}
            >
                {translate('next')}
            </Button>
        </div>
    );
};

export default SignUpStep2;
