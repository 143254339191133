import React from 'react';

const CardList = props => {
    const { Name, Value } = props;
    return (
        <>
            <p className='card__field-name'> {Name} </p>
            <ul className='card__field-value'>
                {Value.map(item => {
                    return (
                        <li
                            className='pb-1 card__field-value--item'
                            key={Value.indexOf(item)}
                        >
                            {item}
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
export default CardList;
