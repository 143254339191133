import React from 'react';
import { Skeleton } from '@mui/material';

const CardPlaceholder = ({animationDelay}) =>{
    return (
        <div className='card-steps  animation__card shadow' style={{animationDelay: `${animationDelay}s`}} >
                <Skeleton variant='text' width={'60%'}/>
                <Skeleton variant='text' />
                <Skeleton variant='text' width={'50%'}/>
                <Skeleton variant='text' width={'30%'}/>
        </div>
    )
}

export default CardPlaceholder
