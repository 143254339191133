export const tabsData = {
    main_info: {
        name: 'main_info',
        title: 'Main Info',
        numberOfTabs: 5,
        tabs: [
            {
                title: 'Commercial',
                tabNumber: 1,
            },
            {
                title: 'Contact',
                tabNumber: 2,
            },
            {
                title: 'Location',
                tabNumber: 3,
            },
            {
                title: 'Area',
                tabNumber: 4,
            },
            // {
            //     title: 'destination_taxes',
            //     tabNumber: 5,
            // },
            {
                title: 'Property',
                tabNumber: 5,
            },
        ],
    },
    description: {
        name: 'description',
        title: 'Description',
        numberOfTabs: 6,
        tabs: [
            {
                title: 'Kid policies',
                tabNumber: 1,
            },
            {
                title: 'Segments',
                tabNumber: 2,
            },
            {
                title: 'services',
                tabNumber: 3,
            },
            {
                title: 'Meal Plans',
                tabNumber: 4,
            },
            {
                title: 'Descriptions',
                tabNumber: 5,
            },
            {
                title: 'Images',
                tabNumber: 6,
            },
        ],
    },
    room_configuration: {
        name: 'rooms-configuration',
        title: 'Rooms Configuration',
        numberOfTabs: 5,
        tabs: [
            {
                title: 'Main room information',
                tabNumber: 1,
            },
            {
                title: 'Occupancy',
                tabNumber: 2,
            },
            {
                title: 'Services',
                tabNumber: 3,
            },
            {
                title: 'Beds',
                tabNumber: 4,
            },
            {
                title: 'Images',
                tabNumber: 5,
            },
        ],
    },
};

export const setCompletedProgressArray = IdSupplierHotel => {
    let progressArray = [];
    for (const step in tabsData) {
        const stepArray = tabsData[step].tabs.map(tab => {
            return {
                IdSupplierHotel,
                Step: step,
                Tab: tab.title,
                Completed: true,
                __isAdded: true,
                __isChanged: true,
                Id: '-1',
            };
        });
        progressArray = [...progressArray, ...stepArray];
    }
    return progressArray;
};
