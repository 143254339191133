import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { mainFont } from '../../../../stylesheets/variables.scss';

const BookingChart = ({ chartData, bookingsData }) => {
    const { translate } = useContext(ContextUserProfile);

    return (
        <div className='booking-chart__container'>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        callbacks: {
                            title: ([tooltipItem]) => {
                                return `${translate(
                                    bookingsData[tooltipItem.index].month
                                )} - ${bookingsData[tooltipItem.index].year}`;
                            },
                        },
                    },
                    dataset: {
                        barThickness: 18,
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    drawOnChartArea: false,
                                    drawTicks: false,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    fontSize: 8,
                                    fontFamily: mainFont,
                                    padding: 5,
                                },
                                stacked: true,
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    lineWidth: 0.5,
                                    drawTicks: false,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    fontSize: 8,
                                    fontFamily: mainFont,
                                    padding: 5,
                                },
                                stacked: true,
                            },
                        ],
                    },
                }}
            />
        </div>
    );
};

export default BookingChart;
