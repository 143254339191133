import React, { useState } from 'react';
import ButtonUserMenu from './ButtonUserMenu';
import ListUserMenu from './ListUserMenu';

const UserMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickButton = element => {
        if (isMenuOpen) {
            setAnchorEl(null);
        } else {
            setAnchorEl(element);
        }
        setIsMenuOpen(prevState => !prevState);
    };

    const handleClose = () => {
        setIsMenuOpen(false);
        setAnchorEl(null);
    };

    return (
        <div>
            <ButtonUserMenu handleClickButton={handleClickButton} />
            <ListUserMenu
                isMenuOpen={isMenuOpen}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </div>
    );
};

export default UserMenu;
