import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../../utils/ProtectedRoute';
import YourBookingsList from './YourBookingsContainer';
import YourBookingDetail from './YourBookingDetail';
import { ProviderYourBookings } from '../../contextsProperty/ContextYourBookings';

const Bookings = props => {
    const { match } = props;

    return (
        <ProviderYourBookings>
            <Switch>
                <ProtectedRoute
                    path={`${match.path}/:encodedIdBooking`}
                    render={props => <YourBookingDetail {...props} />}
                />
                <ProtectedRoute
                    path={`${match.path}`}
                    render={props => <YourBookingsList {...props} />}
                />
            </Switch>
        </ProviderYourBookings>
    );
};

export default Bookings;
