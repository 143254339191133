import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useTranslate from '../../../customHooks/useTranslate';
import MostBookedChart from './MostBookedChart';
import BookingHistoryChart from './BookingHistoryChart';

const LandingCharts = () => {
    return (
        <div className='landing-charts__container'>
            <div className='landing__menu-card'>
                <div>
                    <SectionTitle
                        title={useTranslate('most_booked_hotels')}
                        variant={'h3'}
                        className='landing-chart__title'
                    />
                    <p className='booking-charts__subtitle'>{`${useTranslate(
                        'last_month'
                    )}`}</p>
                </div>
                <MostBookedChart />
            </div>
            <div className='landing__menu-card'>
                <div>
                    <SectionTitle
                        title={useTranslate('history_bookings')}
                        variant={'h3'}
                        className='landing-chart__title'
                    />
                    <p className='booking-charts__subtitle'>{`${useTranslate(
                        'past_12_months'
                    )}`}</p>
                </div>
                <BookingHistoryChart />
            </div>
        </div>
    );
};

export default LandingCharts;
