import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from '../utils/ProtectedRoute';
import routes from '../utils/commonVariables/routes';
import { ProviderAccountInformation } from '../contexts/AccountInformationContext';
import { ProviderPaymentTerms } from '../contexts/PaymentTermsContext';
import { ProviderSettings } from '../contexts/SettingsContext';
import AccountInformationContainer from './accountInformation/AccountInformationContainer';
import PaymentContainer from './payment/PaymentContainer';
import SettingsContainer from './settings/SettingsContainer';
import { ProviderTermsAndConditions } from '../contexts/TermsAndConditionsContext';

const AccountContainer = props => {
    const { match } = props;

    return (
        <section className='owner-portal__content-container'>
            <Switch>
                <ProtectedRoute
                    path={match.path + routes.accountInfo}
                    render={() => (
                        <ProviderAccountInformation>
                            <ProviderTermsAndConditions>
                                <AccountInformationContainer />
                            </ProviderTermsAndConditions>
                        </ProviderAccountInformation>
                    )}
                />
                <ProtectedRoute
                    path={match.path + routes.paymentTerms}
                    render={() => (
                        <ProviderPaymentTerms>
                            <PaymentContainer />
                        </ProviderPaymentTerms>
                    )}
                />
                <ProtectedRoute
                    path={match.path + routes.settings}
                    render={() => (
                        <ProviderSettings>
                            <SettingsContainer />
                        </ProviderSettings>
                    )}
                />
                <ProtectedRoute
                    path={match.path}
                    render={() => (
                        <Redirect to={match.path + routes.accountInfo} />
                    )}
                />
            </Switch>
        </section>
    );
};

export default AccountContainer;
