import React, { useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const LoadingForButtons = () => {
    const { translate } = useContext(ContextUserProfile);
    return (
        <>
            <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
                className='spinner--typeahead'
            />
            {translate('loading')}
        </>
    );
};

export default LoadingForButtons;
