import React, { useContext } from 'react';
import SectionTitle from '../../property-partners-portal/components/SectionTitle/SectionTitle';
import IconButton from '../../muiComponents/IconButton';
import { ContextFastCheckIn } from '../../contexts/FastCheckInContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { getInstructionsData } from '../../utils/PublicSectionsUtils';

const FastCheckInInformation = () => {
    const { translate } = useContext(ContextUserProfile);
    const { isInfoVisible, setIsInfoVisible } = useContext(ContextFastCheckIn);

    const additionalInfoData = getInstructionsData('propertyFastCheckIn');

    return (
        <>
            <div className='title__container'>
                <SectionTitle title={'fast_check_in'} />
                <IconButton
                    iconName='InfoOutlinedIcon'
                    onClick={() => setIsInfoVisible(prevState => !prevState)}
                />
            </div>
            {isInfoVisible && (
                <div className='additional-info__container'>
                    <div>
                        {additionalInfoData.paragraphs.map(para => (
                            <p className='additional_info' key={para.id}>
                                {translate(para.text)}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default FastCheckInInformation;
