import React, { useContext, useEffect, useState } from 'react';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import DescriptionCard from '../../components/DescriptionCard';
import LanguagesDropdown from '../../components/LanguagesDropdown';
import api from '../../../../services/api';
import GeneralButton from '../../components/GeneralButton';
import MessageBarInfo from '../../components/MessageBarInfo';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';

const DescriptionsComponent = () => {
    const { translate } = useContext(ContextUserProfile);
    const {
        Descriptions,
        handleChangeDescription,
        handleAddDescription,
        messageBar,
        setMessageBar,
    } = useContext(ContextOwnerPortal);

    const [languages, setLanguages] = useState([]);
    const [remainingLanguages, setRemainingLanguages] = useState([]);
    const [enabledDescriptions, setEnabledDescriptions] = useState([]);
    const IdEnglishContentLanguage = 2;
    const IdGaelicContentLanguage = 1;
    useEffect(() => {
        getLanguages();
        createInitialDescriptionArray(Descriptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (Descriptions.length && languages.length) {
            const updatedDescriptions = Descriptions?.filter(
                description =>
                    !description.Deleted &&
                    description.IdContentLanguage !== IdGaelicContentLanguage
            ).map(description => {
                const matchingLang = languages?.find(
                    lang => parseInt(lang.Id) === description.IdContentLanguage
                );
                return {
                    ...description,
                    Caption:
                        matchingLang?.LocalizedCaption || matchingLang?.Caption,
                };
            });
            const englishDesc = updatedDescriptions.find(
                item => item.IdContentLanguage === IdEnglishContentLanguage
            );
            const sortedDescriptions = updatedDescriptions.filter(
                item => item.IdContentLanguage !== IdEnglishContentLanguage
            );
            const englishFirst = [englishDesc, ...sortedDescriptions];
            setEnabledDescriptions(englishFirst);
        }

        const availableLanguages = languages.filter(
            language =>
                !Descriptions.find(
                    item => item.IdContentLanguage === parseInt(language.Id)
                ) ||
                Descriptions.find(
                    item =>
                        item.IdContentLanguage === parseInt(language.Id) &&
                        item.Deleted
                )
        );
        setRemainingLanguages(availableLanguages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Descriptions, languages]);
    useEffect(() => {
        const [englishDesc] = Descriptions.filter(
            desc => desc.IdContentLanguage === IdEnglishContentLanguage
        );
        if (
            messageBar.text === 'fill_descriptions' &&
            englishDesc.Description.length > 0
        ) {
            setMessageBar({
                type: '',
                text: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageBar, Descriptions]);
    const createInitialDescriptionArray = savedDescriptions => {
        // Filtro las descripciones que vienen de base de datos para eliminar el gaélico.
        const currentDescriptions = savedDescriptions.filter(
            desc => desc.IdContentLanguage !== IdGaelicContentLanguage
        );
        // Si no tiene descripción guardada en inglés, se la creo.
        if (
            currentDescriptions.every(
                item => item.IdContentLanguage !== IdEnglishContentLanguage
            )
        ) {
            handleAddDescription(IdEnglishContentLanguage);
        }
    };

    async function getLanguages() {
        const GAELIC_CONTENT_LANGUAGE_KEY = 'GAELIC';
        const ENGLISH_CONTENT_LANGUAGE_KEY = 'ENGLISH';
        const endpoint = buildOdataEndPoint({
            entity: readOnlyEntities.ContentLanguage,
            select: 'Id,Caption,LocalizedCaption',
            filter: [
                'Enabled',
                `Key ne '${GAELIC_CONTENT_LANGUAGE_KEY}'`,
                `Key ne '${ENGLISH_CONTENT_LANGUAGE_KEY}'`,
            ],
            orderBy: 'LocalizedCaption',
        });
        const { data } = await api.get(endpoint);
        setLanguages(data.value);
    }

    return (
        <form noValidate className='needs-validation your-properties-form'>
            <h1 className='your-properties-title'>
                {translate('What_is_the_best_way_to_describe_your_property?')}
            </h1>
            {enabledDescriptions.map(description => {
                const { Description, Caption, IdContentLanguage, Id } =
                    description;

                return (
                    <DescriptionCard
                        required={
                            IdContentLanguage === IdEnglishContentLanguage
                        }
                        handleChange={handleChangeDescription}
                        description={Description}
                        language={
                            IdContentLanguage === IdEnglishContentLanguage
                                ? translate('English')
                                : Caption || ''
                        }
                        IdContentLanguage={IdContentLanguage}
                        key={IdContentLanguage}
                        Id={Id}
                    />
                );
            })}
            <LanguagesDropdown
                langArray={remainingLanguages}
                handleAddDescription={handleAddDescription}
            />
            <div className='d-flex justify-content-between'>
                <GeneralButton type='back' text={translate('back')} />
                <GeneralButton type='next' text={translate('continue')} />
            </div>
            {messageBar.text && (
                <div className='mt-4'>
                    <MessageBarInfo
                        type={messageBar.type}
                        text={translate(messageBar.text)}
                    />
                </div>
            )}
        </form>
    );
};

export default DescriptionsComponent;
