import React, { useContext } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../../../property-partners-portal/ownerPortal/components/GeneralButton';

const Card1 = props => {
    const { coverPageInfo } = useContext(ContextOwnerPortal);
    const { translate } = useContext(ContextUserProfile);

    const { HotelName, Address, GeneralEmail } = coverPageInfo;
    const { Porcentage, textButton, urlButton } = props;
    return (
        <div className='card-steps shadow'>
            <div className='iconNtext__container'>
                <i
                    className='fas fa-hotel iconNtext__icon'
                    style={{ fontSize: '24px' }}
                />
                <p className={`iconNtext__text-md`}>
                    {HotelName || translate('not_hotel')}
                </p>
            </div>
            <div className='iconNtext__container mt-4'>
                <i className='fas fa-map-marker-alt iconNtext__icon' />
                <p className={`card-text`}>
                    {Address || translate('not_address')}
                </p>
            </div>
            <div className='iconNtext__container mt-4'>
                <i className='fas fa-at iconNtext__icon' />
                <p className={`card-text`}>
                    {GeneralEmail || translate('not_email')}
                </p>
            </div>
            <div className='card-steps__footer mt-4'>
                <div
                    className='progress mt-2 mb-3'
                    style={{ height: '7px', width: '30%' }}
                >
                    <div
                        className='progress-bar progress-bar bg-success '
                        style={{ width: `${Porcentage}%` }}
                        aria-valuenow={Porcentage}
                        aria-valuemin='0'
                        aria-valuemax='100'
                    />
                </div>
                <GeneralButton
                    type='openStep'
                    text={textButton}
                    url={urlButton}
                    disabled={false}
                />
            </div>
        </div>
    );
};

export default Card1;
