import React, { useContext } from 'react';
import { ContextFastCheckIn } from '../../contexts/FastCheckInContext';
import '../../stylesheets/stylesLayout/service-desk.scss';
import '../../stylesheets/stylesLayout/fast-checkin.scss';
import Steps from '../Steps';
import FastCheckInInformation from './FastCheckInInformation';
import FastCheckInStepsContainer from './FastCheckInStepsContainer';

const FastCheckIn = () => {
    const { stepsCount, step, setStep } = useContext(ContextFastCheckIn);

    return (
        <div className='service-desk__container'>
            <div className='steps__section'>
                <Steps
                    stepsCount={stepsCount}
                    step={step}
                    setStep={setStep}
                    disabledSteps={step === stepsCount}
                />
            </div>
            <div className='service-desk__content'>
                <FastCheckInInformation />
                <FastCheckInStepsContainer />
            </div>
        </div>
    );
};

export default FastCheckIn;
