import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ProviderUserProfile } from './contexts/UserProfileContext';
import defaultTheme from './contexts/Theme';
import { ThemeProvider } from '@mui/material';
import { ProviderGlobalFeatures } from './contexts/GlobalFeatures';
import { NotificationsProvider } from './contexts/NotificationsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { setFavicon } from './utils/usefulFunctions';

setFavicon();

ReactDOM.render(
    <BrowserRouter>
        <ProviderGlobalFeatures>
            <ProviderUserProfile>
                <NotificationsProvider>
                    <ThemeProvider theme={defaultTheme}>
                        <App />
                    </ThemeProvider>
                </NotificationsProvider>
            </ProviderUserProfile>
        </ProviderGlobalFeatures>
    </BrowserRouter>,
    document.getElementById('root')
);
