export const management =
    '/userfiles/PropertyCustomExportFiles/1.Management.xlsx';

export const publicPortrait =
    '/userfiles/PropertyCustomExportFiles/2.Public%20Portrait.xlsx';

export const inventory =
    '/userfiles/PropertyCustomExportFiles/3.Inventory.xlsx';

export const zip =
    '/userfiles/PropertyCustomExportFiles/Import%20Property%20Data.zip';
