import React from 'react';
import LateralMenu from '../../layout/lateralMenu/LateralMenu';
import { ownerPortalMenu } from '../../utils/menusData';

const OwnerPortalContainer = ({ children }) => {
    return (
        <div className='owner-portal__content'>
            <LateralMenu list={ownerPortalMenu} />
            <section className='owner-portal__content-container'>
                {children}
            </section>
        </div>
    );
};

export default OwnerPortalContainer;
