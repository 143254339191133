import React, { useContext } from 'react';
import IconAndTextComponent from '../components/IconAndTextComponent';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { ContextSettings } from '../../contexts/SettingsContext';
import { languagesMenu } from '../../utils/menusData';
import useTranslate from '../../customHooks/useTranslate';

const NonEditionContent = ({ isEditButtonVisible }) => {
    const { language } = useContext(ContextUserProfile);
    const { selectedTimezone, setIsEditionMode } = useContext(ContextSettings);
    const editText = useTranslate('edit');
    const selectedLanguage = useTranslate(
        languagesMenu.find(lang => lang.text === language).name
    );
    return (
        <>
            {isEditButtonVisible && (
                <Tooltip title={editText} placement='top' arrow>
                    <IconButton
                        color='primary'
                        onClick={() => setIsEditionMode(true)}
                        className='edit-button'
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )}

            <div className='account-info__non-edition'>
                <IconAndTextComponent
                    icon={'fas fa-globe'}
                    text={selectedLanguage}
                    description={'language'}
                />
                <IconAndTextComponent
                    icon={'fas fa-clock'}
                    text={selectedTimezone.DisplayName}
                    description={'time_zone'}
                />
            </div>
        </>
    );
};

export default NonEditionContent;
