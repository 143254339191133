import React from 'react';
import SocialLink from './SocialLink';

const SocialLinks = () => {
    // Solo se abre en otra pestaña y una única vez el de linkedin. En general funciona para otras urls (google,mozzila) pero
    // para facebook y twitter se abre siempre una nueva pestaña
    const socialLinksList = [
        {
            url: 'https://www.facebook.com/Fastpayhotels',
            iconName: 'fab fa-facebook-f',
        },
        {
            url: 'https://twitter.com/Fastpayhotels1',
            iconName: 'fab fa-twitter',
        },
        {
            url: 'https://www.linkedin.com/company/fastpayhotels',
            iconName: 'fab fa-linkedin-in',
        },
    ];

    return (
        <ul className='social-links__list'>
            {socialLinksList.map(socialLink => (
                <li key={socialLink.url} className='social-links__item'>
                    <SocialLink {...socialLink} />
                </li>
            ))}
        </ul>
    );
};

export default SocialLinks;
