import React from 'react';
import { IconButton as IconButtonMaterialUI } from '@mui/material';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const IconButton = props => {
    const { iconName, ...restProps } = props;
    const icons = {
        Close,
        Visibility,
        VisibilityOff,
        InfoOutlinedIcon,
    };
    const Icon = icons[iconName];

    return (
        <IconButtonMaterialUI {...restProps}>
            <Icon />
        </IconButtonMaterialUI>
    );
};

export default IconButton;
