import { logOut } from './userProfileContextHelpers';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Divider } from '@mui/material';
import { getFlag } from './flags/getFlag';
import routes from './commonVariables/routes';

export const menuPropertyPublic = [
    {
        name: 'property_service_desk',
        linkTo: routes.serviceDesk,
    },
    {
        name: 'fast_check_in',
        linkTo: routes.fastCheckIn,
    },

    {
        name: 'sign_up',
        linkTo: routes.signUp,
    },
    {
        name: 'log_in',
        linkTo: routes.login,
    },
];

export const menuPropertyPrivate = [
    {
        name: 'home',
        linkTo: routes.landingPublic,
    },
    {
        name: 'property_service_desk',
        linkTo: routes.serviceDesk,
    },
    {
        name: 'fast_check_in',
        linkTo: routes.fastCheckIn,
    },
];

export const userMenu = [
    {
        type: 'link',
        text: 'account',
        IconComponent: ManageAccountsOutlinedIcon,
        link:
            routes.landingPrivate + routes.accountSection + routes.accountInfo,
    },
    {
        type: 'link',
        text: 'payment_terms',
        IconComponent: PaymentOutlinedIcon,
        link:
            routes.landingPrivate + routes.accountSection + routes.paymentTerms,
    },
    {
        type: 'link',
        text: 'settings',
        IconComponent: SettingsOutlinedIcon,
        link: routes.landingPrivate + routes.accountSection + routes.settings,
    },
    { type: 'divider', text: 'divider', IconComponent: Divider },
    {
        type: 'linkAndMethod',
        text: 'Logout',
        IconComponent: LogoutOutlinedIcon,
        link: routes.login,
        onClick: logOut,
    },
];

export const languagesMenu = [
    { text: 'en', Icon: getFlag(2), name: 'English', Id: 1 },
    { text: 'fr', Icon: getFlag(5), name: 'French', Id: 2 },
    { text: 'es', Icon: getFlag(3), name: 'Spanish', Id: 3 },
    { text: 'de', Icon: getFlag(4), name: 'German', Id: 4 },
];

export const ownerPortalMenu = [
    {
        iconClass: 'fas fa-hotel',
        link: '/owner-portal/your-properties',
        text: 'properties',
    },
    {
        iconClass: 'far fa-calendar-alt',
        link: '/owner-portal/your-bookings',
        text: 'bookings',
    },
    {
        iconClass: 'fas fa-list-alt',
        link: '/owner-portal/ari',
        text: 'Ari',
    },
];
