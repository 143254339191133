import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import api, { cachedApi } from '../../../services/api';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { debounce } from '../../../utils/usefulFunctions';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { buildOdataEndPoint, substringof } from '../../../utils/odataEndPoint';

export default function TypeaheadInput(props) {
    const [isFetching, setIsFetching] = useState(false);
    const [open, setOpen] = useState(false);
    const [emptyLabel, setEmptyLabal] = useState('');
    const { translate } = useContext(ContextUserProfile);
    const { visibleMessageTypeahead } = useContext(ContextOwnerPortal);
    const { data, value, handleChange } = props;
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [cancelToken, setCancelToken] = useState(null);
    let defaultData = { triggerLength: 3, validationmessage: 'required' };
    defaultData = { ...defaultData, ...data };
    const {
        title,
        apiEndPoint,
        ActionInfo,
        name,
        required,
        validationmessage,
        disabled,
        triggerLength,
        containerClassName,
    } = defaultData;

    const hintMinLetters = `${translate('Write_at_least')} ${triggerLength} ${
        triggerLength === 1 ? translate('letter') : translate('letters')
    }`;
    const prevFilters = apiEndPoint.filter || [];
    useEffect(() => {
        if (value) getSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const getSelected = async () => {
        const filters = prevFilters.concat(`Id eq '${value}'`);
        const endPoint = buildOdataEndPoint({
            ...apiEndPoint,
            filter: filters,
        });
        const response = await api.get(endPoint);
        let selected = response.data.value;
        selected = selected.map(item => ({
            Name: item[ActionInfo],
            Id: item.Id,
        }));
        setSelected(selected);
    };

    const handleChangeInputTypeahead = async value => {
        setSelected([{ Name: value, Id: 0 }]);
        handleChange({ target: { name, value: 0 } });
        if (value.length >= triggerLength) {
            debounceCallApi(value);
        } else {
            setEmptyLabal(hintMinLetters);
        }
        setOpen(true);
    };

    const callApi = async value => {
        if (cancelToken) {
            cancelToken.cancel();
        }
        const cancelTokenLocal = cachedApi.CancelToken.source();
        setCancelToken(cancelTokenLocal);
        const cancelConfig = {
            cancelToken: cancelTokenLocal.token,
        };

        const filters = prevFilters.concat(substringof(ActionInfo, value));
        const endpoint = buildOdataEndPoint({
            ...apiEndPoint,
            filter: filters,
        });

        setIsFetching(true);
        let { data } = await cachedApi.get(endpoint, cancelConfig);
        data = data.value;
        setIsFetching(false);
        setOptions(
            data.map(option => ({
                Name: option[ActionInfo],
                Id: parseInt(option.Id),
            }))
        );
        setEmptyLabal(translate('not_maches_found'));
    };

    const debounceCallApi = useCallback(debounce(callApi, 500), [cancelToken]);

    const onChange = value => {
        if (value.length === 1) {
            setSelected(value);
            handleChange({ target: { name, value: value[0].Id } });
            setOpen(false);
        }
    };

    const cleanSelection = () => {
        setOpen(false);
        // Si el Id es 0 es que no hay una opción seleccionada, puede que esté a medias de rellenar
        const isOptionSelected = selected[0]?.Id;
        if (!isOptionSelected) {
            setSelected([{ Name: '', Id: 0 }]);
        }
    };

    const onFocus = () => {
        const value = selected[0]?.Name;
        if (value?.length < triggerLength) {
            setOpen(true);
            setEmptyLabal(hintMinLetters);
        }
    };

    return (
        <div className={`form-group input-container ${containerClassName}`}>
            <label>{`${translate(title)} ${required ? ' *' : ''}`}</label>
            <Typeahead
                placeholder={translate(title)}
                id='typeahead-Name'
                labelKey='Name'
                selected={selected}
                options={options}
                minLength={triggerLength}
                onInputChange={handleChangeInputTypeahead}
                onChange={onChange}
                open={open}
                onBlur={cleanSelection}
                inputProps={{ required, className: 'form-control' }}
                emptyLabel={emptyLabel}
                disabled={disabled}
                isLoading={isFetching}
                onFocus={onFocus}
            />
            {selected[0]?.Id === 0 && visibleMessageTypeahead && required && (
                <p className='invalid-text'>{translate(validationmessage)}</p>
            )}
        </div>
    );
}
