import React from 'react';
import { green, red } from '../ariHelpers';
import './detailCard.scss';

const RestrictionBadge = ({ text }) => {
    const badgeColors = {
        closed: red,
        open: green,
    };
    const defaultColor = '#000';

    const color = badgeColors[text] || defaultColor;

    return (
        <div className='restriction-badge' style={{ color }}>
            {text.toUpperCase()}
        </div>
    );
};

export default RestrictionBadge;
