import React, { useEffect, useContext } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ProviderOccupancy } from '../../../contextsProperty/ContextOccupancy';
import { decodeBase64 } from '../../../../utils/encodeBase64';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import MainRoomInfo from './MainRoomInfo';
import Occupancy from './Occupancy';
import RoomServices from './RoomServices';
import Beds from './Beds';
import RoomImages from './RoomImages';

const SingleRoom = ({ IdRoomEncode }) => {
    const { getSingleRoomData, progress, setProgress, singleRoomData } =
        useContext(ContextOwnerPortal);
    const { currentTab, stepName } = progress.current;
    const { lastTabFilled } = progress.prefilledProgress.room_configuration;

    const Id = IdRoomEncode ? decodeBase64(IdRoomEncode) : singleRoomData.Id;
    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                maxTabs: tabsData.room_configuration.numberOfTabs,
                tabName: tabsData.room_configuration.tabs[0].title,
                currentTab: 1,
                stepName: 'room_configuration',
                maxSubTabs: 1,
                currentSubTab: 1,
                lastTabFilled,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepName]);

    useEffect(() => {
        if (IdRoomEncode) {
            getSingleRoomData(Id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCurrentForm = () => {
        switch (currentTab) {
            case 1:
                return <MainRoomInfo Id={Id} />;
            case 2:
                return (
                    <ProviderOccupancy>
                        <Occupancy />
                    </ProviderOccupancy>
                );
            case 3:
                return <RoomServices />;
            case 4:
                return <Beds idRoom={Id} />;
            case 5:
                return <RoomImages />;
            default:
                return <p>componente no encontrada</p>;
        }
    };

    return <>{getCurrentForm()}</>;
};

export default SingleRoom;
