import React, { useContext, useEffect } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import VisServices from '../../yourProperties/description/VisServices';
import ServiceAccordion from './ServiceAccordion';

const ServiceStepsContainer = () => {
    const { progress, setProgress } = useContext(ContextOwnerPortal);
    const { currentSubTab } = progress.current;
    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentSubTab: 1,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return currentSubTab === 1 ? <VisServices /> : <ServiceAccordion />;
};

export default ServiceStepsContainer;
