import React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import ButtonClose from '../common/components/buttons/ButtonClose';
import ReactPannellum from 'react-pannellum';
import '../stylesheets/stylesPopUp/PropertyImagesPopUp.scss';

const PropertyImagesPopUp = props => {
    const { open, setOpen, img } = props;

    const imageConfig3D = {
        autoRotate: 0,
        autoLoad: true,
        id: img.Id,
        uiText: { bylineLabel: `${img.OriginalFileName}` },
        showControls: false,
        minPitch: -88,
        maxPitch: 88,
        avoidShowingBackground: true,
    };

    const imageStyle3D = {
        width: '100%',
        height: '100%',
        borderRadius: '15px',
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            className='container__image'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open} timeout={500}>
                {img.Is360 ? (
                    <div className='container__image--pannellum'>
                        <ButtonClose
                            handleClose={handleClose}
                            left={980}
                            top={0}
                        />
                        <ReactPannellum
                            key={Math.random(0, 100)}
                            id='1'
                            sceneId='firstScene'
                            imageSource={img.VirtualPath}
                            style={imageStyle3D}
                            config={imageConfig3D}
                        />
                    </div>
                ) : (
                    <div className='container__image--2d'>
                        <ButtonClose
                            handleClose={handleClose}
                            left={980}
                            top={0}
                        />
                        <img src={img?.VirtualPath} alt={img.Name} />
                    </div>
                )}
            </Fade>
        </Modal>
    );
};

export default PropertyImagesPopUp;
