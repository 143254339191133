import React, { useContext } from 'react';
import '../../../stylesheets/stylesLayout/tableViews.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import FiltersBar from './FiltersBar';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import { TableBody } from '@material-ui/core';
import TableHeader from './TableHeader';
import { getTableStructure } from './yourBookingsHelpers';
import { ContextYourBookings } from '../../contextsProperty/ContextYourBookings';
import TableRow from './BookingTableRow';
import { Alert } from '@mui/material';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import BookingStatisticsSection from './bookingStatistics/BookingStatisticsSection';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';

const YourBookings = () => {
    const {
        items,
        handleScroll,
        infoMessage,
        isLoading,
        setAreFiltersVisible,
        areFiltersVisible,
        isChartVisible,
        setIsChartVisible,
    } = useContext(ContextYourBookings);
    const { translate } = useContext(ContextUserProfile);

    const tableStructure = getTableStructure();

    // para que la primera vez que se carga la lista, mientras hace la petición, muestre falsos elementos en la lista
    let dummyItems = [];
    for (let i = 0; i < 15; i++) {
        const dummyItem = { Id: i };
        dummyItems = [...dummyItems, dummyItem];
    }

    return (
        <div className='bookings__container'>
            <div className='page-title__container'>
                <SectionTitle title='bookings' />
                <div>
                    <IconButton
                        onClick={() =>
                            setIsChartVisible(prevState => {
                                return !prevState;
                            })
                        }
                    >
                        {isChartVisible ? (
                            <InsertChartOutlinedIcon />
                        ) : (
                            <InsertChartIcon />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            setAreFiltersVisible(prevState => !prevState)
                        }
                    >
                        {areFiltersVisible ? (
                            <FilterAltIcon />
                        ) : (
                            <FilterAltOffIcon />
                        )}
                    </IconButton>
                </div>
            </div>
            <BookingStatisticsSection />
            <FiltersBar />
            {infoMessage.message && (
                <Alert severity={infoMessage.type}>
                    {translate(infoMessage.message)}
                </Alert>
            )}
            <TableContainer
                classes={{ root: 'table__container' }}
                onScroll={handleScroll}
            >
                <Table stickyHeader size='small'>
                    <TableHeader columns={tableStructure.columns} />
                    <TableBody>
                        {/* para que la primera vez que se carga la lista, mientras hace la petición, muestre falsos elementos en la lista */}
                        {!items.length && isLoading
                            ? dummyItems.map(item => {
                                  return <TableRow key={item.Id} row={item} />;
                              })
                            : items.map(item => {
                                  return <TableRow key={item.Id} row={item} />;
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default YourBookings;
