// en las posiciones se pone la transición adecuada pero si se quiere sobreescribir se puede. Ver más abajo ejemplo

export const positions = {
    1: {
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        transitionDirection: 'right',
    },
    2: {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        transitionDirection: 'down',
    },
    3: {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transitionDirection: 'left',
    },
    4: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transitionDirection: 'left',
    },
    5: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transitionDirection: 'up',
    },
    6: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transitionDirection: 'right',
    },
};

// ejemplos.

// setErrorMessage({
//     text: 'login_error_data_entered_not_correct',
//     snackbarProps: {
//         position: 1, // si se quiere cambiar la posición de la message bar
//         autoHideDuration: undefined;
//     },
// });

// para poner un mensaje de warning o de info

// setNotificationMessage({
//     componentText: <button onClick={retryRequest}>Retry request</button>
//     action: undefined // si no quieres que le salga el botón de cerrar
//     alertProps: {
//          severity: 'warning
//     }
//     snackbarProps: {
//         position: 1, // si se quiere cambiar la posición de la message bar
//         autoHideDuration: undefined;
//     },
// })

// setNotificationMessage({
//     text: 'hola',
//     alertProps: {
//         severity: 'success',
//     },
//     snackbarProps: {
//         anchorOrigin: { vertical: 'top', horizontal: 'center' },
//         transitionDirection: 'left',
//     },
// });
