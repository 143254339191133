import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import '../../../stylesheets/stylesLayout/yourProperties.scss';
import encodeBase64 from '../../../utils/encodeBase64';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import api from '../../../services/api';
import convertToOvariant from '../../../utils/convertToOvariant';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import LoadingForButtons from './LoadingForButtons';
import endpoints from '../../../utils/commonVariables/endpoints';
import {
    savableEntities,
    viewModelEntities,
} from '../../../utils/commonVariables/entityKeys';
import supplierHotelRoomTypeContract from '../../../utils/viewModelStructures/SupplierHotelRoomType';
import { NotificationsContext } from '../../../contexts/NotificationsContext';

function CardRoom({ RoomName, Imagen, history, Id }) {
    const { setErrorMessage } = useContext(NotificationsContext);
    const { translate } = useContext(ContextUserProfile);
    const IdEncode = encodeBase64(Id);
    const { cloneRoom, deleteRoom, setSingleRoomData } =
        useContext(ContextOwnerPortal);

    const [isCloningRoom, setIsCloningRoom] = useState(false);
    const [isDeletingRoom, setIsDeletingRoom] = useState(false);

    async function handleDelete() {
        setIsDeletingRoom(true);
        const payload = convertToOvariant(
            savableEntities.SupplierHotelRoomType,
            {
                Ids: [Id],
            }
        );
        const endpoint = endpoints.commonDeleteItems;
        await api.post(endpoint, payload);
        deleteRoom(Id);
        setIsDeletingRoom(false);
    }

    async function handleClone() {
        setIsCloningRoom(true);
        const endpoint = endpoints.viewModel(
            viewModelEntities.SupplierHotelRoomType,
            Id
        );
        const { data } = await api.post(
            endpoint,
            supplierHotelRoomTypeContract
        );
        const newRoom = { ...data.Result };
        const newRoomId = '-1';
        newRoom.Id = newRoomId;
        newRoom.Name += ' copy';
        // Al clonar estos tres campos NO deben copiarse ver ticket #13748
        // no se piden en el view model pero se añaden aquí
        newRoom.MappingCode = '';
        newRoom.Enabled = false;
        newRoom.Active = false;
        if (newRoom.MinAdults === 0) {
            newRoom.Occupancy = '';
            newRoom.MinAdults = 1;
        }
        if (newRoom.MaxAdults === 0) {
            newRoom.Occupancy = '';
            newRoom.MaxAdults = 1;
        }
        if (newRoom.MaxChild < 0 || newRoom.MinChildren < 0) {
            newRoom.Occupancy = '';
            newRoom.MaxChild = 0;
            newRoom.MinChildren = 0;
        }
        const newBeds = newRoom.SupplierHotelRoomBedTypes?.map(bed => ({
            ...bed,
            __isChanged: true,
            __isAdded: true,
        }));
        newRoom.SupplierHotelRoomBedTypes = newBeds;
        const newServices = newRoom.Services?.map(service => ({
            ...service,
            __isChanged: true,
            __isAdded: true,
        }));
        newRoom.Services = newServices;

        const objectToSend = convertToOvariant(
            savableEntities.SupplierHotelRoomType,
            newRoom,
            newRoomId
        );
        try {
            const responseSave = await api.post(
                endpoints.commonSave,
                objectToSend
            );
            const newRoomToAdd = {
                Id: responseSave.data.Id,
                Name: responseSave.data.Name,
            };
            cloneRoom(Id, newRoomToAdd);
            setSingleRoomData(responseSave.data);
            history.push(`inventory/new`);
        } catch (error) {
            setErrorMessage('something_went_wrong');
        }

        setIsCloningRoom(false);
    }

    const imageStyle = {
        backgroundImage: `url(${Imagen})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    };

    return (
        <div className='card rounded shadow card_property-container-room'>
            <div className='card_property-room'>
                <div className='card_img-container-room'>
                    {Imagen ? (
                        <div
                            src={Imagen}
                            alt='room image'
                            className='card-img card-image'
                            style={imageStyle}
                        />
                    ) : (
                        <i
                            className='fas fa-hotel section__image placeholder'
                            aria-hidden='true'
                        />
                    )}
                </div>
                <div className='card-text-container-room'>
                    <div className='card-one-room'>
                        <p className='card-text text-center card-Property_text'>
                            {RoomName}
                        </p>
                    </div>
                    <div className='card-two-room'>
                        <div className='card-btn-container-room'>
                            <button
                                type='button'
                                className='btn card-btn-edit-room btn-outline-light text-reset'
                                onClick={() =>
                                    history.push(`inventory/${IdEncode}`)
                                }
                            >
                                <i
                                    className='fa fa-pencil-alt card-pencil'
                                    aria-hidden='true'
                                />{' '}
                                {translate('edit')}
                            </button>
                            <button
                                type='button'
                                className='btn card-btn-edit-room btn-outline-light text-reset'
                                onClick={handleClone}
                                disabled={isCloningRoom}
                            >
                                {isCloningRoom ? (
                                    <LoadingForButtons />
                                ) : (
                                    <>
                                        <i
                                            className='far fa-clone'
                                            aria-hidden='true'
                                        />{' '}
                                        {translate('clone')}
                                    </>
                                )}
                            </button>
                            <button
                                type='button'
                                className='btn card-btn-edit-room btn-outline-light text-reset'
                                onClick={handleDelete}
                                disabled={isDeletingRoom}
                            >
                                {isDeletingRoom ? (
                                    <LoadingForButtons />
                                ) : (
                                    <>
                                        <i
                                            className='fa fa-trash card-delete'
                                            aria-hidden='true'
                                        />{' '}
                                        {translate('delete')}
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(CardRoom);
