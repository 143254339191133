import React from 'react';
import './conditionsstatemens.scss';

export default function Conditions() {
    return (
        <div id='privacy-page'>
            <div className='container privacy-page'>
                <div id='title'>
                    <h1 className='text-center'>
                        Terms of Use and Conditions Statement
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Conditions of Use
                        </h4>
                        <p>
                            In keeping with Law 34/2002, of June 11, Information
                            Society Services and Electronic Commerce (LSSI-CE),
                            FAST PAY HOTELS SL informs the user that they are
                            the owner of the website www.fastpayhotels.com. In
                            keeping with what is established in Article 10 of
                            the cited Law, FAST PAY HOTELS SL informs users of
                            the following:
                        </p>
                        <p>
                            This website's owner is FAST PAY HOTELS SL, with Tax
                            ID number B57940066 and with their registered office
                            at PERE ANTONI OLIVER 2B 07014, PALMA DE MALLORCA
                            (ILLES BALEARS) included in the Commercial Register
                            in Volume 2535 sheet 19 page PM77490 and entry 1,
                            The company contact email is:{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                        </p>
                        <p>
                            The owner of this website is FAST PAY HOTELS SL,
                            with Tax ID number B57940066 and registered office
                            at PERE ANTONI OLIVER 2B 07014, PALMA DE MALLORCA
                            (ILLES BALEARS). The company contact email is:{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Users and responsibility regime
                        </p>
                        <p>
                            By browsing, accessing and using FAST PAY HOTELS
                            SL’s website, constitutes the person as a user. As a
                            result of browsing FAST PAY HOTELS SL’s website, the
                            said user accepts all the conditions of use
                            established herein, notwithstanding the application
                            of the corresponding obligatory regulations
                            applicable in each case.
                        </p>
                        <p>
                            FAST PAY HOTELS SL’s website provides a broad range
                            of information, services and data. The user accepts
                            their personal responsibility for the correct use of
                            this website. This responsibility includes:
                        </p>
                        <ul>
                            <li>
                                The veracity and legality of the information
                                provided by the user in the formulas requested
                                by FAST PAY HOTELS SL in order to access certain
                                contents or services offered on the website.{' '}
                            </li>
                            <li>
                                The use of information, services and data
                                offered by FAST PAY HOTELS SL contrary to what
                                is established in these terms and conditions,
                                the law, morality, good manners and public
                                order, or any other use that might result in the
                                violation of third-party rights or affect the
                                proper operation of the website.{' '}
                            </li>
                        </ul>
                        <p className='font-weight-bold mt-4'>
                            Policy regarding links and exemption of liability{' '}
                        </p>
                        <p>
                            FAST PAY HOTELS SL is not responsible for the
                            content of any of the websites the user might access
                            through the links included on the website, and in no
                            case will they examine or exercise any type of
                            control over the content of other websites. In
                            addition, they cannot guarantee the technical
                            availability, accuracy, veracity, validity or
                            legality of websites pertaining to others that may
                            be accessed via the links.
                        </p>
                        <p>
                            FAST PAY HOTELS SL has taken all the necessary
                            measures to avoid any sort of harm coming to the
                            users of their website that may result from use of
                            the same. Therefore, FAST PAY HOTELS SL is not
                            responsible, under any circumstances, for the
                            possible damage that the user might suffer as the
                            result of their Internet use.
                        </p>
                        <p className='font-weight-bold mt-4'>Modifications</p>
                        <p>
                            FAST PAY HOTELS SL reserves the right to make any
                            amendments to the content of its website it deems
                            appropriate, without prior notice. This includes the
                            content of the website, its Terms and Conditions of
                            Use, or the General Terms and Conditions of
                            Contracting. Such changes may be made via their
                            website in any way permissible by law, and shall be
                            binding during the time in which they remain
                            published on the website and until they are modified
                            by subsequent amendments.
                        </p>

                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Data protection
                        </h4>
                        <p className='font-weight-bold mt-4'>
                            Ownership of the website and acceptance of the Terms
                            and Conditions
                        </p>
                        <p>
                            The reading and acceptance of these terms and
                            conditions (hereinafter, the "Terms and Conditions")
                            is a necessary condition to use the website
                            www.fastpayhotels.com (hereinafter, the "Website").
                        </p>
                        <p>
                            The Terms and Conditions are General Contracting
                            Conditions, of a contractual and obligatory nature
                            and govern the relationship between all users of the
                            Website (hereinafter, the "User") and Fastpayhotels,
                            S.L. (hereinafter, "Fastpayhotels"), a Spanish
                            company, with registered address at Calle Reina
                            Maria Cristina 2, 2º P.O 07004, registered in the
                            Commercial Registry of Palma de Mallorca in Volume
                            2635, Sheet 19, Page PM-77490, with registered/tax
                            number B-57940066 and e-mail: info@fastpayhotels.com
                        </p>
                        <p>
                            The Terms and Conditions of this Website and its
                            services implies the full acceptance of the
                            provisions included in these Terms and Conditions
                            according to the last version published by
                            Fastpayhotels at the time the User accesses to the
                            Website.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Use of the Website
                        </p>
                        <p>
                            Users shall not use any of the materials and
                            information contained on this Website for purposes
                            that are illicit or expressly prohibited in these
                            Terms and Conditions, as well as the particular
                            conditions that may be enabled, or against the
                            rights and interests of Fastpayhotels, its members
                            or third parties and shall be liable to them in the
                            event of infringement or failure to comply with such
                            obligations or, in any way (including the
                            introduction or dissemination of "computer viruses")
                            damage, disable, overburden, impair or prevent the
                            normal use of the materials and information
                            contained on the Website, the information systems or
                            the documents, files and all kinds of content stored
                            on any computer equipment (hacking) of
                            Fastpayhotels, its members or any User of the
                            Website.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Intellectual and industrial property
                        </p>
                        <p>
                            The Website, including but not limited to its
                            design, structure and distribution, text and
                            content, logos, buttons, images, drawings,
                            trademarks, commercial names, source code,
                            creativities, as well as all intellectual and
                            industrial property rights and any other distinctive
                            sign, belong or are assigned to Fastpayhotels, or,
                            where applicable, to the natural or legal persons as
                            authors or holders of the rights or their licensors,
                            without it being understood that the use and access
                            to the Website and the use of its services gives the
                            User any right to the aforementioned elements.
                        </p>
                        <p>
                            The reproduction, use, alteration, distribution or
                            public communication of the Website for uses other
                            than the legitimate information of the Users is
                            prohibited.
                        </p>
                        <p className='font-weight-bold mt-4'>Cookie policy</p>
                        <p>
                            The Website may use cookies when a User accesses it.
                        </p>
                        <p>
                            Cookies are data files that are downloaded to User’s
                            computer when the User accesses some websites and it
                            allows to store and retrieve data and other
                            technical functions, such as saving browsing
                            preferences, collecting statistical information,
                            information about the equipment, can be used to
                            recognise users by offering them the most
                            appropriate content according to your habits.
                        </p>
                        <p>
                            However, a cookie cannot erase or read information
                            from the computer, nor can it identify a person, but
                            it recognizes a combination of
                            computer-browser-user.
                        </p>
                        <p>
                            This Website uses only its own technical cookies,
                            whose purpose is to allow communication between this
                            Website and the User's device. Using the default
                            configuration of this Web Site, the User accepts
                            that cookies may be installed in their terminal and
                            that they allow us to know the following
                            information:
                        </p>
                        <ul>
                            <li>
                                The User's login to keep the session active on
                                the Website.
                            </li>
                            <li>
                                The format of the Web site is preferable for
                                access from mobile devices.
                            </li>
                            <li>
                                The latest searches carried out on the services
                                of the Website and personalisation data for
                                these services.
                            </li>
                        </ul>
                        <p>
                            Users can accept, revoke consent or delete cookies,
                            as well as delete their browsing data (including
                            cookies) installed on their computers by configuring
                            the browser options that they use.
                        </p>
                        <p>
                            Below are the steps required to configure the use of
                            cookies depending on the browser used:
                        </p>
                        <ul>
                            <li>
                                Internet Explorer: Tools -> Internet Options ->
                                Privacy -> Settings. For more information, you
                                can see Microsoft support or the browser Help.
                            </li>
                            <li>
                                Firefox: Tools -> Options -> Privacy -> History
                                -> Custom Settings. For more information, you
                                can see Mozilla support or the browser's Help.
                            </li>
                            <li>
                                Chrome: Settings -> Show advanced options ->
                                Privacy -> Content settingsFor more information,
                                you can see Google support or browser help.
                            </li>
                            <li>
                                Safari: Preferences -> Security. For more
                                information, you can seet Apple's support or the
                                browser's Help.
                            </li>
                        </ul>
                        <p>
                            Some services of the Website may be not available if
                            you block use of cookies in your browser.
                        </p>
                        <p className='font-weight-bold mt-4'>Privacy Policy</p>
                        <p>
                            Reading and accepting Fastpayhotels' privacy policy
                            (hereinafter, "the Privacy Policy") is a necessary
                            condition for using the Site.
                        </p>
                        <p>
                            This Privacy Policy governs the collection and use
                            of information from Users of the Website from the
                            effective date shown at the end of these Terms of
                            Use. It does not apply to any other URL accessed
                            from the Website, which will have its own policy
                            regarding data collection and use.
                        </p>
                        <p>
                            Please read this Privacy Policy carefully before
                            using the Website and providing any personal
                            information. It will help you make informed
                            decisions.
                        </p>
                        <p>
                            Fastpayhotels complies with the legislation in force
                            in Spain and in the European Union, and specifically
                            with Organic Law 3/2018 of 5 December on the
                            Protection of Personal Data and the guarantee of
                            digital rights, and European Regulation 2016/679 of
                            27 April on the protection of individuals with
                            regard to the processing of personal data and on the
                            free movement of such data and repealing Directive
                            95/46/EC on the protection of personal data
                            (hereinafter the "GDPR") in the processing of the
                            personal data of its Users. Fastpayhotelsin its
                            condition as the Data Controllerof the processing of
                            the personal data (hereinafter the "Data
                            Controller") only processes the personal data
                            obtained from the forms voluntarily filled in by the
                            Users under the principles of legality, loyalty and
                            transparency, purpose limitation, data minimisation,
                            accuracy, limitation of the period of conservation,
                            integrity and confidentiality and proactive
                            responsibility.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            IDENTITY AND CONTACT DETAILS OF THE DATA CONTROLLER
                        </p>
                        <p>
                            Fastpayhotels, S.L. with registered address at Calle
                            Reina Maria Cristina 2, 2º, P.O. Box 07004,
                            registered/tax number B-57940066, with e-mail
                            info@fastpayhotels.com and telephone +34 971 416
                            770.
                        </p>
                        <p>
                            The User may contact the Data Protection Officer of
                            the Data Controller at the following e-mail address
                            info@fastpayhotels.com and by postal mail to the
                            address of the Data Controller.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            DATA CATEGORIES, PURPOSE AND LEGAL BASIS OF
                            PROCESSING
                        </p>
                        <p>
                            The Data Controller processes the following
                            categories of User data:
                        </p>
                        <p>
                            Contact details: name, surname, identity card, age,
                            tax number, postal address, e-mail, telephone
                            number, IP, country, device and language.
                        </p>
                        <p>
                            Preferences: information that the User provides
                            about his/her preferences, for example, the type of
                            services hired by the User.
                        </p>
                        <p>
                            Use of the website and communications: how you use
                            our website as well as the information collected
                            through cookies that the User may consult in our
                            Cookies Policy. Some cookies may affect the privacy
                            of the User by allowing their content to be related
                            to certain personal data, including your tastes and
                            preferences.
                        </p>
                        <p>
                            The Data Controller will only process the personal
                            data provided by the User and will not process
                            special categories of personal data. The main
                            purpose we process the User's information is to
                            provide the services expressly requested by the
                            User. The Data Controller process the data of the
                            Users with the following purposes:
                        </p>
                        <ol type='a'>
                            <li>
                                The provision of services expressly requested by
                                the User, as well as to manage payments and
                                orders.
                            </li>
                            <li>
                                Provide support services and assistance to
                                Users, notify service or account issues (e.g.,
                                warn of misconduct).
                            </li>
                            <li>
                                Carrying out commercial, operational and
                                statistical activities. No automated decisions
                                will be taken based on this profile.
                            </li>
                            <li>
                                Advice and referral of newsletters and other
                                commercial communications by e-mail about the
                                Data Controller and the specific provision of
                                services to its sector. The User may indicate
                                their opposition to receiving such a newsletter
                                at any time. The User expressly consents to
                                receive these e-mails, although they may
                                unsubscribe (i) at the link specified in any
                                communication sent by e-mail, (ii) by sending an
                                e-mail to info@fastpayhotels.com. However, this
                                will not imply the cancellation of certain
                                communications of a non-commercial nature, such
                                as messages relating to the User's access to the
                                Website.
                            </li>
                        </ol>
                        <p>
                            The legal basis for the processing of the User's
                            data by the Data Controller may process the User's
                            data under the legal basis of legitimate interest.
                            This legitimate interest consists of maintaining and
                            promoting commercial and professional relations
                            between the Controller and the User and preventing
                            fraud.
                        </p>
                        <p>
                            The communication of personal data by the User is
                            voluntary, although it is a contractual condition
                            for the acquisition of services and products
                            marketed by the Data Controller. Thus, the refusal
                            of the User will mean the impossibility of providing
                            certain services to the User by the Data Controller.
                        </p>
                        <p>
                            The User may withdraw consent at any time, without
                            this affecting the lawfulness of the processing
                            based on the consent prior to its withdrawal.
                        </p>
                        <p>
                            The Data Controller will always ask for consent and
                            inform the User before using their data for any
                            purpose other than those described in this Privacy
                            Policy.
                        </p>
                        <p className='font-weight-bold mt-4'>DATA TRANSFER</p>
                        <p>
                            The Data Controller informs and expressly guarantees
                            the User that their personal data will not be
                            transferred to third parties under any
                            circumstances, unless legally obliged to do so.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            DATA RETENTION PERIOD
                        </p>
                        <p>
                            The personal data provided will be kept for an
                            indefinite period of time, insofar as the Data
                            Controller needs it in order to use it for the
                            purpose for which they were obtained:
                        </p>
                        <ol type='a'>
                            <li>
                                As long as a contractual and/or commercial
                                relationship exists.
                            </li>
                            <li>
                                As long as the deletion, opposition and/or
                                limitation of the processing of the data by the
                                User is not requested.
                            </li>
                            <li>As required by law.</li>
                        </ol>
                        <p className='font-weight-bold mt-4'>
                            OTHER USER RIGHTS
                        </p>
                        <p>
                            The User can obtain confirmation as to whether the
                            Data Controller is processing personal data
                            concerning him/her and access to such data.
                        </p>
                        <p>
                            The User may request the Data Controller to exercise
                            the rights of access to the personal data, its
                            rectification, deletion, limitation of its
                            processing, opposition and portability, by sending
                            an e-mail to the address [...], attaching a copy of
                            the official identification document of the User and
                            clearly expressing the right he/she want to
                            exercise. The User may request the forms for the
                            exercise of the aforementioned rights from e-mail
                            address.
                        </p>
                        <p>
                            The User may also lodge a complaint with a
                            supervisory authority.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            PRIVACY OF MINORS
                        </p>
                        <p>
                            The Data Controller’s services is for adults and
                            does not voluntarily collect or maintain any type of
                            personal data from minors. If the Data Controller
                            becomes aware that the User is a minor, it will
                            proceed to the immediate cancellation of their
                            personal data and of any requests or orders made by
                            him or her, if applicable.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            SECURITY MEASURES TAKEN BY THE DATA CONTROLLER TO
                            ENSURE THE CONFIDENTIALITY OF THE DATA
                        </p>
                        <p>
                            Data Controller adopts the necessary technical and
                            organisational measures to prevent the loss, misuse,
                            alteration, unauthorised access and theft of the
                            personal data provided, as well as to guarantee a
                            level of security appropriate to the risk, taking
                            into account the state of the technology, the costs
                            of implementation, the nature, scope, context and
                            purposes of the processing, as well as the risks of
                            variable probability and seriousness for the rights
                            and freedoms of natural persons.
                        </p>
                        <p>
                            In the use of the User's personal data, the Data
                            Controller undertakes to respect confidentiality and
                            to use them in accordance with the purpose of their
                            processing, as well as to fulfil its obligation to
                            safeguard them and to adopt all technical and
                            organisational security measures to ensure a level
                            of security appropriate to the risk.
                        </p>
                        <p>
                            The Data Controller takes particular account of the
                            risks involved in data processing, in particular as
                            a result of the accidental or unlawful destruction,
                            loss or alteration of personal data transmitted,
                            stored or otherwise processed, or the unauthorised
                            communication of or access to such data. The Data
                            Controller takes measures to ensure that any person
                            acting under its authority who has access to the
                            User's personal data may only process such data on
                            the instructions of the Data Controller, unless it
                            is obliged to do so by virtue of the applicable law.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Applicable law and competent jurisdiction
                        </p>
                        <p>
                            These Terms and Conditions shall be governed by
                            Spanish law, unless otherwise determined by
                            legislation applicable to the User.
                        </p>
                        <p>
                            The parties submit themselves, at their choice, for
                            the resolution of the conflicts and renounce any
                            other jurisdiction, to the courts of Palma de
                            Mallorca, unless the legislation of imperative
                            application to the User determines otherwise.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Effective date and modifications to the Terms and
                            Conditions
                        </p>
                        <p>
                            These Terms of Use were last updated on 11/12/2020.
                        </p>
                        <p>
                            Fastpayhotels reserves the right to unilaterally
                            modify, correct or change these Terms and
                            Consditions at any time. When this happens, the new
                            Terms and Conditions will be published on the
                            Website as well as the date on which they will take
                            effect in the end of these Terms and Conditions.
                        </p>
                        <p>
                            We recommend you visit this page regularly to keep
                            up to date with any changes that may occur.
                        </p>
                        <p>
                            If you do not accept the modifications of these
                            Terms and Conditions, do not use the Website. Your
                            continued use of the Website constitutes your
                            acceptance of these Terms and Conditions and its
                            possibles updates.
                        </p>

                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Cookie policy{' '}
                        </h4>
                        <p>
                            By browsing our web page you are tacitly accepting
                            the conditions of use of our Cookie Policy. This
                            policy details the conditions of use of cookies and
                            may be updated regularly. We would emphasize that,
                            certain functions of the services in this web page
                            could not work correctly, or could not be available,
                            if certain cookies are disabled.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            What are cookies?
                        </p>
                        <p>
                            Cookies are data files that are downloaded to your
                            computer when you access certain web pages and that
                            allow the web pages to store and retrieve data and
                            other technical features, such as saving browsing
                            preferences, collecting statistical information, and
                            information about the equipment. Cookies can be used
                            to recognize users by offering them the most
                            appropriate content according to their habits.
                            However, a cookie cannot delete or read information
                            from the computer, nor does it identify a person,
                            but rather recognizes a combination of
                            computer-browser-user.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            What types of cookies are used in this web page?
                        </p>
                        <p>
                            The web page https://www.fastpayhotels.com uses
                            exclusively its own technical cookies, which are
                            files installed in the user's device from our own
                            managed domain in order to make possible the
                            communication between the site and user’s device.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            How cookies are used?
                        </p>
                        <p>
                            By accepting the default settings of this web page,
                            the user is accepting that 'cookies' can be
                            installed in her/his terminal, allowing us to know
                            the following information:
                        </p>
                        <ul>
                            <li className='section__paragraph--policies'>
                                The 'login' of the user to keep the active
                                session on the web.
                            </li>
                            <li>
                                The preferred web format when accessing from
                                mobile devices.
                            </li>
                            <li>
                                The latest searches made in the web services and
                                personalization data of these services.
                            </li>
                        </ul>
                        <p className='font-weight-bold mt-4'>
                            How to manage cookies for the browser?
                        </p>
                        <p>
                            You can accept, revoke consent or delete cookies, as
                            well as delete your browsing data (including
                            cookies) installed on your computer by configuring
                            the browser options you are using. The steps
                            required to configure the use of cookies are
                            detailed below, depending on the browser used:
                        </p>
                        <ul>
                            <li className='section__paragraph--policies'>
                                Internet Explorer: Tools -> Internet Options ->
                                Privacy -> Settings. For more information, you
                                can consult Microsoft support or browser Help.
                            </li>
                            <li>
                                Firefox: Tools -> Options -> Privacy -> History
                                -> Custom Settings. For more information, you
                                can consult Mozilla support or browser Help.
                            </li>
                            <li>
                                Chrome: Settings -> Show advanced options ->
                                Privacy -> Content settings. For more
                                information, you can consult Google support or
                                browser Help.
                            </li>
                            <li>
                                Safari: Preferences -> Security. For more
                                information, you can consult Apple support or
                                browser Help.
                            </li>
                        </ul>
                        <p>
                            Certain functions of the services in this web page
                            could not work correctly, or could not be available,
                            if certain cookies are disabled.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Data transfers to third countries
                        </p>
                        <p>
                            For information on transfers to third countries
                            that, where appropriate, are carried out by the
                            third parties identified in this cookie policy and
                            in their corresponding policies see the links
                            provided in the "Third-party cookies" section.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Data retention period.
                        </p>
                        <p>
                            We will keep your personal data to the extent that
                            we need to use it for the purpose for which it was
                            obtained:
                        </p>
                        <ul>
                            <li className='section__paragraph--policies'>
                                As long as there is a contractual and / or
                                commercial relationship.
                            </li>
                            <li>
                                As long as you do not exercise your right to
                                delete, oppose and / or limit the processing of
                                your data.
                            </li>
                            <li>Due to legal requirement. </li>
                        </ul>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            SSL (Secure Sockets Layer) Certificate{' '}
                        </h4>
                        <p>
                            The SSL CERTIFICATE provides authentication, privacy
                            and security of information between FAST PAY HOTELS
                            SL and the user.
                        </p>
                        <p>
                            FAST PAY HOTELS SL has an SSL security certificate
                            in order to perform safe connections.
                        </p>
                        <p>
                            In this process, several parameters are established
                            to ensure safe connections. These are made using
                            pre-established keys, encoding and decoding all
                            information sent until the connection is closed.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Intellectual and Industrial Property{' '}
                        </h4>
                        <p>
                            FAST PAY HOTELS SL in its own right or as a
                            franchisee is the holder of all intellectual and
                            industrial property rights for its webpage. In
                            addition, all elements contained therein (by way of
                            illustration, images, sound, audio, video, software
                            or texts; brands or logos, colour combinations,
                            layout and design, selection of materials used,
                            computer programs needed for correct operation,
                            access and use, etc.) are held by FAST PAY HOTELS
                            SL. As a result, these works are protected as
                            intellectual property by Spanish Law; in this case,
                            both Spanish and European law in this area are
                            applicable, as are international agreements on such
                            matters to which Spain adheres.
                        </p>
                        <p>
                            All rights reserved. By virtue of what is
                            established in the Law on Intellectual Property, the
                            reproduction, distribution or public communication
                            of all or part of the contents of this webpage
                            (including the means by which they are made
                            available) for commercial ends, in any form or by
                            any technical means are expressly forbidden without
                            the authorization of FAST PAY HOTELS SL.
                        </p>
                        <p>
                            The user promises to respect the Intellectual and
                            Industrial Property Rights held by FAST PAY HOTELS
                            SL. Users may view the elements on the site or even
                            print them out, copy them or store them on the hard
                            drive of their computer or in any other physical
                            format, as long as it is solely and exclusively for
                            their own personal and private use. The user must
                            abstain from erasing, altering, evading or
                            manipulating any protective device or security
                            system installed on FAST PAY HOTELS SL´s web pages.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Legal action, applicable legislation and
                            jurisdiction
                        </p>
                        <p>
                            FAST PAY HOTELS SL reserves the right to begin the
                            civil or criminal proceedings which it deems
                            appropriate due to the misuse of its website and
                            contents thereof, or due to the breach of these
                            terms and conditions.
                        </p>
                        <p>
                            The relationship between the user and the service
                            provider shall be governed by current legislation
                            which applies in Spanish territory. Should any
                            dispute arise, the parties may submit their disputes
                            to arbitration or proceed through the regular legal
                            channels, in compliance with the applicable rules on
                            jurisdiction and competence. The registered office
                            of FAST PAY HOTELS SL is in Spain.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Privacy Policy for Social Networks
                        </h4>
                        <p>
                            In keeping with what is established in applicable
                            regulations on the Protection of Personal
                            Information and Law 34/2002 of 11 July on
                            Information Society Services and Electronic Commerce
                            (LSSI-CE), FAST PAY HOTELS SL hereby informs users
                            that they have created a profile on the social
                            network(s) Facebook, Twitter, Instagram, and
                            LinkedIn with the principal aim of advertising their
                            products and services.
                        </p>
                        <p>Information for FAST PAY HOTELS SL:</p>
                        <ul>
                            <li>Tax Identification number (CIF): B57940066</li>
                            <li>
                                {' '}
                                ADDRESS: PERE ANTONI OLIVER 2B 07014, PALMA DE
                                MALLORCA (ILLES BALEARS)
                            </li>
                            <li>
                                {' '}
                                EMAIL:{' '}
                                <a
                                    className='linkStyle'
                                    href='mailto:info@fastpayhotels.com'
                                >
                                    info@fastpayhotels.com
                                </a>
                            </li>
                            <li>
                                WEB ADDRESS:{' '}
                                <a
                                    className='linkStyle'
                                    href='https://www.fastpayhotels.com'
                                >
                                    www.fastpayhotels.com
                                </a>
                            </li>
                        </ul>
                        <p>
                            The user has a profile on the same Social Network
                            and has decided to join the page created by FAST PAY
                            HOTELS SL, thus showing their interest in the
                            information made public through the Network. By
                            joining our page, you are authorising our use of any
                            personal information published on your profile.
                        </p>
                        <p>
                            The user can access the privacy policies of the
                            Social Network at any time, or can configure their
                            profile so as to guarantee their privacy.
                        </p>
                        <p>
                            FAST PAY HOTELS SL has access to and handles users’
                            public information, especially their contact name.
                            This information is only used within the Social
                            Network. It is not added to any sort of file.
                        </p>
                        <p>
                            With regard to the right to access, rectification,
                            cancellation or opposition that you possess and that
                            can be exercised before FAST PAY HOTELS SL in
                            keeping with the RGPD, you must take into account
                            the following:
                        </p>
                        <ul>
                            <li>
                                Access: This will be defined by the
                                functionality of the Social Network and the
                                capacity for accessing information from user
                                profiles.
                            </li>
                            <li>
                                Rectification: Can only be satisfied in relation
                                to information under the control of FAST PAY
                                HOTELS SL. For example, eliminating comments
                                posted on the company’s page. Normally, this
                                right must be exercised before the Social
                                Network.
                            </li>
                            <li>
                                Cancellation and/or Opposition: As in the
                                previous case, this can only be satisfied in
                                relation to information that is under the
                                control of FAST PAY HOTELS SL. For example,
                                ceasing to be connected to the profile.
                            </li>
                        </ul>
                        <p>
                            FAST PAY HOTELS SL will perform the following
                            actions:
                        </p>
                        <ul>
                            <li>Access public profile information.</li>
                            <li>
                                Publish any information already available on the
                                FAST PAY HOTELS SL web page on their user
                                profile.
                            </li>
                            <li>
                                Send personal and individual messages through
                                the channels of the Social Network.
                            </li>
                            <li>
                                Update the status of their page, which will be
                                published on their user profile.
                            </li>
                        </ul>
                        <p>
                            The user can always control their connections,
                            eliminate content that ceases to interest them, and
                            restrict who they share their connections with. In
                            order to do so, they must access their privacy
                            configurations.
                        </p>
                        <p className='font-weight-bold mt-4'>Publications</p>
                        <p>
                            Once they have joined the FAST PAY HOTELS SL page,
                            the user will be able to post comments, links,
                            images, photographs or any other type of multimedia
                            content supported by the Social Network on the page.
                            In any case, the user must have ownership over this
                            content, they must have the authorship rights and
                            intellectual property rights to the same, or have
                            the consent of the affected third parties. Any
                            content posted on the page, whether text, graphics,
                            videos, etc. that is in violation of morality,
                            ethics, good taste or decorum is expressly
                            prohibited, as is any that violates intellectual or
                            industrial property rights, image rights or the Law.
                            Under such circumstances, FAST PAY HOTELS SL
                            reserves the right to immediately remove the
                            content, and may request that the user be
                            permanently blocked.
                        </p>
                        <p>
                            FAST PAY HOTELS SL is not responsible for any
                            content freely published by a user.
                        </p>
                        <p>
                            The user must keep in mind that their posts will be
                            viewed by other users. As a result, they are the
                            principal person in charge of protecting their own
                            privacy.
                        </p>
                        <p>
                            Images posted on the page will not be stored in any
                            sort of file by FAST PAY HOTELS SL, but they will
                            remain on the Social Network.
                        </p>
                        <p className='font-weight-bold mt-4'>
                            Contests and Promotions
                        </p>
                        <p>
                            FAST PAY HOTELS SL reserves the right to organise
                            contests and promotions that users connected to
                            their page can take part in. When the Social Network
                            is used for these contests and promotions, the terms
                            and conditions will be published on the same. This
                            will always be in compliance with the LSSI-CE and
                            any other applicable regulations.
                        </p>
                        <p>
                            The Social Network does not sponsor, support or
                            administer any of our promotions in any way, nor are
                            they associated with any of them.
                        </p>
                        <p className='font-weight-bold mt-4'>Publicity</p>
                        <p>
                            FAST PAY HOTELS SL will use the Social Network to
                            advertise their products and services. In any case,
                            if they decide to handle your contact information
                            for direct commercial research actions, they will
                            always do so in keeping with the RGPD and the
                            LSSI-CE.
                        </p>
                        <p>
                            Recommending FAST PAY HOTELS SL’s page to other
                            users so that they too can enjoy the promotions
                            offered therein or remain informed of its activity
                            will not be considered publicity.
                        </p>
                        <p>
                            Below is a link to the Social Network’s privacy
                            policy:
                        </p>
                        <ul>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='https://twitter.com/privacy'
                                >
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='https://www.facebook.com/privacy/explanation'
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='http://instagram.com/about/legal/privacy/'
                                >
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='http://www.google.com/intl/es/policies/privacy/'
                                >
                                    Google +
                                </a>
                            </li>
                            <li>
                                <a
                                    className='linkStyle'
                                    href='http://www.linkedin.com/legal/privacy-policy?trk=hb_ft_priv'
                                >
                                    LinkedIn
                                </a>
                            </li>
                        </ul>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Informative Statement on GDPR and LSSI-CE
                        </h4>
                        <p>
                            In accordance with the provisions of the current
                            legislation on Personal Data Protection, we hereby
                            inform you that your data will be incorporated into
                            the processing system owned by FAST PAY HOTELS SL,
                            with Tax ID number B57940066 and its registered
                            office at PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) in order to facilitate,
                            attend to and meet the commitments established
                            between the two parties. In keeping with applicable
                            regulations, FAST PAY HOTELS SL informs users that
                            their information will be kept during the necessary
                            period in order to fulfil the aforementioned
                            conditions.
                        </p>
                        <p>
                            FAST PAY HOTELS SL also informs it users that it
                            will process the data in a lawful, honest,
                            transparent, suitable, relevant, limited, accurate
                            and updated manner. FAST PAY HOTELS SL therefore
                            undertakes to take all reasonable measures to ensure
                            that they will be deleted or rectified without delay
                            if they become inaccurate.
                        </p>
                        <p>
                            In accordance with the rights conferred on you by
                            the current legislation on Personal Data Protection,
                            you may exercise the rights of access,
                            rectification, limitation of processing, deletion,
                            portability and opposition by sending your request
                            to the postal address indicated above or by writing
                            an email to{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            In addition and in keeping with what is established
                            in Article 21 of Law 34/2002, of June 11, on
                            Information Society Services and Electronic Commerce
                            (LSSI-CE), we also request your express consent to
                            send you publicity on our products or offers that we
                            think may be of interest to you, whether via email
                            or by any other equivalent means of communication. □
                            I have read these terms and conditions and am
                            willing to receive publicity or promotional
                            information from FAST PAY HOTELS SL. □ I have read
                            these terms and conditions and do not wish to
                            receive publicity or promotional information from
                            FAST PAY HOTELS SL.
                        </p>
                        <p>
                            We hereby inform you that you can revoke your
                            consent to receive commercial mail by sending an
                            email to the following address:{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <ul>
                            <li>First and last name or company name:</li>
                            <li>Personal ID number or Tax ID number:</li>
                            <li>Signature of the person concerned.</li>
                        </ul>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Clause to enter on the web form with advertising
                        </h4>
                        <p>
                            In accordance with the provisions of the current
                            legislation on Personal Data Protection, we hereby
                            inform you that your data will be incorporated into
                            the processing system owned by FAST PAY HOTELS SL,
                            with Tax ID number B57940066 and its registered
                            office at PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) in order to meet your
                            inquiries and send commercial communications that
                            may be of interest to you. In keeping with
                            applicable regulations, FAST PAY HOTELS SL informs
                            users that their information will be kept during the
                            necessary period in order to fulfil the
                            aforementioned conditions.
                        </p>
                        <p>
                            FAST PAY HOTELS SL also informs it users that it
                            will process the data in a lawful, honest,
                            transparent, suitable, relevant, limited, accurate
                            and updated manner. FAST PAY HOTELS SL therefore
                            undertakes to take all reasonable measures to ensure
                            that they will be deleted or rectified without delay
                            if they become inaccurate.
                        </p>
                        <p>
                            In accordance with the rights conferred on you by
                            the current legislation on Personal Data Protection,
                            you may exercise the rights of access,
                            rectification, limitation of processing, deletion,
                            portability and opposition by sending your request
                            to the postal address indicated above or by writing
                            an email to{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            In addition and in keeping with what is established
                            in Article 21 of Law 34/2002, of June 11, on
                            Information Society Services and Electronic Commerce
                            (LSSI-CE), we also request your express consent to
                            send you publicity on our products or offers that we
                            think may be of interest to you, whether via email
                            or by any other equivalent means of communication. □
                            I have read these terms and conditions and am
                            willing to receive publicity or promotional
                            information from FAST PAY HOTELS SL. □ I have read
                            these terms and conditions and do not wish to
                            receive publicity or promotional information from
                            FAST PAY HOTELS SL.
                        </p>
                        <p>
                            We hereby inform you that you can revoke your
                            consent to receive commercial mail by sending an
                            email to the following address:{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            You can go to the competent Control Authority to
                            present the claim that you consider appropriate.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Newsletter clause
                        </h4>
                        <p>
                            In accordance with the provisions of the current
                            legislation on Personal Data Protection, we hereby
                            inform you that your data will be incorporated into
                            the processing system owned by FAST PAY HOTELS SL,
                            with Tax ID number B57940066 and its registered
                            office at PERE ANTONI OLIVER 2B 07014, PALMA DE
                            MALLORCA (ILLES BALEARS) in order to meet your
                            inquiries. In keeping with applicable regulations,
                            FAST PAY HOTELS SL informs users that their
                            information will be kept during the necessary period
                            in order to fulfil the aforementioned conditions.
                        </p>
                        <p>
                            FAST PAY HOTELS SL also informs it users that it
                            will process the data in a lawful, honest,
                            transparent, suitable, relevant, limited, accurate
                            and updated manner. FAST PAY HOTELS SL therefore
                            undertakes to take all reasonable measures to ensure
                            that they will be deleted or rectified without delay
                            if they become inaccurate.
                        </p>
                        <p>
                            In accordance with the rights conferred on you by
                            the current legislation on Personal Data Protection,
                            you may exercise the rights of access,
                            rectification, limitation of processing, deletion,
                            portability and opposition by sending your request
                            to the postal address indicated above or by writing
                            an email to{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            You can go to the competent Control Authority to
                            present the claim that you consider appropriate.
                        </p>
                        <div className='icon'>
                            <span className='fa fa-bookmark-o fa-2x' />
                        </div>
                        <h4
                            className='title after-icon'
                            style={{ paddingTop: '8px' }}
                        >
                            Newsletter clause
                        </h4>
                        <p>
                            In accordance with the provisions of the current
                            legislation on Personal Data Protection, we hereby
                            inform you that the email address that you have
                            provided us will be incorporated into the processing
                            system owned by FAST PAY HOTELS SL, with Tax ID
                            number B57940066 and its registered office at PERE
                            ANTONI OLIVER 2B 07014, PALMA DE MALLORCA (ILLES
                            BALEARS) and stored in a file for which FAST PAY
                            HOTELS SL is responsible, for the purposes of
                            processing your subscription request and to be able
                            to regularly send you our Newsletter.
                        </p>
                        <p>
                            In keeping with applicable regulations, FAST PAY
                            HOTELS SL informs users that their information will
                            be kept during the necessary period in order to
                            fulfil the aforementioned conditions
                        </p>
                        <p>
                            Unless we are notified to the contrary, we will
                            assume that your details have not been modified,
                            that you agree to notify us if they change, and that
                            we have your consent to use them for the
                            aforementioned purposes.
                        </p>
                        <p>
                            FAST PAY HOTELS SL hereby states that it will
                            process the data in a lawful, honest, transparent,
                            suitable, relevant, limited, accurate and updated
                            manner. FAST PAY HOTELS SL therefore undertakes to
                            take all reasonable measures to ensure that it will
                            be deleted or rectified without delay when it is
                            inaccurate.
                        </p>
                        <p>
                            In accordance with the rights conferred on you by
                            the current Personal Data Protection legislation,
                            you may exercise the rights of access,
                            rectification, limitation of processing, deletion,
                            portability and objection to the processing of your
                            personal data and the consent given for its
                            processing, by sending your request to the postal
                            address indicated above or by writing an email to{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            Unless you do not inform us otherwise, we will
                            understand that your data have not been modified,
                            that you undertake to notify us of any changes, and
                            that in accordance with article 21.1 of Law 34/2002
                            on information society services and electronic
                            commerce, once your subscription request has been
                            made, we have your consent to process your data
                            according to the aforementioned purposes.
                        </p>
                        <p>
                            Likewise, we hereby inform you that you can revoke
                            your consent, thus cancelling your subscription to
                            the Newsletter, by sending an email to the following
                            address:{' '}
                            <a
                                className='linkStyle'
                                href='mailto:info@fastpayhotels.com'
                            >
                                info@fastpayhotels.com
                            </a>
                            .
                        </p>
                        <p>
                            You may contact the competent Supervisory Authority
                            to submit any claim you deem appropriate
                        </p>
                        <p>
                            Confirmation of subscription implies acceptance of
                            this clause.
                        </p>
                    </div>
                    <a href='https://www.fastpayhotels.com/'>
                        <button
                            type='button'
                            className='button-submit conditions__button'
                        >
                            Home
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
