//en orden alfabético
const accountInfo = '/account-info';
const accountSection = '/account';
const contact = '/contact';
const cookieConsent = '/cookie-consent';
const fastCheckIn = '/fast-check-in';
const forgotPassword = '/forgot-my-password';
const landingPrivate = '/owner-portal';
const landingPublic = '/';
const login = '/login';
const paymentTerms = '/payment-terms';
const privacyPolicy = '/privacy-policy';
const serviceDesk = '/service-desk';
const settings = '/settings';
const signUp = '/sign-up';
const contactForm = `${contact}/contact-form`;

export default {
    accountInfo,
    accountSection,
    contact,
    cookieConsent,
    fastCheckIn,
    forgotPassword,
    landingPrivate,
    landingPublic,
    login,
    paymentTerms,
    privacyPolicy,
    serviceDesk,
    settings,
    signUp,
    contactForm,
};
