import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import useTranslate from '../../customHooks/useTranslate';

const Loading = () => {
    return (
        <div className='loading__container'>
            <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
                className='spinner--typeahead'
            />
            <p className='loading__text'>{`${useTranslate('loading')}`}</p>
        </div>
    );
};

export default Loading;
