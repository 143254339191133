import React from 'react';
import PerRoom from './PerRoom';
import PerPerson from './PerPerson';
import PerOccupancy from './PerOccupancy';
import { getRateModelKey } from '../ariHelpers';

const Rates = ({ rates }) => {
    const rateModelKey = getRateModelKey(rates);

    const ConditionalCard = models[rateModelKey].component(rates);

    return ConditionalCard;
};

const models = {
    occupancy: {
        subTitle: 'per_occupancy',
        component: ratesData => {
            const { occupancies } = ratesData;
            return <PerOccupancy occupancies={occupancies} />;
        },
    },
    room: {
        subTitle: 'per_room',
        component: ratesData => {
            const {
                // aat = amount after tax
                // abt = amount before tax
                room: { aat },
            } = ratesData;
            return <PerRoom price={aat} />;
        },
    },
    person: {
        subTitle: 'per_person',
        component: ratesData => {
            const { persons } = ratesData;
            return <PerPerson persons={persons} />;
        },
    },
    noRates: {
        subTitle: 'no_rates',
        component: () => null,
    },
};

export const getRatesSubtittle = rates => {
    const rateModelKey = getRateModelKey(rates);
    const rateModel = models[rateModelKey];
    return rateModel?.subTitle;
};

export default Rates;
