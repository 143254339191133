import api from '../services/api';
import endpoints, { publicEndpoints } from './commonVariables/endpoints';

//local storage
export const tokenKey = 'TOKEN';
export const lsToken = localStorage.getItem(tokenKey);

export const rolKey = 'ROL';
export const rol = localStorage.getItem(rolKey);

export const sidKey = 'SID';
export const IdSystemUser = localStorage.getItem(sidKey);

export const IdHotelOwnerKey = 'IdHotelOwner';
export const IdHotelOwner = localStorage.getItem(IdHotelOwnerKey);
export const HotelInfoKey = 'hotelInfo';

const tokenProdKey = 'tokenProd';
export const tokenProd = localStorage.getItem(tokenProdKey);
export const cacheKey = 'cache';

export const lastPageVisitedKey = 'lastPageVisited';

///// functions
export const isAuthorizedRol = roles => {
    const authorizedUsers = ['HOTELOWNER'];
    const arrayRoles = roles.split(',');
    return arrayRoles.some(rol => authorizedUsers.includes(rol));
};

export const logIn = async logInData => {
    const { data } = await api.post(publicEndpoints.login, logInData);
    const { roles, sid, extendedRoles } = data;
    const [, _idHotelOwner] = extendedRoles.split('|');
    if (isAuthorizedRol(roles)) {
        localStorage.setItem(tokenKey, data.access_token);
        localStorage.setItem(rolKey, data.roles);
        localStorage.setItem(sidKey, sid);
        localStorage.setItem(IdHotelOwnerKey, _idHotelOwner);
        return true;
    } else {
        return false;
    }
};

export const logOut = () => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(rolKey);
    localStorage.removeItem(sidKey);
    localStorage.removeItem(IdHotelOwnerKey);
    localStorage.removeItem(HotelInfoKey);
    const lastPageVisited = localStorage.getItem(lastPageVisitedKey);
    localStorage.setItem(lastPageVisitedKey, lastPageVisited);
};

export const getAreLocalStorageUserData = () => {
    return lsToken && rol && IdSystemUser && IdHotelOwner;
};

export const checkCredentials = async () => {
    let isValidToken = null;
    try {
        const { data } = await api.get(endpoints.checkCredentials);
        isValidToken = data.Result;
    } catch {
        isValidToken = false;
    }
    return isValidToken;
};

export const getNavigatorLanguage = navigatorLanguage => {
    if (navigatorLanguage.includes('es')) {
        return 'es';
    } else if (navigatorLanguage.includes('de')) {
        return 'de';
    } else if (navigatorLanguage.includes('fr')) {
        return 'fr';
    } else {
        return 'en';
    }
};

export const getInitialLang = () => {
    const navigatorLanguage = getNavigatorLanguage(navigator.language);
    const langCode = navigatorLanguage.slice(0, 2);
    const customLanguages = ['es', 'fr', 'de'];
    const defaultLanguage = 'en';
    if (customLanguages.includes(langCode)) return langCode;
    return defaultLanguage;
};
