import React, { useContext } from 'react';
import Steps from '../Steps';
import '../../stylesheets/stylesLayout/service-desk.scss';
import ServiceDeskStepsContainer from './ServiceDeskStepsContainer';
import ServiceDeskInformation from './ServiceDeskInformation';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';

const ServiceDesk = () => {
    const { stepsCount, step, setStep } = useContext(ContextServiceDesk);
    return (
        <div className='service-desk__container'>
            <div className='steps__section'>
                <Steps
                    stepsCount={stepsCount}
                    step={step}
                    setStep={setStep}
                    disabledSteps={step === stepsCount}
                />
            </div>
            <div className='service-desk__content'>
                <ServiceDeskInformation />
                <ServiceDeskStepsContainer />
            </div>
        </div>
    );
};

export default ServiceDesk;
