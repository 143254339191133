/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import PaintInputs from '../../components/PaintInputs';
import visServicesData from '../../../../utils/GeneralEditForm/DescriptionVisServices';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';

export default function VisServices() {
    const {
        hotelInfo,
        singleRoomData,
        handleInputsHotelInfo,
        handleInputsRoomInfo,
        progress,
        setIsFetching,
    } = useContext(ContextOwnerPortal);
    const { translate } = useContext(ContextUserProfile);

    const { stepName } = progress.current;
    const elements = visServicesData[stepName].Elements;
    const handler =
        stepName === 'description'
            ? handleInputsHotelInfo
            : handleInputsRoomInfo;
    const content = stepName === 'description' ? hotelInfo : singleRoomData;

    useEffect(() => {
        return () => setIsFetching(true);
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <h5
                style={{
                    alignSelf: 'center',
                }}
            >
                {translate('description_vis_services')}
            </h5>
            <div
                style={{
                    marginTop: 20,
                }}
            >
                {PaintInputs(elements, content, handler)}
            </div>
        </div>
    );
}
