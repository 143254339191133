import React, { useContext } from 'react';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import PrivacyPolicyES from './PrivacyPolicyES';
import PrivacyPolicyEN from './PrivacyPolicyEN';

const SelectPrivacyPolicy = () => {
    const { language } = useContext(ContextUserProfile);

    const translatedCookie = {
        en: <PrivacyPolicyEN />,
        es: <PrivacyPolicyES />,
        fr: <PrivacyPolicyEN />,
        de: <PrivacyPolicyEN />,
    };

    const getTranslatedCookie = language => {
        return translatedCookie[language];
    };

    return <div>{getTranslatedCookie(language)}</div>;
};

export default SelectPrivacyPolicy;
