import React, { useContext } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Icon from '../../../formsProperty/Ari/Icon';
import { qualitySelectOptions } from '../ariHelpers';
import { selectClassNames } from './ComponentsStyles';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { capitalizeSentence } from '../../../../utils/usefulFunctions';

const QualityFilter = ({ value, label, ...rest }) => {
    const { translate } = useContext(ContextUserProfile);
    const id = 'quality-filter';
    const emptyOptionText = '';
    const options = qualitySelectOptions.map(option => ({
        ...option,
        text: translate(option.text),
    }));
    const containerClassName = selectClassNames().container;
    const labelClasses = selectClassNames().label;
    const menuItemClasses = selectClassNames().menuItem;
    const translatedLabel = capitalizeSentence(translate(label));

    return (
        <FormControl className={containerClassName}>
            <InputLabel classes={labelClasses} id={id}>
                {translatedLabel}
            </InputLabel>
            <Select
                size='small'
                labelId={id}
                id={id}
                value={value}
                label={translatedLabel}
                {...rest}
            >
                <MenuItem classes={menuItemClasses} value=''>
                    <span style={{ height: 24 }}>{emptyOptionText}</span>
                </MenuItem>
                {options.map(option => (
                    <MenuItem
                        classes={menuItemClasses}
                        key={option.text}
                        value={option.value}
                    >
                        <div style={{ display: 'flex', gap: 5 }}>
                            <Icon
                                iconName={option.iconName}
                                sx={{ color: option.color }}
                            />
                            {option.text}
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default QualityFilter;
