import React, { useContext } from 'react';
import { ContextFastCheckIn } from '../../contexts/FastCheckInContext';
import SendedFormIcon from '../SendedFormIcon';
import ExclamationFormIcon from './ExclamationFormIcon';

const FastCheckInStep2 = () => {
    const { message } = useContext(ContextFastCheckIn);

    return (
        <div className='step-content__container'>
            <div className='sended-form__container'>
                {message.exclamation ? (
                    <ExclamationFormIcon />
                ) : (
                    <SendedFormIcon />
                )}
                <div>
                    <h3 className='sended-form__title'>
                        {message.BookingStatus}
                    </h3>
                    <p>{message.BookingStatusInfo}</p>
                    <p>{message.BookingNotConfirmed1}</p>
                </div>
            </div>
        </div>
    );
};

export default FastCheckInStep2;
