/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import ComponentMultiSelectButton from './ComponentMultiSelectButton';
import { ContextOccupancy } from '../../../contextsProperty/ContextOccupancy';

const Matrix = ({ roomOccupancy }) => {
    const { adults, kids } = roomOccupancy;
    const {
        formatValue,
        updateSelected,
        occupancyArray,
        updateAllOcupancyArray,
    } = useContext(ContextOccupancy);
    const [selectedItems, setSelectedItems] = useState(occupancyArray);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [combinaciones, setCombinaciones] = useState([]);

    useEffect(() => {
        allCombinations();
    }, [adults, kids]);

    useEffect(() => {
        if (selectedItems.length > 0) {
            selectedItems.forEach(item => updateSelected(item));
            updateAllOcupancyArray(selectedItems);
        } else {
            updateAllOcupancyArray([]);
        }
    }, [selectedItems]);

    const allCombinations = () => {
        const combinations = [];
        // Generar todas las combinaciones posibles
        kids.forEach(kid => {
            adults.forEach(adult => {
                combinations.push(`${formatValue(adult)}.${formatValue(kid)}`);
            });
        });
        setCombinaciones(combinations);
    };

    const handleItemClick = (e, idx2, buttonValue) => {
        setLastSelectedIndex(idx2);

        if (!e.shiftKey) {
            setSelectedItems(prevSelectedItems =>
                prevSelectedItems.includes(buttonValue)
                    ? prevSelectedItems.filter(item => item !== buttonValue)
                    : [...prevSelectedItems, buttonValue]
            );
        } else {
            const newArray = combinaciones.slice(lastSelectedIndex, idx2 + 1);
            setSelectedItems(prevSelectedItems => [
                ...prevSelectedItems,
                ...newArray,
            ]);
        }
    };

    const handleOnClickSelectAll = () => {
        setSelectedItems(combinaciones);
    };

    const handleOnClickDiselectAll = () => {
        setSelectedItems([]);
    };

    return (
        <>
            <Box display='flex' flexDirection='column' gap={1} overflow='auto'>
                {kids.map((child, childIdx) => (
                    <Box display='flex' gap={1} key={childIdx}>
                        {adults.map((adult, adultIdx) => {
                            const index = childIdx * adults.length + adultIdx; // Calcular el índice global
                            return (
                                <ComponentMultiSelectButton
                                    adult={adult}
                                    kid={child}
                                    key={index}
                                    selectedItems={selectedItems}
                                    index={index}
                                    handleItemClick={handleItemClick}
                                />
                            );
                        })}
                    </Box>
                ))}
            </Box>
            <div className='d-flex justify-content-end mt-4 align-self-end'>
                <button
                    type='button'
                    className={`btn btn_general btn-outline-success`}
                    onClick={handleOnClickSelectAll}
                >
                    Select All
                </button>
                <button
                    type='button'
                    className={`btn btn_general btn-outline-success`}
                    onClick={handleOnClickDiselectAll}
                >
                    Delete All
                </button>
            </div>
        </>
    );
};

export default Matrix;
