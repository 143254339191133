import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { capitalize } from '../../utils/usefulFunctions';
import SuffixButton from './SuffixButton';

const TextInputV2 = props => {
    const {
        labelText,
        errorMessage,
        helperText,
        id,
        handleChange,
        deleteIcon = true,
        value,
        disabled,
        ...restProps
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    const { translate } = useContext(ContextUserProfile);
    const label = capitalize(translate(labelText));

    const helper = errorMessage || helperText;

    const handleChangeInput = e => handleChange(e, e.target.value);

    const clearText = () => {
        handleChange({ target: { name: id, value: '' } }, '');
    };

    const isVisibleDeleteButton = deleteIcon && value && !disabled && isHovered;

    return (
        <TextField
            id={id}
            label={label}
            InputProps={{
                endAdornment: isVisibleDeleteButton ? (
                    <SuffixButton
                        classes={{ root: 'text-input__delete-icon' }}
                        iconName='Close'
                        onClick={clearText}
                    />
                ) : null,
            }}
            helperText={translate(helper)}
            onChange={handleChangeInput}
            value={value}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                // Estilo para la raíz del campo de texto
                '& .MuiOutlinedInput-root': {
                    height: '50px',
                    fontSize: '1.2rem',
                },
                '& .MuiFilledInput-root': {
                    height: '50px',
                    fontSize: '1.2rem',
                },
                // Estilo general para el label en estado shrink (con valor o enfocado)
                '& .MuiInputLabel-shrink': {
                    top: '0 !important',
                },
                // Estilo para el label sin enfocar
                '& .MuiInputLabel-outlined': {
                    fontSize: '1.2rem',
                    top: '-5px',
                },
                '& .MuiInputLabel-filled': {
                    fontSize: '1.2rem',
                    top: '-5px',
                },
            }}
            disabled={disabled}
            {...restProps}
        />
    );
};

export default TextInputV2;
