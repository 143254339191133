import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from '../utils/ProtectedRoute';
import { ProviderOwnerPortal } from './ownerPortal/ContextOwnerPortal';
import OwnerPortalContainerMain from './ownerPortal/OwnerPortalContainerMain';
import YourSingleProperty from './ownerPortal/yourProperties/YourSingleProperty';
import PageNotFound from '../layout/PageNotFound';
import routes from '../utils/commonVariables/routes';

const PropertyContainer = () => {
    return (
        <main className='main__owner-portal'>
            <Switch>
                <ProtectedRoute
                    path={routes.landingPrivate}
                    render={props => <OwnerPortalContainerMain {...props} />}
                />
                <ProtectedRoute
                    path='/your-property/:idEncode'
                    render={props => (
                        <ProviderOwnerPortal>
                            <YourSingleProperty
                                IdEncode={props.match.params.idEncode}
                            />
                        </ProviderOwnerPortal>
                    )}
                />
                {/* antiguas ruta */}
                <Route
                    path='/property/forgot-my-password'
                    render={() => <Redirect to={routes.landingPrivate} />}
                />

                <Route
                    path='/property/login'
                    render={() => <Redirect to={routes.login} />}
                />
                <Route
                    path='/property/contact'
                    render={() => <Redirect to={routes.contact} />}
                />
                <Route
                    path='/property/fast-check-in'
                    render={() => <Redirect to={routes.fastCheckIn} />}
                />
                <Route
                    path='/property/service-desk'
                    render={() => <Redirect to={routes.serviceDesk} />}
                />
                <Route
                    path='/property/sign-up'
                    render={() => <Redirect to={routes.signUp} />}
                />
                <Route path='/property/not-found' component={PageNotFound} />
                <Route
                    exact
                    path='/property'
                    render={() => <Redirect to={routes.landingPrivate} />}
                />
                <Redirect to='/property/not-found' />
            </Switch>
        </main>
    );
};

export default PropertyContainer;
