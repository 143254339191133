import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import '../../stylesheets/stylesLayout/private.scss';
import { ownerPortalMenu } from '../../utils/menusData';
import LandingCharts from './landingCharts/LandingCharts';

export default function OwnerPortalLanding() {
    const history = useHistory();
    const { translate } = useContext(ContextUserProfile);

    return (
        <div className='landing__container'>
            <div className='landing__menu-section'>
                <SectionTitle
                    title={translate('welcome_to_your_private_area')}
                />
                <div className='menu-section__items'>
                    {ownerPortalMenu.map(item => {
                        return (
                            <div
                                key={item.text}
                                className='landing__menu-card landing__menu-card-link'
                                onClick={() => history.push(item.link)}
                            >
                                <div className='landing__menu-card__title'>
                                    <i
                                        className={`${item.iconClass} menu-card__title__icon`}
                                    />
                                    <h3 className='menu-card__title__text'>
                                        {translate(item.text)}
                                    </h3>
                                </div>
                                <div>
                                    <p className='menu-card__subtitle'>
                                        {translate(`subtitle_${item.text}`)}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='landing__chart-section'>
                <SectionTitle
                    title={translate('your_statistics')}
                    variant={'h2'}
                />
                <LandingCharts />
            </div>
        </div>
    );
}
