import React, { useContext, useRef, useCallback, useEffect } from 'react';
import '../../../stylesheets/stylesLayout/tableViews.scss';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import FiltersBar from './FiltersBar';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { cleanedAriId, columns, numColumns } from './ariHelpers';
import TableHeader from './TableHeader';
import { ContextAri, ProviderAri } from '../../contextsProperty/ContextAri';
import AriTableRow from './TableRow';
import TablePlaceholder from './TablePlaceholder';
import LinearProgress from '@mui/material/LinearProgress';
import ResultsIndicator from './ResultsIndicator';
import MessageBar from './MessageBar';
import IconButton from '@mui/material/IconButton';
import Icon from '../../formsProperty/Ari/Icon';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import WithTooltip from './WithTooltip';
import { Typography } from '@mui/material';

const AriPage = () => {
    const {
        items,
        isLoading,
        page,
        searchNextPage,
        currentResults,
        totalResults,
        filters,
        isOpenedFiltersBar,
        toggleFiltersBar,
        order,
        sort,
        canSearch,
        setPage,
    } = useContext(ContextAri);

    const { translate } = useContext(ContextUserProfile);

    const observer = useRef();
    const tableContainer = useRef();

    useEffect(() => {
        // para que al buscar, o al hacer scroll y es la página 1 o 0 (porque se han cambiado los filtros)
        // pongo el scroll arriba del todo
        if (page <= 1) tableContainer.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        // si cambian los filtros reseteo la página a 0,
        // paraque si siguiera haciendo scroll al irse a la página siguiente
        //  fuera a la 1
        if (page) setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const lastItem = useCallback(
        node => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    searchNextPage();
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, page]
        // pongo la page en las dependecias, para que si cuando
        // se hace paginación infinita, si cambiar algún filtro coja la nueva página (es por el useEffect)
        // además si cambiar el idSupplierHotel, al ser un filtro, salta la validación
    );

    const noResultsText = translate('no_results');
    const filtersBarIconName = isOpenedFiltersBar
        ? 'FilterAltOff'
        : 'FilterAlt';

    const tableSize = 'small'; // 'small' | 'medium';
    const tooltip = translate(
        isOpenedFiltersBar ? 'hide_filters' : 'show_filters'
    );

    return (
        <div className='ari-page'>
            <MessageBar />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                className='p-1'
            >
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant='h1'>Ari</Typography>
                    {page > 0 && (
                        <ResultsIndicator
                            currentResults={currentResults}
                            totalResults={totalResults}
                            isLoading={isLoading}
                        />
                    )}
                </div>
                <div>
                    <WithTooltip title={tooltip}>
                        <IconButton size='small' onClick={toggleFiltersBar}>
                            <Icon iconName={filtersBarIconName} />
                        </IconButton>
                    </WithTooltip>
                </div>
            </div>
            <FiltersBar />
            <TableContainer
                ref={tableContainer}
                classes={{ root: 'table__container px-1 mt-1' }}
            >
                <Table stickyHeader size={tableSize}>
                    <TableHeader
                        columns={columns}
                        order={order}
                        sort={sort}
                        isLoading={isLoading}
                        canSearch={canSearch}
                    />

                    <TableBody>
                        {isLoading && page === 1 ? (
                            <TablePlaceholder
                                rowClassName={`ari--${tableSize}`}
                                numColumns={numColumns}
                            />
                        ) : (
                            items.map((item, index) => {
                                if (items.length === index + 1) {
                                    return (
                                        <AriTableRow
                                            componentRef={lastItem}
                                            key={cleanedAriId(item.ariId)}
                                            item={item}
                                        />
                                    );
                                }
                                return (
                                    <AriTableRow
                                        key={cleanedAriId(item.ariId)}
                                        item={item}
                                    />
                                );
                            })
                        )}
                        {isLoading && page !== 1 && (
                            <TableRow>
                                <TableCell colSpan={numColumns}>
                                    <LinearProgress
                                        classes={{
                                            bar: 'linear-progress__bar',
                                            root: 'linear-progress',
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {items.length === 0 && !isLoading && (
                            <TableRow>
                                <TableCell colSpan={numColumns}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {noResultsText}
                                    </span>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const AriPageWithProvider = () => (
    <ProviderAri>
        <AriPage />
    </ProviderAri>
);

export default AriPageWithProvider;
