import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRouteToJobs = ({
    path,
    component: Component,
    render,
    redirectJob,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            path={path}
            render={props => {
                if (!localStorage.jobConditionsAccepted) {
                    return <Redirect to='/authorization-personal-data' />;
                }
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};

export default ProtectedRouteToJobs;
