import React, { useContext } from 'react';
import SectionTitle from '../../property-partners-portal/components/SectionTitle/SectionTitle';
import IconButton from '../../muiComponents/IconButton';
import { ContextSignUp } from '../../contexts/SignUpContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import SpeedIcon from '@mui/icons-material/Speed';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const SignUpInformation = () => {
    const { translate } = useContext(ContextUserProfile);
    const { isInfoVisible, setIsInfoVisible } = useContext(ContextSignUp);

    return (
        <>
            <div className='title__container'>
                <SectionTitle title={'hotels_sign_up'} />
                <IconButton
                    iconName='InfoOutlinedIcon'
                    onClick={() => setIsInfoVisible(prevState => !prevState)}
                />
            </div>
            {isInfoVisible && (
                <div className='additional-info__container'>
                    <div className='additional-info__line'>
                        <MoneyOffIcon className='description__icon' />
                        <p className='additional_info'>
                            {translate('free_to_join')}
                        </p>
                    </div>
                    <div className='additional-info__line'>
                        <SpeedIcon className='description__icon' />
                        <p className='additional_info'>
                            {translate('takes_5_minutes_to_register')}
                        </p>
                    </div>
                    <div className='additional-info__line'>
                        <NewReleasesIcon className='description__icon' />
                        <p className='additional_info'>
                            {translate('experience_a_new_distribution_model')}
                        </p>
                    </div>
                    <div className='additional-info__line'>
                        <DirectionsBoatIcon className='description__icon' />
                        <p className='additional_info'>
                            {translate('onboard_quickly')}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default SignUpInformation;
