/* eslint-disable camelcase */
import flag_2_UK from './svg/flag_2_UK.svg';
import flag_3_ES from './svg/flag_3_ES.svg';
import flag_4_DE from './svg/flag_4_DE.svg';
import flag_5_FR from './svg/flag_5_FR.svg';
import flag_6_ZH from './svg/flag_6_ZH.svg';
import flag_7_IT from './svg/flag_7_IT.svg';
import flag_8_RU from './svg/flag_8_RU.svg';
import flag_9_HI from './svg/flag_9_HI.svg';
import flag_10_NL from './svg/flag_10_NL.svg';
import flag_11_SV from './svg/flag_11_SV.svg';
import flag_12_NB from './svg/flag_12_NB.svg';
import flag_13_FI from './svg/flag_13_FI.svg';
import flag_14_DA from './svg/flag_14_DA.svg';
import flag_15_PT from './svg/flag_15_PT.svg';
import flag_16_EL from './svg/flag_16_EL.svg';
import flag_17_TR from './svg/flag_17_TR.svg';
import flag_Brazil from './svg/flag_Brazil.svg';
import flag_USA from './svg/flag_USA.svg';
import flag_Austria from './svg/flag_Austria.svg';

export const flags = {
    2: flag_2_UK,
    3: flag_3_ES,
    4: flag_4_DE,
    5: flag_5_FR,
    6: flag_6_ZH,
    7: flag_7_IT,
    8: flag_8_RU,
    9: flag_9_HI,
    10: flag_10_NL,
    11: flag_11_SV,
    12: flag_12_NB,
    13: flag_13_FI,
    14: flag_14_DA,
    15: flag_15_PT,
    16: flag_16_EL,
    17: flag_17_TR,
};

export const getFlag = idFlag => {
    return flags[idFlag];
};

export const countryFlagById = {
    //coincide el id con el de la base de datos de PAIS no de idioma
    1: flag_3_ES, // spain
    3: flag_USA, // USA
    12: flag_2_UK, // United kingdom
    17: flag_Austria, //Austria
    39: flag_Brazil, // brazil
};

export const getFlagByCountry = idCountry => {
    //coincide el id con el de la base de datos de PAIS no de idioma
    return flags[idCountry];
};
