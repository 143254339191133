import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import useTranslate from '../../customHooks/useTranslate';

const RenderIcon = props => {
    const { iconData } = props;
    const { IconComponent, iconClass, text } = iconData;

    return (
        <Tooltip title={useTranslate(text)} placement='top' arrow>
            {iconClass ? (
                <i className={iconClass + ' menu__item__icon'} />
            ) : (
                <IconComponent />
            )}
        </Tooltip>
    );
};

export default RenderIcon;
