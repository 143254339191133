import React, { useEffect } from 'react';
import useTranslate from '../../customHooks/useTranslate';

function FormWithWarning(props) {
    const { children, isDirty } = props;

    const warningText = useTranslate('unsaved_changes_warning');

    useEffect(() => {
        const handleBeforeUnload = event => {
            // Puedes verificar si hay cambios pendientes aquí.
            // Si hay cambios sin guardar, muestra una advertencia.
            if (isDirty) {
                event.preventDefault();
                event.returnValue = warningText;
            }
        };

        const handleUnload = () => {
            // Borrar una clave específica del localStorage solo si el usuario está saliendo realmente
            if (isDirty) {
                localStorage.removeItem('hotelInfo');
            }
        };

        // Agregar el evento beforeunload al cargar el componente
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Agregar el evento unload al cargar el componente para manejar la limpieza cuando la página se cierre
        window.addEventListener('unload', handleUnload);
        // Remover el evento beforeunload al desmontar el componente
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [isDirty, warningText]); // Asegúrate de que el efecto se ejecute solo una vez.

    return <>{children}</>;
}

export default FormWithWarning;
