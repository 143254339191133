import React from 'react';
import FormLogin from './common/components/forms/FormLogin';

const Login = () => {
    return (
        <main className='main__login'>
            <FormLogin />
        </main>
    );
};

export default Login;
