let moment = require('moment');
require('moment-timezone');

const dateLanguageFormats = {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY',
    es: 'DD/MM/YYYY',
    de: 'DD/MM/YYYY',
};

export const formatDate = (date, lang = 'es') => {
    const languageFormat = dateLanguageFormats[lang];
    const formatedDate = moment(date).format(languageFormat);
    if (
        !date ||
        formatedDate === '01/01/0001' ||
        formatedDate === 'Invalid date'
    ) {
        return '--/--/--';
    } else {
        return formatedDate;
    }
};

export const isMinValueDate = date => {
    return date === '0001-01-01T00:00:00';
};

export const convertUTCtoFPHlocalDateTime = date => {
    if (isMinValueDate(date)) {
        return '--/--/--';
    } else {
        const dateUtc = moment.utc(date);
        return dateUtc.clone().tz('Africa/Ceuta').format('DD/MM/YYYY HH:mm:ss');
    }
};

export const formatDateYYMMDD = date => moment(date).format('YYYY/MM/DD');

export const formatUTCDateTime = (date, lang = 'es') => {
    const languageFormat = dateLanguageFormats[lang];
    const formatedDate = moment.utc(date).format(`${languageFormat} HH:mm:ss`);
    if (
        !date ||
        formatedDate === '01/01/0001' ||
        formatedDate === 'Invalid date'
    ) {
        return '--/--/--';
    } else {
        return formatedDate;
    }
};

export const getNextDay = date => {
    if (!date) return undefined;
    let currentDate = new Date(date);
    return currentDate.setDate(date.getDate() + 1);
};

export const getCurrentMonth = (date = new Date()) => {
    return moment(date).month();
};

export const getCurrentYear = (date = new Date()) => {
    return moment(date).year();
};

export const getYearPeriod = () => {
    //year ago = si estamos a 15 de mayo de 2023 => 01/06/2022
    const yearAgo = moment([
        getCurrentYear() - 1,
        getCurrentMonth() + 1,
        1,
    ]).format('YYYY-MM-DD');
    // endThisMonth = si estamos a 15 de mayo de 2023 => 31/05/2023
    const endThisMonth = moment().endOf('month').format('YYYY-MM-DD');

    return { start: yearAgo, end: endThisMonth };
};

export const getMonthPeriod = (date = new Date()) => {
    //month ago = si estamos a 15 de mayo de 2023 => 15/04/2023

    const monthAgo = moment(date).subtract(1, 'months').format('YYYY-MM-DD');
    const endThisMonth = moment().endOf('month').format('YYYY-MM-DD');
    return { start: monthAgo, end: endThisMonth };
};

export const getYearAgoDate = () => {
    return moment([getCurrentYear() - 1, getCurrentMonth() + 1, 1]).format(
        'YYYY-MM-DD'
    );
};

export const addMonthsToDate = (initialDate, numberOfMonths) => {
    return moment(initialDate)
        .add(numberOfMonths, 'months')
        .format('YYYY-MM-DD');
};

export const dateIsInRange = (stringDate, dateRange) => {
    const { start, end } = dateRange;
    return (
        moment(stringDate).isAfter(start) && moment(stringDate).isBefore(end)
    ); // false
};
