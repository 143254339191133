import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const WithTextPlaceholder = ({ text, isLoading, width, ...rest }) => {
    return isLoading ? (
        <Skeleton width={width} {...rest} variant='text' />
    ) : (
        <span style={{ width, textAlign: 'center' }}>{text}</span>
    );
};

export default WithTextPlaceholder;
