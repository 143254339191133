import React, { useContext, useEffect, useState } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import {
    organizeDataCategories,
    sortByField,
} from '../../../../utils/usefulFunctions';
import api from '../../../../services/api';
import Loading from '../../../../common/components/Loading';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';
import AccordionV2 from './AccordionV2';
import { Pagination } from '@mui/material';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';

const ServiceAccordion = () => {
    const {
        serviceCategories,
        setServiceCategories,
        isFetching,
        setIsFetching,
        progress,
        setProgress,
    } = useContext(ContextOwnerPortal);
    const { translate } = useContext(ContextUserProfile);

    const itemsPerPage = 5;
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState([]);
    const [firstServiceId, setFirstServiceId] = useState([]); // esto es para saber qué categoría de servicios se muestra primero y mostrarlo expandido

    useEffect(() => {
        const getContentStructureInfo = async () => {
            setIsFetching(true);
            const endpoint = buildOdataEndPoint({
                entity: readOnlyEntities.ServiceType,
                select: [
                    'Id',
                    'Caption',
                    'IdServiceCategory',
                    'Free',
                    'Order',
                    'ServiceCategory/Id',
                    'ServiceCategory/Caption',
                    'ServiceCategory/Key',
                    'ServiceCategory/Order',
                    'Priority',
                    'PriorityOrder',
                ],
                expand: readOnlyEntities.ServiceCategory,
                filter: ['Enabled', 'ForHotel', 'ServiceCategory/Enabled'],
            });
            const { data } = await api.get(endpoint);
            const organicedData = sortByField(
                organizeDataCategories(data.value),
                'Caption'
            );
            setFirstServiceId(organicedData[0].Id);
            setServiceCategories(organicedData);
            setProgress({
                ...progress,
                current: {
                    ...progress.current,
                    maxSubTabs: organicedData.length + 1,
                },
            });
            setIsFetching(false);
        };
        getContentStructureInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serviceCategories.length > 1) {
            getPaginatedData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceCategories, page]);

    const totalPages = Math.ceil(serviceCategories.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getPaginatedData = () => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setPaginationData(serviceCategories.slice(startIndex, endIndex));
    };

    return (
        <>
            {isFetching ? (
                <div className='loading__container'>
                    <Loading />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {serviceCategories.length > 1 && (
                        <>
                            <h5>{translate('all_services')}</h5>
                            <AccordionV2
                                data={paginationData}
                                firstServiceId={firstServiceId}
                            />
                            <Pagination
                                count={totalPages}
                                onChange={handlePageChange}
                                style={{ marginTop: 20, marginBottom: 20 }}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default ServiceAccordion;
