import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ButtonClose = props => {
    const { handleClose, left, top, right, size, color } = props;

    const colorDefault = theme => theme.palette.grey[800];
    return (
        <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
                position: 'absolute',
                left: left,
                top: top,
                right: right,
                color: color ? color : colorDefault,
                zIndex: 2,
            }}
            size={size}
        >
            <CloseIcon />
        </IconButton>
    );
};

export default ButtonClose;
