import React from 'react';
import SendedFormIcon from '../SendedFormIcon';
import useTranslate from '../../customHooks/useTranslate';

const SignUpStep5 = () => {
    return (
        <div className='step-content__container'>
            <div className='sended-form__container'>
                <SendedFormIcon />
                <div>
                    <h3 className='sended-form__title'>
                        {useTranslate('thanks')}
                    </h3>
                    <p className=''>{useTranslate('we_will_contact_you')}</p>
                    <p className=''>
                        {`${useTranslate('check_your_spam_folder')}`}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SignUpStep5;
