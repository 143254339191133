import React from 'react';
import ImgComp from '../../../common/components/ImageWithRef';
import ThumbNailButtonList from './ThumbNailButtonList';

export default function ThumbNail({ img, buttonList }) {
    return (
        <>
            <ImgComp img={img} className='thumbNail__image' />
            <ThumbNailButtonList buttonList={buttonList} image={img} />
        </>
    );
}
