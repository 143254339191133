import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import '../../../stylesheets/stylesLayout/yourProperties.scss';

export default function SwitchInput(props) {
    const { data, handleChange, checked } = props;
    const { translate } = useContext(ContextUserProfile);
    const { name, title, disabled, id = name } = data;
    const handleSwitchChange = ({ target }) => {
        const { name, checked } = target;
        handleChange({ target: { name, value: checked } });
    };

    return (
        <div className='custom-control custom-switch'>
            <input
                type='checkbox'
                className='custom-control-input'
                id={id}
                name={name}
                checked={checked}
                onChange={handleSwitchChange}
                disabled={disabled}
            />
            <label className='custom-control-label pointer' htmlFor={id}>
                {translate(title)}
            </label>
        </div>
    );
}
