import React, { useContext } from 'react';
import { ContextContact } from '../../contexts/ContactContext';
import ContactStep1 from './ContactStep1';
import ContactStep2 from './ContactStep2';
import ContactStep3 from './ContactStep3';

const ContactStepsContainer = () => {
    const { step } = useContext(ContextContact);

    const contactStep = {
        1: <ContactStep1 />,
        2: <ContactStep2 />,
        3: <ContactStep3 />,
    };
    return <div>{contactStep[step]}</div>;
};

export default ContactStepsContainer;
