import React from 'react';
import exclamationImage from '../../images/exclamation.png';

const ExclamationFormIcon = () => {
    return (
        <img
            src={exclamationImage}
            className='exclamation-form__icon'
            alt={'exclamation form icon'}
        />
    );
};

export default ExclamationFormIcon;
