import React from 'react';

export default function PrivacyPolicyEN() {
    return (
        <div className='container__policies'>
            <h1 className='conditions__title'>
                Terms and Conditions of Fastpayhotels website
            </h1>
            <ol className='conditions__section'>
                <li className='section__title-li'>
                    Ownership of the website and acceptance of the Terms and
                    Conditions
                </li>
                <p className='section__paragraph'>
                    The reading and acceptance of these terms and conditions
                    (hereinafter, the "Terms and Conditions") is a necessary
                    condition to use the website www.fastpayhotels.com
                    (hereinafter, the "Website").
                </p>
                <p className='section__paragraph--policies'>
                    The Terms and Conditions are General Contracting Conditions,
                    of a contractual and obligatory nature and govern the
                    relationship between all users of the Website (hereinafter,
                    the "User") and Fastpayhotels, S.L. (hereinafter,
                    "Fastpayhotels"), a Spanish company, with registered address
                    at Calle Reina Maria Cristina 2, 2º P.O 07004, registered in
                    the Commercial Registry of Palma de Mallorca in Volume 2635,
                    Sheet 19, Page PM-77490, with registered/tax number
                    B-57940066 and e-mail: info@fastpayhotels.com
                </p>
                <p className='section__paragraph--policies'>
                    The Terms and Conditions of this Website and its services
                    implies the full acceptance of the provisions included in
                    these Terms and Conditions according to the last version
                    published by Fastpayhotels at the time the User accesses to
                    the Website.
                </p>
                <li className='section__title-li'>Use of the Website</li>
                <p className='section__paragraph'>
                    Users shall not use any of the materials and information
                    contained on this Website for purposes that are illicit or
                    expressly prohibited in these Terms and Conditions, as well
                    as the particular conditions that may be enabled, or against
                    the rights and interests of Fastpayhotels, its members or
                    third parties and shall be liable to them in the event of
                    infringement or failure to comply with such obligations or,
                    in any way (including the introduction or dissemination of
                    "computer viruses") damage, disable, overburden, impair or
                    prevent the normal use of the materials and information
                    contained on the Website, the information systems or the
                    documents, files and all kinds of content stored on any
                    computer equipment (hacking) of Fastpayhotels, its members
                    or any User of the Website.
                </p>
                <li className='section__title-li'>
                    Intellectual and industrial property
                </li>
                <p className='section__paragraph'>
                    The Website, including but not limited to its design,
                    structure and distribution, text and content, logos,
                    buttons, images, drawings, trademarks, commercial names,
                    source code, creativities, as well as all intellectual and
                    industrial property rights and any other distinctive sign,
                    belong or are assigned to Fastpayhotels, or, where
                    applicable, to the natural or legal persons as authors or
                    holders of the rights or their licensors, without it being
                    understood that the use and access to the Website and the
                    use of its services gives the User any right to the
                    aforementioned elements.
                </p>
                <p className='section__paragraph--policies'>
                    The reproduction, use, alteration, distribution or public
                    communication of the Website for uses other than the
                    legitimate information of the Users is prohibited.
                </p>
                <li className='section__title-li'>Cookie policy.</li>
                <p className='section__paragraph'>
                    The Website may use cookies when a User accesses it.
                </p>
                <p className='section__paragraph--policies'>
                    Cookies are data files that are downloaded to User’s
                    computer when the User accesses some websites and it allows
                    to store and retrieve data and other technical functions,
                    such as saving browsing preferences, collecting statistical
                    information, information about the equipment, can be used to
                    recognise users by offering them the most appropriate
                    content according to your habits.
                </p>
                <p className='section__paragraph--policies'>
                    However, a cookie cannot erase or read information from the
                    computer, nor can it identify a person, but it recognizes a
                    combination of computer-browser-user.
                </p>
                <p className='section__paragraph--policies'>
                    This Website uses only its own technical cookies, whose
                    purpose is to allow communication between this Website and
                    the User's device. Using the default configuration of this
                    Web Site, the User accepts that cookies may be installed in
                    their terminal and that they allow us to know the following
                    information:
                </p>
                <ul>
                    <li className='section__paragraph--policies'>
                        The User's login to keep the session active on the
                        Website.
                    </li>
                    <li>
                        The format of the Web site is preferable for access from
                        mobile devices.
                    </li>
                    <li>
                        The latest searches carried out on the services of the
                        Website and personalisation data for these services.
                    </li>
                </ul>
                <p className='section__paragraph--policies'>
                    Users can accept, revoke consent or delete cookies, as well
                    as delete their browsing data (including cookies) installed
                    on their computers by configuring the browser options that
                    they use. Below are the steps required to configure the use
                    of cookies depending on the browser used:
                    <ul>
                        <li className='section__paragraph--policies'>
                            Internet Explorer: Tools -> Internet Options ->
                            Privacy -> Settings. For more information, you can
                            see Microsoft support or the browser Help.
                        </li>
                        <li>
                            Firefox: Tools -> Options -> Privacy -> History ->
                            Custom Settings. For more information, you can see
                            Mozilla support or the browser's Help.
                        </li>
                        <li>
                            Chrome: Settings -> Show advanced options -> Privacy
                            -> Content settingsFor more information, you can see
                            Google support or browser help.
                        </li>
                        <li>
                            Safari: Preferences -> Security. For more
                            information, you can seet Apple's support or the
                            browser's Help.
                        </li>
                    </ul>
                    Some services of the Website may be not available if you
                    block use of cookies in your browser.
                </p>
                <li className='section__title-li'>Privacy Policy.</li>
                <p className='section__paragraph'>
                    Reading and accepting Fastpayhotels' privacy policy
                    (hereinafter, "the Privacy Policy") is a necessary condition
                    for using the Site.
                </p>
                <p className='section__paragraph--policies'>
                    This Privacy Policy governs the collection and use of
                    information from Users of the Website from the effective
                    date shown at the end of these Terms of Use. It does not
                    apply to any other URL accessed from the Website, which will
                    have its own policy regarding data collection and use.
                </p>
                <p className='section__paragraph--policies'>
                    Please read this Privacy Policy carefully before using the
                    Website and providing any personal information. It will help
                    you make informed decisions.
                </p>
                <p className='section__paragraph--policies'>
                    Fastpayhotels complies with the legislation in force in
                    Spain and in the European Union, and specifically with
                    Organic Law 3/2018 of 5 December on the Protection of
                    Personal Data and the guarantee of digital rights, and
                    European Regulation 2016/679 of 27 April on the protection
                    of individuals with regard to the processing of personal
                    data and on the free movement of such data and repealing
                    Directive 95/46/EC on the protection of personal data
                    (hereinafter the "GDPR") in the processing of the personal
                    data of its Users. Fastpayhotelsin its condition as the Data
                    Controllerof the processing of the personal data
                    (hereinafter the "Data Controller") only processes the
                    personal data obtained from the forms voluntarily filled in
                    by the Users under the principles of legality, loyalty and
                    transparency, purpose limitation, data minimisation,
                    accuracy, limitation of the period of conservation,
                    integrity and confidentiality and proactive responsibility.
                </p>
                <p className='section__paragraph--policies'>
                    IDENTITY AND CONTACT DETAILS OF THE DATA CONTROLLER
                </p>
                <p className='section__paragraph'>
                    Fastpayhotels, S.L. with registered address at Calle Reina
                    Maria Cristina 2, 2º, P.O. Box 07004, registered/tax number
                    B-57940066, with e-mail info@fastpayhotels.com and telephone
                    +34 971 416 770.
                </p>
                <p className='section__paragraph'>
                    The User may contact the Data Protection Officer of the Data
                    Controller at the following e-mail address
                    info@fastpayhotels.com and by postal mail to the address of
                    the Data Controller.
                </p>
                <p className='section__paragraph--policies'>
                    DATA CATEGORIES, PURPOSE AND LEGAL BASIS OF PROCESSING
                </p>
                <p className='section__paragraph'>
                    The Data Controller processes the following categories of
                    User data:
                </p>
                <p className='section__paragraph'>
                    Contact details: name, surname, identity card, age, tax
                    number, postal address,e-mail , telephone number, IP,
                    country, device and language.
                </p>
                <p className='section__paragraph'>
                    Preferences: information that the User provides about
                    his/her preferences, for example, the type of services hired
                    by the User.
                </p>
                <p className='section__paragraph--policies'>
                    Use of the website and communications: how you use our
                    website as well as the information collected through cookies
                    that the User may consult in our Cookies Policy. Some
                    cookies may affect the privacy of the User by allowing their
                    content to be related to certain personal data, including
                    your tastes and preferences.
                </p>
                <p className='section__paragraph--policies'>
                    The Data Controller will only process the personal data
                    provided by the User and will not process special categories
                    of personal data. The main purpose we process the User's
                    information is to provide the services expressly requested
                    by the User.
                </p>
                <p className='section__paragraph--policies'>
                    The Data Controller process the data of the Users with the
                    following purposes:
                </p>
                <ol type='a'>
                    <li className='section__paragraph--policies'>
                        The provision of services expressly requested by the
                        User, as well as to manage payments and orders.
                    </li>
                    <li>
                        Provide support services and assistance to Users, notify
                        service or account issues (e.g., warn of misconduct).
                    </li>
                    <li>
                        Carrying out commercial, operational and statistical
                        activities. No automated decisions will be taken based
                        on this profile.
                    </li>
                    <li>
                        Advice and referral of newsletters and other commercial
                        communications by e-mail about the Data Controller and
                        the specific provision of services to its sector. The
                        User may indicate their opposition to receiving such a
                        newsletter at any time. The User expressly consents to
                        receive these e-mails, although they may unsubscribe (i)
                        at the link specified in any communication sent by
                        e-mail, (ii) by sending an e-mail to
                        info@fastpayhotels.com. However, this will not imply the
                        cancellation of certain communications of a
                        non-commercial nature, such as messages relating to the
                        User's access to the Website.
                    </li>
                </ol>
                <p className='section__paragraph--policies'>
                    The legal basis for the processing of the User's data by the
                    Data Controller may process the User's data under the legal
                    basis of legitimate interest. This legitimate interest
                    consists of maintaining and promoting commercial and
                    professional relations between the Controller and the User
                    and preventing fraud.
                </p>
                <p className='section__paragraph--policies'>
                    The communication of personal data by the User is voluntary,
                    although it is a contractual condition for the acquisition
                    of services and products marketed by the Data Controller.
                    Thus, the refusal of the User will mean the impossibility of
                    providing certain services to the User by the Data
                    Controller.
                </p>
                <p className='section__paragraph--policies'>
                    The User may withdraw consent at any time, without this
                    affecting the lawfulness of the processing based on the
                    consent prior to its withdrawal.
                </p>
                <p className='section__paragraph--policies'>DATA TRANSFER</p>
                <p className='section__paragraph'>
                    The Data Controller informs and expressly guarantees the
                    User that their personal data will not be transferred to
                    third parties under any circumstances, unless legally
                    obliged to do so.
                </p>
                <p className='section__paragraph--policies'>
                    DATA RETENTION PERIOD
                </p>
                <p className='section__paragraph'>
                    The personal data provided will be kept for an indefinite
                    period of time, insofar as the Data Controller needs it in
                    order to use it for the purpose for which they were
                    obtained:
                </p>
                <ol type='a'>
                    <li className='section__paragraph--policies'>
                        As long as a contractual and/or commercial relationship
                        exists.
                    </li>
                    <li>
                        As long as the deletion, opposition and/or limitation of
                        the processing of the data by the User is not requested.
                    </li>
                    <li>As required by law.</li>
                </ol>
                <p className='section__paragraph--policies'>
                    OTHER USER RIGHTS
                </p>
                <p className='section__paragraph'>
                    The User can obtain confirmation as to whether the Data
                    Controller is processing personal data concerning him/her
                    and access to such data.
                </p>
                <p className='section__paragraph--policies'>
                    The User may request the Data Controller to exercise the
                    rights of access to the personal data, its rectification,
                    deletion, limitation of its processing, opposition and
                    portability, by sending an e-mail to the address
                    info@fastpayhotels.com, attaching a copy of the official
                    identification document of the User and clearly expressing
                    the right he/she want to exercise. The User may request the
                    forms for the exercise of the aforementioned rights from
                    e-mail address. The User may also lodge a complaint with a
                    supervisory authority.
                </p>
                <p className='section__paragraph--policies'>
                    PRIVACY OF MINORS
                </p>
                <p className='section__paragraph'>
                    The Data Controller’s services is for adults and does not
                    voluntarily collect or maintain any type of personal data
                    from minors. If the Data Controller becomes aware that the
                    User is a minor, it will proceed to the immediate
                    cancellation of their personal data and of any requests or
                    orders made by him or her, if applicable.
                </p>
                <p className='section__paragraph--policies'>
                    SECURITY MEASURES TAKEN BY THE DATA CONTROLLER TO ENSURE THE
                    CONFIDENTIALITY OF THE DATA
                </p>
                <p className='section__paragraph'>
                    Data Controller adopts the necessary technical and
                    organisational measures to prevent the loss, misuse,
                    alteration, unauthorised access and theft of the personal
                    data provided, as well as to guarantee a level of security
                    appropriate to the risk, taking into account the state of
                    the technology, the costs of implementation, the nature,
                    scope, context and purposes of the processing, as well as
                    the risks of variable probability and seriousness for the
                    rights and freedoms of natural persons.
                </p>
                <p className='section__paragraph--policies'>
                    In the use of the User's personal data, the Data Controller
                    undertakes to respect confidentiality and to use them in
                    accordance with the purpose of their processing, as well as
                    to fulfil its obligation to safeguard them and to adopt all
                    technical and organisational security measures to ensure a
                    level of security appropriate to the risk.
                </p>
                <p className='section__paragraph--policies'>
                    The Data Controller takes particular account of the risks
                    involved in data processing, in particular as a result of
                    the accidental or unlawful destruction, loss or alteration
                    of personal data transmitted, stored or otherwise processed,
                    or the unauthorised communication of or access to such data.
                    The Data Controller takes measures to ensure that any person
                    acting under its authority who has access to the User's
                    personal data may only process such data on the instructions
                    of the Data Controller, unless it is obliged to do so by
                    virtue of the applicable law.
                </p>
                <li className='section__title-li'>
                    Applicable law and competent jurisdiction
                </li>
                <p className='section__paragraph'>
                    These Terms and Conditions shall be governed by Spanish law,
                    unless otherwise determined by legislation applicable to the
                    User.
                </p>
                <p className='section__paragraph--policies'>
                    The parties submit themselves, at their choice, for the
                    resolution of the conflicts and renounce any other
                    jurisdiction, to the courts of Palma de Mallorca, unless the
                    legislation of imperative application to the User determines
                    otherwise.
                </p>
                <li className='section__title-li'>
                    Effective date and modifications to the Terms and Conditions
                </li>
                <p className='section__paragraph'>
                    These Terms of Use were last updated on 11/12/2020.
                </p>
                <p className='section__paragraph--policies'>
                    Fastpayhotels reserves the right to unilaterally modify,
                    correct or change these Terms and Consditions at any time.
                    When this happens, the new Terms and Conditions will be
                    published on the Website as well as the date on which they
                    will take effect in the end of these Terms and Conditions.
                </p>
                <p className='section__paragraph--policies'>
                    We recommend you visit this page regularly to keep up to
                    date with any changes that may occur.
                </p>
                <p className='section__paragraph--policies'>
                    If you do not accept the modifications of these Terms and
                    Conditions, do not use the Website. Your continued use of
                    the Website constitutes your acceptance of these Terms and
                    Conditions and its possibles updates.
                </p>
            </ol>
            <a href='https://www.fastpayhotels.com/' className='d-flex'>
                <button
                    type='button'
                    className='button-submit policies__button mx-auto'
                >
                    Home
                </button>
            </a>
        </div>
    );
}
