import React, {
    createContext,
    useEffect,
    useRef,
    useState,
    useContext,
    useCallback,
} from 'react';
import { validateForm } from '../utils/validateForms';
import endpoints from '../utils/commonVariables/endpoints';
import {
    readOnlyEntities,
    savableEntities,
} from '../utils/commonVariables/entityKeys';
import { ContextUserProfile } from './UserProfileContext';
import api, { cachedApi } from '../services/api';

export const ContextTermsAndConditions = createContext();

export const ProviderTermsAndConditions = props => {
    const { children } = props;

    const { IdHotelOwner } = useContext(ContextUserProfile);

    const [formTermsAndConditions, setFormTermsAndConditions] = useState({
        //Currency
        selectedCurrency: '',
        //Finance Contact
        contactName: '',
        emailAddress: '',
        phoneNumber: '',
        //General Contact
        generalContactPoint: '',
        emailAddressCustomer: '',
        estimatedResponseTime: '',
        //Escalation Contact
        escalationContactPerson: '',
        escalationPersonEmail: '',
        emergencyPhoneNumber: '',
    });
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const [modifiedFields, setModifiedFields] = useState(new Set());
    const [isReadOnly, setIsReadOnly] = useState(false);

    const componentMounted = useRef(true);

    const fieldsTermsAndConditionsToValidate = {
        Currency: {
            value: formTermsAndConditions.selectedCurrency.Id || '',
            validators: ['requiredString'],
        },
        ContactName: {
            value: formTermsAndConditions.contactName || '',
            validators: ['requiredString'],
        },
        EmailAddress: {
            value: formTermsAndConditions.emailAddress || '',
            validators: ['requiredString', 'email'],
        },
        PhoneNumber: {
            value: formTermsAndConditions.phoneNumber || '',
            validators: ['requiredString', 'phone'],
        },
        GeneralContactPoint: {
            value: formTermsAndConditions.generalContactPoint || '',
            validators: ['requiredString'],
        },
        EmailAddressCustomer: {
            value: formTermsAndConditions.emailAddressCustomer || '',
            validators: ['requiredString', 'email'],
        },
        EstimatedResponseTime: {
            value: formTermsAndConditions.estimatedResponseTime || '',
            validators: ['requiredString'],
        },
        EscalationContactPerson: {
            value: formTermsAndConditions.escalationContactPerson || '',
            validators: ['requiredString'],
        },
        EscalationPersonEmail: {
            value: formTermsAndConditions.escalationPersonEmail || '',
            validators: ['requiredString', 'email'],
        },
        EmergencyPhoneNumber: {
            value: formTermsAndConditions.emergencyPhoneNumber || '',
            validators: ['requiredString', 'phone'],
        },
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(
                fieldsTermsAndConditionsToValidate
            );
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const handleChange = ({ target: { name, value } }) => {
        setFormTermsAndConditions(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeCurrency = (e, value) => {
        const selectedValue = value !== null ? value : ''; // Si el valor es null, establecerlo como una cadena vacía
        setFormTermsAndConditions(prevState => ({
            ...prevState,
            selectedCurrency: selectedValue,
        }));
    };

    const searchContacts = useCallback((allContacts, position) => {
        for (let i = 0; i < allContacts.length; i++) {
            if (allContacts[i].Position === position) {
                return allContacts[i];
            }
        }
        return null; // Si no se encuentra ningún contacto con la posición dada
    }, []);

    const getAndSaveValueCurrency = useCallback(async dataViewModel => {
        try {
            const endpoint = `${endpoints.odataService}${readOnlyEntities.Currency}?$select=Id,Name,Key&$filter=Id eq '${dataViewModel?.IdCurrency}'`;
            const { data } = await cachedApi(endpoint);
            setFormTermsAndConditions(prevState => ({
                ...prevState,
                selectedCurrency: data?.value[0],
            }));
        } catch (error) {
            console.log(error);
        }
    }, []);

    const saveViewModelOnState = useCallback(
        dataViewModel => {
            let getGeneralContact;
            let getEscalationContact;
            let getFinanceContact;

            if (dataViewModel?.Contacts?.length > 0) {
                getGeneralContact = searchContacts(
                    dataViewModel?.Contacts,
                    'General Contact'
                );
                getEscalationContact = searchContacts(
                    dataViewModel?.Contacts,
                    'Escalation Contact'
                );
                getFinanceContact = searchContacts(
                    dataViewModel?.Contacts,
                    'Finance Contact'
                );
            }

            setFormTermsAndConditions(prevState => ({
                ...prevState,
                //Currency
                //Finance Contact
                contactName: getFinanceContact?.Name,
                emailAddress: getFinanceContact?.Email,
                phoneNumber: getFinanceContact?.Phone,
                //General Contact
                generalContactPoint: getGeneralContact?.Name,
                emailAddressCustomer: getGeneralContact?.Email,
                estimatedResponseTime: getGeneralContact?.ResponseTime,
                //Escalation Contact
                escalationContactPerson: getEscalationContact?.Name,
                escalationPersonEmail: getEscalationContact?.Email,
                emergencyPhoneNumber: getEscalationContact?.Phone,
            }));
        },
        [searchContacts]
    );

    const getViewModelHotelOwner = useCallback(async () => {
        const viewModel = {
            IdCurrency: true,
            Contacts: {
                Email: true,
                Name: true,
                Phone: true,
                Position: true,
                ResponseTime: true,
            },
        };
        try {
            const endpoint = endpoints.viewModel(
                savableEntities.HotelOwner,
                IdHotelOwner
            );
            const { data } = await api.post(endpoint, viewModel);
            if (!componentMounted.current) return null;
            saveViewModelOnState(data?.Result);
            getAndSaveValueCurrency(data?.Result);
        } catch (error) {
            console.log(error);
        }
    }, [IdHotelOwner, saveViewModelOnState, getAndSaveValueCurrency]);

    useEffect(() => {
        getViewModelHotelOwner();
    }, [getViewModelHotelOwner]);

    //Draggear popup

    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    const handleMouseDown = e => {
        const { clientX, clientY } = e;
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;

        setIsDragging(true);
        setDragOffset({ x: offsetX, y: offsetY });
    };

    const handleMouseMove = e => {
        if (isDragging) {
            const { clientX, clientY } = e;
            const rect = e.currentTarget.parentElement.getBoundingClientRect();
            const newX = clientX - rect.left - dragOffset.x;
            const newY = clientY - rect.top - dragOffset.y;
            e.currentTarget.style.transform = `translate(${newX}px, ${newY}px)`;
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleStopDragging = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        return () => {
            componentMounted.current = false;
        };
    }, []);

    return (
        <ContextTermsAndConditions.Provider
            value={{
                formTermsAndConditions,
                setFormTermsAndConditions,
                errors,
                setErrors,
                isValidated,
                setIsValidated,
                fieldsTermsAndConditionsToValidate,
                handleValidate,
                modifiedFields,
                setModifiedFields,
                handleChange,
                handleChangeCurrency,
                isReadOnly,
                setIsReadOnly,
                searchContacts,
                handleMouseDown,
                handleMouseMove,
                handleMouseUp,
                handleStopDragging,
            }}
        >
            {children}
        </ContextTermsAndConditions.Provider>
    );
};
