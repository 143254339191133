import React, { useContext, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Cookie from './layout/cookiesConsent/CookieConsent';
import CookieConsentConditional from './layout/cookiesConsent/CookieConsentConditional';
import './stylesheets/App.scss';
import { scrollToTop } from './utils/usefulFunctions';
import MuggleContainer from './muggle/MuggleContainer';
import PropertyContainer from './property-partners-portal/PropertyContainer';
import RedirectToOwnerPortal from './utils/RedirectToOwnerPortal';
import Login from './Login';
import PublicSectionContainer from './layout/publicSection/PublicSectionContainer';
import FormForgotPassword from './common/components/forms/FormForgotPassword';
import LandingContact from './common/components/forms/formContact/LandingContact';
import NotificationsBar from './common/components/NotificationsBar';
import routes from './utils/commonVariables/routes';
import ServiceDesk from './publicSections/serviceDesk/ServiceDesk';
import { ProviderServiceDesk } from './contexts/ServiceDeskContext';
import SignUp from './publicSections/signUp/SignUp';
import { ProviderSignUp } from './contexts/SignUpContext';
import { ProviderLanding } from './contexts/LandingContext';
import FastCheckIn from './publicSections/fastCheckIn/FastCheckIn';
import { ProviderFastCheckIn } from './contexts/FastCheckInContext';
import { ProviderContact } from './contexts/ContactContext';
import Contact from './publicSections/contact/Contact';
import Footer from './layout/footer/Footer';
import GeneralHeader from './common/components/header/GeneralHeader';
import { ContextUserProfile } from './contexts/UserProfileContext';
import { menuPropertyPublic, menuPropertyPrivate } from './utils/menusData';
import { ContextGlobalFeatures } from './contexts/GlobalFeatures';

function App({ location }) {
    const { isLogged } = useContext(ContextUserProfile);
    const isEditting = window.location.pathname.includes('/your-property/');
    const { isMuggle } = useContext(ContextGlobalFeatures);

    useEffect(() => {
        scrollToTop();
    }, [location.pathname]);

    return (
        <div className='App'>
            <GeneralHeader
                isMuggle={isMuggle}
                menuData={isLogged ? menuPropertyPrivate : menuPropertyPublic}
                isVisible={!isEditting}
            />
            <Switch>
                <Route
                    path={routes.cookieConsent}
                    component={CookieConsentConditional}
                />
                <Route
                    path='/(property|owner-portal|your-property)'
                    render={props => (
                        // coloco aquí el provider para que se cargue al entrar en la zona privada y no se monte y desmonte todo el rato y no hacer peticiones innecesarias
                        <ProviderLanding>
                            <PropertyContainer {...props} />
                        </ProviderLanding>
                    )}
                />
                <Route
                    path={routes.fastCheckIn}
                    render={() => (
                        <PublicSectionContainer>
                            <ProviderFastCheckIn>
                                <FastCheckIn />
                            </ProviderFastCheckIn>
                        </PublicSectionContainer>
                    )}
                />
                <Route
                    path={routes.forgotPassword}
                    render={() => (
                        <PublicSectionContainer>
                            <FormForgotPassword />
                        </PublicSectionContainer>
                    )}
                />
                <Route
                    path={routes.contactForm}
                    render={() => (
                        <PublicSectionContainer>
                            <ProviderContact>
                                <Contact />
                            </ProviderContact>
                        </PublicSectionContainer>
                    )}
                />
                <Route
                    path={routes.contact}
                    render={() => (
                        <PublicSectionContainer>
                            <LandingContact />
                        </PublicSectionContainer>
                    )}
                />
                <Route
                    path={routes.serviceDesk}
                    render={() => (
                        <PublicSectionContainer>
                            <ProviderServiceDesk>
                                <ServiceDesk />
                            </ProviderServiceDesk>
                        </PublicSectionContainer>
                    )}
                />
                <Route
                    path={routes.signUp}
                    render={() => (
                        <PublicSectionContainer>
                            <ProviderSignUp>
                                <SignUp />
                            </ProviderSignUp>
                        </PublicSectionContainer>
                    )}
                />
                <Route
                    path='/redirect=/:token/:sid/:rol/:IdHotelOwner'
                    component={RedirectToOwnerPortal}
                />
                <Route path={routes.login} component={Login} />
                <Route
                    path='/'
                    render={props => <MuggleContainer {...props} />}
                />
            </Switch>
            <Footer isMuggle={isMuggle} />
            <Cookie />
            <NotificationsBar />
        </div>
    );
}

export default withRouter(App);
