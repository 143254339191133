import React, {
    createContext,
    useEffect,
    useLayoutEffect,
    useState,
    useContext,
    useCallback,
} from 'react';
import {
    IdHotelOwner as IdHotelOwnerFromLocalStorage,
    getAreLocalStorageUserData,
    lsToken as tokenFromLocalStorage,
    checkCredentials,
    getInitialLang,
    IdSystemUser as IdSystemUserFromLocalStorage,
    getNavigatorLanguage,
} from '../utils/userProfileContextHelpers';
import convertToOvariant from '../utils/convertToOvariant';
import api, { cachedApi } from '../services/api';
import endpoints from '../utils/commonVariables/endpoints';
import { ContextGlobalFeatures } from './GlobalFeatures';
import { parseJson } from '../utils/usefulFunctions';

import EN from '../utils/multilanguageI18n/en.json';
import FR from '../utils/multilanguageI18n/fr.json';
import ES from '../utils/multilanguageI18n/es.json';
import DE from '../utils/multilanguageI18n/de.json';
import {
    savableEntities,
    viewModelEntities,
} from '../utils/commonVariables/entityKeys';

const translations = {
    en: EN,
    fr: FR,
    es: ES,
    de: DE,
};

const getTranslation =
    (langCode = 'en') =>
    key => {
        return translations[langCode][key] || key;
    };

export const ContextUserProfile = createContext();
// contexto para todo lo relacionado con el usuario. Login, settings, language...

export const ProviderUserProfile = props => {
    const { children } = props;
    const { setIsLoadingInterface } = useContext(ContextGlobalFeatures);

    ///////COSAS RELACIONADAS CON EL LOGING
    const [isLogged, setIsLogged] = useState(null);

    const [IdSystemUser, setIdSystemUser] = useState(
        IdSystemUserFromLocalStorage
    );
    const [IdHotelOwner, setIdHotelOwner] = useState(
        IdHotelOwnerFromLocalStorage
    );
    const [token, setToken] = useState(tokenFromLocalStorage);

    useLayoutEffect(() => {
        checkIfLoggedIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIfLoggedIn = async () => {
        if (getAreLocalStorageUserData()) {
            api.ChangeAxiosToken(token);
            cachedApi.ChangeAxiosToken(token);
            const validToken = await checkCredentials();
            setIsLogged(validToken);
        } else {
            setIsLogged(false);
        }
    };

    const getSignedTermsConditions = useCallback(async () => {
        const contract = {
            Signed: true,
        };
        const endpoint = endpoints.viewModel(
            viewModelEntities.HotelOwner,
            IdHotelOwner
        );
        const { data } = await api.post(endpoint, contract);
        return data?.Result?.Signed;
    }, [IdHotelOwner]);

    const [settings, setSettings] = useState({
        idUserProfile: 0,
        language: getInitialLang(),
        timeZone: 'Europe/Madrid',
        signed: false,
    });
    const { language, timeZone, idUserProfile } = settings;

    // llamada general para guardar settings en la base de datos
    const saveSettings = useCallback(
        async (settingsToSend, oldSettings) => {
            delete settingsToSend.idUserProfile;
            const newSystemUser = {
                UserProfile: {
                    ProfileData: JSON.stringify(settingsToSend),
                },
            };

            const contract = {
                UserProfile: { ProfileData: true },
            };

            const objToSend = convertToOvariant(
                savableEntities.SystemUser,
                newSystemUser,
                IdSystemUser,
                contract
            );
            try {
                let { status } = await api.post(
                    endpoints.commonSave,
                    objToSend
                );
                if (status !== 201) {
                    throw new Error();
                }
            } catch (error) {
                setSettings(oldSettings);
                console.log(error);
                //REFACTORIZAR NOTIFICATIONS CONTEXT PARA QUE NO USE TRASLATE, PODER PONERLO POR ENCIMA DE ESTE CONTEXTO
                //Y LANZAR EL ERROR AQUI
            }
        },
        [IdSystemUser]
    );

    //cambias una clave y la guardas directamente en la base de datos
    const saveNewSettings = useCallback(
        async (newInfo, key) => {
            let oldSettings = {};
            setSettings(prevSettings => {
                oldSettings = { ...prevSettings };
                return { ...prevSettings, [key]: newInfo };
            });

            let newData = { ...oldSettings, [key]: newInfo };
            return saveSettings(newData, oldSettings);
        },
        [saveSettings]
    );

    const setAndSaveSignedProperty = useCallback(
        signed => {
            setSettings(prevSet => {
                return {
                    ...prevSet,
                    signed: signed,
                };
            });
            saveNewSettings(signed, 'signed');
        },
        [saveNewSettings]
    );

    const getProfile = useCallback(async () => {
        setIsLoadingInterface(true);
        try {
            const contract = {
                IdUserProfile: true,
                UserProfile: {
                    ProfileData: true,
                },
            };
            const { data } = await api.post(
                endpoints.viewModel(viewModelEntities.SystemUser, IdSystemUser),
                contract
            );

            let profileData = parseJson(data?.Result?.UserProfile?.ProfileData);
            const isSigned = profileData?.signed;
            let idUserProfile = data?.Result?.IdUserProfile;
            const isEmptyProfileData = !Object.keys(profileData).length;

            if (isSigned) {
                const isSignedOwner = await getSignedTermsConditions();
                if (isSignedOwner === isSigned) {
                    setSettings(prevSet => {
                        return {
                            ...prevSet,
                            idUserProfile: idUserProfile,
                            language: profileData?.language,
                            timeZone: profileData?.timeZone,
                            signed: profileData?.signed,
                        };
                    });
                } else {
                    setAndSaveSignedProperty(isSignedOwner);
                }
            }

            if (isEmptyProfileData) {
                const isSignedOwner = await getSignedTermsConditions();

                const defaultLanguage = getNavigatorLanguage(
                    navigator.language
                );
                const defaultTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                const newSystemUserData = {
                    ...data?.Result,
                    UserProfile: {
                        ...data?.Result?.UserProfile,
                        ProfileData: JSON.stringify({
                            language: defaultLanguage,
                            timeZone: defaultTimezone,
                            signed: isSignedOwner,
                        }),
                    },
                };
                setSettings(prevSet => {
                    return {
                        ...prevSet,
                        idUserProfile: idUserProfile,
                        language: defaultLanguage,
                        timeZone: defaultTimezone,
                        signed: isSignedOwner,
                    };
                });
                const objToSend = convertToOvariant(
                    savableEntities.SystemUser,
                    newSystemUserData,
                    IdSystemUser,
                    contract
                );
                let {
                    data: { IdUserProfile },
                } = await api.post(endpoints.commonSave, objToSend);
                profileData = newSystemUserData.UserProfile.ProfileData;
                idUserProfile = IdUserProfile;
            } else if (profileData && !isSigned) {
                const isSignedOwner = await getSignedTermsConditions();
                setAndSaveSignedProperty(isSignedOwner);
            }
            setIsLoadingInterface(false);
        } catch (error) {
            setIsLoadingInterface(false);
            console.log(error);
        }
    }, [
        setIsLoadingInterface,
        getSignedTermsConditions,
        IdSystemUser,
        setAndSaveSignedProperty,
    ]);

    useEffect(() => {
        if (isLogged) {
            getProfile();
        }
    }, [isLogged, getProfile]);

    ///// COSAS RELACIONADAS CON LOS SETTINGS
    const setLanguage = lang => {
        setSettings(prevSet => {
            return { ...prevSet, language: lang };
        });
    };

    const setTimeZone = timeZoneKey => {
        setSettings(prevSet => {
            return { ...prevSet, timeZone: timeZoneKey };
        });
    };

    useLayoutEffect(() => {
        // revisar si esto es necesario => setLanguage(language);
        api.ChangeAxiosLanguage(language);
        cachedApi.ChangeAxiosLanguage(language);
    }, [language]);

    const translate = getTranslation(language);

    return (
        <ContextUserProfile.Provider
            value={{
                isLogged,
                IdHotelOwner,
                translate,
                language,
                timeZone,
                setTimeZone,
                setSettings,
                saveNewSettings,
                idUserProfile,
                setLanguage,
                setIsLogged,
                settings,
                saveSettings,
                IdSystemUser,
                token,
                setIdSystemUser,
                setIdHotelOwner,
                setToken,
            }}
        >
            {children}
        </ContextUserProfile.Provider>
    );
};
