import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import '../../../stylesheets/stylesComponents/dateRangeFilter.scss';
import { boundaryDevice } from '../../../stylesheets/variables.scss';
import { makeStyles } from '@material-ui/core/styles';
import { jungleGreen } from '../../../stylesheets/variables.scss';
import { capitalizeSentence } from '../../../utils/usefulFunctions';

const DateRangeFilter = ({
    value,
    id,
    onChange,
    label,
    fromInputProps,
    toInputProps,
    helperText,
    ...restProps
}) => {
    const { language, translate } = useContext(ContextUserProfile);
    const languageSettings = {
        en: { locale: enLocale, mask: '__/__/____' },
        fr: { locale: frLocale, mask: '__/__/____' },
        es: { locale: esLocale, mask: '__/__/____' },
        de: { locale: deLocale, mask: '__.__.____' },
    };
    const locale = languageSettings[language].locale;
    const mask = languageSettings[language].mask;

    const useStyles = makeStyles(() => ({
        input: {
            '& label.Mui-focused': {
                color: jungleGreen,
                boxShadow: 'unset !important;',
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: jungleGreen,
                    boxShadow: 'unset !important;',
                },
                '&.Mui-focused fieldset': {
                    borderColor: jungleGreen,
                    boxShadow: 'unset !important;',
                },
            },
        },
    }));

    const classes = useStyles();

    const startText = `${capitalizeSentence(translate(label))} ${translate(
        'from'
    )}`;
    const endText = translate('to');
    const desktopModeMediaQuery = `@media (min-width: ${boundaryDevice})`;

    const renderInput = (startProps, endProps) => (
        <>
            <TextField
                {...startProps}
                size='small'
                autoComplete='off'
                className={classes.input}
                {...fromInputProps}
            />
            <TextField
                {...endProps}
                size='small'
                autoComplete='off'
                className={classes.input}
                {...toInputProps}
            />
        </>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <DateRangePicker
                calendars={2}
                startText={startText}
                endText={endText}
                mask={mask}
                value={value}
                clearable
                desktopModeMediaQuery={desktopModeMediaQuery}
                onChange={val => {
                    const e = {
                        target: {
                            name: id,
                            value: val,
                        },
                    };
                    onChange(e);
                }}
                renderInput={renderInput}
                {...restProps}
            />
            <p style={{ height: '30px' }}>{helperText}</p>
        </LocalizationProvider>
    );
};

export default DateRangeFilter;
