import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@material-ui/core/styles';

const WithTooltip = ({
    children,
    title,
    containerStyles,
    textStyles,
    ...rest
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const showTooltip = ({ currentTarget }) => setAnchorEl(currentTarget);

    const closeTooltip = () => setAnchorEl(null);

    const classes = makeStyles({
        paper: {
            backgroundColor: '#3b3a39 !important',
            padding: '2px 6px',
        },
    })();
    const isVisible = !!anchorEl;

    const tooltipTextDefaultStyles = { color: 'white', fontSize: '0.9rem' };
    const tooltipTextMergedStyles = {
        ...tooltipTextDefaultStyles,
        ...textStyles,
    };

    return (
        <div
            style={containerStyles}
            onMouseEnter={showTooltip}
            onMouseLeave={closeTooltip}
            onClick={closeTooltip}
        >
            {children}
            {isVisible && (
                <Popover
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={isVisible}
                    classes={classes}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    onClose={closeTooltip}
                    {...rest}
                >
                    <span style={tooltipTextMergedStyles}>{title}</span>
                </Popover>
            )}
        </div>
    );
};

export default WithTooltip;
