import React, { useContext } from 'react';
import CardItem from './CardItem';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import '../../../stylesheets/stylesComponents/card.scss';

const Card = props => {
    const { cardTitle, cardItems } = props;
    const { translate } = useContext(ContextUserProfile);
    return (
        <div className='card'>
            {cardTitle && (
                <h2 className='card--title'>{translate(cardTitle)}</h2>
            )}
            <ul className='w-100'>
                {cardItems.map((cardItem, i) => (
                    <li className='card__item' key={i}>
                        <CardItem
                            Name={translate(cardItem.name)}
                            Value={translate(cardItem.value)}
                            Headers={translate(cardItem.headers)}
                            button={cardItem.button}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Card;
