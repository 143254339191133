import React, { useContext, useState } from 'react';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import Button from '@mui/material/Button';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';
import encodeBase64 from '../../utils/encodeBase64';
import api from '../../services/api';
import { validateForm } from '../../utils/validateForms';
import { isEmptyObject } from '../../utils/usefulFunctions';

const ServiceDeskStep1 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { setErrorMessage } = useContext(NotificationsContext);
    const { setIsFetching, inputsData, setInputsData, setStep } =
        useContext(ContextServiceDesk);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);

    const fieldsToValidate = {
        BookingCode: {
            value: inputsData.BookingCode,
            validators: ['bookingCode'],
        },
    };
    const checkBooking = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            await sendBooking();
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const sendBooking = async () => {
        const bookingEncoded = encodeBase64(inputsData.BookingCode);
        const url = `${publicEndpoints.customerServiceCheckBooking}${bookingEncoded}`;
        try {
            setIsFetching(true);
            const { data } = await api.get(url);
            setIsFetching(false);
            if (isEmptyObject(data.Result)) {
                setErrorMessage(`${translate('booking_code_does_not_exist')}`);
                setInputsData(prevState => ({ ...prevState, BookingCode: '' }));
            } else {
                const { TravellerName, CheckInDate, CheckOutDate } =
                    data.Result;

                setInputsData(prevState => ({
                    ...prevState,
                    TravellerName: TravellerName || '',
                    CheckInDate: new Date(CheckInDate) || null,
                    CheckOutDate: new Date(CheckOutDate) || null,
                }));
                setStep(2);
            }
        } catch (error) {
            setErrorMessage('something_went_wrong');
            setInputsData(prevState => ({ ...prevState, BookingCode: '' }));
        }
    };

    return (
        <div className='step-content__container'>
            <TextInput
                labelText='booking_code'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, BookingCode: value };
                    });
                }}
                value={inputsData.BookingCode}
                errorMessage={errors.BookingCode}
                onBlur={handleValidate}
                required
            />
            <p>
                {translate("don't_have_one?")}
                <span
                    role='link'
                    className={'linkStyle'}
                    onClick={() => setStep(2)}
                >
                    {translate('click_here_manually')}
                </span>
            </p>
            <Button
                variant='contained'
                onClick={checkBooking}
                style={{ alignSelf: 'flex-end' }}
            >
                {translate('next')}
            </Button>
        </div>
    );
};

export default ServiceDeskStep1;
