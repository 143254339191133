import React, { useContext } from 'react';
import { ContextTermsAndConditions } from '../../../contexts/TermsAndConditionsContext';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import TextInput from '../../../muiComponents/TextInput/TextInput';

const FinanceTermsAndConditionsForm = () => {
    const {
        formTermsAndConditions,
        handleChange,
        handleValidate,
        setIsValidated,
        errors,
        isReadOnly,
    } = useContext(ContextTermsAndConditions);
    const { translate } = useContext(ContextUserProfile);

    const handleValidateField = () => {
        setIsValidated(true);
        handleValidate();
    };

    return (
        <div className='form__container--tac'>
            <p>Finance Contact</p>
            <TextInput
                labelText={translate('contact_person')}
                handleChange={e => handleChange(e)}
                value={formTermsAndConditions.contactName || ''}
                errorMessage={errors.ContactName}
                onBlur={handleValidateField}
                name='contactName'
                id='contactName'
                required
                inputProps={{
                    readOnly: isReadOnly,
                    disabled: isReadOnly,
                }}
                deleteIcon={!isReadOnly}
            />
            <TextInput
                labelText={translate('email_address')}
                handleChange={e => handleChange(e)}
                value={formTermsAndConditions.emailAddress || ''}
                errorMessage={errors.EmailAddress}
                onBlur={handleValidateField}
                name='emailAddress'
                id='emailAddress'
                required
                inputProps={{
                    readOnly: isReadOnly,
                    disabled: isReadOnly,
                }}
                deleteIcon={!isReadOnly}
            />
            <TextInput
                labelText={translate('phone')}
                handleChange={e => handleChange(e)}
                value={formTermsAndConditions.phoneNumber || ''}
                errorMessage={errors.PhoneNumber}
                onBlur={handleValidateField}
                name='phoneNumber'
                id='phoneNumber'
                required
                inputProps={{
                    readOnly: isReadOnly,
                    disabled: isReadOnly,
                }}
                deleteIcon={!isReadOnly}
            />
        </div>
    );
};

export default FinanceTermsAndConditionsForm;
