import React, { useContext } from 'react';
import { ContextServiceDesk } from '../../contexts/ServiceDeskContext';
import ServiceDeskStep1 from './ServiceDeskStep1';
import ServiceDeskStep2 from './ServiceDeskStep2';
import ServiceDeskStep3 from './ServiceDeskStep3';
import ServiceDeskStep4 from './ServiceDeskStep4';

const ServiceDeskStepsContainer = () => {
    const { step } = useContext(ContextServiceDesk);

    const serviceDeskStep = {
        1: <ServiceDeskStep1 />,
        2: <ServiceDeskStep2 />,
        3: <ServiceDeskStep3 />,
        4: <ServiceDeskStep4 />,
    };
    return <div>{serviceDeskStep[step]}</div>;
};

export default ServiceDeskStepsContainer;
