import React, { useContext } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
import roomsConfigurationBeds from '../../../../utils/GeneralEditForm/RoomsConfigurationBeds';
import PaintInputs from '../../components/PaintInputs';

export default function Beds() {
    const { translate } = useContext(ContextUserProfile);
    const { handleInputsRoomInfo, singleRoomData } =
        useContext(ContextOwnerPortal);

    // Json DescriptionSegments
    const elements = roomsConfigurationBeds.Elements;
    // const titulo = roomsConfigurationBeds.entityTitle;

    return (
        <div className='your-properties-form'>
            {/* <h1 className='your-properties-title'>{translate(titulo)}</h1> */}
            {PaintInputs(elements, singleRoomData, handleInputsRoomInfo)}
            <div className='d-flex justify-content-between'>
                <GeneralButton type='back' text={translate('back')} />
                <GeneralButton type='next' text={translate('continue')} />
            </div>
        </div>
    );
}
