import React, { useContext, useEffect, useState, useCallback } from 'react';
import EmptyTypeahead from '../../muiComponents/EmptyTypeahead';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';
import { ContextSignUp } from '../../contexts/SignUpContext';
import { debounce } from '../../utils/usefulFunctions';
import api from '../../services/api';

const HotelsTypeahead = ({ onBlur, error }) => {
    const {
        setInputsData,
        hotelInputValue,
        setHotelInputValue,
        selectedHotelOption,
        setSelectedHotelOption,
        hotelOptions,
        setHotelOptions,
    } = useContext(ContextSignUp);

    const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState();
    const [cancelToken, setCancelToken] = useState(null);

    const getHotels = async () => {
        if (cancelToken) {
            cancelToken.cancel();
        }
        const cancelTokenLocal = api.CancelToken.source();
        setCancelToken(cancelTokenLocal);
        const cancelConfig = {
            cancelToken: cancelTokenLocal.token,
        };

        // try {
        setIsLoading(true);
        const { data } = await api.get(
            publicEndpoints.signUpFormHotelsTypeAhead(hotelInputValue.trim()),
            cancelConfig
        );
        setHotelOptions(data.Result);
        setIsLoading(false);
        // setError('');
        // }
        //  catch (error) {
        //     console.log(error);
        //     console.log(Object.keys(error));
        //     setIsLoading(false);
        //     setError('something_went_wrong');
        // }
    };

    const debounceGetHotels = useCallback(debounce(getHotels, 200), [
        hotelInputValue,
    ]);

    useEffect(() => {
        if (hotelInputValue.trim().length > 2 && !selectedHotelOption) {
            debounceGetHotels();
        }
    }, [hotelInputValue, debounceGetHotels, selectedHotelOption]);

    const changeInputValue = string => {
        if (string) {
            setInputsData(prev => {
                return {
                    ...prev,
                    HotelName: string,
                };
            });
        } else {
            setInputsData(prev => {
                return {
                    ...prev,
                    IdHotel: null,
                    IdOwnerType: 0,
                    IdCountry: 0,
                    HotelName: '',
                    City: '',
                    IdCity: 0,
                };
            });
            setSelectedHotelOption(null);
        }
        setHotelInputValue(string);
    };

    return (
        <EmptyTypeahead
            labelText='property_or_company_name'
            options={hotelOptions}
            setInputValue={changeInputValue}
            inputValue={hotelInputValue}
            optionsLabelKey={'Name'}
            errorMessage={error}
            loading={isLoading}
            onChange={(e, value) => {
                const { IdHotel, IdOwnerType, IdCountry, CityName, IdCity } =
                    value;

                setInputsData(prev => {
                    return {
                        ...prev,
                        IdHotel,
                        IdOwnerType,
                        IdCountry,
                        City: CityName,
                        IdCity,
                    };
                });
                setSelectedHotelOption(value);
            }}
            freeSolo
            onBlur={onBlur}
            required
        />
    );
};

export default HotelsTypeahead;
