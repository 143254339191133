import React, { useState } from 'react';
import '../../stylesheets/jobsMuggle.scss';

export default function CardJobs({ job }) {
    const [show, setShow] = useState(false);

    return (
        <>
            <h2 className='jobs-title font-weight-bold'>{job.Title}</h2>
            <div className='text-justify job jobs-report m-0'>
                <span className='font-weight-bold'>
                    Report to {job.ReportTo} - {job.Duration} - Ref.
                    {job.Reference}
                </span>
            </div>
            <div className='text-justify jobs-departament m-0'>
                <span className=''>Departament:</span> {job.Department}
            </div>
            <div className='text-justify jobs-location m-0'>
                <span className=''>Location:</span> {job.Location}
            </div>
            <div
                className='text-justify jobs-short m-0'
                dangerouslySetInnerHTML={{ __html: `${job.ShortDescription}` }}
            />
            <button
                type='button'
                className='jobsMuggle-boton'
                onClick={() => {
                    setShow(!show);
                }}
            >
                {show ? 'close' : 'More details...'}
            </button>
            {show && (
                <div>
                    <div className='prueba justify-text m-0'>
                        <h3 className='jobs-title pb-2 font-weight-bold mt-0'>
                            Main Responsabilities
                        </h3>
                        <div
                            className='text-justify'
                            dangerouslySetInnerHTML={{
                                __html: `${job.Functions}`,
                            }}
                        />
                        <h3 className='jobs-title pb-2 font-weight-bold'>
                            Skills Required
                        </h3>
                        <div
                            className='text-justify'
                            dangerouslySetInnerHTML={{
                                __html: `${job.Skills}`,
                            }}
                        />
                        <h3 className='font-weight-bold'>
                            Experience Required
                        </h3>
                        <div
                            className='text-justify'
                            dangerouslySetInnerHTML={{
                                __html: `${job.ExperienceRequired}`,
                            }}
                        />
                        <h5 className='jobs-title font-weight-bold'>
                            Please apply by sending CV + cover letter to:
                        </h5>
                        <h5 className='jobs-title font-weight-bold'>
                            hr@fastpayhotels.com
                        </h5>
                    </div>
                </div>
            )}
        </>
    );
}
