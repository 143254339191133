import React, { useCallback, useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { cachedApi } from '../../../../services/api';
import { ContextAri } from '../../../contextsProperty/ContextAri';
import './typeAhead.scss';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { formatHotels } from '../ariHelpers';
import { makeStyles } from '@material-ui/core/styles';
import {
    jungleGreen,
    colorError,
    greenSelected,
    greenSelectedDark,
} from '../../../../stylesheets/variables.scss';
import {
    capitalizeSentence,
    debounce,
    encodeURIOdata,
} from '../../../../utils/usefulFunctions';
import TypeaheadErrorMessage from './TypeaheadErrorMessage';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';

const TypeAhead = ({
    options: totalOptions,
    isSmallOwner,
    loading,
    errorOnLoad,
    ...rest
}) => {
    const { setFilters, reloadSH, validationErrors } = useContext(ContextAri);
    const { translate, IdHotelOwner } = useContext(ContextUserProfile);

    const [queryOptions, setQueryOptions] = useState(totalOptions);
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);

    const isLoading = loading || isLoadingOptions;
    // Dependen de si es big owner
    const options = isSmallOwner ? totalOptions : queryOptions;
    const onInputChange = (_e, value) => {
        if (isSmallOwner) return;
        setIsLoadingOptions(true);
        debounceOnChange(value);
    };

    useEffect(() => {
        setQueryOptions(totalOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmallOwner]);

    const searchHotels = async value => {
        const border = 50;
        const encodedValue = encodeURIOdata(value);
        const endPoint = buildOdataEndPoint({
            entity: readOnlyEntities.SupplierHotel,
            select: 'MappingCode,Name,Id,Hotel/Code,IdHotel',
            filter: {
                and: [
                    `IdHotelOwner eq ${IdHotelOwner}`,
                    {
                        or: [
                            `substringof('${encodedValue}', Name)`,
                            `substringof('${encodedValue}', MappingCode)`,
                        ],
                    },
                ],
            },
            orderBy: 'Name asc',
            expand: readOnlyEntities.Hotel,
            top: border,
        });
        if (cancelToken) {
            cancelToken.cancel();
        }
        const cancelTokenLocal = cachedApi.CancelToken.source();
        setCancelToken(cancelTokenLocal);
        const cancelConfig = {
            cancelToken: cancelTokenLocal.token,
        };
        const { data } = await cachedApi.get(endPoint, cancelConfig);
        setIsLoadingOptions(false);
        setQueryOptions(data.value.map(formatHotels));
    };

    const debounceOnChange = useCallback(debounce(searchHotels, 500), [
        cancelToken,
    ]);

    const classes = makeStyles({
        root: {
            '& label.Mui-focused': {
                color: jungleGreen,
            },
            '& label.Mui-focused.Mui-error': {
                color: colorError,
            },
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: jungleGreen,
                },
                '&.Mui-focused fieldset': {
                    borderColor: jungleGreen,
                },
                '&.Mui-focused.Mui-error fieldset': {
                    borderColor: colorError,
                },
                '&.Mui-focused fieldset.Mui-error': {
                    borderColor: colorError,
                },
            },
        },
        option: {
            '&[aria-selected="true"]': {
                backgroundColor: `${greenSelected} !important`,
            },
            '&[aria-selected="true"]:hover': {
                backgroundColor: `${greenSelectedDark} !important`,
            },
        },
    })();

    const isOptionEqualToValue = (option, currentValue) => {
        const haveSameCode = option.Cyrenne === currentValue.Cyrenne;
        const haveSameIdSH =
            option.IdSupplierHotel === currentValue.IdSupplierHotel;
        return haveSameCode && haveSameIdSH;
    };

    const getOptionLabel = option =>
        option ? `${option.Name} - ${option.MappingCode}` : '';

    const onChange = (_e, option) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            code: option?.Cyrenne,
            idSupplierHotel: option?.IdSupplierHotel,
            MappingCode: option?.MappingCode,
            Name: option?.Name,
            idHotel: option?.IdHotel,
        }));
    };

    const renderInput = params => {
        const hasErrorMessage = !!validationErrors['typeahead'];
        const hotelNameLabel = capitalizeSentence(translate('hotel_name'));
        const mappingCodeLabel = 'Mapping Code';
        const label = `${hotelNameLabel} - ${mappingCodeLabel}`;
        const placeholder = `${hotelNameLabel} ${translate(
            'or'
        )} ${mappingCodeLabel}`;
        return (
            <TextField
                error={hasErrorMessage}
                label={label}
                placeholder={placeholder}
                {...params}
            />
        );
    };

    const noOptionsText = errorOnLoad ? (
        <TypeaheadErrorMessage onClick={reloadSH} />
    ) : (
        translate('no_results')
    );

    const loadingText = `${translate('loading')}...`;

    return (
        <Autocomplete
            size='small'
            options={options}
            clearOnBlur
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            classes={classes}
            onInputChange={onInputChange}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={renderInput}
            loadingText={loadingText}
            {...rest}
            loading={isLoading}
            noOptionsText={noOptionsText}
        />
    );
};

export default TypeAhead;
