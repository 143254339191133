import React, { useContext } from 'react';
import {
    formatNumberByLanguage,
    getOrdinalSuffix,
} from '../../../../utils/usefulFunctions';
import CardList from './CardList';
import IconDetail from './IconDetail';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import WithTooltip from '../WithTooltip';
import { ageSegmentTypes } from '../ariHelpers';

const PerPerson = ({ persons }) => {
    const { translate, language } = useContext(ContextUserProfile);
    const { rates = [] } = persons;

    const adultPersonType = 10;
    const childPersonType = 8;

    const isAdult = IdPersonType => IdPersonType === adultPersonType;
    const isChild = IdPersonType => IdPersonType === childPersonType;

    const adults = rates
        .filter(({ personType }) => isAdult(personType))
        .sort((a, b) => a.personIndex - b.personIndex); // ordenados por personIndex

    const children = rates
        .filter(({ personType }) => isChild(personType))
        .sort((a, b) => {
            if (a.ageSegment === b.ageSegment)
                return a.personIndex - b.personIndex;
            return a.ageSegment - b.ageSegment; // ordenados por ageSegment y luego por personIndex
        });

    const basePrice = adults.find(({ personIndex }) => personIndex === 1);
    const singleUse = adults.filter(({ personIndex }) => personIndex === 0);
    const additionalAdults = adults.filter(
        ({ personIndex }) => personIndex > 1
    );

    const IconBaseOccupancy = () => {
        const additionalAdultsPersonIndexList = additionalAdults.map(
            item => item.personIndex
        );
        if (additionalAdultsPersonIndexList.length === 0) return null;
        const min = Math.min(...additionalAdultsPersonIndexList);
        const numBaseOccupancy = min - 1;
        const title = `${translate(
            'base_occupancy'
        )} ${numBaseOccupancy} ${translate(
            numBaseOccupancy === 1 ? 'adult' : 'Adults'
        ).toLowerCase()}`;

        const numIcons = [];
        numIcons.length = numBaseOccupancy;
        numIcons.fill(numBaseOccupancy);
        return (
            <WithTooltip
                title={title}
                containerStyles={{ display: 'flex' }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                {numIcons.map((_item, index) => (
                    <IconDetail iconName='adult' key={index} />
                ))}
            </WithTooltip>
        );
    };

    const BasePriceRow = ({ aat }) => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                    }}
                >
                    <IconDetail iconName='home' />
                    <IconBaseOccupancy />
                </div>
                <span>{formatNumberByLanguage(aat, language)}</span>
            </>
        );
    };

    const SingleUseRow = ({ aat }) => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                    }}
                >
                    <span
                        style={{
                            display: 'inline-block',
                            width: 26,
                            lineHeight: '9px',
                        }}
                    >
                        SU
                    </span>
                    <WithTooltip
                        title={translate('single_use')}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                    >
                        <IconDetail iconName='adult' />
                    </WithTooltip>
                </div>
                <span>{formatNumberByLanguage(aat, language)}</span>
            </>
        );
    };

    const singleUseList = singleUse.map((item, index) => (
        <SingleUseRow key={index} aat={item.aat} />
    ));

    const restAdultsRows = additionalAdults.map(restAdultRow => {
        const ordinal = getOrdinalSuffix(restAdultRow.personIndex);
        const price = `+${formatNumberByLanguage(restAdultRow.aat, language)}`;
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                    }}
                >
                    <span
                        style={{
                            display: 'inline-block',
                            width: 26,
                            lineHeight: '9px',
                        }}
                    >
                        {ordinal}
                    </span>

                    <WithTooltip
                        title={translate('adult')}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                    >
                        <IconDetail iconName='adult' />
                    </WithTooltip>
                </div>
                <span>{price}</span>
            </>
        );
    });

    const childrenRows = children.map(child => {
        const ordinal = getOrdinalSuffix(child.personIndex);
        const price = `+${formatNumberByLanguage(child.aat, language)}`;
        const ageSegmentType = ageSegmentTypes(child.ageSegment);
        const iconName = ageSegmentType.iconName;
        const title = translate(ageSegmentType.tooltipText);
        const ageRange = ageSegmentType.ageRange;

        const Icons = () => {
            // Si tiene rango le pongo tooltip al icono del rango, si no se lo pongo al icono
            if (ageRange) {
                return (
                    <IconDetail
                        iconName={iconName}
                        ageRange={ageRange}
                        title={title}
                    />
                );
            }
            return (
                <WithTooltip
                    title={title}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                >
                    <IconDetail iconName={iconName} />
                </WithTooltip>
            );
        };

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                    }}
                >
                    <span
                        style={{
                            display: 'inline-block',
                            width: 26,
                            lineHeight: '9px',
                        }}
                    >
                        {ordinal}
                    </span>
                    <Icons />
                </div>
                <span>{price}</span>
            </>
        );
    });

    const items = [
        <BasePriceRow key='base-price' aat={basePrice.aat} />,
        ...singleUseList,
        ...restAdultsRows,
        ...childrenRows,
    ];

    return <CardList items={items} />;
};

export default PerPerson;
