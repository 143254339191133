import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import useTranslate from '../../customHooks/useTranslate';

const LoadingV2 = () => {
    return (
        <>
            <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
                className='spinner--typeahead'
            />
            <p className='loading__text'>{`${useTranslate('loading')}`}</p>
        </>
    );
};

export default LoadingV2;
