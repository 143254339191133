import React, { useContext, useState, useEffect } from 'react';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { formatDateTime } from '../../utils/usefulFunctions';

const Clock = () => {
    const { language, timeZone } = useContext(ContextUserProfile);

    const [date, setDate] = useState(new Date());

    function refreshClock() {
        setDate(new Date());
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <p> {formatDateTime(date, language, timeZone)}</p>
        </div>
    );
};

export default Clock;
