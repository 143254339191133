import React, { createContext, useState, useContext } from 'react';
import { ContextUserProfile } from './UserProfileContext';

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const { translate } = useContext(ContextUserProfile);
    const [snackbarProps, setSnackbarProps] = useState({});
    const [alertProps, setAlertProps] = useState({});

    // esta función siempre se puede usar si se quieren poner mensajes de warning o de info
    const setNotificationMessage = ({
        text = '',
        componentText = null,
        snackbarProps: _snackbarProps,
        alertProps: _alertProps,
    }) => {
        const translatedMessage = translate(text) || componentText;
        setMessage(translatedMessage);
        setSnackbarProps(_snackbarProps);
        setAlertProps(_alertProps);
    };

    const deleteNotificationMessage = () => {
        setMessage(null);
        setSnackbarProps({});
        setAlertProps({});
    };

    const setSeverity = (props, severity) => {
        let newProps = { alertProps: { severity }, snackbarProps: {} };
        if (typeof props === 'string') {
            newProps.text = props;
        } else {
            newProps = {
                ...props,
                alertProps: {
                    ...props.alertProps,
                    severity: newProps.alertProps.severity,
                },
            };
        }
        setNotificationMessage(newProps);
    };

    // setErrorMessage y setSuccessMessage son atajos para lo que se prevee que en la práctica se utilizará. Ver ejemplos en notificationsBarHelpers
    // si solo se le pasa como argumento un string, lo traducirá pondrá la severity correspondiente y las defautlProps correspondientes
    const setErrorMessage = props => {
        setSeverity(props, 'error');
    };

    const setSuccessMessage = props => {
        setSeverity(props, 'success');
    };

    return (
        <NotificationsContext.Provider
            value={{
                setNotificationMessage,
                setErrorMessage,
                setSuccessMessage,
                message,
                deleteNotificationMessage,
                snackbarProps,
                alertProps,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};
