import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';

const CardSectionTitle = ({ title, subTitle }) => {
    const { translate } = useContext(ContextUserProfile);

    const titleStyles = {
        borderBottom: '1px solid rgb(221, 221, 221)',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: '4px 8px',
    };
    return (
        <div style={titleStyles}>
            <span>{translate(title).toUpperCase()}</span>
            <span>{subTitle}</span>
        </div>
    );
};

export default CardSectionTitle;
