import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { capitalize } from '../../../utils/usefulFunctions';
import { cachedApi } from '../../../services/api';
import { debounce } from '@mui/material';
import endpoints from '../../../utils/commonVariables/endpoints';

const getLabelKey = (arrayOptions, key) => {
    return arrayOptions.map(opt => {
        return { ...opt, label: opt[key] || opt.Caption || opt.Name };
    });
};

const LoadedOptionsTypeahead = props => {
    const {
        labelText,
        optionsLabelKey = 'label',
        handleChange,
        id,
        errorMessage,
        infoMessage,
        required,
        idValue,
        value,
        endpoint,
        odataAdditionalFilter,
        ...TypeaheadProps
    } = props;
    const { translate } = useContext(ContextUserProfile);
    const label = capitalize(translate(labelText));
    const [inputValue, setInputValue] = useState('');
    const [optionsTypeahead, setOptionstypeahead] = useState([
        { Id: 0, label: '' },
    ]);
    let active = useRef(true);

    const getOptions = async filterValue => {
        let filterEndpoint = '';
        if (odataAdditionalFilter) {
            const additionalFilter = `${odataAdditionalFilter.key} ${odataAdditionalFilter.operator} ${odataAdditionalFilter.value}`;

            if (!filterValue) {
                filterEndpoint = `${endpoint}&$filter=(${additionalFilter})`;
            } else {
                filterEndpoint = `${endpoint}&$filter=(substringof('${filterValue}', ${optionsLabelKey})and(${additionalFilter}))`;
            }
        } else {
            if (!filterValue) {
                filterEndpoint = `${endpoint}`;
            } else {
                filterEndpoint = `${endpoint}&$filter=substringof('${filterValue}', ${optionsLabelKey})`;
            }
        }

        const { data } = await cachedApi(
            `${endpoints.odataService}${filterEndpoint}`
        );
        if (active.current) {
            setOptionstypeahead(getLabelKey(data.value, optionsLabelKey));
        }
    };

    const debounceGetOptions = useCallback(debounce(getOptions, 500), []);

    useEffect(() => {
        debounceGetOptions(inputValue);

        return () => {
            active.current = false;
        };
    }, [debounceGetOptions, inputValue]);

    return (
        <Stack width={'100%'} height={'67px'}>
            <Autocomplete
                disablePortal
                id={label}
                options={optionsTypeahead}
                sx={{ width: '100%' }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        size='small'
                        error={!!errorMessage}
                        required={required}
                    />
                )}
                onChange={(e, opt) => {
                    e.target.name = id;
                    e.target.value = opt ? parseInt(opt?.Id) : 0;
                    handleChange(e);
                }}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                noOptionsText={errorMessage}
                value={
                    value
                        ? value
                        : optionsTypeahead.find(
                              opt => parseInt(opt.Id) === parseInt(idValue)
                          ) || null
                }
                {...TypeaheadProps}
            />
            <p className='input__error-message'>{translate(errorMessage)}</p>
            {infoMessage && (
                <p className='input__info-message'>
                    ⓘ {translate(infoMessage)}
                </p>
            )}
        </Stack>
    );
};

export default LoadedOptionsTypeahead;
