import React, { useEffect, useContext } from 'react';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { tabsData } from '../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import Segments from './description/Segments';
import Services from './description/Services';
import Descriptions from './description/Descriptions';
import Images from './description/Images';
import MealPlans from './description/MealPlans';
import KidPolicies from './description/KidPolicies';

const Description = ({ IdEncode }) => {
    const { progress, setProgress, checkLocalStorage } =
        useContext(ContextOwnerPortal);
    const { currentTab, stepName } = progress.current;
    const { lastTabFilled } = progress.prefilledProgress.description;
    useEffect(() => {
        checkLocalStorage(IdEncode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setProgress({
            ...progress,
            current: {
                maxTabs: tabsData.description.numberOfTabs,
                tabName: tabsData.description.tabs[0].title,
                currentTab: 1,
                stepName: 'description',
                maxSubTabs: 1,
                currentSubTab: 1,
                lastTabFilled,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepName]);

    const getCurrentForm = () => {
        switch (currentTab) {
            case 1:
                return <KidPolicies />;
            case 2:
                return <Segments />;
            case 3:
                return <Services />;
            case 4:
                return <MealPlans />;
            case 5:
                return <Descriptions />;
            case 6:
                return <Images />;
            default:
                return <p>componente no encontrada</p>;
        }
    };

    return <>{getCurrentForm()}</>;
};

export default Description;
