import React, { useContext } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import TextInput from '../../../../muiComponents/TextInput/TextInputV2';
import { getValueFromObjetByComposedKey } from '../../../../utils/usefulFunctions';
import Button from '@mui/material/Button';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import SwitchInput from '../../components/SwitchInput';

export default function KidPolicies() {
    const { translate } = useContext(ContextUserProfile);
    const {
        hotelInfo,
        handleInputsHotelInfo,
        setProgress,
        progress,
        saveActualProgress,
    } = useContext(ContextOwnerPortal);
    const { currentTab, lastTabFilled, stepName } = progress.current;
    const { Primary } = hotelInfo.SupplierHotelInfo;

    const getValue = (content, name) => {
        const savedValue = getValueFromObjetByComposedKey(content, name);
        const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const storedValue = getValueFromObjetByComposedKey(storedInfo, name);
        const value = storedValue || savedValue;
        return value;
    };

    const checkValidate = async () => {
        saveActualProgress();
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab + 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab + 1
                ).title,
                lastTabFilled:
                    currentTab < lastTabFilled
                        ? lastTabFilled
                        : lastTabFilled + 1,
            },
        });
    };

    const isInputDisabled = !Primary;

    const marginTop = { marginTop: '30px' };

    const variant = isInputDisabled ? 'filled' : 'outlined';

    const isAdultOnly = hotelInfo.MasterHotel.AdultsOnly;

    const isMobile = window.innerWidth < 918;

    return (
        <div
            className='your-properties-formV2'
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <SwitchInput
                checked={!hotelInfo.MasterHotel.AdultsOnly}
                handleChange={e => {
                    const { name, value } = e.target;
                    handleInputsHotelInfo({
                        target: { name, value: !value },
                    });
                }}
                data={{
                    name: 'MasterHotel.AdultsOnly',
                    title: translate('accept_kids'),
                    disabled: isInputDisabled,
                }}
            />
            {!isAdultOnly && (
                <>
                    <TextInput
                        labelText={isMobile ? 'age_infant_mob' : 'age_infant'}
                        handleChange={handleInputsHotelInfo}
                        value={getValue(hotelInfo, 'MasterHotel.BabiesAge')}
                        name='MasterHotel.BabiesAge'
                        type='number'
                        id='MasterHotel.BabiesAge'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginTop}
                    />
                    <TextInput
                        labelText={isMobile ? 'age_child_mob' : 'age_child'}
                        handleChange={handleInputsHotelInfo}
                        value={getValue(hotelInfo, 'MasterHotel.FirstChildAge')}
                        name='MasterHotel.FirstChildAge'
                        type='number'
                        id='MasterHotel.FirstChildAge'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginTop}
                    />
                    <TextInput
                        labelText={
                            isMobile ? 'age_teenager_mob' : 'age_teenager'
                        }
                        handleChange={handleInputsHotelInfo}
                        value={getValue(
                            hotelInfo,
                            'MasterHotel.SecondChildAge'
                        )}
                        name='MasterHotel.SecondChildAge'
                        type='number'
                        id='MasterHotel.SecondChildAge'
                        disabled={isInputDisabled}
                        variant={variant}
                        style={marginTop}
                    />
                </>
            )}
            <TextInput
                labelText={isMobile ? 'age_adult_mob' : 'age_adult'}
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.AdultAge')}
                name='MasterHotel.AdultAge'
                type='number'
                id='MasterHotel.AdultAge'
                disabled={isInputDisabled}
                variant={variant}
                style={marginTop}
            />
            <Button
                variant='contained'
                onClick={checkValidate}
                style={{ alignSelf: 'flex-end', marginTop: '30px' }}
            >
                {translate('continue')}
            </Button>
        </div>
    );
}
