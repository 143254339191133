import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

export default function InputText(props) {
    const { data, handleChange, value } = props;
    const { translate } = useContext(ContextUserProfile);
    const { name, validationmessage, pattern, caption, required } = data;

    // error en la terminal xk containerClassName o labelClassName no existe como atributo de html
    const {
        containerClassName,
        labelClassName,
        messageClassName,
        ...restData
    } = data;

    let containerInputClassName = 'form-group input-container';
    let validationMessageClassName = 'invalid-feedback';
    if (containerClassName) containerInputClassName += containerClassName;
    if (messageClassName)
        validationMessageClassName = `${validationMessageClassName} ${messageClassName}`;
    return (
        <div className={containerInputClassName}>
            <label htmlFor={name} className={labelClassName}>
                {`${translate(caption)} ${required ? ' *' : ''}`}
            </label>
            <input
                className='form-control'
                {...restData}
                id={name}
                value={value}
                placeholder={translate(caption)}
                onChange={handleChange}
                title={translate(caption)}
                pattern={pattern || '[^]*'}
            />
            <div className={validationMessageClassName}>
                {translate(validationmessage)}
            </div>
        </div>
    );
}
