import React, { useContext } from 'react';
import { ContextSignUp } from '../../contexts/SignUpContext';
import SignUpStep1 from './SignUpStep1';
import SignUpStep2 from './SignUpStep2';
import SignUpStep3 from './SignUpStep3';
import SignUpStep4 from './SignUpStep4';
import SignUpStep5 from './SignUpStep5';

const SignUpStepsContainer = () => {
    const { step } = useContext(ContextSignUp);

    const serviceDeskStep = {
        1: <SignUpStep1 />,
        2: <SignUpStep2 />,
        3: <SignUpStep3 />,
        4: <SignUpStep4 />,
        5: <SignUpStep5 />,
    };

    return <div>{serviceDeskStep[step]}</div>;
};

export default SignUpStepsContainer;
