//en orden alfabético
const checkCredentials = '/api/v2/account/CheckCredentials';
const commonSave = '/api/hotelowner/save';
const login = '/security/token';
const viewModelEndpoint = (entityName, id) =>
    `/api/hotelowner/ViewModel/${entityName}/${id}`;
const odataService = '/DataService/HotelOwnerEntityService.svc/';
const getSupplierHotelsPaged = (resultsCount, skipCount, filterText) =>
    `/api/HotelOwner/getSupplierHotelsPaged/${resultsCount}/${skipCount}/${filterText}`;
const saveSupplierHotelFromWizard =
    '/api/V2/supplier/SaveSupplierHotelFromWizard';
const ari = '/api/HotelOwner/ari/v2';
const removeSupplierHotelImages = '/api/HotelOwner/removesupplierhotelimagesV3';
const getRoomTypes = id =>
    `/api/HotelOwner/GetRoomTypesFromCurrentSupplierHotel/${id}`;
const getSupplierHotelDetail = id =>
    `api/v2/supplier/supplierhoteldetails/${id}`;
const getRoomDetail = roomId =>
    `/api/HotelOwner/supplierHotelRoomType/${roomId}`;

const addImages = (
    IdSupplierHotel,
    IdHotelImageType,
    IdSupplierHotelRoomType
) =>
    `api/HotelOwner/addImagesToSupplierHotelsV2/${IdSupplierHotel}/${IdHotelImageType}/${IdSupplierHotelRoomType}`;
const getCreditCardNumber = paymentId =>
    `/api/HotelOwner/payment/info?id=${paymentId}`;
const commonDeleteItems = '/api/HotelOwner/deleteItems';
const forgotPassword = '/api/V2/account/forgotpassword';

const contactForm = '/api/v2/support/contact';
const customerServiceCheckBooking = '/api/V2/support/check/booking/code/';

const customerServiceFormAttachments = '/api/v2/support/ticket/attachment';
const customerServiceFormIssueTypes = '/api/v2/data/issueTypes';
const customerServiceFormSupportTicketScopes =
    '/api/v2/data/supportTicketScopes';
const customerServiceFormSupportTicketPriorities =
    '/api/v2/data/supportTicketPriorities';
const customerService_addAttachedFiles = '/api/v2/support/ticket/add/v2';
const signUpForm = '/api/v2/support/signup';
const signUpFormOwnerTypes = '/api/data/ownerTypes'; // hay que cambiarlo al v2 pero aún no está disponible /api/v2/data/ownerTypes
const signUpFormAvailableSupplierChannels =
    '/api/data/availableSupplierChannels'; // hay que cambiarlo al v2 pero aún no está disponible /api/v2/data/availableSupplierChannels
const signUpFormCountries = '/api/v2/data/countries'; // quizás puede cambiarse en el futuro por /api/V2/typeahead/countries/${text}
const signUpFormCities = idCountry =>
    `/api/v2/data/countries/${idCountry}/cities`; // quizás pueda cambiarse en el futuro por /api/V2/typeahead/cities/${text}
const signUpFormHotelsTypeAhead = text => `/api/v2/typeahead/hotels/${text}`;
const confirmCheckInForm = '/api/v2/support/booking/confirm';
const unconfirmCheckInForm = '/api/v2/support/booking/unconfirm';
const getJobOffers = '/api/V2/data/jobOffers';
const bookingStats = (from, to) =>
    //from / to format 2022-01-01
    `/api/HotelOwner/SupplierBookingStats/${from}/${to}`;
const mostBookedHotels = (from, to) =>
    //from / to format 2022-01-01
    `/api/HotelOwner/MostBookedHotels/${from}/${to}`;

export default {
    checkCredentials,
    commonSave, // securizada
    viewModel: viewModelEndpoint, // securizada
    odataService, // securizada
    getSupplierHotelsPaged, // securizado
    saveSupplierHotelFromWizard,
    ari,
    removeSupplierHotelImages, // securizado
    addImages, // securizado
    getCreditCardNumber, // securizada
    getRoomTypes, // securizado
    getSupplierHotelDetail,
    getRoomDetail, // securizado
    commonDeleteItems, // securizado
    bookingStats,
    mostBookedHotels,
};

export const publicEndpoints = {
    login,
    contactForm,
    customerServiceCheckBooking,
    customerServiceFormAttachments,
    customerServiceFormIssueTypes,
    customerServiceFormSupportTicketScopes,
    customerServiceFormSupportTicketPriorities,
    customerService_addAttachedFiles,
    signUpForm,
    signUpFormOwnerTypes,
    signUpFormAvailableSupplierChannels,
    signUpFormCountries,
    signUpFormCities,
    signUpFormHotelsTypeAhead,
    confirmCheckInForm,
    unconfirmCheckInForm,
    forgotPassword,
    getJobOffers,
};
