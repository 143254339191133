export const regexBookingCode = '(^[0-9a-zA-Z]{6}$)|^(FPH)[0-9a-zA-Z]{8}$';

export const regexTelephone =
    '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?)) *[)]?[- .]?[(]?[0-9]{1,3}[)]?([- .]?[0-9]{3})([- .]?[0-9]{3,4})';

export const regexEmail = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';

export const regexName = '^[a-zA-Z .-]{2,40}$';

export const regexText = '^[a-zA-Z0-9 .-]+$';
export const regexTelephoneWithCharacters = '^[(()+)0-9 .-]+$';

export const regexThreeCharactersMinimum = '^.{3,}$';

export const ownerPortalRegex = {
    email: regexEmail,
    telephone: regexTelephoneWithCharacters,
    text: regexText,
};

export const patterns = {
    email: regexEmail,
    phone: regexTelephone,
    bookingCode: regexBookingCode,
    threeCharactersMinimum: regexThreeCharactersMinimum,
};
