import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { decodeBase64 } from './encodeBase64';
import { ContextUserProfile } from '../contexts/UserProfileContext';
import api, { cachedApi } from '../services/api';
import {
    tokenKey,
    sidKey,
    rolKey,
    IdHotelOwnerKey,
} from './userProfileContextHelpers';
import routes from './commonVariables/routes';

const RedirectToOwnerPortal = props => {
    const { setIsLogged, setIdSystemUser, setIdHotelOwner, setToken } =
        useContext(ContextUserProfile);
    useEffect(() => {
        const objectParams = props.match.params;
        const {
            token: tokenUrlParams,
            sid,
            rol,
            IdHotelOwner: IdHotelOwnerUrlParams,
        } = objectParams;
        localStorage.setItem(tokenKey, tokenUrlParams);
        localStorage.setItem(sidKey, sid);
        localStorage.setItem(rolKey, decodeBase64(rol));
        localStorage.setItem(IdHotelOwnerKey, IdHotelOwnerUrlParams);
        cachedApi.ChangeAxiosToken(tokenUrlParams);
        api.ChangeAxiosToken(tokenUrlParams);
        setIdSystemUser(sid);
        setIdHotelOwner(IdHotelOwnerUrlParams);
        setToken(tokenUrlParams);
        setIsLogged(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Redirect to={routes.landingPrivate} />;
};

export default RedirectToOwnerPortal;
