import React from 'react';
import Cards from 'react-credit-cards';
import '../../../stylesheets/stylesComponents/vcc.scss';

export default function Vcc({ expiry, name, number, cvc }) {
    //Le paso el valor cvc aunque no se usa porque es una prop requerida por la librería
    return (
        <div id='PaymentForm' className='container__card'>
            <Cards
                expiry={expiry}
                name={name}
                number={number}
                cvc={cvc}
                focused='name'
            />
            {cvc && (
                <Cards
                    expiry={expiry}
                    name={name}
                    number={number}
                    cvc={cvc}
                    focused='cvc'
                />
            )}
        </div>
    );
}
