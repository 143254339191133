import React from 'react';
import { Skeleton } from '@mui/material';

const ImagePlaceholder = ({animationDelay}) =>{
    return (
            <div className='img-container my-4 animation__card  shadow' style={{animationDelay: `${animationDelay}s`}} >
                <Skeleton variant='rectangular' width={600} height={400} />              
            </div>
    )
}

export default ImagePlaceholder
