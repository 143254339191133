import React, { useContext, useEffect } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
import Loading from '../../../../common/components/Loading';
import MessageBarInfo from '../../components/MessageBarInfo';
import ThumbNailList from '../../components/ThumbNailList';
import ImageDropZone from '../../components/ImageDropZone';
import endpoints from '../../../../utils/commonVariables/endpoints';

export default function RoomImages() {
    const { translate } = useContext(ContextUserProfile);
    const {
        errorMaxAllowed,
        errorMessage,
        deleteImage,
        isFetching,
        singleRoomData,
        messageBar,
        updateIs360ImageKey,
        toggle360,
        setErrorMaxAllowed,
    } = useContext(ContextOwnerPortal);

    useEffect(() => {
        return () => setErrorMaxAllowed([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        IdSupplierHotel,
        SupplierHotelRoomTypesImages,
        Id: IdSupplierHotelRoomType,
    } = singleRoomData;

    const maxSizeAllowed = 10_485_760; // 10MB = 10 * 1024 *1024 bytes = 26214400
    const maxSizeAllowedMB = 10;
    const IdHotelImageType = 2;
    const uploadEndPoint = endpoints.addImages(
        IdSupplierHotel,
        IdHotelImageType,
        IdSupplierHotelRoomType
    );

    const errors = errorMaxAllowed.concat(errorMessage);
    const buttons = [
        {
            title: 'panorama_image',
            icon: 'thumbNail__360-icon',
            handleClick: toggle360,
        },
        {
            title: 'delete_image',
            icon: 'fas fa-trash-alt',
            handleClick: deleteImage,
        },
    ];
    return (
        <div className='your-properties-form-img'>
            {/* <h1 className='your-properties-title'>
                {translate('room_images')}
            </h1> */}
            {messageBar.text && (
                <MessageBarInfo
                    type={messageBar.type}
                    text={translate(messageBar.text)}
                />
            )}
            {isFetching ? (
                <Loading />
            ) : (
                <div className='description-image-container'>
                    <ImageDropZone
                        uploadEndPoint={uploadEndPoint}
                        maxSizeAllowed={maxSizeAllowed}
                        maxSizeAllowedMB={maxSizeAllowedMB}
                    />
                    <ThumbNailList
                        handle360={updateIs360ImageKey}
                        imageList={SupplierHotelRoomTypesImages}
                        buttonList={buttons}
                    />

                    {!!errors.length && (
                        <ul>
                            {errors.map(error => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
            <div className='d-flex justify-content-between description-card-img_btn'>
                <GeneralButton type='back' text={translate('back')} />
                <GeneralButton type='submit' text={translate('finish')} />
            </div>
        </div>
    );
}
