import React, { createContext, useState, useEffect, useContext } from 'react';
import api, { cachedApi } from '../../services/api';
import { getNextDay } from '../../utils/formatDates';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import { buildOdataEndPoint } from '../../utils/odataEndPoint';
import {
    formatAriDates,
    formatHotels,
    formatLosParam,
    formatQualityFilter,
    dateInThreeMonths,
} from '../formsProperty/Ari/ariHelpers';
import { readOnlyEntities } from '../../utils/commonVariables/entityKeys';
import endpoints from '../../utils/commonVariables/endpoints';

export const ContextAri = createContext();

export const ProviderAri = ({ children }) => {
    const { IdHotelOwner } = useContext(ContextUserProfile);

    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const defaultOrder = { field: 'date', dir: 'asc' };
    const [order, setOrder] = useState(defaultOrder);
    const [validationErrors, setValidationErrors] = useState({});

    const today = new Date();

    const filtersInitialState = {
        code: '',
        idSupplierHotel: 0,
        los: null,
        idSupplierHotelRoomType: null,
        idSupplierHotelRatePlan: null,
        idSupplierHotelMealPlan: null,
        dateLastUpdateFrom: null,
        dateLastUpdateTo: null,
        dateFrom: today,
        dateTo: dateInThreeMonths(today),
        quality: '',
    };

    const [filters, setFilters] = useState(filtersInitialState);

    const { idSupplierHotel, code, dateFrom, dateTo } = filters;

    const toggleDirection = direction => (direction === 'asc' ? 'desc' : 'asc');

    const sort = (orderField, defaultDirection) => {
        const isSameField = orderField === order.field;
        const newDirection = isSameField
            ? toggleDirection(order.dir)
            : defaultDirection;
        const newOrder = { field: orderField, dir: newDirection };
        setOrder(newOrder);
        handleSearch(1, newOrder);
    };

    const filtersOptionsInitialState = [
        {
            name: 'room',
            hasError: false,
            options: [],
            endPoint: id => ({
                entity: readOnlyEntities.SupplierHotelRoomType,
                select: 'MappingCode,Id,Name',
                filter: {
                    and: [`IdSupplierHotel eq ${id}`, 'Enabled'],
                },
            }),
            isLoading: false,
        },
        {
            name: 'rate',
            hasError: false,
            options: [],
            endPoint: id => ({
                entity: readOnlyEntities.SupplierHotelRatePlan,
                select: 'MappingCode,Id,Name',
                filter: {
                    and: [`IdSupplierHotel eq ${id}`, 'Enabled'],
                },
            }),
            isLoading: false,
        },
        {
            name: 'board',
            hasError: false,
            options: [],
            endPoint: id => ({
                entity: readOnlyEntities.SupplierHotelMealPlan,
                select: 'MappingCode,Id,Name',
                filter: {
                    and: [`IdSupplierHotel eq ${id}`, 'Enabled'],
                },
            }),
            isLoading: false,
        },
    ];

    const numberResultsPerPage = 35;
    const currentResults = items.length;
    const totalPages = Math.ceil(totalResults / numberResultsPerPage);

    useEffect(() => {
        getSupplierHotels(IdHotelOwner);
    }, [IdHotelOwner]);

    const [supplierHotels, setSupplierHotels] = useState({
        hotels: [],
        isSmallOwner: true,
        isLoading: true,
    });

    const datesInRange = formatAriDates(dateFrom) <= formatAriDates(dateTo);
    const datesOutOfRange = dateFrom && dateTo && !datesInRange;

    const validDates = dateFrom && dateTo && datesInRange;
    const canSearch = idSupplierHotel && code && validDates;

    const validateFilters = () => {
        if (canSearch) {
            return true;
        } else {
            const _validationErrors = {};
            if (datesOutOfRange) {
                _validationErrors.dateFrom = true;
                _validationErrors.dateTo = true;
                setErrorMessage({
                    onRequest: false,
                    error: 'invalid_dates',
                });
            } else {
                if (!idSupplierHotel && !code) {
                    _validationErrors.typeahead = true;
                }
                if (!dateFrom) {
                    _validationErrors.dateFrom = true;
                }
                if (!dateTo) {
                    _validationErrors.dateTo = true;
                }
                setErrorMessage({
                    onRequest: false,
                    error: 'fill_in_required_fields',
                });
            }

            setValidationErrors(_validationErrors);

            return false;
        }
    };

    const errorMessageInitialState = {};

    const [errorMessage, setErrorMessage] = useState(errorMessageInitialState);

    const getSupplierHotels = async idOwner => {
        const border = 50; // este número limita si es un owner con muchos hoteles o con pocos
        const endPoint = buildOdataEndPoint({
            entity: readOnlyEntities.SupplierHotel,
            select: 'MappingCode,Name,Id,Hotel/Code,IdHotel',
            filter: `IdHotelOwner eq ${idOwner}`,
            orderBy: 'Name asc',
            expand: readOnlyEntities.Hotel,
            top: border,
        });

        try {
            const { data } = await cachedApi.get(endPoint);
            setSupplierHotels({
                hotels: data.value.map(formatHotels),
                isSmallOwner: data['odata.count'] <= border,
                isLoading: false,
            });
        } catch (error) {
            setSupplierHotels({
                hotels: [],
                isSmallOwner: null,
                isLoading: false,
                errorMessage: true,
            });
        }
    };

    const handleSearch = (pageNumber, newOrder) => {
        const isValid = validateFilters();
        if (isValid) {
            setPage(pageNumber);
            getItems(pageNumber, newOrder);
        }
    };

    const searchNextPage = () => {
        const nextPage = page + 1;
        if (nextPage <= totalPages) handleSearch(nextPage);
    };

    useEffect(() => {
        if (idSupplierHotel) {
            getFiltersOptions();
            setPage(0);
            setItems([]);
            setTotalResults(0);
        } else {
            setFiltersOptions(filtersOptionsInitialState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSupplierHotel]);

    const getOptions = async name => {
        const filterIndex = filtersOptions.findIndex(
            filter => filter.name === name
        );
        const endPoint = buildOdataEndPoint(
            filtersOptions[filterIndex].endPoint(idSupplierHotel)
        );
        setFiltersOptions(prevFilters => {
            return prevFilters.map((filter, index) => {
                if (index === filterIndex) {
                    return {
                        ...filter,
                        isLoading: true,
                    };
                }
                return filter;
            });
        });

        try {
            const {
                data: { value },
            } = await api.get(endPoint);

            setFiltersOptions(prevFilters =>
                prevFilters.map((filter, index) => {
                    if (filterIndex === index)
                        return {
                            ...filter,
                            isLoading: false,
                            options: value,
                            hasError: false,
                        };

                    return filter;
                })
            );
        } catch (error) {
            setFiltersOptions(prevFilters => {
                return prevFilters.map((filter, index) => {
                    if (filterIndex === index) {
                        return {
                            ...filter,
                            isLoading: false,
                        };
                    }
                    return filter;
                });
            });
        }
    };

    const [filtersOptions, setFiltersOptions] = useState(
        filtersOptionsInitialState
    );

    const filtersOptionsEndPoints = filtersOptionsInitialState.map(filter =>
        buildOdataEndPoint(filter.endPoint(idSupplierHotel))
    );

    const getFiltersOptions = async () => {
        setFiltersOptions(prevFilters =>
            prevFilters.map(filter => ({ ...filter, isLoading: true }))
        );
        try {
            const responsePromiseAll = await Promise.allSettled(
                filtersOptionsEndPoints.map(endPoint => api.get(endPoint))
            );
            setFiltersOptions(prevFilters =>
                prevFilters.map((fil, index) => {
                    const responseFilter = responsePromiseAll[index];
                    const hasError = !(responseFilter.status === 'fulfilled');
                    const options = responseFilter.value?.data?.value || [];
                    return {
                        ...fil,
                        hasError,
                        options,
                        isLoading: false,
                    };
                })
            );
        } catch (error) {
            setFiltersOptions(prevFilters =>
                prevFilters.map(filter => ({
                    ...filter,
                    hasError: true,
                    isLoading: false,
                }))
            );
        }
    };

    const getItems = async (pageNumber, newOrder = order) => {
        setIsLoading(true);
        const { field, dir } = newOrder;
        const model = {
            start: (pageNumber - 1) * numberResultsPerPage,
            length: numberResultsPerPage,
            params: {
                code: filters.code,
                idSupplierHotel: filters.idSupplierHotel,
                idSupplierHotelRoomType: filters.idSupplierHotelRoomType?.Id,
                idSupplierHotelRatePlan: filters.idSupplierHotelRatePlan?.Id,
                idSupplierHotelMealPlan: filters.idSupplierHotelMealPlan?.Id,
                los: formatLosParam(filters.los?.value),
                dateLastUpdateFrom: formatAriDates(filters.dateLastUpdateFrom),
                dateLastUpdateTo: formatAriDates(
                    getNextDay(filters.dateLastUpdateTo)
                ),
                dateFrom: formatAriDates(dateFrom),
                dateTo: formatAriDates(dateTo),
                quality: formatQualityFilter(filters.quality),
                idHotel: filters.idHotel,
            },
            order: [
                {
                    field,
                    dir,
                },
            ],
            options: {
                fd: false,
                names: true,
            },
        };
        setIsLoading(true);

        try {
            const { data } = await api.post(endpoints.ari, model);
            if (data.StatusCode === 200) {
                const Result = data.Result;
                if (pageNumber === 1) {
                    setTotalResults(Result.recordsFiltered || 0);
                    setItems(Result.data || []);
                } else
                    setItems(prevItems => [
                        ...prevItems,
                        ...(Result.data || []),
                    ]);
                setIsLoading(false);
            } else if (data.StatusCode === 400) {
                setTotalResults(0);
                setItems([]);
                setIsLoading(false);
            }
        } catch (error) {
            setTotalResults(0);
            setItems([]);
            setIsLoading(false);
            setErrorMessage({
                onRequest: true,
                error: 'There was a problem, please try again',
            });
        }
    };

    const clearFilters = () => {
        setFilters(filtersInitialState);
    };

    const [isOpenedFiltersBar, setIsOpenedFiltersBar] = useState(true);

    const toggleFiltersBar = () => setIsOpenedFiltersBar(state => !state);

    const reloadSH = () => {
        getSupplierHotels(IdHotelOwner);
    };

    const deleteErrorMessage = () => setErrorMessage(errorMessageInitialState);

    const clearValidationErrors = field =>
        setValidationErrors(prevValidationErrors => {
            const newValidationErrors = { ...prevValidationErrors };
            delete newValidationErrors[field];
            return newValidationErrors;
        });

    useEffect(() => {
        if (validationErrors.typeahead && idSupplierHotel)
            clearValidationErrors('typeahead');
        if (validationErrors.dateFrom && dateFrom)
            clearValidationErrors('dateFrom');
        if (validationErrors.dateTo && dateTo) clearValidationErrors('dateTo');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSupplierHotel, dateFrom, dateTo]);

    return (
        <ContextAri.Provider
            value={{
                items,
                isLoading,
                page,
                setFilters,
                filtersOptions,
                handleSearch,
                searchNextPage,
                supplierHotels,
                filters,
                totalResults,
                currentResults,
                errorMessage,
                deleteErrorMessage,
                validateFilters,
                clearFilters,
                isOpenedFiltersBar,
                toggleFiltersBar,
                reloadSH,
                getOptions,
                order,
                sort,
                canSearch,
                validationErrors,
                setPage,
            }}
        >
            {children}
        </ContextAri.Provider>
    );
};
