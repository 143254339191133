import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@material-ui/core/Slide';
import { ContextAri } from '../../contextsProperty/ContextAri';
import { IconButton } from '@mui/material';
import Icon from '../../formsProperty/Ari/Icon';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const MessageBar = () => {
    const { errorMessage, deleteErrorMessage, handleSearch } =
        useContext(ContextAri);
    const { translate } = useContext(ContextUserProfile);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
    });

    const TransitionDown = props => <Slide {...props} direction='down' />;

    const hasError = !!errorMessage.error;
    const hasRequestError = hasError && errorMessage.onRequest;
    const severity = hasRequestError ? 'error' : 'warning';

    const TextError = ({ onRequest }) => {
        if (onRequest)
            return (
                <div style={{ display: 'flex', gap: 10 }}>
                    <span>{errorMessage.error}</span>
                    <IconButton
                        title='Reload'
                        size='small'
                        sx={{ color: 'white', padding: 0 }}
                        onClick={() => {
                            const newPage = 1;
                            handleSearch(newPage);
                        }}
                    >
                        <Icon iconName='Replay' />
                    </IconButton>
                </div>
            );
        return translate(errorMessage.error);
    };

    return (
        <>
            {hasError && (
                <Snackbar
                    open
                    autoHideDuration={6000}
                    onClose={deleteErrorMessage}
                    TransitionComponent={TransitionDown}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={deleteErrorMessage}
                        severity={severity}
                        sx={{
                            width: '100%',
                            backgroundColor: '#3b3a39',
                        }}
                    >
                        <TextError onRequest={hasRequestError} />
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default MessageBar;
