import React, { useContext, useEffect, useState } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import GeneralButton from '../../components/GeneralButton';
import api from '../../../../services/api';
import ImagesStep2 from './ImagesStep2';
import Loading from '../../../../common/components/Loading';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import MessageBarInfo from '../../components/MessageBarInfo';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';

export default function Images() {
    const { translate } = useContext(ContextUserProfile);
    const [currentPage, setCurrentPage] = useState('ImagesList');
    const { SupplierHotelImages, messageBar } = useContext(ContextOwnerPortal);
    const [hotelImagesTypes, setHotelImagesTypes] = useState([]);
    const [currentSection, setCurrentSection] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        supplierHotelInfoImg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function supplierHotelInfoImg() {
        setIsFetching(true);
        const BEDROOM_IMAGE_TYPE_KEY = 'BEDROOM';
        const endpoint = buildOdataEndPoint({
            entity: readOnlyEntities.HotelImageType,
            select: 'Id,Caption,LocalizedCaption',
            filter: ['Enabled', `Key ne '${BEDROOM_IMAGE_TYPE_KEY}'`],
        });
        const { data } = await api.get(endpoint);
        setHotelImagesTypes(data.value);
        setIsFetching(false);
    }

    const SectionImages = props => {
        const { section } = props;
        const images = section.Images.filter((item, index) => index < 3);

        for (let i = 0; i < 3; i++) {
            const image = images[i];
            if (!image) images.push('placeholder');
        }

        return images.map((image, index) => {
            const conditionalClass = index > 0 ? 'hidden-sm-down' : '';
            if (image === 'placeholder') {
                return (
                    <i
                        key={index}
                        className={`fas fa-hotel section__image placeholder ${conditionalClass}`}
                        aria-hidden='true'
                    />
                );
            }
            const { Id, VirtualPath, OriginalFileName } = image;
            return (
                <div
                    key={Id}
                    role='img'
                    aria-label={OriginalFileName}
                    className={`section__image description-image  ${conditionalClass}`}
                    style={{
                        backgroundImage: `url(${VirtualPath})`,
                    }}
                />
            );
        });
    };

    const MoreResults = props => {
        // Estos son los 3 puntos que hay al lado de las imágenes
        const { section } = props;
        const numberImages = section.Images.length;
        // En móvil sólo se ve 1 imagen previa, en desktop 3
        // Si hay más de 3 imágnes, se debe ver SIEMPRE visibilityClassName = ''
        // Si hay 1 o 0 no debe verse nunca
        // Si hay 2 o 3, se debe ver sólo si estás en móvil visibilityClassName = 'd-md-block d-lg-none'
        const visibilityClassName =
            numberImages > 1 && numberImages <= 3 ? 'd-md-block d-lg-none' : '';

        return (
            numberImages > 1 && (
                <button
                    type='button'
                    onClick={() => openAddOrEdit(section)}
                    className={`button--more-results ${visibilityClassName}`}
                >
                    <i
                        className='fas fa-ellipsis-h'
                        title={translate('... more_results')}
                    />
                </button>
            )
        );
    };

    function openAddOrEdit(section) {
        setCurrentPage('ImagesStep2');
        setCurrentSection(section);
    }

    return (
        <>
            {currentPage === 'ImagesList' && (
                <div className='your-properties-form-img'>
                    <h1 className='your-properties-title'>
                        {translate('images')}
                    </h1>
                    {messageBar.text && (
                        <MessageBarInfo
                            type={messageBar.type}
                            text={translate(messageBar.text)}
                        />
                    )}
                    <div className='images__container'>
                        <div>
                            <i className='fas fa-exclamation-triangle exclamation-icon' />
                        </div>
                        <div>
                            <p className='images__help-text'>
                                {translate('rooms_images_text')}
                            </p>
                        </div>
                    </div>
                    {isFetching ? (
                        <Loading />
                    ) : (
                        <div className='description-cards '>
                            {hotelImagesTypes.map(_section => {
                                const { Id, Caption, LocalizedCaption } =
                                    _section;
                                const sectionTitle =
                                    LocalizedCaption || Caption;
                                const section = {
                                    Id: _section.Id,
                                    Caption: sectionTitle,
                                    Images: SupplierHotelImages.filter(
                                        image =>
                                            image.IdHotelImageType ===
                                            parseInt(_section.Id)
                                    ),
                                };
                                return (
                                    <div
                                        className='description-card_img'
                                        key={Id}
                                    >
                                        <div className='description-card-img_text'>
                                            <p>{sectionTitle}</p>
                                            <button
                                                type='button'
                                                className='description-card-add_btn'
                                                onClick={() =>
                                                    openAddOrEdit(section)
                                                }
                                            >
                                                {translate('add_or_edit')}
                                            </button>
                                        </div>
                                        <div className='card rounded shadow description-card-img_container'>
                                            <SectionImages section={section} />
                                            <MoreResults section={section} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div className='d-flex justify-content-between description-card-img_btn'>
                        <GeneralButton type='back' text={translate('back')} />
                        <GeneralButton
                            type='submit'
                            text={translate('finish')}
                        />
                    </div>
                </div>
            )}
            {currentPage === 'ImagesStep2' && (
                <ImagesStep2
                    setCurrentPage={setCurrentPage}
                    section={currentSection}
                />
            )}
        </>
    );
}
