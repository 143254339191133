import React, { useContext, useEffect } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { ContextOwnerPortal } from '../ContextOwnerPortal';

export default function CheckboxInputForVisibility(props) {
    const { data } = props;
    const { isChecked, setIsChecked, hotelInfo } =
        useContext(ContextOwnerPortal);
    const { translate } = useContext(ContextUserProfile);
    const { name, subtype, caption } = data;
    const { MasterHotel } = hotelInfo;
    const {
        ReservationEmail,
        ReservationPhone,
        AccountingEmail,
        AccountingPhone,
    } = MasterHotel;

    useEffect(() => {
        setChecked();
        return () => {
            setIsChecked(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setChecked() {
        if (
            ReservationEmail ||
            ReservationPhone ||
            AccountingEmail ||
            AccountingPhone
        ) {
            setIsChecked(true);
        }
    }

    return (
        <div
            className='custom-control custom-checkbox pb-2'
            style={{ marginBottom: '15px' }}
        >
            <input
                type={subtype}
                checked={isChecked}
                className='custom-control-input'
                id={name}
                name={name}
                onChange={() => {
                    setIsChecked(!isChecked);
                }}
            />
            <label
                className='custom-control-label green_checkbox pointer'
                htmlFor={name}
            >
                {`${translate(caption)}`}
            </label>
        </div>
    );
}
