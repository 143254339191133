import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRouteToJobs from '../utils/ProtectedRouteJobs';
import Jobs from './Sections/Jobs';
import AuthorizationOfPersonalDataCV from './Sections/AuthorizationOfPersonalDataCV';
import PageNotFound from '../layout/PageNotFound';
import SelectPrivacyPolicy from '../layout/cookiesConsent/SelectPrivacyPolicy';
import '../stylesheets/muggle.scss';
import '../stylesheets/App.scss';
import routes from '../utils/commonVariables/routes';
import { ContextGlobalFeatures } from '../contexts/GlobalFeatures';

const MuggleContainer = props => {
    const { setIsMuggle } = useContext(ContextGlobalFeatures);

    useEffect(() => {
        setIsMuggle(true);
        return () => {
            setIsMuggle(false);
        };
    }, [setIsMuggle]);

    return (
        <main className='pb-5 flex-grow-1 d-flex'>
            <Switch>
                <ProtectedRouteToJobs path='/jobs' render={() => <Jobs />} />
                <Route
                    path='/authorization-personal-data'
                    render={() => <AuthorizationOfPersonalDataCV {...props} />}
                />
                <Route
                    path={routes.privacyPolicy}
                    component={SelectPrivacyPolicy}
                />
                <Route path='/not-found' component={PageNotFound} />
                <Route
                    path='/'
                    exact
                    render={() => <main className='muggleContainer' />}
                >
                    <Redirect to='/owner-portal' />
                </Route>
                <Redirect to='/not-found' />
            </Switch>
        </main>
    );
};

export default MuggleContainer;
