import React, { useState, useEffect, useContext } from 'react';
import api from '../../../services/api';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

export default function SelectInput(props) {
    const { translate } = useContext(ContextUserProfile);
    const { data, handleChange, value } = props;
    const [options, setOptions] = useState([]);
    const {
        title,
        apiEndPoint,
        ActionInfo,
        name,
        caption,
        required,
        disabled,
        validationmessage,
        ActionInfo2,
        containerClassName,
    } = data;

    useEffect(() => {
        fetchOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOptions = async () => {
        const defaultOption = { Name: '', Id: '' };
        let { data } = await api.get(apiEndPoint);
        data = data.value;
        const options = data.map(option => ({
            Name: ActionInfo2
                ? option[ActionInfo] || option[ActionInfo2]
                : option[ActionInfo],
            Id: option.Id,
        }));
        options.unshift(defaultOption);
        setOptions(options);
    };

    return (
        <div className={`form-group input-container ${containerClassName}`}>
            <label htmlFor={title}>
                {`${translate(caption)} ${required ? ' *' : ''}`}
            </label>
            <select
                className='custom-select custom-select-md'
                name={name}
                id={title}
                placeholder={title}
                required={required}
                value={value}
                onChange={handleChange}
                disabled={disabled}
            >
                {options.map((option, index) => {
                    return (
                        <option key={index} value={option.Id}>
                            {option.Name}
                        </option>
                    );
                })}
            </select>
            <div className='invalid-feedback'>
                {translate(validationmessage)}
            </div>
        </div>
    );
}
