import React, { useContext } from 'react';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { jungleGreen, colorError } from '../../../stylesheets/variables.scss';

export default function MessageBarInfo() {
    const { translate } = useContext(ContextUserProfile);
    const { messageBar } = useContext(ContextOwnerPortal);
    const { type, text } = messageBar;

    const getColor = messageType =>
        messageType === 'success' ? jungleGreen : colorError;

    return <div style={{ color: getColor(type) }}>{translate(text)}</div>;
}
