import React, { useContext, useState } from 'react';
import { ContextContact } from '../../contexts/ContactContext';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import Button from '@mui/material/Button';
import { validateForm } from '../../utils/validateForms';
import { CategoryTypes } from '../../utils/categoryTypes';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import api from '../../services/api';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';
import { NotificationsContext } from '../../contexts/NotificationsContext';

const ContactSteps2 = () => {
    const { translate } = useContext(ContextUserProfile);
    const { inputsData, setInputsData, setStep, setIsFetching } =
        useContext(ContextContact);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const { setErrorMessage } = useContext(NotificationsContext);

    const fieldsToValidate = {
        Subject: {
            value: inputsData.Subject,
            validators: ['requiredString'],
        },
        Comments: {
            value: inputsData.Comments,
            validators: ['requiredString'],
        },
        IdCategory: {
            value: inputsData.IdCategory,
            validators: ['requiredIdEntity'],
        },
    };

    const handleSend = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            try {
                setIsFetching(true);
                const data = await api.post(
                    publicEndpoints.contactForm,
                    inputsData
                );
                if (data.data.StatusCode === 200) {
                    setStep(3);
                } else {
                    setErrorMessage('something_went_wrong');
                }
                setIsFetching(false);
            } catch {
                setIsFetching(false);
                setErrorMessage('something_went_wrong');
            }
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const handleTypeahead = value => {
        if (value) {
            setInputsData(prevState => {
                return {
                    ...prevState,
                    Category: value.Caption,
                    IdCategory: value.Id.toString(),
                };
            });
        } else {
            setInputsData(prevState => {
                return {
                    ...prevState,
                    Category: '',
                    IdCategory: null,
                };
            });
        }
    };

    return (
        <div className='step-content__container'>
            <LoadedOptionsTypeahead
                labelText={'category'}
                options={CategoryTypes}
                idValue={inputsData.IdCategory}
                handleChange={(e, newValue) => {
                    handleTypeahead(newValue, 'IdCategory');
                }}
                errorMessage={errors.IdCategory}
                required
            />
            <TextInput
                labelText='subject'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, Subject: value };
                    });
                }}
                value={inputsData.Subject}
                errorMessage={errors.Subject}
                onBlur={handleValidate}
                required
            />
            <TextInput
                labelText='message'
                handleChange={(e, value) => {
                    setInputsData(prevState => {
                        return { ...prevState, Comments: value };
                    });
                }}
                value={inputsData.Comments}
                errorMessage={errors.Comments}
                onBlur={handleValidate}
                required
                multiline
                rows={5}
            />
            <Button
                variant='contained'
                onClick={handleSend}
                style={{ alignSelf: 'flex-end', marginTop: '50px' }}
            >
                {translate('send')}
            </Button>
        </div>
    );
};

export default ContactSteps2;
