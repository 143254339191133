import React, { useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { getTableStructure, statusColors } from './yourBookingsHelpers';
import { formatDate, formatDateTime } from '../../../utils/usefulFunctions';
import encodeBase64 from '../../../utils/encodeBase64';
import { useHistory } from 'react-router-dom';
import { ContextYourBookings } from '../../contextsProperty/ContextYourBookings';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';

const BookingTableRow = ({ row }) => {
    const tableStructure = getTableStructure();
    const history = useHistory();
    const { isLoading } = useContext(ContextYourBookings);
    const { language, timeZone } = useContext(ContextUserProfile);

    const paintCell = (type, key) => {
        const cellValue = row[key];
        switch (type) {
            case 'Status':
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px',
                        }}
                    >
                        <i
                            className='fas fa-circle'
                            style={{
                                fontSize: 10,
                                color: statusColors[cellValue],
                            }}
                        />
                        <p className='priority-2'>{cellValue}</p>
                    </div>
                );
            case 'DateTime':
                return formatDateTime(cellValue, language, timeZone);
            case 'Date':
                return formatDate(cellValue, language);
            default:
                return cellValue;
        }
    };

    return (
        <TableRow
            hover
            style={{ cursor: 'pointer' }}
            onClick={() => {
                const currentPath = history.location.pathname;
                history.push(`${currentPath}/${encodeBase64(row.Id)}`);
            }}
        >
            {tableStructure.columns.map((col, index) => {
                if (isLoading) {
                    return (
                        <TableCell key={index}>
                            <div
                                style={{
                                    width: '100%',
                                    height: 29,
                                    backgroundColor: '#fafafa',
                                }}
                            />
                        </TableCell>
                    );
                } else {
                    return (
                        <TableCell
                            key={index}
                            className={`priority-${col.priority}`}
                        >
                            {paintCell(col.type, col.key)}
                        </TableCell>
                    );
                }
            })}
        </TableRow>
    );
};

export default BookingTableRow;
