import React, { useMemo, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { ContextOwnerPortal } from '../ContextOwnerPortal';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#454545',
    borderStyle: 'dashed',
    backgroundColor: '#eee',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};
export default function ImageDropZone({
    maxSizeAllowed,
    maxSizeAllowedMB,
    uploadEndPoint,
}) {
    const { translate } = useContext(ContextUserProfile);
    const { setErrorMaxAllowed, setErrorMessage, uploadFiles } =
        useContext(ContextOwnerPortal);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        accept: 'image/jpeg, image/jpg, image/png',
        noClick: true,
        noKeyboard: true,
        onDrop: droppedFiles => {
            if (droppedFiles.length) {
                const allowedFiles = droppedFiles.filter(
                    file => file.size < maxSizeAllowed
                );
                if (allowedFiles.length < droppedFiles.length) {
                    const notAllowedFiles = droppedFiles.filter(
                        file => file.size >= maxSizeAllowed
                    );
                    setErrorMaxAllowed(
                        notAllowedFiles.map(
                            file =>
                                `[${file.name}] => ${translate(
                                    'error_max_size_allowed'
                                )} ${maxSizeAllowedMB}MB`
                        )
                    );
                } else {
                    setErrorMaxAllowed([]);
                }
                const formatedFiles = allowedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                );
                if (formatedFiles.length) {
                    uploadFiles(formatedFiles, uploadEndPoint);
                } else {
                    setErrorMessage([]);
                }
            }
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    return (
        <div {...getRootProps({ style })}>
            <button
                type='button'
                onClick={open}
                className='description-img-drag_btn'
            >
                <input {...getInputProps()} />
                {translate('drag_pictures')}
            </button>
        </div>
    );
}
