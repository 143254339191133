import React, { useContext, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { ContextLanding } from '../../../contexts/LandingContext';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { INITIAL_STATE_BOOKING_HISTORY } from '../../../utils/bookingChartsUtils';
import { primaryBrigth, mainFont } from '../../../stylesheets/variables.scss';
import LoadingV2 from '../../../common/components/LoadingV2';

const BookingHistoryChart = () => {
    const { historyBookingData, isLoadingHistoryBookingData } =
        useContext(ContextLanding);
    const { translate } = useContext(ContextUserProfile);

    const [chartData, setChartData] = useState({
        labels: historyBookingData.map(data =>
            translate(data.month)?.substring(0, 1)
        ),
        datasets: [
            {
                label: translate('Bookings'),
                data: INITIAL_STATE_BOOKING_HISTORY.map(
                    data => data.bookingCount
                ),
                backgroundColor: primaryBrigth,
            },
        ],
    });

    useEffect(() => {
        if (historyBookingData.length) {
            setChartData(prevState => {
                return {
                    ...prevState,
                    labels: historyBookingData.map(it =>
                        translate(it.month)?.substring(0, 1)
                    ),
                    datasets: [
                        {
                            label: 'Commited',
                            data: historyBookingData.map(
                                item => item.commitedBookingCount
                            ),
                            backgroundColor: primaryBrigth,
                        },
                    ],
                };
            });
        }
    }, [historyBookingData, translate]);

    return (
        <>
            {isLoadingHistoryBookingData ? (
                <div className='landing__menu-card-loading'>
                    <LoadingV2 />
                </div>
            ) : (
                // </div>
                <Bar
                    data={chartData}
                    options={{
                        responsive: true,
                        aspectRatio: 1,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            callbacks: {
                                title: ([tooltipItem]) => {
                                    return `${translate(
                                        historyBookingData[tooltipItem.index]
                                            .month
                                    )} - ${
                                        historyBookingData[tooltipItem.index]
                                            .year
                                    }`;
                                },
                            },
                        },
                        dataset: {
                            barThickness: 18,
                        },
                        scales: {
                            xAxes: [
                                {
                                    gridLines: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        fontSize: 8,
                                        fontFamily: mainFont,
                                        padding: 5,
                                    },
                                    stacked: true,
                                },
                            ],
                            yAxes: [
                                {
                                    gridLines: {
                                        lineWidth: 0.5,
                                        drawTicks: false,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        fontSize: 8,
                                        fontFamily: mainFont,
                                        padding: 5,
                                        userCallback: label => {
                                            if (Math.floor(label) === label) {
                                                return label;
                                            }
                                        },
                                    },
                                    stacked: true,
                                },
                            ],
                        },
                    }}
                    width={300}
                    height={150}
                />
            )}
        </>
    );
};

export default BookingHistoryChart;
