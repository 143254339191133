import React, { useContext, useState, useEffect } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import TextInput from '../../../../muiComponents/TextInput/TextInputV2';
import { getValueFromObjetByComposedKey } from '../../../../utils/usefulFunctions';
import Button from '@mui/material/Button';
import { validateForm } from '../../../../utils/validateForms';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import LoadedOptionsTypeaheadV2 from '../../../../muiComponents/LoadedOptionsTypeaheadV2';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';
import useOdataGet from '../../../../customHooks/useOdataGet';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { cachedApi } from '../../../../services/api';

export default function Commercial() {
    const { translate } = useContext(ContextUserProfile);
    const {
        hotelInfo,
        handleInputsHotelInfo,
        setProgress,
        progress,
        saveActualProgress,
        resetLocation,
    } = useContext(ContextOwnerPortal);
    const { currentTab, lastTabFilled, stepName } = progress.current;
    const { Primary } = hotelInfo.SupplierHotelInfo;
    const [isValidated, setIsValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [regionOptions, setRegionOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);

    const countriesEnpoint = {
        entity: readOnlyEntities.Country,
        filter: 'Active',
        orderBy: 'Caption asc',
    };

    const countriesRequest = useOdataGet(countriesEnpoint, true);

    useEffect(() => {
        const getRegions = async () => {
            const regionsEndpoint = buildOdataEndPoint({
                entity: readOnlyEntities.Region,
                select: 'Id,Name,IdCountry',
                filter: `IdCountry eq ${hotelInfo.MasterHotel.IdCountry}`,
                orderBy: 'Name asc',
            });
            const { data } = await cachedApi.get(regionsEndpoint);
            setRegionOptions(data.value);
        };
        const getProvinces = async () => {
            const provincesEndpoint = buildOdataEndPoint({
                entity: readOnlyEntities.Province,
                select: 'Id,Name,IdCountry',
                filter: `IdCountry eq ${hotelInfo.MasterHotel.IdCountry}`,
                orderBy: 'Name asc',
            });
            const { data } = await cachedApi.get(provincesEndpoint);
            setProvinceOptions(data.value);
        };
        const getCities = async () => {
            const citiesEndpoint = buildOdataEndPoint({
                entity: readOnlyEntities.City,
                select: 'Id,Name,IdCountry',
                filter: `IdCountry eq ${hotelInfo.MasterHotel.IdCountry}`,
                orderBy: 'Name asc',
            });
            const { data } = await cachedApi.get(citiesEndpoint);
            setCityOptions(data.value);
        };
        if (hotelInfo.MasterHotel.IdCountry) {
            getRegions();
            getProvinces();
            getCities();
        } else {
            resetLocation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotelInfo.MasterHotel.IdCountry]);

    const getValue = (content, name) => {
        const savedValue = getValueFromObjetByComposedKey(content, name);
        const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const storedValue = getValueFromObjetByComposedKey(storedInfo, name);
        const value = storedValue || savedValue;
        return value;
    };

    const fieldsToValidate = {
        'MasterHotel.Address': {
            value: hotelInfo.MasterHotel.Address,
            validators: ['requiredString'],
        },
        'MasterHotel.IdCountry': {
            value: hotelInfo.MasterHotel.IdCountry,
            validators: ['requiredIdEntity'],
        },
        'MasterHotel.IdCity': {
            value: hotelInfo.MasterHotel.IdCity,
            validators: ['requiredIdEntity'],
        },
        'MasterHotel.PostalCode': {
            value: hotelInfo.MasterHotel.PostalCode,
            validators: ['requiredString'],
        },
    };

    const checkValidate = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            saveActualProgress();
            setProgress({
                ...progress,
                current: {
                    ...progress.current,
                    currentTab: currentTab + 1,
                    tabName: tabsData[stepName].tabs.find(
                        item => item.tabNumber === currentTab + 1
                    ).title,
                    lastTabFilled:
                        currentTab < lastTabFilled
                            ? lastTabFilled
                            : lastTabFilled + 1,
                },
            });
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const goBack = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab - 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab - 1
                ).title,
                currentSubTab: 1,
            },
        });
    };

    const isInputDisabled = !Primary;

    const marginBottom = { marginBottom: '30px' };
    const marginBottonError = { marginBottom: '7px' };

    const variant = isInputDisabled ? 'filled' : 'outlined';

    const autocompleteInputProps = {
        sx: {
            '& .MuiOutlinedInput-root': {
                height: '50px',
                fontSize: '1.2rem',
            },
            '& .MuiFilledInput-root': {
                height: '50px',
                fontSize: '1.2rem',
            },
            '& .MuiInputLabel-shrink': {
                top: '0 !important',
            },
            '& .MuiInputLabel-outlined': {
                fontSize: '1.2rem',
                // top: '-5px',
            },
            '& .MuiInputLabel-filled': {
                fontSize: '1.2rem',
                top: '-5px',
            },
        },
        variant: isInputDisabled ? 'filled' : 'outlined',
    };

    return (
        <div className='your-properties-formV2'>
            <TextInput
                labelText='full_address'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.Address')}
                errorMessage={errors['MasterHotel.Address']}
                onBlur={handleValidate}
                name='MasterHotel.Address'
                type='text'
                required
                id='MasterHotel.Address'
                disabled={isInputDisabled}
                variant={variant}
                error={!!errors['MasterHotel.Address']}
                style={
                    errors['MasterHotel.Address']
                        ? marginBottonError
                        : marginBottom
                }
            />
            <LoadedOptionsTypeaheadV2
                labelText='Country'
                options={countriesRequest.options}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdCountry',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdCountry')}
                onBlur={handleValidate}
                errorMessage={errors['MasterHotel.IdCountry']}
                required
                disabled={isInputDisabled}
                error={errors['MasterHotel.IdCountry'] ? true : undefined}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdCountry'
            />
            <LoadedOptionsTypeaheadV2
                labelText='region'
                options={regionOptions}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdRegion',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdRegion')}
                disabled={isInputDisabled}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdRegion'
            />
            <LoadedOptionsTypeaheadV2
                labelText='province'
                options={provinceOptions}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdProvince',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdProvince')}
                disabled={isInputDisabled}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdProvince'
            />
            <LoadedOptionsTypeaheadV2
                labelText='City'
                options={cityOptions}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdCity',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdCity')}
                onBlur={handleValidate}
                errorMessage={errors['MasterHotel.IdCity']}
                required
                disabled={isInputDisabled}
                error={errors['MasterHotel.IdCity'] ? true : undefined}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdCity'
            />
            <TextInput
                labelText='postal_code'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.PostalCode')}
                errorMessage={errors['MasterHotel.PostalCode']}
                onBlur={handleValidate}
                name='MasterHotel.PostalCode'
                type='text'
                required
                id='MasterHotel.PostalCode'
                disabled={isInputDisabled}
                variant={variant}
                error={!!errors['MasterHotel.PostalCode']}
                style={
                    errors['MasterHotel.PostalCode']
                        ? marginBottonError
                        : marginBottom
                }
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant='contained'
                    onClick={goBack}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('back')}
                </Button>
                <Button
                    variant='contained'
                    onClick={checkValidate}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('continue')}
                </Button>
            </div>
        </div>
    );
}
