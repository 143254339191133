import React from 'react';
import WithTextPlaceholder from './WithTextPlaceholder';

const ResultsIndicator = ({ currentResults, totalResults, isLoading }) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
            }}
        >
            <WithTextPlaceholder
                width={30}
                height={20}
                text={currentResults.toLocaleString('es')}
                isLoading={isLoading}
            />
            <span>-</span>
            <WithTextPlaceholder
                width={30}
                height={20}
                text={totalResults.toLocaleString('es')}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ResultsIndicator;
