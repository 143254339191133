import encodeBase64 from './encodeBase64';

export const convertToOvariant = (entity, object, Id, dataStructure) => {
    const objectEncoded = encodeBase64(JSON.stringify(object));
    if (dataStructure) {
        return {
            d: objectEncoded,
            oType: entity,
            Id,
            contract: dataStructure,
        };
    } else if (Id && entity) {
        return {
            d: objectEncoded,
            oType: entity,
            Id,
        };
    } else if (!entity && Id) {
        return {
            d: objectEncoded,
            Id,
        };
    } else if (!entity && !Id) {
        return {
            d: objectEncoded,
        };
    } else {
        return {
            d: objectEncoded,
            oType: entity,
        };
    }
};

export default convertToOvariant;
