import React, { useContext, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import '../../stylesheets/notificationsBar.scss';
import { positions } from '../../utils/notificationsBarHelpers';
import useTranslate from '../../customHooks/useTranslate';

const NotificationsBar = props => {
    const {
        alertProps: defaultAlertProps,
        snackbarProps: defaultSnackbarProps,
    } = props;
    const { isMultiline: defaultIsMultiline, ...restDefaultAlertProps } =
        defaultAlertProps;
    const { position: defaultPosition, ...restDefaultSnackbarProps } =
        defaultSnackbarProps;

    const {
        message,
        deleteNotificationMessage,
        snackbarProps = {},
        alertProps,
    } = useContext(NotificationsContext);

    const { isMultiline: customIsMultiline, ...restAlertProps } = alertProps;
    const {
        transitionDirection: customTransitionDirection = '',
        ...restSnackbarProps
    } = snackbarProps;

    const Alert = React.forwardRef(function Alert(_props, ref) {
        return (
            <MuiAlert elevation={6} ref={ref} variant='filled' {..._props} />
        );
    });

    const messagePosition = snackbarProps?.position || defaultPosition;
    const position = positions[messagePosition];

    const { anchorOrigin } = position; // si se quiere pisar, se puede pasar en snackbarProps

    const transitionDirection =
        customTransitionDirection || position.transitionDirection;

    const TransitionComponent = _props => (
        <Slide {..._props} direction={transitionDirection} />
    );

    const severity = alertProps.severity || defaultAlertProps.severity;
    const isMultiline = defaultIsMultiline || customIsMultiline;

    useEffect(() => {
        return deleteNotificationMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeText = useTranslate('close');

    return (
        <>
            {message && (
                <Snackbar
                    {...restDefaultSnackbarProps}
                    open
                    onClose={deleteNotificationMessage}
                    TransitionComponent={TransitionComponent}
                    anchorOrigin={anchorOrigin}
                    {...restSnackbarProps}
                >
                    <Alert
                        {...restDefaultAlertProps}
                        classes={{
                            icon: `notifications-bar__icon ${severity}`,
                            root: `notifications-bar ${severity} ${
                                isMultiline ? 'multiline' : ''
                            }`,
                            action: `notifications-bar__action`,
                            message: 'notifications-bar__message',
                        }}
                        action={
                            <IconButton
                                onClick={deleteNotificationMessage}
                                title={closeText}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                        {...restAlertProps}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

NotificationsBar.defaultProps = {
    alertProps: {
        severity: 'error',
        isMultiline: false, // Si el mensaje es muy largo están maquetados el icono y el botón de cerrar
    },
    snackbarProps: {
        position: 5, // del 1 al 6 son válidos
        autoHideDuration: 6000, // undefined si quieres que no se cierre nunca
    },
    // se puede mandar la componente componentText en lugar de text si necesitas por ejemplo que en ese texto aparezcan compoentes. Como un botón o un link a algo. habría que traducirlo desde el padre
};

export default NotificationsBar;
