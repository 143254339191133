import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

const CardPlaceholder = ({ height }) => {
    return (
        <Card
            sx={{
                width: '750px',
                padding: '60px 80px 40px',
                borderRadius: 2.5,
                height,
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    gap: '40px',
                }}
            >
                <Skeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'40px'}
                />

                <Skeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'24px'}
                />
                <Skeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'24px'}
                />
                <Skeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'24px'}
                />
                <Skeleton
                    variant='rectangular'
                    width={'100%'}
                    height={'24px'}
                />
            </CardContent>
        </Card>
    );
};

export default CardPlaceholder;
