import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ContextUserProfile } from '../contexts/UserProfileContext';
import { capitalize } from '../utils/usefulFunctions';

const getLabelKey = (arrayOptions, key) => {
    return arrayOptions.map((opt, index) => {
        return {
            ...opt,
            key: opt?.Id || index,
            label: opt[key] || opt.Caption || opt.Name,
        };
    });
};

const LoadedOptionsTypeaheadV2 = props => {
    const {
        labelText,
        options = [],
        optionsLabelKey = 'label',
        handleChange,
        id,
        errorMessage,
        infoMessage,
        required,
        idValue,
        value,
        inputProps,
        ...TypeaheadProps
    } = props;
    const { translate } = useContext(ContextUserProfile);
    const label = capitalize(translate(labelText));
    const [inputValue, setInputValue] = useState('');
    const [optionsTypeahead, setOptionstypeahead] = useState(
        getLabelKey(options, optionsLabelKey)
    );

    useEffect(() => {
        const newOptions = getLabelKey(options, optionsLabelKey);
        setOptionstypeahead(newOptions);
    }, [options, optionsLabelKey]);

    const handleFilter = typeaheadOptions => {
        return typeaheadOptions.filter(op => {
            return op.label?.toLowerCase().includes(inputValue.toLowerCase());
        });
    };

    return (
        <>
            <Autocomplete
                disablePortal
                id={label}
                options={optionsTypeahead}
                sx={{ width: '100%' }}
                renderInput={params => (
                    <TextField
                        {...params}
                        {...inputProps}
                        label={label}
                        size='small'
                        error={!!errorMessage}
                        required={required}
                    />
                )}
                onChange={(e, newValue) => {
                    e.target.name = id;
                    e.target.value = newValue ? parseInt(newValue?.Id) : null;
                    handleChange(e, newValue);
                }}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                noOptionsText={errorMessage}
                filterOptions={handleFilter}
                value={
                    value
                        ? value
                        : optionsTypeahead.find(
                              opt => parseInt(opt.Id) === parseInt(idValue)
                          ) || null
                }
                type='number'
                name='MasterHotel.IdCountry'
                {...TypeaheadProps}
            />
            <p className='input__error-message'>{translate(errorMessage)}</p>
            {infoMessage && (
                <p className='input__info-message'>
                    ⓘ {translate(infoMessage)}
                </p>
            )}
        </>
    );
};

export default LoadedOptionsTypeaheadV2;
