import React, { useState, useEffect, useContext } from 'react';
import '../stylesheets/stylesLayout/pageNotFound.scss';
import variables from '../stylesheets/variables.scss';
import { ContextUserProfile } from '../contexts/UserProfileContext';

const PageNotFound = () => {
    const { translate } = useContext(ContextUserProfile);
    const { black, white } = variables;
    const [color1, setColor1] = useState(black);
    const [color2, setColor2] = useState(white);
    const speed = 60;
    const delay = 600;
    // Para cambiar el tamaño sólo cambiar alto
    const size = 200;

    useEffect(() => {
        const interval = setInterval(() => {
            setColor1(prevColor => (prevColor === white ? black : white));
            setColor2(prevColor => (prevColor === white ? black : white));
        }, speed);
        setTimeout(() => {
            clearInterval(interval);
        }, delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='bunny-not-found'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='248'
                    height={size}
                    viewBox='0 0 248 300'
                    className='mx-auto'
                >
                    <g fill='none' fillRule='evenodd'>
                        <path
                            fill={color1}
                            fillRule='nonzero'
                            d='M78.2 299.3c-2.8-.4-7.1-4.1-25-21.6-11.9-11.6-21.9-21.5-22.1-22-.1-.4 1.2-10.7 2.9-22.8 1.8-12.1 3.1-22.1 2.9-22.3-.2-.3-8.6-2.4-18.6-4.8-21.1-5-19.1-2.6-17.1-19.6 1.1-10.1 1.3-10.4 5-13.8 3.5-3.1 3.8-3.8 3.2-7.2l-.6-3.7 11.8-5.8c9.1-4.5 11.9-5.5 12.5-4.5.5.7 3 4.8 5.6 9 2.6 4.2 5 7.4 5.3 7 .3-.4 25.3-12.7 55.5-27.2 30.3-14.5 55.2-26.5 55.4-26.7.5-.4 2.1-24.6 1.7-25C156 87.6 25.7 79 16.5 79c-2.8 0-3.7-.6-5-3.1-.9-1.7-1.4-3.3-1.2-3.6.3-.2 18.9-7.6 41.4-16.3l40.8-15.8 19 3.3c10.5 1.8 19.1 3.2 19.2 3.1.1-.1-15.6-7.5-34.8-16.6C62.5 14.3 61 13.5 61 10.9c0-1.8.6-2.9 1.8-3.3C67.9 6.1 98.4 0 100.7 0c1.6 0 12.5 2.4 24.3 5.2l21.5 5.3 32 26.3c40.4 33.3 69.3 59.7 69.4 63.2 0 1.4-1 7-2.3 12.5l-2.2 9.9-19.9 8.3c-10.9 4.5-20.2 8.6-20.6 9-1.1 1.1-12.9 29.5-12.9 31 0 .7 3.4 9.9 7.4 20.5 8.8 22.7 8.5 21.7 6.6 23-1.2.8-4.1 0-11.4-3.2-5.4-2.3-10-4-10.2-3.7-.2.2 2.1 9.5 5.2 20.6 5.2 19.3 5.4 20.4 3.7 21.6-1.4 1-2.1 1.1-3.2.2-.8-.7-8.2-6.9-16.5-13.9l-15.1-12.7-8.2 8.2c-4.5 4.4-8.1 8.3-7.8 8.5.2.2 10.7 5.1 23.2 10.9 12.5 5.8 23 10.7 23.2 10.9.3.3-.2 1.1-1 1.9-1 1.1-6.3 1.7-20.9 2.5-10.7.5-21.7 1-24.5 1h-5l20.5 13.6c11.3 7.5 20.9 13.9 21.3 14.4.5.4.4 1.7-.2 2.9-1.2 2.1-1.3 2.1-48.4 1.9-26 0-48.7-.3-50.5-.5z'
                        />
                        <g transform='matrix(-1 0 0 1 235 75)'>
                            <path
                                fill={color2}
                                stroke={color1}
                                d='M0 22.987l3.372-1.575L21.547 8.144l9.783-5.418C34.143.909 39.981 0 48.843 0c13.294 0 26.283 15.754 23.864 25.515-1.612 6.507-3.395 10.32-5.347 11.441l-4.211-3.571v8.801l-3.215 4.468L53.686 50h-7.595l-4.896-2.458H12.12l-7.863-5.356h11.647l5.643-3.636c1.028-.876 1.285-2.597.771-5.165-.514-2.568-3.414-3.153-8.7-1.753l-4.564 1.753-3.75 1.89-3.25 6.056L0 38.55v-6.176l2.053-4.801L0 22.987z'
                            />
                            <circle cx='46.5' cy='19.5' r='9.5' fill={color1} />
                        </g>
                        <g fill={color2}>
                            <path d='M157 113l8 6.409V123l-8-1.617zM157 125.937l6.422-1.937v4.692l3.578 2.543-3.578.888L160.56 134l-1.157-3.25zM160.83 135.207l5.752-2.975.853 4.354 3.863 1.703-3.239 1.48-2.38 2.266-1.69-2.804zM167.263 142.455l5.003-4.096 1.747 4.082 4.135.87-2.858 2.115-1.854 2.708-2.24-2.394zM173.667 148.593l3.758-.949 1.975-1.964.561 4.668 2.25 3.049-3.292.282-2.797 1.25-.568-3.015-1.887-3.32zM175.74 155.091l3.53.34 2.324-1.09-1.007 4.213.96 3.333-2.917-.772-2.801.218.473-2.785-.562-3.457z' />
                        </g>
                        <g fill={color2}>
                            <path d='M179.324 172.635l-7.975-4.443-20.842-25.188L133.303 133 113 150.715l9.28 8.554 21.564 5.007L164.856 181 185 179.392zM54.592 185.356l5.16-12.212 3.84 3.636 7.495 1.32-8.33 4.012-1.265 4.46zM61.137 171.32l8.675-10.45 2.586 4.455 6.802 3.14-9.228 1.776-2.572 3.97zM110.584 156.79C100.832 169.61 96 176.4 96.088 177.158c.088.758-.348 3.296-1.306 7.613l1.306 4.618 2.474-9.088c1.206-3.029 2.838-5.885 4.896-8.57 2.058-2.684 5.517-6.466 10.376-11.347l-.448-3.863-2.802.267zM114.061 160.122c-7.98 16.48-11.842 25.123-11.587 25.93.256.808.755 3.099 1.496 6.872l2.256 3.321-1.017-9.297 12.968-23.444-1.302-4.113-2.814.731zM121.407 163.01c-3.123 7.093-5.337 12.077-6.644 14.953-2.06 4.535-3.769 9.423-3.769 13.31 0 7.586 5.284 12.152 15.85 13.698h9.912c-9.088-1.158-14.763-2.534-17.026-4.13-2.262-1.596-3.918-4.134-4.967-7.616 1.769-5.32 3.425-9.627 4.967-12.923 1.543-3.296 4.022-7.823 7.438-13.582l-2.475-5.036-3.286 1.325zM108.28 151c-11.176 10.001-16.764 15.375-16.764 16.12 0 .746-.505 3.953-1.516 9.621L92.527 178l.908-9.677L111 154.796V151h-2.72zM50 201l2.38-13 4.332 2.933H64l-7 5.428-.288 4.639z' />
                            <path d='M85.95 151.104l12.617-6.35.362 5.022 4.804 5.375-9.227-1.777-4.132 2.679zM72.713 161.218l8.875-10.312 2.5 4.495 6.74 3.247-9.26 1.63-2.649 3.93zM132.106 165.932l2.96-1.932 1.934 3.52-8.68 21.798c-.485 3.724-.485 6.408 0 8.052.487 1.644 1.933 3.854 4.34 6.63l-5.154-1.354c-1.378-1.185-2.454-2.489-3.229-3.913-.775-1.423-1.2-3.785-1.277-7.085l7.935-24.127' />
                            <path d='M141.037 165l2.666 2.366-5.075 21.971 1.31 4.93 7.062 9.87-4.186.863-8.79-10.732c-1.22-1.37-1.888-2.589-2.002-3.658-.115-1.068.223-2.938 1.01-5.61l8.005-20z' />
                            <path d='M125 209.41V211c11.115-1.155 18.78-2.565 22.994-4.23 4.215-1.664 11.883-6.588 23.006-14.77l-8.722 3.5c-5.805 4.687-10.886 7.879-15.24 9.576-4.355 1.698-11.7 3.143-22.038 4.335z' />
                            <path d='M150.855 173.696L146 169v24.556c.814 1.837 1.603 3.17 2.367 4 .764.83 2.257 1.978 4.48 3.444l3.153-2.05c-1.948-.309-3.275-.773-3.98-1.394-.706-.62-1.423-1.954-2.15-4l.985-19.86zM155 177l4.633 2.253c-.298 2.944-.298 5.408 0 7.39.298 1.983 1.087 4.768 2.367 8.357h-1.132c-1.972-2.77-3.295-5.188-3.969-7.254-.673-2.067-1.306-5.649-1.899-10.746z' />
                        </g>
                        <g fill={color2}>
                            <path d='M52.64 206.18c6.263-3.012 10.696-3.874 13.3-2.585 2.602 1.29 2.936 11.685 1.001 31.185l-15.618 14.115c2.317-5.946 3.549-11.292 3.695-16.04.105-3.402-4.017-11.19-4.579-18.909-.148-2.033.586-4.622 2.2-7.765zM60.187 259.891l3.23-2.284c1.97 12.262 4.333 17.585 7.088 15.97 2.127-1.246 2.774-5.655.921-13.374-.365-1.521-1.256-4.11-2.674-7.767l5.897-5.39a22.676 22.676 0 0 1 1.228 8.446c-.068 1.565.008 3.428-.006 6.864-.008 1.931.553 5.807 1.685 11.625l-3.963 5.944-6.04 6.581c-1.676.578-3.044.547-4.103-.092-1.059-.64-1.636-2.317-1.733-5.034 1.043-2.866 1.242-5.237.596-7.113-.646-1.876-1.354-6.668-2.126-14.376z' />
                            <path d='M50 252.798V258c9.1-3.797 15.24-6.65 18.421-8.561 8.548-5.134 15.072-13.164 26.496-17.047 4.556-1.548 9.312-3.415 13.342-4.187 1.895-.363 4.44-.01 7.633 1.057 3.282-.095 5.499-.447 6.651-1.057 1.153-.61 2.305-2.039 3.457-4.288-1.677-3.387-3.317-5.813-4.92-7.276-1.604-1.464-4.314-3.011-8.131-4.641-10.433 8.607-19.498 15.405-27.193 20.392-7.695 4.988-19.614 11.79-35.756 20.406zM118.346 235.35c3-1.335 6.341 1.381 7.222 4.169.881 2.788.537 9.543-2.191 11.749-2.728 2.205.098-1.08-11.4 7.377-7.667 5.638-16.632 15.423-26.897 29.355l-2.05-2.232-5.03.819c9.466-10.39 16.476-18.476 21.029-24.258 2.995-3.804 6.148-9.208 10.777-15.792 2.406-3.423 5.54-9.85 8.54-11.186zM74 289.877c12.864 0 24.688-.367 35.474-1.102 16.18-1.1 4.377 0 20.498-.226 16.122-.227 15.245-5.311 17.343-4.451 2.368.97 4.783 1.997 9.917 4.677 1.762.92 5.351 2.328 10.768 4.225l-16.453-.882-6.12-3.343-15.455 3.343L74 293v-3.123z' />
                        </g>
                    </g>
                </svg>
                <h2 style={{ marginTop: '20px', alignSelf: 'center' }}>
                    {translate('page_not_found')}
                </h2>
            </div>
        </>
    );
};

export default PageNotFound;
