import { patterns } from './regex';

const validatorTypes = {
    requiredString: {
        isValid: value => !!value.trim(),
        errorMessage: 'required',
    },
    requiredIdEntity: {
        isValid: value => !!value,
        errorMessage: 'required',
    },
    requiredDate: {
        isValid: date =>
            date &&
            Object.prototype.toString.call(date) === '[object Date]' &&
            !isNaN(date),
        errorMessage: 'required',
    },
    email: {
        isValid: value => value.trim().match(patterns.email),
        errorMessage: 'fill_valid_email',
    },
    phone: {
        isValid: value => value.trim().match(patterns.phone),
        errorMessage: 'fill_valid_phone',
    },
    bookingCode: {
        isValid: value => value.trim().match(patterns.bookingCode),
        errorMessage: 'booking_code_does_not_exist',
    },
    threeCharactersMinimum: {
        isValid: value => value.trim().match(patterns.threeCharactersMinimum),
    },
    // custom
    customCustomSupplierChannel: {
        isValid: ({ IdAvailableSupplierChannel, CustomSupplierChannel }) => {
            if (IdAvailableSupplierChannel === 22) {
                return CustomSupplierChannel.trim().match(
                    patterns.threeCharactersMinimum
                );
            }
            return true;
        },
        errorMessage: 'minimium_three_characters',
    },
    requiredNumber: {
        isValid: value => !!value,
        errorMessage: 'required',
    },
};

export const validateForm = form => {
    let errors = [];

    for (const fieldValidation in form) {
        const fieldValidatorProps = form[fieldValidation];
        const fieldErrors = fieldValidatorProps.validators.flatMap(
            validatorName => {
                const validatorType = validatorTypes[validatorName];
                const isValidFunction = validatorType?.isValid;
                const { value } = fieldValidatorProps;
                const isValid = isValidFunction(value);
                // fieldKey es la key del input al que se le quiere aplicar el mensaje de error
                const inputKey =
                    fieldValidatorProps?.inputKey || fieldValidation;
                if (isValid) return [];
                return [
                    {
                        inputKey,
                        errorMessage: validatorType?.errorMessage,
                    },
                ];
            }
        );
        errors = errors.concat(fieldErrors);
    }
    return errors;
};
