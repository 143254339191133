import React from 'react';
import '../../../../stylesheets/stylesComponents/bookingStatistics.scss';
import Skeleton from '@mui/material/Skeleton';

function BookingStatisticsPlaceHolder() {
    return (
        <>
            <Skeleton
                variant='rectangular'
                width={'100%'}
                height={'16px'}
                style={{ marginBottom: '8px' }}
            />
            <div className='booking-statistics__content'>
                <div className='booking-chart__container'>
                    <Skeleton
                        variant='rectangular'
                        width={'100%'}
                        height={150}
                    />
                </div>
                <div className='booking-data__container'>
                    <Skeleton
                        variant='rectangular'
                        width={'100%'}
                        height={24}
                    />
                    <Skeleton
                        variant='rectangular'
                        width={'100%'}
                        height={24}
                    />
                </div>
            </div>
        </>
    );
}

export default BookingStatisticsPlaceHolder;
