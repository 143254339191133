import React from 'react';

export default function CheckboxListAccordion(props) {
    const { title, checkboxList, handleChange, arraySelected } = props;
    const isInCheckedOptionsArray = id =>
        !!arraySelected?.find(
            item => item.Id === parseInt(id) || item.Id === id
        );

    const isDeleted = id => {
        const item = arraySelected.find(
            item => item.Id === parseInt(id) || item.Id === id
        );
        return !!item.Deleted;
    };

    return (
        <div className='checkbox__list'>
            <h5 className='checkbox__list-title'>{title}</h5>
            <ul>
                {checkboxList.map(item => {
                    return (
                        <div
                            className='custom-control custom-checkbox pb-2 '
                            key={`${item.key}${item.Id}`}
                        >
                            <input
                                type='checkbox'
                                className='custom-control-input'
                                id={item.Id}
                                name={item.Caption}
                                checked={
                                    !(
                                        !isInCheckedOptionsArray(item.Id) ||
                                        isDeleted(item.Id)
                                    )
                                }
                                onChange={e => {
                                    const isBeingAdded = e.target.checked;
                                    const itemId = parseInt(e.target.id);
                                    const thisItem = arraySelected.find(
                                        item => parseInt(item.Id) === itemId
                                    );
                                    if (isBeingAdded) {
                                        if (isInCheckedOptionsArray(itemId)) {
                                            // Es porque tiene las claves Deleted y __isChanged, así que se las borro y lo dejo como si no hubiera pasado nada
                                            delete thisItem.Deleted;
                                            delete thisItem.__isChanged;
                                            handleChange(itemId, thisItem);
                                        } else {
                                            // No estaba en el array, así que lo añado
                                            const newOption = {
                                                Id: itemId,
                                                __isAdded: true,
                                                __isChanged: true,
                                            };
                                            handleChange(itemId, newOption);
                                        }
                                    } else {
                                        // Me dispongo a descheckear
                                        // item nunca es undefined, el item siempre está en el array
                                        if ('__isAdded' in item) {
                                        } else {
                                            thisItem.Deleted = true;
                                            thisItem.__isChanged = true;
                                            handleChange(itemId, thisItem);
                                        }
                                    }
                                }}
                            />
                            <label
                                className='custom-control-label checkbox__label pointer'
                                htmlFor={item.Id}
                            >
                                {item.Caption}
                            </label>
                        </div>
                    );
                })}
            </ul>
        </div>
    );
}
