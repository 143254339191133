import React, { useEffect, useContext, useState } from 'react';
import { Switch } from 'react-router-dom';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import HeaderYourProperty from './HeaderYourProperty';
import ProtectedRoute from '../../../utils/ProtectedRoute';
import MainInfo from './MainInfo';
import Description from './Description';
import GeneralDescriptionSteps from './generalDescriptionSteps/GeneralDescriptionSteps';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { decodeBase64 } from '../../../utils/encodeBase64';
import RoomsConfigurationList from './roomsConfiguration/RoomsConfigurationList';
import SingleRoom from './roomsConfiguration/SingleRoom';
import { deepEqualObjects } from '../../../utils/objetsFunctions';
import FormWithWarning from '../../../common/components/FormWithWarning';

const YourSingleProperty = ({ IdEncode }) => {
    const Id = decodeBase64(IdEncode);
    const [isDirty, setIsDirty] = useState(false);

    const { translate } = useContext(ContextUserProfile);
    const { hotelInfo, fetchCurrentHotelOwner, progress, originalHotelInfo } =
        useContext(ContextOwnerPortal);

    const { HotelName } = hotelInfo.MasterHotel;
    const { stepName } = progress.current;

    useEffect(() => {
        fetchCurrentHotelOwner(Id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hotelInfo && originalHotelInfo) {
            setIsDirty(!deepEqualObjects(hotelInfo, originalHotelInfo));
        }
    }, [hotelInfo, originalHotelInfo]);

    return (
        <FormWithWarning isDirty={isDirty}>
            <HeaderYourProperty
                hotelName={HotelName}
                step={translate(stepName)}
            />
            <div
                style={{
                    maxHeight: 'calc(100vh - 116px)',
                    overflow: 'auto',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Switch>
                        <ProtectedRoute
                            path='/your-property/:idEncode/management'
                            render={() => (
                                <MainInfo IdEncode={Id} encodeId={IdEncode} />
                            )}
                        />
                        <ProtectedRoute
                            path='/your-property/:idEncode/public-portrait'
                            render={() => <Description IdEncode={Id} />}
                        />
                        <ProtectedRoute
                            path='/your-property/:idEncode/inventory/new'
                            render={() => <SingleRoom />}
                        />
                        <ProtectedRoute
                            path='/your-property/:idEncode/inventory/:idRoomEncode'
                            render={props => (
                                <SingleRoom
                                    IdRoomEncode={
                                        props.match.params.idRoomEncode
                                    }
                                />
                            )}
                        />
                        <ProtectedRoute
                            path='/your-property/:idEncode/inventory'
                            render={() => <RoomsConfigurationList />}
                        />
                        <ProtectedRoute
                            path='/your-property/:idEncode'
                            render={() => (
                                <GeneralDescriptionSteps IdEncode={IdEncode} />
                            )}
                        />
                    </Switch>
                </div>
            </div>
        </FormWithWarning>
    );
};

export default YourSingleProperty;
