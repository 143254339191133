import React, { useState, useEffect, useContext } from 'react';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { tabsData } from '../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import '../../../stylesheets/stylesLayout/yourProperties.scss';

export default function Tabs() {
    const { translate } = useContext(ContextUserProfile);
    const [namesData, setNamesData] = useState([]);
    const { progress, setProgress } = useContext(ContextOwnerPortal);
    const { stepName, maxTabs, currentTab, lastTabFilled } = progress.current;

    useEffect(() => {
        stepName && getTabs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepName, lastTabFilled]);

    const getTabs = () => {
        const allTabs = tabsData[stepName].tabs;
        const stepTitle = tabsData[stepName].title;
        const allTabTranslated = allTabs.map(tab => {
            return {
                ...tab,
                translatedTitle:
                    tab.title === 'Services' &&
                    stepTitle === 'Rooms Configuration'
                        ? translate('Attributes')
                        : translate(tab.title),
            };
        });
        const tabsToPaint = [];

        for (let i = 0; i < maxTabs; i++) {
            if (i < lastTabFilled) {
                const tab = allTabTranslated[i];
                tabsToPaint.push(tab);
            } else {
                tabsToPaint.push({ title: '' });
            }
        }
        setNamesData(tabsToPaint);
    };

    return (
        <div className='tabs-container'>
            <nav className='nav nav-fill'>
                {namesData.map((tab, index) => {
                    if (!tab.title)
                        return (
                            <div
                                className='nav-item tab-item_empty'
                                key={index}
                            />
                        );
                    return (
                        <div
                            style={{
                                cursor: 'pointer',
                                width: `calc(100% /${maxTabs} )`,
                            }}
                            onClick={() => {
                                setProgress({
                                    ...progress,
                                    current: {
                                        ...progress.current,
                                        currentTab: tab.tabNumber,
                                        tabName: tab.title,
                                        currentSubTab: 1,
                                    },
                                });
                            }}
                            className={`tab-item ${
                                tab.tabNumber === currentTab &&
                                'font-weight-bold'
                            }`}
                            key={index}
                        >
                            {tab.translatedTitle}
                        </div>
                    );
                })}
            </nav>
        </div>
    );
}
