import React, { useContext } from 'react';
import IconAndTextComponent from '../components/IconAndTextComponent';
import CardTitle from '../components/CardTitle';
import { ContextPaymentTerms } from '../../contexts/PaymentTermsContext';
import { sortByName } from '../../utils/sortByName';
import { Grid } from '@mui/material';

const NonEditionContent = ({ paymentProfile: paymentData }) => {
    const { setOpenedCard, editButtonVisible } =
        useContext(ContextPaymentTerms);

    const address = [
        paymentData.Address,
        paymentData.PostalCode,
        paymentData.City.Caption,
        paymentData.Country.Caption,
    ]
        .filter(item => item)
        .join(' - ');

    const paymentMethodsEnabled = paymentData.PaymentMethods.filter(
        method => method.Enabled
    );
    const paymentsByPriority = sortByName(paymentMethodsEnabled, 'Priority');
    const importantPaymentMethod = paymentsByPriority[0];

    return (
        <>
            <CardTitle
                isEditButtonVisible={editButtonVisible === paymentData.Id}
                title={paymentData.Name}
                setEditionMode={() => setOpenedCard(paymentData.Id)}
                label={'payment_profile_name'}
            />
            <Grid
                container
                rowSpacing={{ xs: 1, md: 4 }}
                columnSpacing={4}
                columns={2}
            >
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'fas fa-university'}
                        text={paymentData?.TaxId}
                        description={'tax_id'}
                        fontWeight={'700'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'far fa-id-badge'}
                        text={paymentData?.ContactName}
                        description={'billing_contact'}
                    />
                </Grid>

                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'fas fa-map-marker-alt'}
                        text={address}
                        description={'address'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'far fa-envelope'}
                        text={paymentData?.ContactEmail}
                        description={'your_email'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'fas fa-mobile-alt'}
                        text={paymentData?.ContactPhone}
                        description={'phone'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'fas fa-receipt'}
                        text={paymentData?.InvoiceReceivingMethodType?.Caption}
                        description={'invoice_receiving_method'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'fas fa-credit-card'}
                        text={
                            importantPaymentMethod?.PaymentMethod?.Caption || ''
                        }
                        description={'payment_type'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconAndTextComponent
                        icon={'fas fa-coins'}
                        text={paymentData.PaymentCurrencies.map(
                            curr => curr?.Currency?.Name
                        )
                            .filter(item => item)
                            .join(' - ')}
                        description={'payment_currency'}
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconAndTextComponent
                        icon={'fas fa-tasks'}
                        text={paymentData.PaymentRules.map(
                            rule => rule?.Name
                        ).filter(rule => rule)}
                        description={'payment_rules'}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default NonEditionContent;
