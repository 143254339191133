import React, { useContext, useEffect, useState } from 'react';
import { ContextUserProfile } from '../../contexts/UserProfileContext';
import Button from '@mui/material/Button';
import { publicEndpoints } from '../../utils/commonVariables/endpoints';
import { validateForm } from '../../utils/validateForms';
import { ContextSignUp } from '../../contexts/SignUpContext';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import useErrorHandledApiCall from '../../customHooks/useErrorHandledApiCall';
import { sortByName } from '../../utils/sortByName';

const SignUpStep3 = () => {
    const { translate } = useContext(ContextUserProfile);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const { setStep, inputsData, setInputsData } = useContext(ContextSignUp);
    const { options: countryOptions } = useErrorHandledApiCall(
        publicEndpoints.signUpFormCountries
    );
    const [cityUrl, setCityUrl] = useState('');
    const { options: cityOptions } = useErrorHandledApiCall(cityUrl);

    useEffect(() => {
        if (inputsData.IdCountry) {
            setCityUrl(publicEndpoints.signUpFormCities(inputsData.IdCountry));
        } else {
            setCityUrl('');
            setInputsData(prev => {
                return { ...prev, City: '', IdCity: null };
            });
        }
    }, [inputsData.IdCountry, setInputsData]);

    const fieldsToValidate = {
        IdCountry: {
            value: inputsData.IdCountry,
            validators: ['requiredIdEntity'],
        },
        IdCity: {
            value: inputsData.IdCity,
            validators: ['requiredIdEntity'],
        },
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const hadleNextStep = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            setStep(prev => prev + 1);
        }
    };

    return (
        <div className='step-content__container'>
            <LoadedOptionsTypeahead
                labelText='country'
                options={sortByName(countryOptions, 'Name')}
                handleChange={(e, value) =>
                    setInputsData(prev => {
                        return {
                            ...prev,
                            IdCountry: value ? parseInt(value.Id) : null,
                            Country: value ? value.Name : '',
                        };
                    })
                }
                idValue={inputsData.IdCountry}
                onBlur={handleValidate}
                errorMessage={errors.IdCountry}
                required
            />
            <LoadedOptionsTypeahead
                labelText='city'
                options={sortByName(cityOptions, 'Name')}
                handleChange={(e, value) =>
                    setInputsData(prev => {
                        return {
                            ...prev,
                            IdCity: value ? parseInt(value.Id) : null,
                            City: value ? value.Name : '',
                        };
                    })
                }
                idValue={inputsData.IdCity}
                onBlur={handleValidate}
                errorMessage={errors.IdCity}
                required
            />
            <Button
                variant='contained'
                onClick={hadleNextStep}
                style={{ alignSelf: 'flex-end' }}
            >
                {translate('next')}
            </Button>
        </div>
    );
};

export default SignUpStep3;
