import React from 'react';
import { withRouter } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import encodeBase64 from '../../../utils/encodeBase64';
import {
    grey300,
    jungleGreen,
    colorWhiteTransparent,
} from '../../../stylesheets/variables.scss';
import '../../../stylesheets/stylesLayout/cardHotelProperties.scss';

function CardProperty({ HotelName, Imagen, Porcentage, history, Id, key }) {
    const IdEncode = encodeBase64(Id);

    const StyledEditIconButton = styled(IconButton)(() => ({
        backgroundColor: colorWhiteTransparent,
        '&:hover': {
            backgroundColor: colorWhiteTransparent,
        },
    }));
    const StyledLinearProgress = styled(LinearProgress)(() => ({
        margin: '20px 12px',
        height: 7,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: grey300,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: jungleGreen,
        },
    }));

    const imageStyle = {
        backgroundImage: `url(${Imagen})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    };

    const handleEditHotel = () => {
        history.push(`/your-property/${IdEncode}`);
    };

    return (
        <div
            className='card__property__container'
            onClick={handleEditHotel}
            data-id={Id}
            key={key}
        >
            <div className='card__property__container--image'>
                <div className='card__property__container--edit'>
                    <StyledEditIconButton
                        onClick={handleEditHotel}
                        aria-label='edit'
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            color: theme => theme.palette.grey[800],
                        }}
                        size='small'
                    >
                        <EditIcon />
                    </StyledEditIconButton>
                </div>
                {Imagen ? (
                    <div
                        alt='hotel image'
                        className='hotel__image'
                        style={imageStyle}
                    />
                ) : (
                    <i
                        className='fas fa-hotel hotel__image__placeholder'
                        aria-hidden='true'
                    />
                )}
            </div>
            <StyledLinearProgress variant='determinate' value={Porcentage} />
            <div className='card__property__container--name__hotel'>
                <p>{HotelName}</p>
            </div>
        </div>
    );
}

export default withRouter(CardProperty);
