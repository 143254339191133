import React, { useContext, useState, useEffect } from 'react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { ContextPaymentTerms } from '../../contexts/PaymentTermsContext';
import TextInput from '../../muiComponents/TextInput/TextInput';
import LoadedOptionsTypeahead from '../../muiComponents/LoadedOptionsTypeahead';
import useOdataGet from '../../customHooks/useOdataGet';
import endpoints from '../../utils/commonVariables/endpoints';
import { validateForm } from '../../utils/validateForms';
import convertToOvariant from '../../utils/convertToOvariant';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import PaymentProfileModel from '../../utils/viewModelStructures/paymentTerm';
import CardTitle from '../components/CardTitle';
import api, { cachedApi } from '../../services/api';
import { Grid } from '@mui/material';
import {
    readOnlyEntities,
    savableEntities,
} from '../../utils/commonVariables/entityKeys';
import { buildOdataEndPoint } from '../../utils/odataEndPoint';

const EditionContent = props => {
    const { paymentProfile: paymentData } = props;
    const { setIsLoadingPaymentView, setOpenedCard, updatePaymentProfiles } =
        useContext(ContextPaymentTerms);
    const { setErrorMessage, setSuccessMessage } =
        useContext(NotificationsContext);
    const [errors, setErrors] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const [singlePayment, setSinglePayment] = useState(paymentData);
    const [cityOptions, setCityOptions] = useState([]);

    const handleTextInput = e => {
        const {
            target: { value, name },
        } = e;
        setSinglePayment(prevState => {
            return { ...prevState, [name]: value };
        });
    };

    const handleTypeahead = (e, value) => {
        const {
            target: { name },
        } = e;
        setSinglePayment(prevState => {
            return { ...prevState, [name]: parseInt(value?.Id) || 0 };
        });
    };

    const countriesEnpoint = {
        entity: readOnlyEntities.Country,
        filter: 'Active',
        orderBy: 'Caption asc',
    };

    const countriesRequest = useOdataGet(countriesEnpoint, true);

    useEffect(() => {
        const getCities = async () => {
            const citiesEndpoint = buildOdataEndPoint({
                entity: readOnlyEntities.City,
                select: 'Id,Name,IdCountry',
                filter: `IdCountry eq ${singlePayment.IdCountry}`,
                orderBy: 'Name asc',
            });
            const { data } = await cachedApi.get(citiesEndpoint);
            setCityOptions(data.value);
        };
        if (singlePayment.IdCountry) {
            getCities();
        } else {
            setSinglePayment(prevState => {
                return { ...prevState, IdCity: 0 };
            });
        }
    }, [singlePayment.IdCountry]);

    const invoiceMethodsEnpoint = {
        entity: readOnlyEntities.InvoiceReceivingMethodType,
        select: 'Id,Caption',
        orderBy: 'Caption',
    };
    const invoiceReceivingRequest = useOdataGet(invoiceMethodsEnpoint, true);

    const fieldsToValidate = {
        TaxId: { value: singlePayment.TaxId, validators: ['requiredString'] },
        ContactName: {
            value: singlePayment.ContactName,
            validators: ['requiredString'],
        },
        Address: {
            value: singlePayment.Address,
            validators: ['requiredString'],
        },
        PostalCode: {
            value: singlePayment.PostalCode,
            validators: ['requiredString'],
        },
        IdCountry: {
            value: singlePayment.IdCountry,
            validators: ['requiredIdEntity'],
        },
        IdCity: {
            value: singlePayment.IdCity,
            validators: ['requiredIdEntity'],
        },
        ContactEmail: {
            value: singlePayment.ContactEmail,
            validators: ['requiredString', 'email'],
        },
        ContactPhone: {
            value: singlePayment.ContactPhone,
            validators: ['requiredString'],
        },
        IdInvoiceReceivingMethodType: {
            value: singlePayment.IdInvoiceReceivingMethodType,
            validators: ['requiredIdEntity'],
        },
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const handleSend = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        const {
            Id,
            TaxId,
            ContactName,
            Address,
            PostalCode,
            IdCity,
            IdCountry,
            ContactEmail,
            ContactPhone,
            IdInvoiceReceivingMethodType,
        } = singlePayment;
        // sólo mando lo que se va a guardar porque si mando por ejemplo PaymentMethods me da error
        const savedPaymentProfile = {
            Id,
            TaxId,
            ContactName,
            Address,
            PostalCode,
            IdCity,
            IdCountry,
            ContactEmail,
            ContactPhone,
            IdInvoiceReceivingMethodType,
        };

        if (isValid) {
            setIsLoadingPaymentView(true);
            const payload = convertToOvariant(
                savableEntities.PaymentProfile,
                savedPaymentProfile,
                savedPaymentProfile?.Id,
                PaymentProfileModel
            );

            try {
                const res = await api.post(endpoints.commonSave, payload);
                setIsLoadingPaymentView(false);
                if (res.status === 201) {
                    setSuccessMessage(
                        'Your_data_has_been_updated_successfully'
                    );
                    updatePaymentProfiles(res.data);
                    setOpenedCard('');
                } else {
                    setErrorMessage('something_went_wrong');
                }
            } catch (error) {
                setErrorMessage('something_went_wrong');
                setIsLoadingPaymentView(false);
            }
        }
    };

    return (
        <>
            <CardTitle
                title={singlePayment.Name}
                isEditButtonVisible={false}
                label='payment_profile_name'
            />
            <Grid
                container
                rowSpacing={{ xs: 1, md: 1 }}
                columnSpacing={2}
                columns={2}
            >
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'tax_id'}
                        value={singlePayment.TaxId}
                        errorMessage={errors.TaxId}
                        id={'TaxId'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'contact_person'}
                        value={singlePayment.ContactName}
                        errorMessage={errors.ContactName}
                        id={'ContactName'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'billing_address'}
                        value={singlePayment.Address}
                        errorMessage={errors.Address}
                        id={'Address'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                        width={'50%'}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'billing_post_code'}
                        value={singlePayment.PostalCode}
                        errorMessage={errors.PostalCode}
                        id={'PostalCode'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <LoadedOptionsTypeahead
                        labelText={'Country'}
                        idValue={singlePayment.IdCountry}
                        errorMessage={errors.IdCountry}
                        id={'IdCountry'}
                        options={countriesRequest.options}
                        handleChange={handleTypeahead}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <LoadedOptionsTypeahead
                        labelText={'City'}
                        idValue={singlePayment.IdCity}
                        errorMessage={errors.IdCity}
                        id={'IdCity'}
                        options={cityOptions}
                        handleChange={handleTypeahead}
                        onBlur={handleValidate}
                        infoMessage={
                            !singlePayment.IdCountry &&
                            'fill out country field first'
                        }
                        disabled={!singlePayment.IdCountry}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'billing_contact_email'}
                        value={singlePayment.ContactEmail}
                        errorMessage={errors.ContactEmail}
                        id={'ContactEmail'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <TextInput
                        labelText={'billing_contact_phone'}
                        value={singlePayment.ContactPhone}
                        errorMessage={errors.ContactPhone}
                        id={'ContactPhone'}
                        handleChange={handleTextInput}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <LoadedOptionsTypeahead
                        labelText={'invoice_receiving_method'}
                        idValue={singlePayment.IdInvoiceReceivingMethodType}
                        errorMessage={errors.IdInvoiceReceivingMethodType}
                        id={'IdInvoiceReceivingMethodType'}
                        options={invoiceReceivingRequest.options}
                        handleChange={handleTypeahead}
                        onBlur={handleValidate}
                        required
                    />
                </Grid>
            </Grid>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                    variant='outlined'
                    onClick={() => {
                        setOpenedCard('');
                    }}
                >
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSend}>
                    Update
                </Button>
            </CardActions>
        </>
    );
};

export default EditionContent;
