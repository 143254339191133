import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import '../../../stylesheets/stylesComponents/inputs.scss';

export default function InputFile({ onChange, onDrop, onClick }) {
    const { translate } = useContext(ContextUserProfile);
    const { getRootProps, getInputProps } = useDropzone();

    return (
        <>
            <div
                {...getRootProps({
                    className: 'dropzone',
                })}
                onDrop={onDrop}
                onChange={onChange}
            >
                <input
                    {...getInputProps({
                        className: 'input__file',
                        onClick,
                    })}
                />
                <label htmlFor='file' className='label__file'>
                    <i className='fas fa-cloud-upload-alt icon__file' />
                    {translate('upload_file')}
                </label>
            </div>
        </>
    );
}

InputFile.propTypes = {
    attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
    }).isRequired,
};
