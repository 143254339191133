import React, { useEffect, useState, useCallback, useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { readOnlyEntities } from '../../../utils/commonVariables/entityKeys';
import { cachedApi } from '../../../services/api';
import endpoints from '../../../utils/commonVariables/endpoints';
import { capitalizeSentence } from '../../../utils/usefulFunctions';
import { ContextTermsAndConditions } from '../../../contexts/TermsAndConditionsContext';
import LoadedOptionsTypeahead from '../../../muiComponents/LoadedOptionsTypeahead';

const CurrenciesTermsAndConditions = () => {
    const { translate } = useContext(ContextUserProfile);
    const {
        formTermsAndConditions,
        handleValidate,
        errors,
        setIsValidated,
        handleChangeCurrency,
        isReadOnly,
    } = useContext(ContextTermsAndConditions);

    const { odataService } = endpoints;
    const { Currency } = readOnlyEntities;
    const [currencies, setCurrencies] = useState([]);

    const translatedLabel = capitalizeSentence(translate('Currency'));

    const getCurrencies = useCallback(async () => {
        try {
            const endpoint = `${odataService}${Currency}?$select=Id,Name,Key&$filter=Enabled eq true`;
            const { data } = await cachedApi(endpoint);
            setCurrencies(data.value);
        } catch (error) {
            console.log(error);
        }
    }, [Currency, odataService]);

    useEffect(() => {
        getCurrencies();
    }, [getCurrencies]);

    const handleValidateField = () => {
        setIsValidated(true);
        handleValidate();
    };

    return (
        <div className='form__container--tac_currency'>
            <LoadedOptionsTypeahead
                labelText={translatedLabel}
                options={currencies}
                value={formTermsAndConditions?.selectedCurrency?.Name}
                onChange={handleChangeCurrency}
                errorMessage={errors.Currency}
                name='selectedCurrency'
                onBlur={e => handleValidateField(e, 'selectedCurrency')}
                required
                disabled={isReadOnly}
            />
        </div>
    );
};

export default CurrenciesTermsAndConditions;
