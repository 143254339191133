import { useEffect, useState, useRef, useContext } from 'react';
import api, { cachedApi, commonErrorMessage } from '../services/api';
import { stringifyCode } from '../utils/usefulFunctions';
import { NotificationsContext } from '../contexts/NotificationsContext';
import { buildOdataEndPoint } from '../utils/odataEndPoint';

const useOdataGet = (odataQueryParams, cached = true, isSendingRequest) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorRequest, setErrorRequest] = useState(null);
    const [options, setOptions] = useState([]);
    const { setErrorMessage } = useContext(NotificationsContext);
    const componentMounted = useRef(true);
    const endpoint = buildOdataEndPoint(odataQueryParams);

    const getHttp = cached => {
        if (cached) return cachedApi;
        return api;
    };

    useEffect(() => {
        const getOptions = async () => {
            setErrorRequest(null);
            setIsLoading(true);
            let _options = [];
            const http = getHttp(cached);
            try {
                const {
                    data: { value },
                } = await http.get(endpoint);
                _options = value;
            } catch (error) {
                let _errorRequest = {};
                if (error?.response?.status < 500) {
                    _errorRequest = {
                        statusCode: error.response.status,
                        message:
                            'Please, contact usoft admin with this error message',
                        errorMessage: stringifyCode(error?.response),
                    };
                    setErrorMessage(stringifyCode(error?.response));
                } else {
                    _errorRequest = {
                        statusCode: 500,
                        message: commonErrorMessage,
                    };
                    setErrorMessage(commonErrorMessage);
                }
                setErrorRequest(_errorRequest);
            }
            if (!componentMounted.current) return null;
            setOptions(_options);
            setIsLoading(false);
        };
        getOptions();
        return () => {
            componentMounted.current = false;
        };
    }, [isSendingRequest, cached, endpoint, setErrorMessage]);

    return { isLoading, errorRequest, options };
};

export default useOdataGet;
