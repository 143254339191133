import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import { getValueFromObjetByComposedKey } from '../../../../utils/usefulFunctions';
import Button from '@mui/material/Button';
import { validateForm } from '../../../../utils/validateForms';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import LoadedOptionsTypeaheadV2 from '../../../../muiComponents/LoadedOptionsTypeaheadV2';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';
import useOdataGet from '../../../../customHooks/useOdataGet';
import TextInput from '../../../../muiComponents/TextInput/TextInputV2';

export default function Property({ encodeId }) {
    let history = useHistory();
    const { translate } = useContext(ContextUserProfile);
    const {
        hotelInfo,
        handleInputsHotelInfo,
        setProgress,
        progress,
        handleSave,
    } = useContext(ContextOwnerPortal);
    const { currentTab, stepName } = progress.current;
    const { Primary } = hotelInfo.SupplierHotelInfo;
    const [errors, setErrors] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    const apiHotelTypes = {
        entity: readOnlyEntities.HotelType,
        select: 'Id,Caption,LocalizedCaption',
        filter: 'Enabled',
    };

    const hotelTypes = useOdataGet(apiHotelTypes, true);

    const apiHotelCategories = {
        entity: readOnlyEntities.HotelCategory,
        select: 'Id,Caption,LocalizedCaption',
        filter: 'Enabled',
    };

    const hotelCategories = useOdataGet(apiHotelCategories, true);

    const apiHotelChains = {
        entity: readOnlyEntities.HotelChain,
        select: 'Id,Name',
        filter: 'Enabled',
    };

    const hotelChains = useOdataGet(apiHotelChains, true);

    const apiCurrencies = {
        entity: readOnlyEntities.Currency,
        select: 'Id,Name',
        filter: 'Enabled',
    };

    const hotelCurrencies = useOdataGet(apiCurrencies, true);

    const getValue = (content, name) => {
        const savedValue = getValueFromObjetByComposedKey(content, name);
        const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const storedValue = getValueFromObjetByComposedKey(storedInfo, name);
        const value = storedValue || savedValue;
        return value;
    };

    const fieldsToValidate = {
        'MasterHotel.IdHotelType': {
            value: hotelInfo.MasterHotel.IdHotelType,
            validators: ['requiredIdEntity'],
        },
        'MasterHotel.IdHotelCategory': {
            value: hotelInfo.MasterHotel.IdHotelCategory,
            validators: ['requiredIdEntity'],
        },
        'MasterHotel.Rooms': {
            value: hotelInfo.MasterHotel.Rooms,
            validators: ['requiredNumber'],
        },
        'MasterHotel.IdCurrency': {
            value: hotelInfo.MasterHotel.IdCurrency,
            validators: ['requiredIdEntity'],
        },
    };

    const handleValidate = () => {
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
    };

    const goBack = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab - 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab - 1
                ).title,
                currentSubTab: 1,
            },
        });
    };

    const isInputDisabled = !Primary;

    const autocompleteInputProps = {
        sx: {
            '& .MuiOutlinedInput-root': {
                height: '50px',
                fontSize: '1.2rem',
            },
            '& .MuiFilledInput-root': {
                height: '50px',
                fontSize: '1.2rem',
            },
            '& .MuiInputLabel-shrink': {
                top: '0 !important',
            },
            '& .MuiInputLabel-outlined': {
                fontSize: '1.2rem',
                // top: '-5px',
            },
            '& .MuiInputLabel-filled': {
                fontSize: '1.2rem',
                top: '-5px',
            },
        },
        variant: isInputDisabled ? 'filled' : 'outlined',
    };

    const marginBottom = { marginBottom: '30px' };
    const marginBottonError = { marginBottom: '7px' };

    const variant = isInputDisabled ? 'filled' : 'outlined';

    // Función que formatea el horario, por si viene de base de datos con un solo digito, le agregamos un 0.
    const formatTime = time =>
        time
            .split(':')
            .map(h => (h.length < 2 ? `0${h}` : h))
            .join(':');

    const _handleSave = async () => {
        setIsFetching(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            await handleSave();
            history.push(`/your-property/${encodeId}`);
        }
        setIsFetching(false);
    };

    return (
        <div className='your-properties-formV2'>
            <LoadedOptionsTypeaheadV2
                labelText='property_segment'
                options={hotelTypes.options}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdHotelType',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdHotelType')}
                onBlur={handleValidate}
                errorMessage={errors['MasterHotel.IdHotelType']}
                required
                disabled={isInputDisabled}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdHotelType'
                optionsLabelKey='LocalizedCaption'
            />
            <LoadedOptionsTypeaheadV2
                labelText='category'
                options={hotelCategories.options}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdHotelCategory',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdHotelCategory')}
                onBlur={handleValidate}
                errorMessage={errors['MasterHotel.IdHotelCategory']}
                required
                disabled={isInputDisabled}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdHotelCategory'
                optionsLabelKey='LocalizedCaption'
            />
            <LoadedOptionsTypeaheadV2
                labelText='chain'
                options={hotelChains.options}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdHotelChain',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdHotelChain')}
                onBlur={handleValidate}
                disabled={isInputDisabled}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdHotelChain'
            />
            <TextInput
                labelText='amount_rooms'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.Rooms')}
                errorMessage={errors['MasterHotel.Rooms']}
                onBlur={handleValidate}
                name='MasterHotel.Rooms'
                type='number'
                required
                id='MasterHotel.Rooms'
                disabled={isInputDisabled}
                variant={variant}
                error={!!errors['MasterHotel.Rooms']}
                style={
                    errors['MasterHotel.Rooms']
                        ? marginBottonError
                        : marginBottom
                }
            />
            <LoadedOptionsTypeaheadV2
                labelText='Currency'
                options={hotelCurrencies.options}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdCurrency',
                            value: newValue ? newValue.Id : 0,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdCurrency')}
                onBlur={handleValidate}
                errorMessage={errors['MasterHotel.IdCurrency']}
                disabled={isInputDisabled}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdCurrency'
                required
            />
            <TextInput
                labelText='check_out_hour'
                handleChange={handleInputsHotelInfo}
                value={formatTime(
                    getValue(hotelInfo, 'MasterHotel.CheckInHour')
                )}
                errorMessage={errors['MasterHotel.CheckInHour']}
                onBlur={handleValidate}
                name='MasterHotel.CheckInHour'
                type='time'
                id='MasterHotel.CheckInHour'
                disabled={isInputDisabled}
                variant={variant}
                style={marginBottom}
            />
            <TextInput
                labelText='check_in_hour'
                handleChange={handleInputsHotelInfo}
                value={formatTime(
                    getValue(hotelInfo, 'MasterHotel.CheckOutHour')
                )}
                errorMessage={errors['MasterHotel.CheckOutHour']}
                onBlur={handleValidate}
                name='MasterHotel.CheckOutHour'
                type='time'
                id='MasterHotel.CheckOutHour'
                disabled={isInputDisabled}
                variant={variant}
                style={marginBottom}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant='contained'
                    onClick={goBack}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('back')}
                </Button>
                <Button
                    variant='contained'
                    onClick={_handleSave}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {isFetching ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>{translate('finish')}</>
                    )}
                </Button>
            </div>
        </div>
    );
}
