import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { getFlag } from '../../../utils/flags/getFlag';

const DescriptionCard = props => {
    const { translate } = useContext(ContextUserProfile);
    const { handleDeleteDescription, trimValueOnBlur } =
        useContext(ContextOwnerPortal);
    const {
        required,
        description,
        language,
        IdContentLanguage,
        handleChange,
        Id,
    } = props;
    const requiredLabel = required ? '*' : `(${translate('optional')})`;

    const IdLanguage = IdContentLanguage;
    const handleChangeTextArea = ({ target }) => {
        const { id, value } = target;
        handleChange(id, value);
    };

    return (
        <div className='mb-5'>
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <label htmlFor={Id} className='description__label-language'>
                    <img
                        src={getFlag(IdLanguage)}
                        className='flag'
                        alt={language}
                    />
                    {` ${language} ${requiredLabel}`}
                </label>
                {!required && (
                    <button
                        type='button'
                        className='button-delete'
                        onClick={() => handleDeleteDescription(IdLanguage)}
                    >
                        {translate('delete')} <i className='far fa-trash-alt' />
                    </button>
                )}
            </div>
            <div className='textarea__container'>
                <textarea
                    id={Id}
                    className='textarea-description textarea-description--long'
                    name='Description'
                    value={description}
                    onChange={handleChangeTextArea}
                    onBlur={e => trimValueOnBlur(e, handleChangeTextArea)}
                />
            </div>
        </div>
    );
};

DescriptionCard.defaultProps = {
    required: false,
};

export default DescriptionCard;
