let id = 1;

const contact = {
    title: 'contact_US',
    paragraphs: [
        {
            id: (id += 1),
            text: 'please_feel_free_to',
        },
        {
            id: (id += 1),
            text: 'we_will_answer',
        },
    ],
};

const serviceDesk = {
    paragraphs: [
        {
            id: (id += 1),
            text: 'our_service_desk',
        },
        {
            id: (id += 1),
            text: 'we_are_74%_time_more',
        },
        {
            id: (id += 1),
            text: 'our_portal_will_redirect',
        },
        {
            id: (id += 1),
            text: 'if_you_wish_to_contact_us',
        },
    ],
    contact: [
        // el id coincide con el id de país en la bbdd. Por eso pongo 10000 en emergency, porque no va a haber un país con ese id
        {
            id: 1,
            key: 'Spain',
            contactNumber: 'contact_number_spain',
        },
        {
            id: 39,
            key: 'Brazil',
            contactNumber: 'contact_number_brazil',
        },
        {
            id: 12,
            key: 'United Kingdom',
            contactNumber: 'contact_number_uk',
        },
        {
            id: 3,
            key: 'United States',
            contactNumber: 'contact_number_usa',
        },
        {
            id: 17,
            key: 'Austria',
            contactNumber: 'contact_number_austria',
        },
        {
            id: 10000,
            key: 'Emergency',
            contactNumber: 'contact_number_emergency',
        },
    ],
};
const propertyFastCheckIn = {
    title: 'fast_check_in',
    paragraphs: [
        {
            id: (id += 1),
            text: 'insert_your_property',
        },
        {
            id: (id += 1),
            text: 'make_sure_guests',
        },
        {
            id: (id += 1),
            text: 'reduce_waiting_time',
        },
        {
            id: (id += 1),
            text: 'avoid_issues_with',
        },
    ],
};

const forms = {
    contact,
    serviceDesk,
    propertyFastCheckIn,
};

export const getInstructionsData = formName => {
    return forms[formName];
};
