import React, { useContext } from 'react';
import { ContextPaymentTerms } from '../../contexts/PaymentTermsContext';
import SectionTitle from '../../property-partners-portal/components/SectionTitle/SectionTitle';
import CardPlaceholder from '../components/CardPlaceholder';
import PaymentCard from './PaymentCard';

const PaymentContainer = () => {
    const { isLoadingPaymentView, paymentTerms } =
        useContext(ContextPaymentTerms);
    return (
        <div className='payment-terms__container'>
            <div className='page-title__container'>
                <SectionTitle title={'payment_terms'} />
            </div>
            {isLoadingPaymentView ? (
                <CardPlaceholder />
            ) : (
                paymentTerms.map(payment => {
                    return (
                        <PaymentCard key={payment.Id} paymentData={payment} />
                    );
                })
            )}
        </div>
    );
};

export default PaymentContainer;
