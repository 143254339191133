import buildQuery from 'odata-query';
import { encodeURIOdata } from './usefulFunctions';
import endpoints from './commonVariables/endpoints';

export const resultsPerPage = 20;

/**
 * addCommonParameters. Función auxiliar para construir el endPoint.
 * Concatena el prefijo '/DataService/HotelOwnerEntityService.svc/' y el sufijo '&$inlinecount=allpages&__noLoadWidth=1'
 * antes y después de la query en cuestión.
 * @param {string} entityName
 * @param {*string} query
 */

const addCommonParameters = (entityName, query) => {
    const prefix = endpoints.odataService;
    const suffix = '&$inlinecount=allpages&__noLoadWidth=1';
    return prefix + entityName + query + suffix;
};

/**
 * getTypeaheadEndPoint
 * @param {* string} value valor escrito en el input del typeahead. Se usa para filtrar las opciones del typeahead
 * @param {* object} parameters objeto con propiedades para construir el endpoint
 * enabledKey Clave para filtrar las opciones habilitadas, suele ser Enabled o Active, algunas tablas no tienen esta propiedad
 * entityName nombre de la entidad de odata Country, OwnerType...
 * labelKey clave de la entidad que se va a usar para mostrar en las opciones del typeahead. Suele ser Caption, LocalizedCaption, Name
 */

export const getTypeaheadEndPoint = (value, parameters) => {
    const { entityName, labelKey, enabledKey } = parameters;
    const defaultFilterArray = [`substringof(%27${value}%27,${labelKey})`];
    // Si hay enabledKey (Enabled, Active) le añade un elemento más al array filter
    const filterArray = enabledKey
        ? defaultFilterArray.concat({
              [enabledKey]: true,
          })
        : defaultFilterArray;

    const queryParameters = {
        filter: {
            and: filterArray,
        },
    };
    const endPoint = addCommonParameters(
        entityName,
        buildQuery(queryParameters)
    );
    return endPoint;
};

export const getSelectedOptionEndPoint = (value, parameters) => {
    const { entityName, enabledKey } = parameters;

    const defaultFilterArray = [`Id eq '${value}'`];

    const filterArray = enabledKey
        ? defaultFilterArray.concat({
              [enabledKey]: true,
          })
        : defaultFilterArray;

    const queryParameters = {
        filter: {
            and: filterArray,
        },
    };
    const endPoint = addCommonParameters(
        entityName,
        buildQuery(queryParameters)
    );
    return endPoint;
};

export const buildOdataEndPoint = obj => {
    const prefix = endpoints.odataService;
    let { expand = '' } = obj;
    expand = encodeURIComponent(expand);
    const { entity, ...restProps } = obj;

    return `${prefix}${entity}${buildQuery({
        ...restProps,
        expand,
    })}&__noLoadWidth=1&$inlinecount=allpages`;
};

export const substringof = (key, value) => {
    const encodedValue = encodeURIOdata(value.trim());
    return `substringof('${encodedValue}', ${key})`;
};
