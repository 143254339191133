import React from 'react';
import { getRandomNumber } from '../../../../utils/usefulFunctions';

const CoverPageImage = props => {
    const { src } = props;
    const placeholderIcons = [
        'fas fa-concierge-bell',
        'fas fa-swimming-pool',
        'fas fa-luggage-cart',
        'fas fa-hot-tub',
        'fas fa-door-open',
        'fas fa-umbrella-beach',
        'fas fa-suitcase',
        'fas fa-suitcase-rolling',
    ];

    const iconNumber = getRandomNumber(0, placeholderIcons.length - 1);
    return src ? (
        <img {...props} alt='hotel photography' />
    ) : (
        <div className='steps-img--secondary'>
            <i
                className={`${placeholderIcons[iconNumber]} coverpage_img-placeholder`}
                aria-hidden='true'
            />
        </div>
    );
};

export default CoverPageImage;
