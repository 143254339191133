import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { Link } from 'react-router-dom';

export default function GenericMenu({ menuData }) {
    const { translate } = useContext(ContextUserProfile);

    return (
        <nav className='navbar__header'>
            <ul className='navbar__header--list'>
                {menuData.map(itemNav => {
                    const { linkTo, name } = itemNav;
                    return (
                        <li key={name} className='navbar__header--item'>
                            <Link
                                to={linkTo}
                                className='navbar__header--item-link'
                            >
                                {translate(name)}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}
