import React, { useContext } from 'react';
import CardSectionTitle from './CardSectionTitle';
import './detailCard.scss';
import GeneralInfo from './GeneralInfo';
import Rates, { getRatesSubtittle } from './Rates';
import Restrictions from './Restrictions';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';

const DetailCard = ({ item, qualityStatus }) => {
    const generalInfo = {
        date: item.date,
        lastUpdated: item.lastUpdated,
        los: item.los,
        availability: item.availability,
        qualityStatus,
    };

    const { translate } = useContext(ContextUserProfile);

    const getTranslatedRatesSubtittle = (rates, currency) => {
        const subtittle = getRatesSubtittle(rates);
        const currencySubtittle =
            subtittle === 'no_rates' ? '' : ` - ${currency}`;
        return `${translate(subtittle)}${currencySubtittle}`;
    };

    const rateSubtittle = getTranslatedRatesSubtittle(
        item.rates,
        item.currency
    );

    return (
        <div className='detail-card'>
            <div className='section'>
                <GeneralInfo {...generalInfo} />
            </div>
            <div className='section'>
                <CardSectionTitle title='rates' subTitle={rateSubtittle} />
                <Rates rates={item.rates} />
            </div>
            <div className='section'>
                <CardSectionTitle title='restrictions' />
                <Restrictions restrictions={item.restrictions} />
            </div>
        </div>
    );
};

export default DetailCard;
