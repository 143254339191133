import React from 'react';
import MoodIcon from '@mui/icons-material/Mood';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ReplayIcon from '@mui/icons-material/Replay';

const MaterialUIIcon = ({ iconName, ...restProps }) => {
    const icons = {
        mood: MoodIcon,
        SentimentNeutral: SentimentNeutralIcon,
        SentimentVeryDissatisfied: SentimentVeryDissatisfiedIcon,
        WarningAmber: WarningAmberIcon,
        KeyboardArrowDown: KeyboardArrowDownIcon,
        KeyboardArrowUp: KeyboardArrowUpIcon,
        Search: SearchIcon,
        SearchOff: SearchOffIcon,
        FilterAlt: FilterAltIcon,
        FilterAltOff: FilterAltOffIcon,
        Replay: ReplayIcon,
    };

    const Icon = icons[iconName];
    return <Icon {...restProps} />;
};

export default MaterialUIIcon;
