import React, { useContext } from 'react';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import CheckboxList from '../../components/CheckboxList';
import { buildOdataEndPoint } from '../../../../utils/odataEndPoint';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';

const MealPlans = () => {
    const { translate } = useContext(ContextUserProfile);
    const { handleInputsHotelInfo, SupplierHotelMealPlansTypes } =
        useContext(ContextOwnerPortal);
    const mealPlansCheckBoxProps = {
        name: 'SupplierHotelInfo.SupplierHotelMealPlansTypes',
        apiEndPoint: buildOdataEndPoint({
            entity: readOnlyEntities.MealPlanType,
            select: 'Id,Caption,LocalizedCaption',
            filter: 'Enabled',
        }),
        ActionInfo: 'LocalizedCaption',
        ActionInfo2: 'Caption',
        containerClassName: 'meal-plans__container',
    };
    return (
        <div className='your-properties-form'>
            <h1 className='your-properties-title'>
                {translate('meal_plans_title')}
            </h1>
            <CheckboxList
                data={mealPlansCheckBoxProps}
                value={SupplierHotelMealPlansTypes}
                handleChange={handleInputsHotelInfo}
            />
            <div className='d-flex justify-content-between'>
                <GeneralButton type='back' text={translate('back')} />
                <GeneralButton type='next' text={translate('continue')} />
            </div>
        </div>
    );
};

export default MealPlans;
