import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useRef,
} from 'react';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import api from '../../../services/api';
import { capitalize } from '../../../utils/usefulFunctions';
import CardProperty from '../../../common/components/cards/CardProperty';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import PropertiesMessageInfo from './PropertiesMessageInfo';
import SearcherYourProperty from './SearcherYourProperty';
import CardPropertyShimmer from '../../../common/components/cards/CardPropertyShimmer';
import '../../../stylesheets/stylesLayout/yourHotelProperties.scss';
import endpoints from '../../../utils/commonVariables/endpoints';

export default function YourProperty() {
    const { translate } = useContext(ContextUserProfile);

    const [supplierHotelsToShow, setSupplierHotelsToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(true);
    const [totalResults, setTotalResults] = useState(0);
    const [queryParams, setQueryParams] = useState({
        numberOfElementsToShow: 20,
        skipNumber: 0,
        filter: '',
    });
    const [page, setPage] = useState(1);
    const componentMounted = useRef(true);
    const containerHotels = useRef(null);
    const inputFilterValue = useRef(queryParams.filter);

    const totalPages = Math.ceil(
        totalResults / queryParams.numberOfElementsToShow
    );
    const titleSectionProperties = 'your_properties_title';
    const areHotels = supplierHotelsToShow.length;

    const shimmeredCards = 4;
    const cardsShimmerdToShow = Array.from(
        { length: shimmeredCards },
        (_, index) => <CardPropertyShimmer key={index} />
    );
    const Card = ({ Id, Url, Name, Progress }) => (
        <CardProperty
            Id={Id}
            Imagen={Url}
            HotelName={capitalize(Name)}
            Porcentage={Progress}
            key={Id}
        />
    );
    // Condición para renderizar las tarjetas
    const shouldRenderShimmerCards = isLoading && !areHotels;
    // Mappeo la lista de cards, si isLoading muestro las cards con shimmer y sino la card normal
    const CardsList = () => (
        <>
            {supplierHotelsToShow.map((card, index) =>
                isLoading ? (
                    <CardPropertyShimmer key={index} />
                ) : (
                    <Card {...card} key={index} />
                )
            )}
        </>
    );

    const searchPagedSupplierHotels = useCallback(
        async newSkipNumber => {
            setIsLoading(true);
            const { numberOfElementsToShow } = queryParams;
            const _skipNumber = newSkipNumber ?? 0;
            const endpoint = endpoints.getSupplierHotelsPaged(
                numberOfElementsToShow,
                _skipNumber,
                inputFilterValue.current
            );
            const {
                data: {
                    Result: { recordsFiltered, data },
                },
            } = await api.get(endpoint);

            if (!componentMounted.current) return null;
            setTotalResults(recordsFiltered);

            if (_skipNumber === 0) {
                setSupplierHotelsToShow(data);
            } else {
                setSupplierHotelsToShow(prevState => [...prevState, ...data]);
            }
            setIsLoading(false);
        },
        [queryParams]
    );

    useEffect(() => {
        if (!areHotels && !queryParams.filter) searchPagedSupplierHotels();
    }, [searchPagedSupplierHotels, areHotels, queryParams.filter]);

    useEffect(() => {
        return () => {
            componentMounted.current = false;
        };
    }, []);

    const handleInputText = async e => {
        const filterValue = e.currentTarget.value;
        setQueryParams(prevState => ({
            ...prevState,
            filter: filterValue,
        }));
    };

    const fetchMoreSupplierHotels = useCallback(async () => {
        if (page < totalPages) {
            const { skipNumber, numberOfElementsToShow } = queryParams;
            const newSkipNumber = skipNumber + numberOfElementsToShow;
            setIsLoading(true);
            setPage(p => p + 1);
            setQueryParams(prevState => {
                return {
                    ...prevState,
                    skipNumber: newSkipNumber,
                };
            });
            await searchPagedSupplierHotels(newSkipNumber);
            setIsLoading(false);
        }
        return;
    }, [page, totalPages, queryParams, searchPagedSupplierHotels]);

    const options = {
        rootMargin: '0px',
        threshold: 1.0,
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                fetchMoreSupplierHotels();
            }
        });
    }, options);

    useEffect(() => {
        if (
            !isLoading &&
            containerHotels?.current &&
            containerHotels?.current?.lastChild
        ) {
            const lastElement = containerHotels?.current?.lastChild;
            observer.observe(lastElement);

            return () => {
                observer.unobserve(lastElement);
            };
        }
    }, [observer, isLoading]);

    const resetQueryParams = () => {
        setQueryParams(prevState => {
            return {
                ...prevState,
                skipNumber: 0,
            };
        });
        setPage(1);
    };

    const onSearchButton = () => {
        resetQueryParams();
        inputFilterValue.current = queryParams.filter;
        containerHotels.current.scrollTop = 0;
        searchPagedSupplierHotels();
    };

    return (
        <div className='properties__container'>
            <SectionTitle title={titleSectionProperties} />
            <SearcherYourProperty
                onChange={handleInputText}
                value={queryParams.filter}
                onClick={onSearchButton}
            />
            {isMessageBarVisible && (
                <PropertiesMessageInfo
                    setIsVisible={setIsMessageBarVisible}
                    isVisible={isMessageBarVisible}
                />
            )}
            <div className='card__container' ref={containerHotels}>
                {shouldRenderShimmerCards ? cardsShimmerdToShow : <CardsList />}
                {!areHotels && !isLoading && (
                    <div className='card__container--no__results'>
                        {translate('no_results')}
                    </div>
                )}
            </div>
        </div>
    );
}
