import { readOnlyEntities } from '../commonVariables/entityKeys';
import { buildOdataEndPoint } from '../odataEndPoint';

const descriptionVisServices = {
    entityName: 'description_vis_services',
    entityTitle: 'description_vis_services',
    entityStepOf: 'description',
    Elements: [
        {
            Caption: 'vis_services',
            title: 'vis_services',
            name: 'SupplierHotelInfo.Services',
            type: 'checkboxList',
            apiEndPoint: buildOdataEndPoint({
                entity: readOnlyEntities.ServiceType,
                select: 'Id,Caption,LocalizedCaption',
                filter: [
                    'Enabled',
                    'ForHotel',
                    'ServiceCategory/Enabled',
                    'Priority',
                ],
                orderBy: 'PriorityOrder asc',
            }),

            required: false,
            ActionInfo: 'LocalizedCaption',
            ActionInfo2: 'Caption',
        },
    ],
};

const roomVisServices = {
    entityName: 'description_vis_services',
    entityTitle: 'description_vis_services',
    entityStepOf: 'room_configuration',
    Elements: [
        {
            Caption: 'vis_services',
            title: 'vis_services',
            name: 'Services',
            type: 'checkboxList',
            apiEndPoint: buildOdataEndPoint({
                entity: readOnlyEntities.ServiceType,
                select: 'Id,Caption,LocalizedCaption',
                filter: [
                    'Enabled',
                    'ForRoomType',
                    'ServiceCategory/Enabled',
                    'Priority',
                ],
                orderBy: 'PriorityOrder asc',
            }),
            required: false,
            ActionInfo: 'LocalizedCaption',
            ActionInfo2: 'Caption',
        },
    ],
};

export default {
    room_configuration: roomVisServices,
    description: descriptionVisServices,
};
