import React, {
    createContext,
    useEffect,
    useState,
    useRef,
    useCallback,
    useContext,
} from 'react';
import api from '../../services/api';
import {
    cleanBookingData,
    getTableStructure,
    filtersInitialState,
} from '../ownerPortal/yourBookings/yourBookingsHelpers';
import { convertDateToUTC } from '../../utils/usefulFunctions';
import { buildOdataEndPoint } from '../../utils/odataEndPoint';
import { readOnlyEntities } from '../../utils/commonVariables/entityKeys';
import { ContextUserProfile } from '../../contexts/UserProfileContext';

export const ContextYourBookings = createContext();

export const ProviderYourBookings = ({ children }) => {
    const { IdHotelOwner } = useContext(ContextUserProfile);

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [areFiltersVisible, setAreFiltersVisible] = useState(true);
    const filtersStructure = getTableStructure().filters;
    const itemsPerPage = 15;
    const [filters, setFilters] = useState(filtersInitialState);
    const [orderBy, setOrderBy] = useState({
        key: 'CheckInDate',
        order: 'desc',
    });
    const [page, setPage] = useState(1);
    const [resultNumber, setResultNumber] = useState(15);
    const [totalResults, setTotalResults] = useState(0);
    const INFO_MESSAGE_INITIAL_STATE = { type: '', message: '' };
    const [infoMessage, setInfoMessage] = useState(INFO_MESSAGE_INITIAL_STATE);
    const [isChartVisible, setIsChartVisible] = useState(true);

    let active = useRef(true);

    const buildFiltersQuery = useCallback(() => {
        const filtersArray = [];
        for (let filterData of filtersStructure) {
            // han filtrado por ese campo
            if (filters[filterData.stateKey]) {
                if (filterData.type === 'text') {
                    filtersArray.push(
                        `(substringof('${filters[filterData.stateKey]}',${
                            filterData.odataKey
                        }))`
                    );
                } else if (
                    filterData.type === 'select' ||
                    filterData.type === 'typeahead'
                ) {
                    filtersArray.push(
                        `(${filterData.odataKey} eq ${
                            filters[filterData.stateKey]
                        })`
                    );
                } else if (filterData.type === 'daterange') {
                    const dateRange = filters[filterData.stateKey];
                    if (dateRange[0]) {
                        const dateFrom = filters[filterData.stateKey][0];
                        filtersArray.push(
                            `(${
                                filterData.odataKey
                            } ge datetime'${convertDateToUTC(dateFrom)}')`
                        );
                    }
                    if (dateRange[1]) {
                        const dateTo = filters[filterData.stateKey][1];
                        filtersArray.push(
                            `(${
                                filterData.odataKey
                            } le datetime'${convertDateToUTC(dateTo)}')`
                        );
                    }
                }
            }
        }
        return filtersArray.join('and');
    }, [filters, filtersStructure]);

    const buildQuery = useCallback(
        pageNumber => {
            // El || undefined es porque si no hay filtros en el estado, por ejemplo dejando la fecha del check in date vacío, retorna un string vacío '' y en el and genera una consulta
            // que da error, con el undefined ignra esa presencia en el array
            const filterStringFromState = buildFiltersQuery() || undefined;

            const endpoint = buildOdataEndPoint({
                entity: readOnlyEntities.SupplierBooking,
                expand: [
                    readOnlyEntities.SupplierHotel,
                    readOnlyEntities.SupplierBookingStatus,
                    `${readOnlyEntities.SupplierBookingStatus}/${readOnlyEntities.SupplierBookingStatusType}`,
                ],
                select: [
                    'Id',
                    'SupplierBookingStatus/IdSupplierBookingStatusType',
                    'SupplierBookingStatus/SupplierBookingStatusType/Caption',
                    'SupplierBookingStatus/SupplierBookingStatusType/Color',
                    'BookingCode',
                    'BookingDate',
                    'CheckInDate',
                    'CheckOutDate',
                    'SupplierHotel/Name',
                ],
                filter: [
                    {
                        or: [
                            'SupplierBookingStatus/IdSupplierBookingStatusType eq 2',
                            'SupplierBookingStatus/IdSupplierBookingStatusType eq 3',
                        ],
                    },
                    'IdSupplierBookingRequest eq null',
                    filterStringFromState,
                ],
                orderBy: `${orderBy.key} ${orderBy.order}`,
                top: itemsPerPage,
                skip: `${(pageNumber - 1) * itemsPerPage}`,
            });
            return endpoint;
        },
        [buildFiltersQuery, orderBy.key, orderBy.order]
    );

    const getBookingData = useCallback(
        async (pageNumber = 1) => {
            setIsLoading(true);
            const query = buildQuery(pageNumber);
            try {
                if (infoMessage.message) {
                    setInfoMessage(INFO_MESSAGE_INITIAL_STATE);
                }
                const { data } = await api(query);
                if (!active.current) return null;
                const _totalResults = parseInt(data['odata.count']);
                setTotalResults(_totalResults);
                if (!_totalResults) {
                    setInfoMessage({
                        type: 'info',
                        message: 'no_results',
                    });
                }
                const _items = cleanBookingData(data.value);
                if (pageNumber === 1) {
                    setItems(_items);
                } else {
                    setItems(prevItems => [...prevItems, ..._items]);
                }
            } catch {
                setInfoMessage({
                    type: 'error',
                    message: 'an_error_occurred_while_processing_your_request',
                });
            }
            setIsLoading(false);
        },
        [INFO_MESSAGE_INITIAL_STATE, buildQuery, infoMessage.message]
    );

    useEffect(() => {
        if (IdHotelOwner) {
            getBookingData();
        }
        return () => {
            active.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBy]);

    const handleScroll = ({ target }) => {
        const { scrollTop, scrollHeight, clientHeight } = target;
        const isOnBottom = scrollTop > scrollHeight - clientHeight - 1;
        if (isOnBottom && !isLoading && resultNumber < totalResults) {
            const newAmount =
                resultNumber < totalResults ? resultNumber + 15 : totalResults;
            setResultNumber(newAmount);
            resultNumber < totalResults && setPage(page + 1);
            getBookingData(page + 1);
        }
    };

    const handleClearFilters = () => {
        setFilters(filtersInitialState);
        setPage(1);
    };

    return (
        <ContextYourBookings.Provider
            value={{
                items,
                areFiltersVisible,
                setAreFiltersVisible,
                IdHotelOwner,
                filters,
                setFilters,
                orderBy,
                setOrderBy,
                getBookingData,
                handleScroll,
                isLoading,
                handleClearFilters,
                infoMessage,
                setInfoMessage,
                isChartVisible,
                setIsChartVisible,
            }}
        >
            {children}
        </ContextYourBookings.Provider>
    );
};
