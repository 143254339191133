const PaymentProfileModel = {
    Name: true,
    TaxId: true,
    Address: true,
    ContactName: true,
    ContactEmail: true,
    PostalCode: true,
    ContactPhone: true,
    IdCountry: true,
    Country: { Caption: true },
    IdCity: true,
    City: { Caption: true },
    IdInvoiceReceivingMethodType: true,
    InvoiceReceivingMethodType: { Caption: true },
    PaymentMethods: {
        PaymentMethod: { Caption: true },
        Enabled: true,
        Priority: true,
    },
    PaymentRules: { Name: true },
    PaymentCurrencies: { Currency: { Name: true } },
};

export default PaymentProfileModel;
