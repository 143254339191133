import React from 'react';
import IconAdult from './IconAdult';
import IconChild from './IconChild';
import IconHome from './IconHome';
import IconBaby from './IconBaby';
import IconChildSegment from './IconChildSegment';

const IconDetail = ({ iconName, ...restProps }) => {
    const iconTypes = {
        adult: () => <IconAdult {...restProps} />,
        child: () => <IconChild {...restProps} />,
        home: () => <IconHome {...restProps} />,
        baby: () => <IconBaby {...restProps} />,
        childSegment: () => <IconChildSegment {...restProps} />,
    };
    const Icon = iconTypes[iconName];
    return <Icon />;
};

export default IconDetail;
