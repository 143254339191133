import React, { useContext } from 'react';
import { openExtLink } from '../../utils/usefulFunctions';
import { ContextUserProfile } from '../../contexts/UserProfileContext';

export default function AuthorizationOfPersonalDataCV(props) {
    const { translate } = useContext(ContextUserProfile);
    const { history } = props;

    function handleOnClick() {
        localStorage.setItem('jobConditionsAccepted', true);
        history.push('/jobs');
    }

    return (
        <div className='w-100'>
            <div className='faq mx-auto mb-4 text-justify'>
                <p className='pb-4'>{translate('authorization_title')}</p>
                <p className='pb-2'>{translate('authorization_paragraph_2')}</p>
                <p className='pb-2'>{translate('authorization_paragraph_3')}</p>
                <p className='pb-2'>{translate('authorization_paragraph_4')}</p>
                <p className='pb-2'>{translate('authorization_paragraph_5')}</p>
                <p className='pb-2'>
                    {translate('authorization_paragraph_6')}
                    <a
                        className='linkStyle'
                        href='https://www.aepd.es/es'
                        onClick={openExtLink}
                    >
                        www.aepd.es
                    </a>
                    .
                </p>
                <p className='pb-2'>{translate('authorization_paragraph_7')}</p>
            </div>
            <div className='faq mx-auto mb-4 pb-4 d-flex justify-content-between'>
                <a
                    href='https://www.fastpayhotels.com/'
                    className='btn btn-outline-success'
                >
                    {translate('cancel')}
                </a>
                <button
                    type='button'
                    className='btn btn-outline-success'
                    href='/job'
                    onClick={handleOnClick}
                >
                    {translate('accept')}
                </button>
            </div>
        </div>
    );
}
