import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { tabsData } from '../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import LoadingForButtons from './LoadingForButtons';

function GeneralButton(props) {
    const [isFetching, setIsFetching] = useState(false);
    const { url, type, text, history, form, match, disabled } = props;
    let { setFormClass, setCurrentPage } = props;
    // el botón está desabilitado cuando viene así de props, y cuando está mandando datos al servidor cuando son de tipo next/skip/finish
    const disabledButton =
        (isFetching && (type !== 'openStep' || type !== 'back')) || disabled;

    GeneralButton.defaultProps = {
        className: '',
        disabled: false,
    };

    const {
        setMessageBar,
        setVisibleMessageTypeahead,
        handleSave,
        progress,
        setProgress,
        hotelInfo,
        saveActualProgress,
        singleRoomData,
    } = useContext(ContextOwnerPortal);

    const { currentTab, lastTabFilled, stepName, tabName, currentSubTab } =
        progress.current;
    const { Descriptions } = hotelInfo.SupplierHotelInfo;

    if (!setFormClass) {
        setFormClass = () => {};
    }

    const goForwardGeneral = () => {
        saveActualProgress();
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab + 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab + 1
                ).title,
                lastTabFilled:
                    currentTab < lastTabFilled
                        ? lastTabFilled
                        : lastTabFilled + 1,
            },
        });
    };

    const goBackwardGeneral = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab - 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab - 1
                ).title,
                currentSubTab: 1,
            },
        });
    };

    const goBackwardSubSteps = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentSubTab: currentSubTab - 1,
            },
        });
    };

    const handleDescriptionsValidation = async () => {
        const [englishDescription] = Descriptions.filter(
            desc => desc.IdContentLanguage === 2
        );

        const isFilled = englishDescription?.Description.length;

        if (isFilled) {
            setMessageBar({
                type: '',
                text: '',
            });
            setIsFetching(true);
            await handleSave();
            setIsFetching(false);
            goForwardGeneral();
        } else {
            setMessageBar({
                type: 'error',
                text: 'fill_descriptions',
            });
        }
    };

    const goBackListImages = () => {
        setCurrentPage('ImagesList');
    };

    const handleOnClick = async () => {
        let newFormClass = '';
        switch (type) {
            case 'next':
                switch (stepName) {
                    case 'description':
                        if (
                            tabName === 'Segments' ||
                            tabName === 'Meal Plans'
                        ) {
                            goForwardGeneral();
                        } else if (tabName === 'services') {
                            goForwardGeneral();
                        } else if (tabName === 'Descriptions') {
                            await handleDescriptionsValidation();
                        } else if (tabName === 'Kid policies') {
                            newFormClass =
                                'needs-validation your-properties-form was-validated';
                            setFormClass(newFormClass);
                            if (form?.current.checkValidity()) {
                                setVisibleMessageTypeahead(false);
                                goForwardGeneral();
                            } else {
                                setVisibleMessageTypeahead(true);
                            }
                        }
                        return;
                    case 'room_configuration':
                        if (tabName === 'Services') {
                            goForwardGeneral();
                        } else if (tabName === 'Occupancy') {
                            if (singleRoomData.Occupancy !== '') {
                                goForwardGeneral();
                            }
                        } else if (tabName === 'Beds') {
                            setIsFetching(true);
                            await handleSave();
                            setIsFetching(false);
                            goForwardGeneral();
                        } else {
                            goForwardGeneral();
                        }
                        return;
                    default:
                        setIsFetching(true);
                        await handleSave();
                        setIsFetching(false);
                        goForwardGeneral();
                        return;
                }
            case 'skip':
                switch (stepName) {
                    default:
                        goForwardGeneral();
                }
                break;
            case 'back':
                switch (tabName) {
                    case 'Services':
                        if (currentSubTab === 1) {
                            goBackwardGeneral();
                        } else {
                            goBackwardSubSteps();
                        }
                        return;
                    default:
                        goBackwardGeneral();
                        return;
                }
            case 'submit':
                switch (stepName) {
                    case 'room_configuration':
                        setIsFetching(true);
                        await handleSave();
                        setIsFetching(false);
                        history.push(
                            `/your-property/${match.params.idEncode}/inventory`
                        );
                        return;
                    default:
                        setIsFetching(true);
                        await handleSave();
                        setIsFetching(false);
                        history.push(`/your-property/${match.params.idEncode}`);
                        return;
                }

            case 'openStep':
                history.push(url);
                return;
            case 'goBackListImages':
                goBackListImages();
                return;
            default:
                goForwardGeneral();
        }
    };

    return (
        <Button
            type='button'
            disabled={disabledButton}
            onClick={handleOnClick}
            variant='contained'
        >
            {isFetching && (type !== 'openStep' || type !== 'back') ? (
                <LoadingForButtons />
            ) : (
                text
            )}
        </Button>
    );
}

export default withRouter(GeneralButton);
