import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import '../../stylesheets/stylesComponents/card.scss';
import useTranslate from '../../customHooks/useTranslate';

const IconAndTextComponent = ({
    icon,
    text,
    description,
    fontWeight = 400,
}) => {
    return (
        <div className='icon-and-text__container'>
            <Tooltip title={useTranslate(description)} placement='top' arrow>
                <i className={`${icon} description__icon`} />
            </Tooltip>
            <Tooltip title={useTranslate(description)} placement='top' arrow>
                <Typography sx={{ fontSize: '16px', fontWeight: fontWeight }}>
                    {text || '-----'}
                </Typography>
            </Tooltip>
        </div>
    );
};

export default IconAndTextComponent;
