import React, { useContext } from 'react';
import ButtonClose from '../../../common/components/buttons/ButtonClose';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import { zip } from '../../../utils/variablesEntornoExcel';
import '../../../stylesheets/stylesLayout/yourProperties.scss';

const PropertiesMessageInfo = props => {
    const { translate } = useContext(ContextUserProfile);

    const { setIsVisible } = props;

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        <div className='properties__container__info'>
            <ButtonClose
                handleClose={handleClose}
                top='5px'
                right='5px'
                size='small'
                color={theme => theme.palette.grey[600]}
            />
            <p>
                {translate('massive_loading_instructions')}
                <a href={zip} className='linkStye'>
                    {translate('these')}
                </a>
                {translate('massive_loading_instructions_2')}
                <a href={zip} className='linkStye'>
                    {translate('click_here_download')}
                </a>
            </p>
        </div>
    );
};

export default PropertiesMessageInfo;
