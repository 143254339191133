import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import { ProviderOwnerPortal } from './ContextOwnerPortal';
import ProtectedRoute from '../../utils/ProtectedRoute';
import YourProperties from './yourProperties/YourProperties';
import OwnerPortalLanding from './OwnerPortalLanding';
import '../../stylesheets/stylesLayout/private.scss';
import AriPage from '../formsProperty/Ari/AriPage';
import { ContextGlobalFeatures } from '../../contexts/GlobalFeatures';
import BunnyLoading from '../../common/components/BunnyLoading';
import TermsAndConditionsPopUp from '../../popups/TermsAndConditionsPopUp';
import routes from '../../utils/commonVariables/routes';
import OwnerPortalContainer from '../../layout/ownerPortalContainer/OwnerPortalContainer';
import AccountContainer from '../../accountSection/AccountContainer';
import Bookings from './yourBookings/Bookings';
import { ProviderTermsAndConditions } from '../../contexts/TermsAndConditionsContext';

function OwnerPortalContainerMain(props) {
    const { match } = props;

    const { isLoadingInterface } = useContext(ContextGlobalFeatures);

    return isLoadingInterface ? (
        <BunnyLoading />
    ) : (
        <ProviderOwnerPortal>
            <Switch>
                <ProtectedRoute
                    path={`${match.path}/your-properties`}
                    render={() => (
                        <OwnerPortalContainer>
                            <YourProperties />
                        </OwnerPortalContainer>
                    )}
                />
                <ProtectedRoute
                    path={`${match.path}/your-bookings`}
                    render={props => (
                        <OwnerPortalContainer>
                            <Bookings {...props} />
                        </OwnerPortalContainer>
                    )}
                />
                <ProtectedRoute
                    exact
                    path={`${match.path}/ari`}
                    render={() => (
                        <OwnerPortalContainer>
                            <AriPage />
                        </OwnerPortalContainer>
                    )}
                />
                <ProtectedRoute
                    path={match.path + routes.accountSection}
                    render={routerProps => (
                        <AccountContainer {...routerProps} />
                    )}
                />
                <ProtectedRoute
                    exact
                    path={`${match.path}`}
                    render={() => (
                        <OwnerPortalContainer>
                            <OwnerPortalLanding />
                        </OwnerPortalContainer>
                    )}
                />
            </Switch>
            <ProviderTermsAndConditions>
                <TermsAndConditionsPopUp />
            </ProviderTermsAndConditions>
        </ProviderOwnerPortal>
    );
}

export default OwnerPortalContainerMain;
