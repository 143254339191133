import React, { useContext } from 'react';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import GeneralButton from '../../components/GeneralButton';

const Card2 = props => {
    const { coverPageInfo } = useContext(ContextOwnerPortal);
    const { Services, AdultsOnly, Description } = coverPageInfo;
    const { translate } = useContext(ContextUserProfile);
    const { Porcentage, textButton, urlButton, disabled } = props;
    return (
        <div className='card-steps shadow'>
            <div className='card-steps-services'>
                <div className='iconNtext__container'>
                    <i
                        className='fas fa-concierge-bell iconNtext__icon'
                        style={{ fontSize: '20px' }}
                    />
                    <p className={`iconNtext__text-sm `}>
                        {Services} {translate('services_selected')}
                    </p>
                </div>
                <div className='iconNtext__container'>
                    <i
                        className={`fas ${
                            AdultsOnly ? 'fa-male' : 'fa-child'
                        } iconNtext__icon`}
                        style={{ fontSize: '20px' }}
                    />
                    <p className={`iconNtext__text-sm`}>
                        {AdultsOnly
                            ? translate('adults_only')
                            : translate('kids_welcome')}
                    </p>
                </div>
            </div>
            <p className={`card-text mt-4`}>
                {Description || translate('not_descriptions')}
            </p>
            <div className='card-steps__footer mt-4'>
                <div
                    className='progress mt-2 mb-3'
                    style={{ height: '7px', width: '30%' }}
                >
                    <div
                        className='progress-bar progress-bar bg-success '
                        style={{ width: `${Porcentage}%` }}
                        aria-valuenow={Porcentage}
                        aria-valuemin='0'
                        aria-valuemax='100'
                    />
                </div>
                <GeneralButton
                    type='openStep'
                    text={textButton}
                    url={urlButton}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default Card2;
