import React from 'react';

const IconAdult = ({ ...restProps }) => {
    return (
        <i
            className='fas fa-male'
            style={{
                fontSize: 22,
                lineHeight: '22px',
                color: 'rgb(1, 87, 44)',
                display: 'flex',
                justifyContent: 'center',
                width: 15,
            }}
            {...restProps}
        />
    );
};

export default IconAdult;
