import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { regexEmail } from '../../../utils/regex';
import convertToOvariant from '../../../utils/convertToOvariant';
import api from '../../../services/api';
import useTranslate from '../../../customHooks/useTranslate';
import { savableEntities } from '../../../utils/commonVariables/entityKeys';
import { publicEndpoints } from '../../../utils/commonVariables/endpoints';

export default function FormForgotPassword() {
    const [inputEmail, setInputEmail] = useState({ email: '' });
    const { email } = inputEmail;
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(false);
    const [sendedEmail, setSendedEmail] = useState(false);

    const handleChange = event => {
        const { value, name } = event.currentTarget;
        setInputEmail({
            ...inputEmail,
            [name]: value,
        });
    };

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            e.persist();
            setValidated(true);
            if (e.currentTarget.checkValidity()) {
                const emailToSend = { Email: email };
                const objectToSend = convertToOvariant(
                    savableEntities.SystemUser,
                    emailToSend
                );
                try {
                    const endpoint = publicEndpoints.forgotPassword;
                    const res = await api.post(endpoint, objectToSend);
                    if (res.status === 200) {
                        setSendedEmail(true);
                    }
                    if (res.status !== 200) {
                        setError(true);
                    }
                } catch {
                    setError(true);
                }
            } else {
                setSendedEmail(false);
            }
        } catch {
            setError(true);
        }
    };

    const sendEmailText = useTranslate('sended_email');
    const somethingWrongText = useTranslate('something_went_wrong');

    return (
        <div className='container-login d-flex justify-content-center align-items-center my-auto'>
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className='formLogin'
            >
                <p className='text-center mb-4'>{`${useTranslate(
                    'forgot_password_text'
                )}`}</p>
                <FormControl
                    className='main-input'
                    type='email'
                    name='email'
                    placeholder='email'
                    required
                    value={email}
                    onChange={handleChange}
                    pattern={regexEmail}
                />
                <FormControl.Feedback type='invalid'>
                    {useTranslate('invalid')}
                </FormControl.Feedback>
                <div className='btn-login'>
                    <Button
                        type='submit'
                        className='button-submit'
                    >{`${useTranslate('send')}`}</Button>
                </div>
                {sendedEmail && (
                    <div className='w-100 text-center'>
                        <p>{`${sendEmailText}`}</p>
                    </div>
                )}
                {error && (
                    <div className='w-100 text-center'>
                        <p className='text-danger'>{`${somethingWrongText}`}</p>
                    </div>
                )}
            </Form>
        </div>
    );
}
