import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import greenRabit from '../../../images/green-rabitt.png';
import { ContextOwnerPortal } from '../ContextOwnerPortal';
import { ContextUserProfile } from '../../../contexts/UserProfileContext';
import Tabs from '../components/Tabs';
import encodeBase64 from '../../../utils/encodeBase64';
import LoadingForButtons from '../components/LoadingForButtons';

function HeaderYourProperty(props) {
    const { translate } = useContext(ContextUserProfile);
    const { hotelName, step, history } = props;
    const { handleSave, progress, IdHotel, singleRoomData } =
        useContext(ContextOwnerPortal);
    const { stepName, currentTab, maxTabs, tabName } = progress.current;
    const { percent } = progress.prefilledProgress.main_info;
    const [visibleSaveAndExitButton, setVisibleSaveAndExitButton] =
        useState(false);
    const [isFetchingSave, setIsFetchingSave] = useState(false);

    const IdRoom = singleRoomData?.Id;

    function handleButton() {
        //para quitar el botón en el paso your-properties
        if (stepName === '') {
            setVisibleSaveAndExitButton(false);
        }
        //para quitar el botón en el paso room_configuration pero sin estar en el detalle de ninguna room
        else if (stepName === 'room_configuration' && tabName === '') {
            setVisibleSaveAndExitButton(false);
        } else if (stepName === 'main_info' && percent === 100) {
            setVisibleSaveAndExitButton(true);
        } else if (stepName === 'main_info' && currentTab === maxTabs) {
            setVisibleSaveAndExitButton(true);
        } else if (
            stepName === 'room_configuration' &&
            tabName === 'Main room information'
        ) {
            setVisibleSaveAndExitButton(false);
        } else if (stepName === 'room_configuration' && !tabName) {
            setVisibleSaveAndExitButton(true);
        } else if (stepName && stepName !== 'main_info') {
            setVisibleSaveAndExitButton(true);
        } else if (!stepName) {
            setVisibleSaveAndExitButton(true);
        } else {
            setVisibleSaveAndExitButton(false);
        }
    }

    function comeBack() {
        const location = history.location.pathname;

        if (
            location ===
            `/your-property/${encodeBase64(IdHotel)}/inventory/${encodeBase64(
                IdRoom
            )}`
        ) {
            history.push(`/your-property/${encodeBase64(IdHotel)}/inventory`);
        } else if (location === `/your-property/${encodeBase64(IdHotel)}`) {
            history.push('/owner-portal/your-properties');
        } else {
            history.push(`/your-property/${encodeBase64(IdHotel)}`);
        }
    }

    useEffect(() => {
        handleButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabName, stepName]);

    return (
        <header
            style={{ position: 'sticky', top: 0, width: '100%', zIndex: 2 }}
        >
            <div className='d-flex w-100 your_property-header-container justify-content-between'>
                <div className='d-flex'>
                    <a
                        href='/owner-portal/your-properties'
                        className='navbar-brand mx-4'
                    >
                        <img
                            src={greenRabit}
                            className='your_property-header-image'
                            alt='fast pay hotel'
                        />
                    </a>
                    <div className='your_property-header-title'>
                        <h4 className='align-self-center text_title-header'>
                            {hotelName}{' '}
                            {step && (
                                <span className='font-weight-bold'>{step}</span>
                            )}
                        </h4>
                    </div>
                </div>
                <div className='align-self-center mr-4'>
                    {visibleSaveAndExitButton && (
                        <button
                            type='button'
                            className='btn btn-outline-success '
                            onClick={async () => {
                                setIsFetchingSave(true);
                                await handleSave();
                                comeBack();
                                setIsFetchingSave(false);
                            }}
                        >
                            {isFetchingSave ? (
                                <LoadingForButtons />
                            ) : (
                                translate('save_and_exit')
                            )}
                        </button>
                    )}
                </div>
            </div>
            {tabName && <Tabs />}
        </header>
    );
}

export default withRouter(HeaderYourProperty);
