import React, { createContext, useState, useEffect } from 'react';
import { getYearPeriod, getMonthPeriod } from '../utils/formatDates';
import {
    formatCancelledAndCommited,
    formatMostBookedHotelsData,
} from '../utils/usefulFunctions';
import endpoints from '../utils/commonVariables/endpoints';
import api from '../services/api';
import { INITIAL_STATE_BOOKING_HISTORY } from '../utils/bookingChartsUtils';

export const ContextLanding = createContext();

export const ProviderLanding = props => {
    const { children } = props;
    const [historyBookingData, setHistoryBookingData] = useState([]);
    const [mostBookedHotels, setMostBookedHotels] = useState([]);
    const [isLoadingHistoryBookingData, setLoadingHistoryBookingData] =
        useState(true);
    const [isLoadingMostBookedHotels, setLoadingMostBookedHotels] =
        useState(true);

    const getBookingChartData = async () => {
        const period = getYearPeriod();
        const endpoint = endpoints.bookingStats(period.start, period.end);
        try {
            const { data } = await api.get(endpoint);
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    const getMostBookedHotels = async () => {
        const period = getMonthPeriod();
        const endpoint = endpoints.mostBookedHotels(period.start, period.end);
        try {
            const { data } = await api.get(endpoint);
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setLoadingHistoryBookingData(true);
        setLoadingMostBookedHotels(true);
        const bookingChart = async () => {
            return await getBookingChartData();
        };
        const hotelChart = async () => {
            return await getMostBookedHotels();
        };

        bookingChart()
            .then(data =>
                formatCancelledAndCommited(data, INITIAL_STATE_BOOKING_HISTORY)
            )
            .then(formatedData => {
                setHistoryBookingData(formatedData);
                setLoadingHistoryBookingData(false);
            });

        hotelChart()
            .then(data => formatMostBookedHotelsData(data))
            //dejo esto comentado para hacer pruebas sin llamar a la api
            // .then(data =>
            //     formatMostBookedHotelsData([
            //         {
            //             BookingCount: 51,
            //             IdHotel: 794232,
            //             HotelName: 'Las Vegas Hilton at Resorts World',
            //         },
            //         {
            //             BookingCount: 48,
            //             IdHotel: 794283,
            //             HotelName: 'Hilton Singapore Orchard',
            //         },
            //         {
            //             BookingCount: 45,
            //             IdHotel: 370172,
            //             HotelName: 'Hampton By Hilton London Gatwick Airport',
            //         },
            //     ])
            // )
            .then(data => {
                setMostBookedHotels(data);
                setLoadingMostBookedHotels(false);
            });
    }, []);

    return (
        <ContextLanding.Provider
            value={{
                historyBookingData,
                mostBookedHotels,
                isLoadingHistoryBookingData,
                isLoadingMostBookedHotels,
            }}
        >
            {children}
        </ContextLanding.Provider>
    );
};
