import React, { useContext, useState } from 'react';
import '../../../../stylesheets/stylesLayout/yourProperties.scss';
import { ContextOwnerPortal } from '../../ContextOwnerPortal';
import { ContextUserProfile } from '../../../../contexts/UserProfileContext';
import TextInput from '../../../../muiComponents/TextInput/TextInputV2';
import { getValueFromObjetByComposedKey } from '../../../../utils/usefulFunctions';
import Button from '@mui/material/Button';
import { validateForm } from '../../../../utils/validateForms';
import { tabsData } from '../../../../utils/OwnerPortalAuxiliaryJsons/OwnerPortalTabs';
import LoadedOptionsTypeaheadV2 from '../../../../muiComponents/LoadedOptionsTypeaheadV2';
import { readOnlyEntities } from '../../../../utils/commonVariables/entityKeys';
import useOdataGet from '../../../../customHooks/useOdataGet';

export default function Commercial() {
    const { translate } = useContext(ContextUserProfile);
    const {
        hotelInfo,
        handleInputsHotelInfo,
        setProgress,
        progress,
        saveActualProgress,
    } = useContext(ContextOwnerPortal);
    const { currentTab, lastTabFilled, stepName } = progress.current;
    const { Primary } = hotelInfo.SupplierHotelInfo;
    const [isValidated, setIsValidated] = useState(false);
    const [errors, setErrors] = useState({});

    const countriesEnpoint = {
        entity: readOnlyEntities.TimeZoneUtil,
        orderBy: 'DisplayName asc',
    };

    const timezoneRequest = useOdataGet(countriesEnpoint, true);

    const getValue = (content, name) => {
        const savedValue = getValueFromObjetByComposedKey(content, name);
        const storedInfo = JSON.parse(localStorage.getItem('hotelInfo'));
        const storedValue = getValueFromObjetByComposedKey(storedInfo, name);
        const value = storedValue || savedValue;
        return value;
    };

    const fieldsToValidate = {
        'MasterHotel.IdTimeZone': {
            value: hotelInfo.MasterHotel.IdTimeZone,
            validators: ['requiredIdEntity'],
        },
    };

    const checkValidate = async () => {
        setIsValidated(true);
        const validationErrors = validateForm(fieldsToValidate);
        let newErrors = {};
        for (let newError of validationErrors) {
            newErrors[newError.inputKey] = newError.errorMessage;
        }
        setErrors(newErrors);
        const isValid = validationErrors.length === 0;
        if (isValid) {
            saveActualProgress();
            setProgress({
                ...progress,
                current: {
                    ...progress.current,
                    currentTab: currentTab + 1,
                    tabName: tabsData[stepName].tabs.find(
                        item => item.tabNumber === currentTab + 1
                    ).title,
                    lastTabFilled:
                        currentTab < lastTabFilled
                            ? lastTabFilled
                            : lastTabFilled + 1,
                },
            });
        }
    };

    const handleValidate = () => {
        if (isValidated) {
            const validationErrors = validateForm(fieldsToValidate);
            let newErrors = {};
            for (let newError of validationErrors) {
                newErrors[newError.inputKey] = newError.errorMessage;
            }
            setErrors(newErrors);
        }
    };

    const goBack = () => {
        setProgress({
            ...progress,
            current: {
                ...progress.current,
                currentTab: currentTab - 1,
                tabName: tabsData[stepName].tabs.find(
                    item => item.tabNumber === currentTab - 1
                ).title,
                currentSubTab: 1,
            },
        });
    };

    const isInputDisabled = !Primary;

    const variant = isInputDisabled ? 'filled' : 'outlined';

    const autocompleteInputProps = {
        sx: {
            '& .MuiOutlinedInput-root': {
                height: '50px',
                fontSize: '1.2rem',
            },
            '& .MuiFilledInput-root': {
                height: '50px',
                fontSize: '1.2rem',
            },
            '& .MuiInputLabel-shrink': {
                top: '0 !important',
            },
            '& .MuiInputLabel-outlined': {
                fontSize: '1.2rem',
                // top: '-5px',
            },
            '& .MuiInputLabel-filled': {
                fontSize: '1.2rem',
                top: '-5px',
            },
        },
        variant: isInputDisabled ? 'filled' : 'outlined',
    };

    const marginBottom = { marginBottom: '30px' };

    return (
        <div className='your-properties-formV2'>
            <TextInput
                labelText='nearest_airport_code'
                handleChange={handleInputsHotelInfo}
                value={getValue(hotelInfo, 'MasterHotel.AirportCode')}
                name='MasterHotel.AirportCode'
                type='text'
                id='MasterHotel.AirportCode'
                disabled={isInputDisabled}
                variant={variant}
                style={marginBottom}
            />
            <LoadedOptionsTypeaheadV2
                labelText='time_zone'
                options={timezoneRequest.options}
                handleChange={(e, newValue) => {
                    handleInputsHotelInfo({
                        target: {
                            name: 'MasterHotel.IdTimeZone',
                            value: newValue ? newValue.Id : null,
                        },
                    });
                }}
                idValue={getValue(hotelInfo, 'MasterHotel.IdTimeZone')}
                onBlur={handleValidate}
                errorMessage={errors['MasterHotel.IdTimeZone']}
                required
                disabled={isInputDisabled}
                error={errors['MasterHotel.IdTimeZone'] ? true : undefined}
                inputProps={autocompleteInputProps}
                name='MasterHotel.IdTimeZone'
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    variant='contained'
                    onClick={goBack}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('back')}
                </Button>
                <Button
                    variant='contained'
                    onClick={checkValidate}
                    style={{ alignSelf: 'flex-end', marginTop: '30px' }}
                >
                    {translate('continue')}
                </Button>
            </div>
        </div>
    );
}
