import React, { createContext, useState } from 'react';

export const ContextGlobalFeatures = createContext();

export const ProviderGlobalFeatures = props => {
    const { children } = props;

    const [isLoadingInterface, setIsLoadingInterface] = useState(false);
    const [isMuggle, setIsMuggle] = useState(false);

    return (
        <ContextGlobalFeatures.Provider
            value={{
                isLoadingInterface,
                setIsLoadingInterface,
                isMuggle,
                setIsMuggle,
            }}
        >
            {children}
        </ContextGlobalFeatures.Provider>
    );
};
