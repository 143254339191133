import React from 'react';
import SocialLinks from './SocialLinks';
import FooterNav from './FooterNav';
import '../../stylesheets/stylesLayout/footer.scss';

const Footer = ({ isMuggle }) => {
    // por ejemplo /jobs se ve desde fastpayhotels.com y sólo se tienen que ver los links a redes sociales pero no los de contact us
    const muggle = isMuggle
        ? 'justify-content-center'
        : 'justify-content-between';

    const isVisible = isMuggle ? false : true;

    return (
        <footer className={`footer ${muggle}`}>
            {isVisible && <FooterNav />}
            <SocialLinks />
        </footer>
    );
};

export default Footer;
